import Dexie from 'dexie';
import { mapState, mapMutations } from 'vuex';
import { initializeFirebase, getFCMToken, deleteToken } from '@/firebase';
import { getApiUri } from '../api/util';
import api from '../api/api';

const generalMixin = {
  computed: {
    ...mapState('general', [
      'site',
    ]),
  },
  methods: {
    ...mapMutations({
      setMessageReceivedVisible: 'general/setMessageReceivedVisible',
      setMessageReceived: 'general/setMessageReceived',
    }),
    /*
                 * Get component by ref
                 */
    getRef(refName) {
      // const app = window.app._instance;
      const refs = window.vueApp.$refs;
      const findRef = (refName, refs) => {
        for (const ref in refs) {
          if (ref == refName) return refs[ref];
          if (refs[ref] != null) {
            const childRef = findRef(refName, refs[ref].$refs);
            if (childRef != null) return childRef;
          }
        }
        return null;
      };
      for (const ref in refs) {
        if (ref == refName) return refs[ref];
        if (refs[ref] != null) {
          const childRef = findRef(refName, refs[ref].$refs);
          if (childRef != null) return childRef;
        }
      }
      return null;
    },
    /**
                 * Request notification permission
                 */
    async requestPermission() {
      if (!process.env.VUE_APP_FIREBASE_FILE_NAME || !process.env.VUE_APP_FIREBASE_VAPID_KEY || !process.env.VUE_APP_FIREBASE_PROJECT_ID) {
        console.error('Firebase configuration is missing');
        return;
      }
      try {
        const registration = await navigator.serviceWorker.register(`/firebase/${process.env.VUE_APP_FIREBASE_FILE_NAME}`);
        const messaging = await initializeFirebase();
        const currentToken = await getFCMToken(messaging, { vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY, serviceWorkerRegistration: registration });
        this.setSettingsParam('profile', this.site);

        if (!currentToken) {
          console.log('No registration token available. Request permission to generate one.');
        } else {
          // eslint-disable-next-line no-underscore-dangle
          window._mtm.push({
            event: 'comshiva',
            eventcategory: 'ComShiVa',
            eventAction: 'Suscribe notification',
            eventname: `Suscribe notification firebase currentToken: ${currentToken} site: ${this.site} hours: ${new Date().getHours()}:${new Date().getMinutes()}`,
          });
          this.setServicesTokenFirebase(currentToken);
        }
      } catch (error) {
        console.error('Error getting permission or registering service worker', error);
      }
    },
    setServicesTokenFirebase(currentToken) {
      api.get(`${getApiUri()}services/subscribe_user/?registration_token=${currentToken}&topic=${this.site}&project_id=${process.env.VUE_APP_FIREBASE_PROJECT_ID}`).then().catch((error) => {
        console.error('Error setting token', error);
      });
    },
    serviceWorkerUnsubscribe(currentToken) {
      api.get(`${getApiUri()}services/unsubscribe_user/?registration_token=${currentToken}&topic=${this.site}&project_id=${process.env.VUE_APP_FIREBASE_PROJECT_ID}`).then().catch((error) => {
        console.error('Error setting token', error);
      });
    },
    async deleteSuscriptionFirebase() {
      try {
        const registration = await navigator.serviceWorker.register(`/firebase/${process.env.VUE_APP_FIREBASE_FILE_NAME}`);
        const messaging = await initializeFirebase();
        const currentToken = await getFCMToken(messaging, { vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY, serviceWorkerRegistration: registration });

        deleteToken(messaging).then(() => {
          localStorage.removeItem('notificationWarningViewed');
          registration.unregister().then(() => {
            console.log('Service worker unregistered');
          });
          // eslint-disable-next-line no-underscore-dangle
          window._mtm.push({
            event: 'comshiva',
            eventcategory: 'ComShiVa',
            eventAction: 'Delete suscribe notification',
            eventname: `Delete suscribe notification firebase currentToken: ${currentToken} site: ${this.site} hours: ${new Date().getHours()}:${new Date().getMinutes()}`,
          });
          const notification = {
            title: this.$t('notice'),
            body: this.$t('disabled_notifications'),
          };
          this.setMessageReceived(notification);
          this.setMessageReceivedVisible(true);
          this.serviceWorkerUnsubscribe(currentToken);
        }).catch((error) => {
          console.error('Error deleting token', error);
        });
      } catch (error) {
        console.error('Error deleting token', error);
      }
    },
    /*
                 * Decode Google Maps encoded polyline
                 */
    decodePolyline(encoded) {
      if (!encoded) {
        return [];
      }
      var poly = [];
      var index = 0; var
        len = encoded.length;
      var lat = 0; var
        lng = 0;

      while (index < len) {
        var b; var shift = 0; var
          result = 0;

        do {
          b = encoded.charCodeAt(index++) - 63;
          // eslint-disable-next-line no-bitwise
          result |= ((b & 0x1f) << shift);
          shift += 5;
        } while (b >= 0x20);

        // eslint-disable-next-line no-bitwise
        var dlat = (result & 1) != 0 ? ~(result >> 1) : (result >> 1);
        lat += dlat;

        shift = 0;
        result = 0;

        do {
          b = encoded.charCodeAt(index++) - 63;
          // eslint-disable-next-line no-bitwise
          result |= ((b & 0x1f) << shift);
          shift += 5;
        } while (b >= 0x20);

        // eslint-disable-next-line no-bitwise
        var dlng = (result & 1) != 0 ? ~(result >> 1) : (result >> 1);
        lng += dlng;

        var p = {
          latitude: lat / 1e5,
          longitude: lng / 1e5,
        };
        poly.push(p);
      }
      return poly;
    },
    /*
                 * get settings param of db
                 */
    getSettingsParam(param) {
      try {
        const db = new Dexie('bcn');
        db.version(1).stores({
          settings: 'key,value',
        });
        return db.settings.get(param).then((result) => {
          return result.value;
        });
      } catch (error) {
        console.error('Error getting settings param', error);
      }
    },
    /*
                 * set settings param of db
                 */
    setSettingsParam(param, value) {
      try {
        const db = new Dexie('bcn');
        db.version(1).stores({
          settings: 'key,value',
        });
        // si el parámetro ya existe, lo actualizamos en la base de datos
        db.settings.get(param).then((result) => {
          if (result) {
            const valueArray = result.value.split(',');
            if (!valueArray.includes(value)) {
              // concatenamos el valor con el valor actual separado por una coma
              db.settings.update(param, { value: `${result.value},${value}` });
            }
          } else {
            db.settings.add({ key: param, value });
          }
        });
      } catch (error) {
        console.error('Error setting settings param', error);
      }
    },
    /*
                 * format notification message
                 */
    async notificationTextLocalization(notification) {
      const titleParts = notification.title.split('|');
      const bodyParts = notification.body.split('|');
      const profilePush = titleParts[0];

      let profile;
      await this.getSettingsParam('profile').then((result) => {
        profile = result.includes(',') ? result.split(',') : [result];
      });

      if (!profile.includes(profilePush) && profilePush !== this.site) return;

      let lang;
      await this.getSettingsParam('lang').then((result) => {
        lang = result;
      });

      const notificationTranslation = {
        title: '',
        body: '',
      };

      for (let i = 0; i < bodyParts.length; i++) {
        const [languageCode, text] = bodyParts[i].split(':');
        if (languageCode === lang) {
          notificationTranslation.body = text;
          break;
        }
      }

      for (let i = 1; i < titleParts.length; i++) {
        const [languageCode, text] = titleParts[i].split(':');
        if (languageCode === lang) {
          notificationTranslation.title = text;
          break;
        }
      }

      return notificationTranslation;
    },
  },
};

export default generalMixin;

export default [
  {
    order: 0,
    grupo: 'walk',
    modo: 'WALKING',
    code: 'walk',
    translations: 'walkTranslations',
    icon: 'csvicon-walk',
    select_all: {
      type: 'general',
      text: 'all_foot',
    },
    options: [
      {
        id: 1031,
        nombre: 'pedestrian_zones',
        code: 'zonaspeatonales',
        url: 'zonesvianants/',
        layerIcon: 'zonesvianants.svg',
        layerColorBackground: '#4285F4',
        minZoomLevel: 10,
        selected: false,
        style: {
          strokeColor: '#4285F4',
          strokeOpacity: 1,
          strokeWeight: 1,
          fillColor: '#4285F4',
        },
      },
      {
        id: 1039,
        nombre: 'carrers_30',
        code: 'carrers30_p',
        url: 'carrers30/',
        layerIcon: 'carrers30.svg',
        layerColorBackground: '#2B7F9F',
        minZoomLevel: 10,
        selected: false,
        style: {
          strokeColor: '#2B7F9F',
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: '#2B7F9F',
        },
      },
      {
        id: 1040,
        nombre: 'pedestrian_priority_streets',
        code: 'carrersprioritatvianants_p',
        url: 'carrersprioritatvianants/',
        layerIcon: 'carrersprioritatvianants.svg',
        layerColorBackground: '#3BA935',
        minZoomLevel: 10,
        selected: false,
        style: {
          strokeColor: '#3BA935',
          strokeOpacity: 1,
          strokeWeight: 2,
        },
      },
      {
        id: 1041,
        nombre: 'streets_without_motorized_vehicles',
        code: 'carrerssensemotoritzats_p',
        url: 'carrerssensemotoritzats/',
        layerIcon: 'carrerssensemotoritzats.svg',
        layerColorBackground: '#9AC31C',
        minZoomLevel: 10,
        selected: false,
        style: {
          strokeColor: '#9AC31C',
          strokeOpacity: 1,
          strokeWeight: 2,
        },
      },
      {
        id: 1032,
        nombre: 'escalators',
        code: 'escalerasmecanicas',
        url: 'escalesmecaniques/',
        layerIcon: 'escalesmecaniques.svg',
        layerColorBackground: '#de4797',
        maxZoomClusteringLevel: 16,
        clusterMarker: 'escales_mecaniques.svg',
        adaptativeMarkers: true,
        minZoomLevel: 10,
        iconFolder: 'apie',
        markerIcon: 'escales_mecaniques.svg',
        displayInfo: true,
        infoTitle: 'name',
        selected: false,
        info: [
          { label: 'address', value: 'address' },
          { label: 'district', value: 'district' },
          { label: 'url', value: 'code_url', type: 'url' },
        ],
      },
      {
        nombre: 'marathon',
        code: 'marathon',
        url: 'marato/',
        layerIcon: 'ico-marato.svg',
        layerColorBackground: '#103E8C',
        minZoomLevel: 10,
        selected: false,
        isWidget: 'zurich-marato',
        style: {
          strokeColor: '#103E8C',
          strokeOpacity: 5,
          strokeWeight: 5,
        },
        plus: [
          {
            nombre: 'point_marathon',
            code: 'point_marathon',
            url: 'maratopoints/',
            layerIcon: 'ico-marato.svg',
            layerColorBackground: '#de4797',
            maxZoomClusteringLevel: 16,
            clusterMarker: 'marker-marato.svg',
            adaptativeMarkers: true,
            minZoomLevel: 10,
            iconFolder: 'apie',
            markerIcon: 'marker-marato.svg',
            displayInfo: true,
            infoTitle: 'name',
            selected: false,
            info: [
              { label: 'Description', value: 'description' },
            ],
          },
        ],
      },
      // {
      //   nombre: 'traffic_calmed_streets',
      //   code: 'carrerspacificats',
      //   url: 'carrerspacificats/',
      //   minZoomLevel: 10,
      //   style: {
      //     strokeColor: '#741f7d',
      //     strokeOpacity: 1,
      //     strokeWeight: 2,
      //   },
      // },
      // {
      //   nombre: 'widened_pavements',
      //   code: 'ampliaciovoreres',
      //   url: 'ampliaciovoreres/',
      //   minZoomLevel: 10,
      //   style: {
      //     strokeColor: '#F08178',
      //     strokeOpacity: 1,
      //     strokeWeight: 2,
      //   },
      // },
      // {
      //   nombre: 'sides_roads_closed_traffic',
      //   code: 'lateralstallats',
      //   url: 'lateralstallats/',
      //   minZoomLevel: 10,
      //   style: {
      //     strokeColor: '#5B5B5B',
      //     strokeOpacity: 1,
      //     strokeWeight: 2,
      //   },
      // },
    ],
  },
  {
    order: 1,
    grupo: 'bici',
    code: 'bici',
    modo: 'BICYCLING',
    translations: 'biciTranslations',
    icon: 'csvicon-bici',
    select_all: {
      type: 'general',
      text: 'all_bici',
    },
    options: [
      {
        nombre: 'cycle_lanes',
        code: 'carrilbicigrupo',
        opened: false,
        options: [
          {
            id: 1020,
            nombre: 'cycle_lanes',
            code: 'carrilsbici',
            url: 'carrilbici/',
            layerIcon: 'carrilsbici.svg',
            layerColorBackground: '#D82323',
            minZoomLevel: 10,
            selected: false,
            synonymous: {
              ca: 'carril bici',
              es: 'carril bici',
              en: '',
            },
            style: {
              strokeColor: '#D82323',
              strokeOpacity: 0.8,
              strokeWeight: 2,
            },
          },
          {
            id: 1021,
            nombre: 'carrilsbicimunicipis',
            code: 'carrilsbicimunicipis',
            url: 'carrilsbicimunicipis/',
            layerIcon: 'carrilsbici.svg',
            layerColorBackground: '#DB9194',
            minZoomLevel: 10,
            selected: false,
            style: {
              strokeColor: '#DB9194',
              strokeOpacity: 0.8,
              strokeWeight: 2,
            },
          },
          {
            id: 1022,
            nombre: 'new_bicycle_lanes',
            code: 'corredorsbici',
            url: 'corredorsbici/',
            layerIcon: 'carrilsbici.svg',
            layerColorBackground: '#d49f07',
            minZoomLevel: 10,
            selected: false,
            style: {
              strokeColor: '#d49f07',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
          },
          {
            id: 1023,
            nombre: 'cycle_lanes_under_construction',
            code: 'carrilbiciconstruccion',
            url: 'carrilbiciconstruccio/',
            layerIcon: 'carrilbiciconstruccion.svg',
            layerColorBackground: '#D871BB',
            minZoomLevel: 10,
            selected: false,
            style: {
              strokeColor: '#D871BB',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
          },
          {
            id: 1036,
            nombre: 'carrers_30',
            code: 'carrers30_b',
            url: 'carrers30/',
            layerIcon: 'carrers30.svg',
            layerColorBackground: '#2B7F9F',
            minZoomLevel: 10,
            selected: false,
            style: {
              strokeColor: '#2B7F9F',
              strokeOpacity: 1,
              strokeWeight: 2,
              fillColor: '#2B7F9F',
            },
          },
          {
            id: 1037,
            nombre: 'pedestrian_priority_streets',
            code: 'carrersprioritatvianants_b',
            url: 'carrersprioritatvianants/',
            layerIcon: 'carrersprioritatvianants.svg',
            layerColorBackground: '#3BA935',
            minZoomLevel: 10,
            selected: false,
            style: {
              strokeColor: '#3BA935',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
          },
          {
            id: 1038,
            nombre: 'streets_without_motorized_vehicles',
            code: 'carrerssensemotoritzats_b',
            url: 'carrerssensemotoritzats/',
            layerIcon: 'carrerssensemotoritzats.svg',
            layerColorBackground: '#9AC31C',
            minZoomLevel: 10,
            selected: false,
            style: {
              strokeColor: '#9AC31C',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
          },
          {
            id: 1024,
            nombre: 'green_route',
            code: 'rondaverde',
            url: 'rondaverda/',
            layerIcon: 'rondaverda.svg',
            layerColorBackground: '#6C9F43',
            minZoomLevel: 10,
            selected: false,
            style: {
              strokeColor: '#6C9F43',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
          },
        ],
      },
      {
        nombre: 'car_parks',
        code: 'puntos_anclajebici',
        opened: false,
        options: [
          {
            id: 1025,
            nombre: 'bicycle_anchoring_points',
            code: 'puntosanclajebicis',
            url: 'puntsancoratgebici/',
            layerIcon: 'anclaje.svg',
            layerColorBackground: '#474242',
            maxZoomClusteringLevel: 16,
            clusterMarker: 'anclaje.svg',
            adaptativeMarkers: true,
            minZoomLevel: 10,
            selected: false,
            iconFolder: 'bicicleta',
            markerIcon: 'anclaje.svg',
            displayInfo: true,
            infoTitle: 'name',
            info: [
              { label: 'district', value: 'district' },
              { label: 'places', value: 'num_plazas' },
            ],
          },
          {
            id: 1026,
            nombre: 'car_parks_with_bicycle_places',
            code: 'aparcamientosserviciobicis',
            url: 'aparcamentsserveibicis/',
            layerIcon: 'parkingbici.svg',
            layerColorBackground: '#003b95',
            maxZoomClusteringLevel: 16,
            clusterMarker: 'parking_bici.svg',
            adaptativeMarkers: true,
            minZoomLevel: 10,
            selected: false,
            iconFolder: 'bicicleta',
            markerIcon: 'parking_bici.svg',
            displayInfo: true,
            infoTitle: 'name',
            info: [
              { label: 'address', value: 'address' },
              { label: 'district', value: 'district' },
              { label: 'phonenumber', value: 'phonenumber' },
              { label: 'url', value: 'code_url', type: 'url' },
            ],
          },
          {
            id: 1002,
            nombre: 'bike_parking_close',
            code: 'aparcamentbicicletescercanos',
            url: 'copaamericapoints?tipus=bosses_estacionaments_bicis',
            layerIcon: 'parkingbici.svg',
            layerColorBackground: '#003b95',
            maxZoomClusteringLevel: 16,
            clusterMarker: 'parking_bici.svg',
            adaptativeMarkers: true,
            minZoomLevel: 10,
            iconFolder: 'bicicleta',
            markerIcon: 'parking_bici.svg',
            displayInfo: true,
            infoTitle: 'nombre',
            info: [
              { label: 'description', value: 'description' },
              { label: 'capacity', value: 'capacity' },
            ],
          },
        ],
      },
      {
        id: 1027,
        nombre: 'Bicing',
        code: 'bicing',
        url: 'bicingelectric/',
        layerIcon: 'bicing.svg',
        layerColorBackground: '#ff0000',
        maxZoomClusteringLevel: 16,
        clusterMarker: 'bicing.svg',
        adaptativeMarkers: true,
        minZoomLevel: 10,
        selected: false,
        iconFolder: 'bicicleta',
        markerIcon: 'bicing.svg',
        displayInfo: true,
        infoTitle: 'street',
        info: [
          { label: 'free_bycicle_slots', value: 'slots' },
          { label: 'mechanical_bycicles', value: 'mechanicalBikes' },
          { label: 'electrical_bycicles', value: 'electricalBikes' },
        ],
      },
      {
        id: 1028,
        nombre: 'bicycle_hire',
        code: 'lloguerbici',
        url: 'lloguerbicis/',
        layerIcon: 'lloguerbici.svg',
        layerColorBackground: '#00ccec',
        maxZoomClusteringLevel: 16,
        clusterMarker: 'alquiler_bici.svg',
        adaptativeMarkers: true,
        minZoomLevel: 10,
        selected: false,
        iconFolder: 'bicicleta',
        markerIcon: 'alquiler_bici.svg',
        displayInfo: true,
        infoTitle: 'name',
        info: [
          { label: 'address', value: 'address' },
          { label: 'district', value: 'district' },
          { label: 'phonenumber', value: 'phonenumber' },
          { label: 'url', value: 'code_url', type: 'url' },
        ],
      },
      {
        id: 1029,
        nombre: 'bike_repairs',
        code: 'bicirepair',
        url: 'tallersbici/',
        adaptativeMarkers: true,
        layerIcon: 'tallersbici.svg',
        layerColorBackground: '#f7941d',
        maxZoomClusteringLevel: 16,
        clusterMarker: 'tallers_bici.svg',
        minZoomLevel: 10,
        selected: false,
        iconFolder: 'bicicleta',
        markerIcon: 'tallers_bici.svg',
        displayInfo: true,
        infoTitle: 'name',
        info: [
          { label: 'address', value: 'address' },
          { label: 'district', value: 'district' },
          { label: 'phonenumber', value: 'phonenumber' },
          { label: 'url', value: 'code_url', type: 'url' },
        ],
      },
      {
        id: 1030,
        nombre: 'bici_friends',
        code: 'bicifriends',
        url: 'bicifriends/',
        adaptativeMarkers: true,
        layerIcon: 'bicifriends.svg',
        layerColorBackground: '#00adee',
        maxZoomClusteringLevel: 16,
        clusterMarker: 'edifici_amicbici_certified.svg',
        minZoomLevel: 10,
        selected: false,
        iconFolder: 'bicicleta',
        markerIcon: 'edifici_amicbici_certified.svg',
        displayInfo: true,
        infoTitle: 'edifici',
        info: [
          { label: 'address', value: 'address' },
          { label: 'district', value: 'districte' },
        ],
      },
    ],
  },
  {
    order: 2,
    grupo: 'public_transport',
    translations: 'transportTranslations',
    code: 'transport',
    modo: 'TRANSIT',
    select_all: {
      type: 'transport',
      text: 'select_all',
    },
    options: [
      {
        nombre: 'metro',
        code: 'metro',
        select_all: {
          type: 'metro',
          text: 'see_all_metro_lines',
          selected: false,
        },
      },
      {
        nombre: 'bus',
        code: 'bus',
        select_all: {
          type: 'bus',
          text: 'see_all_bus_lines',
          selected: false,
        },
      },
      {
        nombre: 'fgc_trains',
        code: 'ferrocarril',
        select_all: {
          type: 'ferrocarril',
          text: 'see_all_fgc_train_lines',
          selected: false,
        },
      },
      {
        nombre: 'local_trains',
        code: 'rodalies',
        select_all: {
          type: 'rodalies',
          text: 'see_all_local_train_lines',
          selected: false,
        },
      },
      {
        nombre: 'trams',
        code: 'tram',
        select_all: {
          type: 'tram',
          text: 'see_all_trams_lines',
          selected: false,
        },
      },
      {
        nombre: 'aerobus',
        code: 'aerobus',
        selected: false,
      },
      {
        nombre: 'funicular',
        code: 'funicular',
        selected: false,
      },
      {
        id: 1033,
        nombre: 'Taxi',
        code: 'taxi',
        url: 'paradestaxi/',
        icon: 'csvicon-car',
        maxZoomClusteringLevel: 16,
        layerColorBackground: '#ffcf00',
        clusterMarker: 'taxi.svg',
        adaptativeMarkers: true,
        layerIcon: 'taxi.svg',
        minZoomLevel: 10,
        iconFolder: 'publico',
        markerIcon: 'taxi.svg',
        displayInfo: true,
        infoTitle: 'name',
        selected: false,
        synonymous: {
          ca: 'taxis',
          es: 'taxis',
          en: 'taxis',
        },
        info: [
          { label: 'address', value: 'address' },
          { label: 'district', value: 'district' },
          { label: 'phonenumber', value: 'phonenumber' },
          { label: 'url', value: 'code_url', type: 'url' },
        ],
      },
      {
        id: 1103,
        nombre: 'mar_bus',
        code: 'marbus',
        url: 'copaamericapoints/?tipus=busmar',
        layerColorBackground: '#377d22',
        layerIcon: 'busnautico.svg',
        adaptativeMarkers: true,
        minZoomLevel: 10,
        iconFolder: 'other',
        markerIcon: 'busnautico.svg',
        displayInfo: true,
        infoTitleTxt: 'mar_bus',
        selected: false,
        info: [
          { label: 'stop', value: 'nombre' },
          { label: 'schedule', value: 'schedule' },
          { label: 'frequency', value: 'temps' },
          { label: 'travel_time', value: 'travelTime' },
          { label: 'max_capacity', value: 'capacity' },
          { label: 'price', value: 'preu' },
          { label: 'ticket', value: 'ticket', type: 'url' },
        ],
        plus: [
          {
            code: 'marbuslines',
            nombre: 'marbuslines',
            url: 'copaamericalines/?tipus=busmar',
            style: {
              strokeColor: '#377d22',
              strokeOpacity: 1,
              strokeWeight: 3,
            },
          },
        ],
      },
    ],
  },
  {
    order: 3,
    grupo: 'transit',
    translations: 'trafficTranslations',
    code: 'car',
    modo: 'DRIVING',
    icon: 'csvicon-car',
    select_all: {
      type: 'general',
      text: 'all_traffic',
    },
    options: [
      {
        id: 1001,
        nombre: 'traffic_state',
        code: 'traficoactual',
        url: 'tramstransit/',
        idField: 'id',
        layerIcon: 'transit.svg',
        layerColorBackground: '#f0b14a',
        selected: false,
        style: {
          strokeColor: '#cccccc',
          strokeOpacity: 1,
          strokeWeight: 3,
        },
      },
      /* {
        id: 1002,
        nombre: 'green_hubs',
        code: 'eixosverds',
        url: 'eixosverdsbase/',
        idField: 'id',
        layerIcon: 'transit.svg',
        layerColorBackground: '#2B7F9F',
        selected: false,
        style: {
          strokeColor: '#fcba03',
          strokeOpacity: 1,
          strokeWeight: 6,
        },
      }, */
      {
        nombre: 'traffic_incidents',
        code: 'incidencies',
        opened: false,
        options: [
          {
            id: 1004,
            nombre: 'traffic_jams',
            code: 'retenciones',
            url: 'incidencies/2/',
            minZoomLevel: 10,
            selected: false,
            iconFolder: 'trafico',
            markerIcon: 'retenciones.svg',
            layerIcon: 'retenciones.svg',
            layerColorBackground: '#FF0000',
            adaptativeMarkers: true,
            displayInfo: true,
            infoTitle: 'descripcio',
            info: [
              { label: 'type', value: 'descripcio_tipus' },
              { label: 'cause', value: 'causa' },
              { label: 'carretera', value: 'carretera' },
              { label: 'sentit', value: 'sentit' },
            ],
          },
          {
            id: 1003,
            nombre: 'traffic_restrictions',
            code: 'afectacions',
            url: 'afectacions/',
            layerIcon: 'obras.svg',
            layerColorBackground: '#474444',
            style: {
              strokeColor: '#464343',
              fillColor: '#464343',
              strokeOpacity: 1,
              strokeWeight: 3,
            },
            minZoomLevel: 10,
            selected: false,
            iconFolder: 'trafico',
            markerIcon: 'obras.svg',
            adaptativeMarkers: true,
            displayInfo: true,
            infoTitle: 'descripcio',
            info: [
              { label: 'type', value: 'desc_tipus' },
              { label: 'subtype', value: 'desc_subtipus' },
              { label: 'from', value: 'AFE_DESDE' },
              { label: 'to', value: 'AFE_FINS' },
              { label: 'periodicity', value: 'ACT_PERIODICITAT' },
              { label: 'vehicle_involvement', value: 'desc_grau_cal' },
              { label: 'pedestrian_involvement', value: 'desc_grau_vor' },
              { label: 'public_involvement', value: 'desc_grau_trp' },
            ],
          },
          {
            id: 1005,
            nombre: 'sections_with_cones',
            code: 'tramscons',
            url: 'incidencies/1/',
            iconFolder: 'trafico',
            markerIcon: 'conos.svg',
            layerIcon: 'conos.svg',
            layerColorBackground: '#ff6900',
            minZoomLevel: 10,
            selected: false,
            adaptativeMarkers: true,
            displayInfo: true,
          },
        ],
      },
      {
        nombre: 'ring_roads',
        code: 'rondes',
        opened: false,
        options: [
          {
            id: 1006,
            nombre: 'ring_road_entrances',
            code: 'entradasrondas',
            url: 'entradesisortidesrondes/?LLEPOST_I=E',
            layerIcon: 'entrada.svg',
            layerColorBackground: '#00b506',
            minZoomLevel: 10,
            selected: false,
            iconFolder: 'trafico',
            markerIcon: 'entrada.svg',
            adaptativeMarkers: true,
            displayInfo: true,
            infoTitle: 'NOM_COMPLE',
            info: [
              { label: 'entry', value: 'NUMPOST_I' },
            ],
          },
          {
            id: 1007,
            nombre: 'ring_road_exits',
            code: 'salidasrondas',
            url: 'entradesisortidesrondes/?LLEPOST_I=S',
            layerIcon: 'salida.svg',
            layerColorBackground: '#008300',
            minZoomLevel: 10,
            selected: false,
            iconFolder: 'trafico',
            markerIcon: 'salida.svg',
            adaptativeMarkers: true,
            displayInfo: true,
            infoTitle: 'NOM_COMPLE',
            info: [
              { label: 'exit', value: 'NUMPOST_I' },
            ],
          },
        ],
      },
      {
        nombre: 'car_parks',
        code: 'aparcaments',
        opened: false,
        options: [
          {
            id: 1008,
            nombre: 'car_parks',
            code: 'aparcamientos',
            url: 'aparcaments/',
            iconFolder: 'trafico',
            markerIcon: 'parking.svg',
            maxZoomClusteringLevel: 16,
            clusterMarker: 'parking.svg',
            layerIcon: 'parking.svg',
            layerColorBackground: '#003D91',
            adaptativeMarkers: true,
            minZoomLevel: 10,
            selected: false,
            displayInfo: true,
            infoTitle: 'name',
            info: [
              { label: 'address', value: 'address' },
              { label: 'district', value: 'district' },
              { label: 'phonenumber', value: 'phonenumber' },
              { label: 'url', value: 'code_url', type: 'url' },
            ],
          },
          {
            id: 1009,
            nombre: 'blue_area',
            code: 'zonasazules',
            url: 'areesaparcaments/?tipus=AZL',
            layerColorBackground: '#0000FF',
            idField: 'id',
            selected: false,
            style: {
              strokeColor: '#0000ff',
              strokeOpacity: 1,
              strokeWeight: 3,
            },
          },
          {
            id: 1010,
            nombre: 'green_area',
            code: 'zonasverdes',
            url: 'areesaparcaments/?tipus=VM',
            layerColorBackground: '#008000',
            idField: 'id',
            selected: false,
            style: {
              strokeColor: '#008000',
              strokeOpacity: 1,
              strokeWeight: 3,
            },
          },
          {
            id: 1011,
            nombre: 'exclusive_green_area',
            code: 'zonasverdesexclusivas',
            url: 'areesaparcaments/?tipus=VR',
            layerColorBackground: '#00ff00',
            idField: 'id',
            selected: false,
            style: {
              strokeColor: '#00ff00',
              strokeOpacity: 1,
              strokeWeight: 3,
            },
          },
        ],
      },
      {
        nombre: 'sings_and_other_factors',
        code: 'senyals',
        opened: false,
        options: [
          {
            id: 1012,
            nombre: 'traffic_cameras',
            code: 'camarastrafico',
            url: 'camerestransit/',
            maxZoomClusteringLevel: 16,
            clusterMarker: 'camara.svg',
            layerIcon: 'camara.svg',
            layerColorBackground: '#5F5F5F',
            adaptativeMarkers: true,
            minZoomLevel: 10,
            selected: false,
            iconFolder: 'trafico',
            markerIcon: 'camara.svg',
            displayInfo: true,
            infoTitle: 'carretera',
            info: [
              { label: 'municipio', value: 'municipi' },
              { label: 'font', value: 'font' },
            ],
          },
          {
            id: 1013,
            nombre: 'loading_and_unloading_zones',
            code: 'cargadescarga',
            url: 'zonescarregadescarrega/',
            maxZoomClusteringLevel: 16,
            clusterMarker: 'camion.svg',
            layerIcon: 'camion.svg',
            layerColorBackground: '#00CAE8',
            adaptativeMarkers: true,
            minZoomLevel: 10,
            selected: false,
            iconFolder: 'trafico',
            markerIcon: 'camion.svg',
            displayInfo: true,
            infoTitle: 'name',
            info: [
              { label: 'address', value: 'address' },
              { label: 'district', value: 'district' },
              { label: 'phonenumber', value: 'phonenumber' },
              { label: 'url', value: 'code_url', type: 'url' },
            ],
          },
          {
            id: 1014,
            nombre: 'controlled_access_zones',
            code: 'zonasaccesocontrolado',
            url: 'zonesaccescontrolat/',
            layerIcon: 'restringido.svg',
            layerColorBackground: '#4285F4',
            maxZoomClusteringLevel: 16,
            clusterMarker: 'restringido.svg',
            adaptativeMarkers: true,
            minZoomLevel: 10,
            selected: false,
            iconFolder: 'trafico',
            markerIcon: 'restringido.svg',
            displayInfo: true,
            infoTitle: 'Name',
            info: [
              { label: 'description', value: 'descriptio' },
            ],
          },
          {
            id: 1015,
            nombre: 'carrers_30',
            code: 'carrers30_c',
            url: 'carrers30/',
            layerIcon: 'carrers30.svg',
            layerColorBackground: '#2B7F9F',
            minZoomLevel: 10,
            selected: false,
            style: {
              strokeColor: '#2B7F9F',
              strokeOpacity: 1,
              strokeWeight: 2,
              fillColor: '#2B7F9F',
            },
          },
          {
            id: 1016,
            nombre: 'pedestrian_priority_streets',
            code: 'carrersprioritatvianants_c',
            url: 'carrersprioritatvianants/',
            layerIcon: 'carrersprioritatvianants.svg',
            layerColorBackground: '#3BA935',
            minZoomLevel: 10,
            selected: false,
            style: {
              strokeColor: '#3BA935',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
          },
          {
            id: 1017,
            nombre: 'streets_without_motorized_vehicles',
            code: 'carrerssensemotoritzats_c',
            url: 'carrerssensemotoritzats/',
            layerIcon: 'carrerssensemotoritzats.svg',
            layerColorBackground: '#9AC31C',
            minZoomLevel: 10,
            selected: false,
            style: {
              strokeColor: '#9AC31C',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
          },
          {
            id: 1018,
            nombre: 'electric_vehicle_recharging_points',
            code: 'recargavehiculose',
            url: 'recarregavehicleselectrics/',
            layerIcon: 'enchufe.svg',
            layerColorBackground: '#00B506',
            minZoomLevel: 10,
            selected: false,
            maxZoomClusteringLevel: 16,
            clusterMarker: 'enchufe.svg',
            iconFolder: 'trafico',
            markerIcon: 'enchufe.svg',
            adaptativeMarkers: true,
            displayInfo: true,
            infoTitle: 'name',
            info: [
              { label: 'address', value: 'address' },
            ],
          },
          {
            id: 1019,
            nombre: 'petrol_stations',
            code: 'gasolineras',
            url: 'benzineres/',
            layerIcon: 'gasolinera.svg',
            layerColorBackground: '#003149',
            maxZoomClusteringLevel: 16,
            clusterMarker: 'gasolinera.svg',
            minZoomLevel: 10,
            selected: false,
            iconFolder: 'trafico',
            markerIcon: 'gasolinera.svg',
            adaptativeMarkers: true,
            displayInfo: true,
            infoTitle: 'name',
            info: [
              { label: 'address', value: 'address' },
              { label: 'district', value: 'district' },
              { label: 'phonenumber', value: 'phonenumber' },
              { label: 'url', value: 'code_url', type: 'url' },
            ],
          },
        ],
      },
    ],
  },
  {
    order: 4,
    grupo: 'general_interest',
    code: 'interes-general',
    options: [
      {
        id: 1000,
        nombre: 'turisme',
        code: 'turisme',
        url: 'turisme/',
        layerIcon: 'info-turisme.svg',
        maxZoomClusteringLevel: 16,
        clusterMarker: 'turisme.svg',
        adaptativeMarkers: true,
        minZoomLevel: 10,
        iconFolder: 'apie',
        markerIcon: 'turisme.svg',
        displayInfo: true,
        infoTitle: 'name',
        info: [
          { label: 'address', value: 'address' },
          { label: 'district', value: 'district' },
        ],
      },
    ],
  },
];

<template>
  <div>
    <div id="drop-menu" class="points-dropdown">
      <img src="/images/icons/vertical-points.svg">
    </div>
    <div id="dropdown-lateral-menu"  ref="dropdownMenu">
      <a @click="showHelpClear()">{{ $t('help_planroute_demo') }}</a>
      <!-- TO DO -->
      <!-- <a @click="$bvModal.show('bcn-trabel-card-modal'); showLateralDropdown();">{{ $t('hello_bcn_travel') }}</a>
      <a @click="openGoMeet('go_airport'); showLateralDropdown();">{{ $t('go_airport') }}</a>
      <a @click="openGoMeet('beyond_bcn'); showLateralDropdown();">{{ $t('beyond_bcn') }}</a>
      <a @click="showMapLines()">{{ $t('maps_and_lines') }}</a> -->
      <a :href="$t('link_contact')" target="_blank">{{ $t('contact') }}</a>
      <a :href="$t('link_legal')" target="_blank">{{ $t('legal_disclaimer') }}</a>
      <a :href="$t('link_accessibility')" target="_blank">{{ $t('accessibility') }}</a>
      <a v-if="site=='copa-america'" :href="$t('link_travel_card')" target="_blank" class="info-link">Hola Barcelona Travel Card</a>
      <a v-if="site=='copa-america' && viewDisabledNotifications" @click="disableNotifications()" class="info-link">{{$t('unsubscribe_notifications')}}</a>
    </div>
    <travel-card></travel-card>
    <go-meet :type="typeGoMeet"></go-meet>
  </div>
</template>

<script>

import { mapState, mapMutations } from 'vuex';
import { PanelMixins } from './mixins/PanelMixins';
import generalMixin from '../mixins/generalMixin';

export default {
  name: 'LateralMenu',
  mixins: [
    PanelMixins,
    generalMixin,
  ],
  components: {
    TravelCard: () => import('./TravelCard.vue'),
    GoMeet: () => import('./GoMeet.vue'),
  },
  data() {
    return {
      openLateralDropdown: false,
      typeGoMeet: null,
      viewDisabledNotifications: false,
      lang: null,
    };
  },
  computed: {
    ...mapState('general', [
      'site',
    ]),
  },
  async mounted() {
    window.addEventListener('click', this.handleClickOutside);
    await this.getSettingsParam('lang').then((result) => {
      const baseUrl = process.env.VUE_APP_BASE_URL;
      this.lang = baseUrl + result;
    });
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    ...mapMutations({
      setShowHelp: 'help/setShowHelp',
    }),
    showHelpClear() {
      this.refs.planificador.showHelpClear();
      this.setShowHelp(true);
    },
    handleClickOutside(event) {
      this.viewDisabledNotifications = localStorage.notificationWarningViewed;

      if ((event.target.parentElement?.id == 'drop-menu' || event.target.parentElement?.id == this.$refs.dropdownMenu.id)
        && !this.openLateralDropdown) {
        $('#drop-menu').addClass('active-option-lateral-menu');
        $('#dropdown-lateral-menu').css('display', 'grid');
        this.openLateralDropdown = true;
        this.closePanels('');
      } else {
        $('#drop-menu').removeClass('active-option-lateral-menu');
        $('#dropdown-lateral-menu').css('display', 'none');
        this.openLateralDropdown = false;
      }
    },
    openGoMeet(type) {
      this.typeGoMeet = type;
      this.$bvModal.show('go-meet-modal');
    },
    showMapLines() {
      this.$bvModal.hide('go-meet-modal');
      this.$bvModal.show('modal-selector-lines');
    },
    disableNotifications() {
      this.deleteSuscriptionFirebase();
      this.$bvModal.hide('go-meet-modal');
    },
  },
  created() {
    this.refs = this.isWidget === true ? this.$root.$children[0].$children[0].$children[0].$refs : this.$root.$children[0].$children[0].$refs;
  },
};
</script>
<style>
.points-dropdown {
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 50%;
}
#dropdown-lateral-menu {
  position: absolute;
  right: 14px;
  margin-top: 2px;
  z-index: 35;
  width: 140px;
  background-color: #FFF7EB;
  border-radius: 6px;
  padding: 14px 18px;
  box-shadow: 0px 2px 12px #0000003D;
  text-align: left;
  display: none;
}
#dropdown-lateral-menu a {
  padding-top: 14px;
  padding-bottom: 14px;
  text-decoration: none;
  font-size: 15px;
  color: #3E3E3C;
}
</style>

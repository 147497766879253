/* eslint-disable */

import axios from 'axios';
import { getApiUri } from '../api/util';
import store from '../store/index';
import api from '../api/api';

window.LineaTransportTram = function() {

}

LineaTransportTram.lineas = [
  {
    linea: 'T1',
    descripcio: 'Francesc Macià / Bon Viatge',
    color: '008173',
    codi: 'T1',
    type: 'tram',
    icon: 'csvicon-tranvia',
    estacions: {},
    reverse: false,
    selected: false,
  },
  {
    linea: 'T2',
    descripcio: 'Francesc Macià / Llevant-Les planes',
    color: '008173',
    codi: 'T2',
    type: 'tram',
    icon: 'csvicon-tranvia',
    estacions: {},
    reverse: false,
    selected: false,
  },
  {
    linea: 'T3',
    descripcio: 'Francesc Macià / Sant Feliu-Consell Comarcal',
    color: '008173',
    codi: 'T3',
    type: 'tram',
    icon: 'csvicon-tranvia',
    estacions: {},
    reverse: false,
    selected: false,
  },
  {
    linea: 'T4',
    descripcio: 'Verdaguer / Estació de Sant Adrià',
    color: '008173',
    codi: 'T4',
    type: 'tram',
    icon: 'csvicon-tranvia',
    estacions: {},
    reverse: false,
    selected: false,
  },
  {
    linea: 'T5',
    descripcio: 'Ciutadella-Vila Olímpica / Gorg',
    color: '008173',
    codi: 'T5',
    type: 'tram',
    icon: 'csvicon-tranvia',
    estacions: {},
    reverse: false,
    selected: false,
  },
  {
    linea: 'T6',
    descripcio: 'Ciutadella-Vila Olímpica / Estació de Sant Adrià',
    color: '008173',
    codi: 'T6',
    type: 'tram',
    icon: 'csvicon-tranvia',
    estacions: {},
    reverse: false,
    selected: false,
  }
]

LineaTransportTram.prototype.loadTransport = function () {
  // Load tram lines
  for (var c in LineaTransportTram.lineas) {
    var linea = LineaTransportTram.lineas[c];
    store.commit('transport/setLineas', {
      tram: {
        [linea.linea]: linea,
      },
    });
  }
}

LineaTransportTram.prototype.loadStation = function (linea) {
  var promise = new Promise(function(resolve, reject) {
    api.get(`${getApiUri()}tramviaparades/?NOM_CAPA=Estacions&LINIA=${linea}`).then(function (response) {
      const url = response.config.url;
      const urlParams = new URLSearchParams(url.split('?')[1]);
      const line_code = urlParams.get('LINIA');
      var results = response.data.features;

      for (let c in results) {
        const result = results[c];
        const stopIds = result.properties.ID.split("/");
        var stopId = "";

        for (let i in stopIds) {
          if (stopIds[i].includes(line_code)) {
            stopId = stopIds[i].replace(line_code+".","");
            break;
          };
        };

        if (stopId != "") {
          store.commit('transport/setLineas', {
            tram: {
              [line_code]: {
                estacions: {
                  [stopId]: {
                    nom: result.properties.NOM_ESTACI,
                    codi: stopId,
                    geometry: result.geometry
                  },
                },
              },
            },
          });
        };
      };
      resolve(response);
    });
  })
  return promise;
}

LineaTransportTram.getLinea = function(linea) {
  for (var c in store.state.transport.lineas.tram) {
    if (store.state.transport.lineas.tram[c].linea == linea) {
      return store.state.transport.lineas.tram[c];
    }
  }
  return {};
}

LineaTransportTram.getStation = function(linea, code) {
  for (var c in linea.estacions) {
    if (linea.estacions[c].codi==code) {
      return linea.estacions[c];
    }
  }
  return {};
}

LineaTransportTram.displayInfo = function(feature) {
  const refs = store.state.map.isWidget === true ? window.app.$children[0].$children[0].$children[0].$refs : window.app.$children[0].$children[0].$refs;
  refs.infoPanel.initInfoPanel();

  store.commit('infopanel/setInfoPanel', {
    datos: null,
  });
  const datos = [
    {"label": window.app.$t('lines'), "data": feature.getProperty('LINIA')},
  ];
  if (feature.getProperty('CORRESPOND') != '') {
    datos.push({"label": window.app.$t('correspondence'), "data": feature.getProperty('CORRESPOND')});
  }
  store.commit('infopanel/setInfoPanel', {
    title: feature.getProperty('NOM_ESTACI'),
    linea: `logo-tram.png`,
    nom_linea: feature.getProperty('LINIA'),
    datos,
    type: 'tram',
    feature: feature,
    visible: true,
  });
  refs.infoPanel.destacaMarker();
  refs.infoPanel.updateTramTimes();
  refs.infoPanel.initPanel();
}

LineaTransportTram.loadLineMap = function(linea_name, zoom, station_name) {

  var linea = LineaTransportTram.getLinea(linea_name);

  var configStation = Object.assign({}, {
    ZOOM: 16,
    zoomPoint: 17,
    CENTER: new google.maps.LatLng(41.404, 2.173),
    MAP_TYPE: google.maps.MapTypeId.ROADMAP,
    REGION: 'ca',
    BUFFER_DISTANCE: 0.2, // Km
    savePosition: true,
    displayInfo: true,
    setMarkerSearch: true,
    adaptativeMarkers: true,
    iconURL: "/images/markers/publico",
    forceZoom: false,
    mapTypeControl: false,
    minZoomLevel: 14,
    visible: true,
    maxZoomClusteringLevel: 16,
    clusterIconSize: [24, 35],
  });

  configStation.callback = {
    displayInfo: LineaTransportTram.displayInfo,
  }

  configStation.style = {
    strokeColor: "#"+linea.color,
    fillColor: "#"+linea.color,
    strokeOpacity: 1,
    strokeWeight: 2,
    path: "/images/markers/publico",
    marker: "tramvia.svg",
    icon: "tramvia.svg",
    clusterMarker: "transport.svg",
  };

  configStation.name = "tramestacions" + linea.linea;

  var configLine = Object.assign({}, {
    ZOOM: 16,
    zoomPoint: 17,
    CENTER: new google.maps.LatLng(41.404, 2.173),
    MAP_TYPE: google.maps.MapTypeId.ROADMAP,
    REGION: 'ca',
    BUFFER_DISTANCE: 0.2, // Km
    savePosition: true,
    setMarkerSearch: true,
    adaptativeMarkers: true,
    iconURL: "/images/markers/",
    forceZoom: false,
    mapTypeControl: false,
  });

  configLine.style = {
    strokeColor: "#"+linea.color,
    fillColor: "#"+linea.color,
    strokeOpacity: 1,
    strokeWeight: 2,
  };

  configLine.name = "tramlinies" + linea.linea;

  var dataLayerStation = new DataLayer(configStation, store.state.map.mapObj);
  var dataLayerLine = new DataLayer(configLine, store.state.map.mapObj);

  var layerUrlLine = `${getApiUri()}tramvialinies/?LINIA=${linea.linea}`;
  var layerUrlStacions = `${getApiUri()}tramviaparades/?NOM_CAPA=Estacions&LINIA=${linea.linea}`;

  Promise.all([
    dataLayerLine.addGeoJsonDataLayer(layerUrlLine),
    dataLayerStation.addGeoJsonDataLayer(layerUrlStacions)
  ]).then(() => {
    if (station_name!=undefined) {
      var station = LineaTransportTram.getStation(linea, station_name);
      store.state.map.mapObj.setCenter(new google.maps.LatLng(station.geometry.coordinates[1],station.geometry.coordinates[0]));
      store.state.map.mapObj.setZoom(configStation.zoomPoint);
    }
    if (zoom=='line') {
      dataLayerLine.cropToBounds();
      if (store.state.map.mapObj.getZoom()<12) {
        store.state.map.mapObj.setZoom(12);
      }
    }
    linea.selected = true;
    linea.group = "public_transport";
    store.commit('map/addActiveLayer', linea);
  });

}

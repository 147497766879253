import { remove } from 'lodash';

/*
    Right panel status:
    rightPanelOpened
       -> panelActiveLayers (all, traffic, bike, transport, walk)
            -> bus, metro, fgc, rodalies, tram, aerobus, funicular, taxi
       -> panelLayerSelection
            - panelLayerTypeSelected (all, traffic, bike, transport, walk)
       -> panelTrafficWarnings
       -> panelCamera
*/
const state = () => ({
  rightPanelOpened: false,
  panelActiveLayers: false,
  panelLayerSelection: false,
  panelLayerTypeSelected: false,
  panelTrafficWarnings: false,
  panelCamera: false,
  panelPreferences: false,
});

const mutations = {
  setPanelActiveLayers(state, status) {
    mutations.closeOtherPanels(state, 'panelActiveLayers', status);
    state.panelActiveLayers = status;
  },
  setPanelLayerSelection(state, status) {
    mutations.closeOtherPanels(state, 'panelLayerSelection', status);
    state.panelLayerSelection = status;
  },
  setPanelLayerTypeSelected(state, status) {
    state.panelLayerTypeSelected = status;
  },
  setPanelTrafficWarnings(state, status) {
    mutations.closeOtherPanels(state, 'panelTrafficWarnings', status);
    state.panelTrafficWarnings = status;
  },
  setPanelPreferences(state, status) {
    mutations.closeOtherPanels(state, 'panelPreferences', status);
    state.panelPreferences = status;
  },
  setPanelCamera(state, status) {
    mutations.closeOtherPanels(state, 'panelCamera', status);
    state.panelCamera = status;
  },
  closeOtherPanels(state, panelOpened, status) {
    if (status && !state.rightPanelOpened) {
      // mutations.setRightPanelOpened(state, true);
    }
    const panels = ['panelActiveLayers', 'panelLayerSelection', 'panelTrafficWarnings', 'panelCamera', 'panelPreferences'];
    remove(panels, (panel) => panel == panelOpened);
    for (const panel of panels) {
      state[panel] = false;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};

const state = () => ({
  activeSearch: false,
  activeLayerSearch: false,
  linesSearchWord: '',
  linesSearchLayerWord: '',
  searchResults: {
    metro: {
      lines: [],
      stations: [],
    },
    bus: {
      lines: [],
      stations: [],
    },
    rodalies: {
      lines: [],
      stations: [],
    },
    ferrocarril: {
      lines: [],
      stations: [],
    },
    tram: {
      stations: [],
    },
  },
  searchLayerResults: {
    metro: {
      lines: [],
      stations: [],
    },
    bus: {
      lines: [],
      stations: [],
    },
    rodalies: {
      lines: [],
      stations: [],
    },
    ferrocarril: {
      lines: [],
      stations: [],
    },
    tram: {
      stations: [],
    },
  },
  panelSelected: 'left',
});

const mutations = {
  setLinesSearchWord(state, linesSearchWord) {
    state.linesSearchWord = linesSearchWord;
  },

  setLinesSearchLayerWord(state, linesSearchLayerWord) {
    state.linesSearchLayerWord = linesSearchLayerWord;
  },

  setSearchResults(state, searchResults) {
    state.searchResults = searchResults;
  },

  setSearchLayerResults(state, searchLayerResults) {
    state.searchLayerResults = searchLayerResults;
  },

  setActiveSearch(state, activeSearch) {
    state.activeSearch = activeSearch;
  },

  setActiveLayerSearch(state, activeLayerSearch) {
    state.activeLayerSearch = activeLayerSearch;
  },

  setPanelSelected(state, panelSelected) {
    state.panelSelected = panelSelected;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};

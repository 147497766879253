import { initializeApp } from 'firebase/app';
import {
  getMessaging, getToken as getFCMToken, onMessage, isSupported, deleteToken,
} from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

let app;
let messaging;

async function initializeFirebase() {
  const isSupportedBrowser = await isSupported();

  if (
    firebaseConfig.apiKey
    && firebaseConfig.authDomain
    && firebaseConfig.projectId
    && firebaseConfig.storageBucket
    && firebaseConfig.messagingSenderId
    && firebaseConfig.appId
  ) {
    app = initializeApp(firebaseConfig);
    if (isSupportedBrowser) {
      messaging = getMessaging(app);
    } else {
      console.warn('Firebase messaging is not supported in this browser.');
    }
  } else {
    console.warn('Firebase configuration is incomplete. Firebase will not be initialized. Check your environment variables.');
  }
  return messaging;
}

export {
  initializeFirebase,
  getFCMToken,
  onMessage,
  deleteToken,
};

import { merge } from 'lodash';

const state = () => ({
  mainMenuStatus: {
    opened: true,
  },
  layerMenuStatus: {
    opened: false,
  },
  layerMenu: [],
  menuNavigationApp: {
    optionSelected: 'comshiva',
  },
});

const mutations = {
  setMainMenuStatus(state, mainMenuStatus) {
    state.mainMenuStatus = merge({}, state.mainMenuStatus, mainMenuStatus);
  },
  setLayerMenuStatus(state, layerMenuStatus) {
    state.layerMenuStatus = merge({}, state.layerMenuStatus, layerMenuStatus);
  },
  setLayerMenu(state, layerMenu) {
    state.layerMenu = layerMenu;
  },
  setMenuNavigationApp(state, menuNavigationApp) {
    state.menuNavigationApp = menuNavigationApp;
  },
  resetLayerMenuSelected(state) {
    state.layerMenu.forEach((grupo) => {
      grupo.options.forEach((opcion) => {
        opcion.selected = false;
        if (opcion.options) {
          opcion.options.forEach((subopcion) => {
            subopcion.selected = false;
          });
        }
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};

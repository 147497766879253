<template>
  <div id="alertsPanelWrapper">
    <div class="swipeZone">
      <div class="swipeHelper">
        <div class="helper"></div>
      </div>
      <div class="header-panel">
        <div class="title">
          <img src="/images/icons/bell.svg">
          {{ $t('traffic_alerts') }}
        </div>
      </div>
    </div>
    <div class="alertsPanelContainer" :class="{'opened': panelTop}">
      <div class="trafficContainer">
        <div class="traffic">
          <div class="t-alert" v-for="(incident, key) in trafficIncidents.incidents"
               v-bind:key="key">
            <div class="alert-p1">
              <div class="point" v-if="isNew(incident)"></div>
            </div>
            <div class="alert-p2" v-html="incident"></div>
            <div class="alert-p3">
              <img src="/images/icons/share.svg" @click="changeSocialOpened(key)">
              <div :id="'social-'+key" class="social d-none">
                <a :href="getUrl('email', incident)" @click="initShare" target="_blank"><i class="bcn-icon-correu-email"></i></a>
                <a :href="getUrl('facebook', incident)" @click="initShare" target="_blank"><i class="bcn-icon-facebook"></i></a>
                <a :href="getUrl('twitter', incident)" @click="initShare" target="_blank"><i class="bcn-icon-x"></i></a>
              </div>
            </div>
          </div>
          <div class="no-incidents"
               v-if="trafficIncidents.incidents.length === 0 && !trafficIncidents.first_read">
            {{ $t('no_incidents') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapMutations } from 'vuex';
import { PanelMixins } from './mixins/PanelMixins';
import IncidentsMixins from './mixins/IncidentsMixins';

export default {
  name: 'TrafficAlerts',
  mixins: [
    PanelMixins,
    IncidentsMixins,
  ],
  props: ['open'],
  emits: {
    closeTrafficAlerts: null,
  },
  data() {
    return {
      panelTop: false,
      touchstartY: 0,
      touchendY: 0,
      timeInteraction: 0,
    };
  },
  computed: {
    ...mapState('incidents', [
      'trafficIncidents',
    ]),
    ...mapState('map', [
      'isWidget',
    ]),
  },
  watch: {
    open(newVal, oldVal) {
      if (newVal == true) {
        this.initPanel();
      } else {
        this.hidePanel();
      }
    },
  },
  methods: {
    initPanel() {
      this.posPanel();
      this.setPanelHeight();
      this.detectSlide();
      this.closePanels('trafficAlerts');
    },
    hidePanel() {
      $('#alertsPanelWrapper').animate(
        {
          top: '100%',
        },
        {
          complete: () => {
            this.panelTop = false;
          },
        },
      );
    },
    posPanel() {
      const mapCanvas = document.getElementById('map-canvas');
      const top = mapCanvas.clientHeight;

      $('#alertsPanelWrapper').animate(
        {
          top: `${this.isWidget ? top / 1.25 : top}px`,
        },
        {
          complete: () => {
          },
        },
      );
      $('.alertsPanelContainer').scrollTop(0);
    },
    onSwipeUp() {
      if (this.panelTop) return false;

      let top = document.getElementById('c-flexible-data').clientHeight + 5;
      if (document.getElementById('car-selector-tabs')) {
        top += document.getElementById('car-selector-tabs').clientHeight;
      }

      $('#alertsPanelWrapper').animate(
        {
          top: `${top}px`,
          marginTop: 0,
        },
        {
          complete: () => {
            $('.social').addClass('d-none');
          },
        },
      );
      this.panelTop = true;
      this.setPanelHeight();
    },
    onSwipeDown() {
      if (!this.panelTop) {
        const top = window.innerHeight - 65;
        $('#alertsPanelWrapper').animate(
          {
            top: `${top}px`,
            marginTop: '25px',
          },
          {
            complete: () => {
              this.$emit('closeTrafficAlerts');
              $('.social').addClass('d-none');
            },
          },
        );
      } else {
        this.panelTop = false;
        // this.setPanelHeight();
        this.onSwipeDown();
      }
    },
    setPanelHeight() {
      $('.alertsPanelContainer').css('height', `${document.getElementById('map-canvas').clientHeight}px`);
    },
    detectSlide() {
      var gesuredZone = document.getElementById('alertsPanelWrapper').children[0];

      gesuredZone.addEventListener('touchstart', (event) => {
        this.touchstartY = event.changedTouches[0].screenY;
      }, false);

      gesuredZone.addEventListener('touchend', (event) => {
        this.touchendY = event.changedTouches[0].screenY;
        if (((Date.now() - this.timeInteraction) / 1000) > 1) {
          this.handleGesure();
        }
      }, false);
    },
    handleGesure() {
      if (this.touchendY < this.touchstartY) {
        this.onSwipeUp();
      }
      if (this.touchendY > this.touchstartY) {
        this.onSwipeDown();
      }
      this.timeInteraction = Date.now();
    },
    getUrl(type, text) {
      const title = "Com s'hi va. Alerta de trànsit";
      let url;
      switch (type) {
        case 'facebook':
          url = `https://www.facebook.com/sharer/sharer.php?title=${title}&description=${text}`;
          break;
        case 'twitter':
          url = `https://twitter.com/intent/tweet?text=${text}`;
          break;
        case 'email':
          url = `mailto:?subject=${title}&body=${text}`;
          break;
        default:
          break;
      }

      return url;
    },
    changeSocialOpened(key) {
      if ($(`#social-${key}`).hasClass('d-none')) {
        $('.social').addClass('d-none');
        $(`#social-${key}`).removeClass('d-none');
      } else {
        $(`#social-${key}`).addClass('d-none');
      }
    },
    initShare() {
      // eslint-disable-next-line no-underscore-dangle
      window._mtm.push({
        event: 'comshiva',
        eventcategory: 'ComShiVa',
        eventaction: 'Compartir alerta de trànsit',
        eventname: 2001,
      });
    },
  },
  mounted() {
    this.getIncidents();
  },
  unmounted() {
    this.setTrafficIncidentsReaded(true);
    this.setTrafficIncidentsNew(0);
    this.setTrafficIncidentsFirstRead(false);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

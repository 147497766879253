<template>
  <div id="infoPanelWrapper" :style="[infoPanel.show_search ? {'background': '#F6F6F5', 'z-index': 30} : {'z-index': 20}]">
    <div class="swipeZone">
      <div class="swipeHelper">
        <div class="helper"></div>
      </div>
      <div class="header-panel">
        <!-- Search -->
        <h2 v-if="infoPanel.show_search">
          {{ $t('show_map') }}
          <small> {{ $t('search_interest_items') }}</small>
        </h2>
        <!-- Info Layer -->
        <div class="info" v-if="infoPanel.visible">
          <h3 class="title">
            <img v-if="infoPanel.type != 'bus' && infoPanel.type != 'aerobus' && infoPanel.type != 'info_point'"
              :src="`/images/${infoPanel.linea}`" class="metro">
            <img v-if="infoPanel.icon_title != null" :src="`/images/${infoPanel.icon_title}`" class="icon_title">
            <span class="bus-line" :style="{'background-color': '#' + getBusColor(infoPanel.linea)}"
              v-if="infoPanel.type == 'bus' || infoPanel.type == 'aerobus'">
              {{ infoPanel.linea }}
            </span>
            <div class="me-1">
              {{ infoPanel.title }}
              <div class="access" v-if="infoPanel.feature.getProperty('NOM_ACCES') != undefined">{{
                infoPanel.feature.getProperty('NOM_ACCES') }}</div>
            </div>
          </h3>
        </div>
      </div>
    </div>
    <div class="infoPanelContainer" :class="{'opened': panelTop}">
      <emissions-panel ref="info-panel-actual" v-if="isLegend && !infoPanel.show_search"></emissions-panel>
      <div class="planificador-capas" v-if="infoPanel.show_search">
        <cerca-lines ref="cercacapes" :placeholder="$t('search_examples')" layers="layers" internalpadding="15" @closeSearch="onSwipeDown()"></cerca-lines>
        <div class="example-txt">{{ $t('examples') }}:</div>
        <div class="layers">
          <div class="layer" v-for="(example, index) in getExamplesVisiblePlanificador()" :key="index">
            <div class="check-wrapper" v-if="example.code">
              <input :id="'sample-' + index" :checked="example.selected" :name="'sample-' + index" type="checkbox"
                @change="selectOpcion(example)">
              <label :for="'sample-' + index">
                <span>{{ $t(example.nombre) }}</span>
              </label>
            </div>
            <div class="check-wrapper" v-if="example.linea">
              <input :id="'sample-' + index" :checked="example.selected" :name="'sample-' + index" type="checkbox"
                @change="toggleLine(example)">
              <label :for="'sample-' + index">
                <span>{{ $t(example.linea) }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="ficha" v-if="infoPanel.visible">
        <div class="content">
          <div v-if="infoPanel.foto != null" v-html="infoPanel.foto" class="foto">
          </div>
          <ul class="data">
            <div class="lineas" v-if="infoPanel.lineas.length > 0">
              <span class="txt-lineas">{{ $t('lines') }}</span>
              <div class="logos">
                <img class="metro" :src="`/images/${linea}`" v-for="(linea, index) in infoPanel.lineas"
                  v-bind:key="index">
              </div>
            </div>
            <hr v-if="infoPanel.lineas_bus.length > 0">
            <div class="lineas" v-if="infoPanel.lineas_bus.length>0">
              <span class="txt-lineas"><img class="gray" src="/images/icons/bus.svg"></span>
              <div class="logos">
                <span v-for="(linea, index) in infoPanel.lineas_bus" v-bind:key="index" class="bus-line"
                  :style="{'background-color': '#' + getBusColor(linea)}">
                  {{ linea }}
                </span>
              </div>
            </div>
            <div class="lineas" v-if="infoPanel.lineas_tram.length>0">
              <span class="txt-lineas"><img class="metro tram" src="/images/logo-tram.png"></span>
              <div class="logos tramlines">
                {{ infoPanel.lineas_tram.join(', ') }}
              </div>
            </div>
            <a v-if="infoPanel.code === 'bicing'" @click="updateBicing()" class="link-update" href="javascript://">
              <span class="icon-reload bcn-icon-proces-tramit"></span>
            </a>
            <li v-for="(dato, index) in infoPanel.datos" v-bind:key="index">
              <strong v-html="dato.label" class="label"></strong>
              <span v-html="dato.data" class="dato"></span>
            </li>
          </ul>

          <div v-if="infoPanel.type == 'bus'">
            <hr>
            <a @click="updateBusTime()" class="link-update" href="javascript://">
              <span class="icon-reload bcn-icon-proces-tramit"></span>
            </a>
            <div class="bus_times">
              <div v-for="(time,index) in infoPanel.bustimes" v-bind:key="index">
                <div v-if="index == 0" class="fs-16 mb-2 font-weight-bold">{{ $t('next_exits') }}</div>
                <div class="mb-1">
                  <span class="line-time-bus" :style="{'background-color': '#' + getBusColor(time.line)}">{{ time.line
                    }}</span>
                  <span>{{ $t(time.time) }}</span>
                </div>
              </div>
            </div>
            <div v-if="infoPanel.bustimes.length == 0">{{ $t('no_time_table_available') }}</div>
            <div class="clearfix"></div>
          </div>
          <div v-if="infoPanel.type == 'tram'">
            <hr>
            <a @click="updateTramTimes()" class="link-update" href="javascript://">
              <span class="icon-reload bcn-icon-proces-tramit"></span>
            </a>
            <div class="tram_times">
              <div v-for="(line,index) in infoPanel.tramtimes" v-bind:key="index">
                <div v-if="index == 0" class="fs-16 mb-2 font-weight-bold">{{ $t('next_exits') }}</div>
                <div class="mb-1">
                  <div><b>{{ line[0].line }}</b> - {{ $t('to') }} {{ $t(line[0].destination) }}</div>
                  <div>
                    <small v-for="(time, index2) in line" v-bind:key="index2">{{ $t(time.time) }}<span
                        v-if="index2 < line.length-1">, </span></small>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="infoPanel.tramtimes.length == 0">{{ $t('no_time_table_available') }}</div> -->
            <div class="clearfix"></div>
          </div>
          <div v-if="infoPanel.type == 'rodalies'" style="display: none">
            <hr>
            <a @click="updateTrainsTime()" class="link-update" href="javascript://">
              <span class="icon-reload bcn-icon-proces-tramit"></span>
            </a>
            <div class="train_times">
              <div v-for="(time,index) in infoPanel.traintimes" v-bind:key="index">
                <div v-if="index==0" class="fs-16 mb-2 font-weight-bold">{{ $t('upcoming_trains') }}</div>
                <div class="mb-1" :class="visibleTime(index)">
                  <span class="visibleItem"><img :src="`/images/rodalies/${time.line}.png`" class="metro"></span>
                  <span class="mr-1 visibleItem scheduled">{{ $t('arrival') }}: <b>{{ time.arrive }}</b> - {{
                    $t('departure') }}: <b>{{ time.leave }}</b></span>
                  <span class="csvicon-wheelchair mr-1 visibleItem"></span>
                  <div class="destination">{{ time.destination_name }}</div>
                </div>
              </div>
            </div>
            <div v-if="infoPanel.traintimes.length == 0">{{ $t('no_time_table_available') }}</div>
            <div class="clearfix"></div>
          </div>
          <div v-if="infoPanel.show_fgc_times && infoPanel.type == 'ferrocarril'">
            <hr>
            <a @click="updateFGCTime()" class="link-update" href="javascript://">
              <span class="icon-reload bcn-icon-proces-tramit"></span>
            </a>
            <div class="train_times">
              <div v-for="(time,index) in infoPanel.fgctimes" v-bind:key="index">
                <div v-if="index == 0" class="fs-16 mb-2 font-weight-bold">{{ $t('upcoming_trains') }}</div>
                <div class="mb-2">
                  <div>
                    <span class="visibleItem"><img :src="`/images/fgc/${time.line}.png`" class="metro"></span> <b
                      class="mr-1">{{ time.trip_headsign }}</b>
                    <span class="csvicon-wheelchair visibleItem mr-1" v-if="time.wheelchair_boarding == 1"></span>
                  </div>
                  <div class="destination">
                    <span class="mr-1 visibleItem scheduled">{{ $t('arrival') }}: <b>{{ time.arrival_time }}</b> - {{
                      $t('departure') }}: <b>{{ time.departure_time }}</b></span>
                  </div>
                  <div class="destination">
                    <span class="scheduled mr-1" v-if="time.occupancy_status && time.occupancy_status!=7">{{
                      $t('occupancy') }}: <img class="metro"
                        :src="`/images/occupancy/occupancy-${time.occupancy_status}.png`"></span>
                  </div>
                  <div class="destination">
                    <div class="scheduled" v-if="time.in_stop">{{ $t('located_at') }}: {{ time.in_stop }}</div>
                    <div class="scheduled text-red" v-if="time.alerts.length > 0"><i class="glyphicon-alert"></i> {{
                      time.alerts[0] }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="infoPanel.fgctimes.length == 0">{{ $t('no_time_table_available') }}</div>
            <div class="clearfix"></div>
          </div>

          <div class="links">
            <hr>
            <div v-if="infoPanel.accessible && infoPanel.feature.getProperty('NOM_ACCES') == undefined" class="adapted">
              <span class="text">{{ $t('adapted_station') }}</span>
              <span class="csvicon-ascensor mr-1" v-if="getNumAscensorMetro() > 0"></span>
              <span class="csvicon-wheelchair mr-1"></span>
            </div>
            <div
              v-if="infoPanel.feature.getProperty('NOM_TIPUS_ACCESSIBILITAT') == 'Accessible' && infoPanel.feature.getProperty('NOM_ACCES') != undefined"
              class="adapted">
              <span class="text">{{ $t('adapted_access') }}</span>
              <span class="me-1"><img src="/images/icons/wheelchair.svg"></span>
              <span class="me-1" v-if="infoPanel.feature.getProperty('NUM_ASCENSORS') > 0"><img
                  src="/images/icons/elevator.svg"></span>
            </div>
          </div>
        </div>
      </div>

      <!-- Button arrive-->
      <div class="arrive" v-if="opened && !isWidget && !infoPanel.show_search && infoPanel.code !== 'legend_traffict'">
        <button class="button-arrive" @click="howToArrive">
          {{ $t('how_to_arrive') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import { toRaw } from 'vue';
import { getApiUri } from '../api/util';
import api from '../api/api';
import EmissionsPanel from './EmissionsPanel.vue';
import { PanelMixins } from './mixins/PanelMixins';
import CercaLines from './CercaLines.vue';
import { LayerMixins } from './mixins/LayerMixins';

export default {
  name: 'InfoPanel',
  mixins: [
    PanelMixins,
    LayerMixins,
  ],
  components: {
    EmissionsPanel,
    CercaLines,
  },
  computed: {
    ...mapState('infopanel', [
      'infoPanel',
    ]),
    ...mapState('map', [
      'mapObj',
      'isWidget',
      'mapFullScreen',
    ]),
  },
  watch: {
    open(newVal, oldVal) {
      this.initPanel();
    },
  },
  props: ['open'],
  data() {
    return {
      isBus: false,
      isCamera: false,
      isLegend: false,
      bicing: false,
      opened: false,
      metro: false,
      panelTop: false,
      refs: null,
      touchstartY: 0,
      touchendY: 0,
      timeInteraction: 0,
      isSubscribed: false,
    };
  },
  created() {
    this.refs = this.isWidget === true ? this.$root.$children[0].$children[0].$children[0].$refs : this.$root.$children[0].$children[0].$refs;
  },
  methods: {
    ...mapMutations({
      setInfoPanel: 'infopanel/setInfoPanel',
      initInfoPanel: 'infopanel/initInfoPanel',
      setIsOpenInfoPanel: 'map/setIsOpenInfoPanel',
    }),
    initPanel() {
      $('#infoPanelWrapper').css('height', 0);
      this.isBus = this.infoPanel.type === 'bus';
      this.isCamera = this.infoPanel.code === 'camarastrafico';
      this.isLegend = this.infoPanel.code === 'legend_traffict';
      this.metro = this.infoPanel.type === 'metro'
        || this.infoPanel.type === 'funicular';
      this.bicing = this.infoPanel.code === 'puntosanclajebicis'
        || this.infoPanel.code === 'aparcamientosserviciobicis'
        || this.infoPanel.code === 'bicing'
        || this.infoPanel.type === 'tram'
        || this.infoPanel.type === 'rodalies'
        || this.infoPanel.type === 'ferrocarril'
        || this.infoPanel.code === 'taxi';
      this.detectSlide();
      this.closePanels('infoPanel');
      this.posPanel();
    },
    posPanel() {
      const mapCanvas = document.getElementById('map-canvas');
      let top = '';
      if (this.isCamera) {
        top = ((mapCanvas.clientHeight / 1.35) - 200);
      } else if (this.isLegend) {
        top = 352;
      } else {
        top = (mapCanvas.clientHeight / 1.35);
      }
      this.opened = true;
      this.panelTop = false;
      setTimeout(() => {
        const height = this.setPanelHeight();
        $('#infoPanelWrapper').animate(
          {
            height,
          },
          {
            complete: () => {
              if (this.isBus) this.onSwipeUp();
              if (this.infoPanel.code != 'camarastrafico') this.refs.mapa.moveMapButtons('up', 'infoPanelWrapper');
            },
          },
        );
        this.setIsOpenInfoPanel(true);
        $('.infoPanelContainer').scrollTop(0);
      }, 300);
    },
    onSwipeUp() {
      if (this.panelTop || !!this.isLegend) return false;
      // El 270px me interesa para las camaras
      const topDinamic = this.isBus ? '280px' : '270px';
      const height = this.setPanelHeight() + document.getElementsByClassName('arrive')[0]?.clientHeight + 20;
      $('#infoPanelWrapper').animate(
        {
          height,
        },
        {
          complete: () => {
            this.opened = true;
          },
        },
      );
      this.panelTop = true;
    },
    onSwipeDown() {
      const height = !this.panelTop ? 0 : this.setPanelHeight();
      $('#infoPanelWrapper').animate(
        {
          height,
        },
        {
          duration: 100,
          complete: () => {
            if (!this.panelTop) {
              this.setIsOpenInfoPanel(false);
              this.opened = false;
              this.close();
            }
            this.panelTop = false;
            if (this.infoPanel.code != 'camarastrafico') this.refs.mapa.moveMapButtons('down', 'infoPanelWrapper');
          },
        },
      );
    },
    setPanelHeight() {
      const heightPanelContainer = document.getElementsByClassName('swipeZone')[0].clientHeight
      + document.getElementsByClassName('infoPanelContainer')?.[0].clientHeight;
      return heightPanelContainer;
    },
    detectSlide() {
      if (this.isSubscribed) return;
      const gesuredZone = document.querySelector('#infoPanelWrapper .swipeZone');

      const handleTouchStart = (event) => {
        this.touchstartY = event.changedTouches[0].screenY;
      };

      const handleTouchEnd = (event) => {
        this.touchendY = event.changedTouches[0].screenY;
        const currentTime = Date.now();
        if (((currentTime - this.timeInteraction) / 1000) > 1) {
          this.handleGesure();
        }
      };

      gesuredZone.addEventListener('touchstart', handleTouchStart);
      gesuredZone.addEventListener('touchend', handleTouchEnd);
      this.isSubscribed = true;
    },
    handleGesure() {
      if (this.touchendY < this.touchstartY) {
        this.onSwipeUp();
      } else if (this.touchendY > this.touchstartY) {
        this.onSwipeDown();
      }
      this.timeInteraction = Date.now();
    },
    howToArrive() {
      this.panelTop = false;
      this.onSwipeDown();

      let position = new google.maps.LatLng();
      this.infoPanel.feature.getGeometry().forEachLatLng((latlng) => {
        position = latlng;
      });
      const destination = `${position.lat()},${position.lng()}`;
      const destination_txt = this.infoPanel.title;
      this.refs.planificador.openPlan(
        undefined,
        undefined,
        undefined,
        destination,
        undefined,
        destination_txt,
        'WALKING',
      );

      this.$bvModal.show('modal-planificador');
    },
    close() {
      this.defaultMarker();
      this.setInfoPanel({
        visible: false,
        show_search: false,
      });
    },
    getBusColor(linea) {
      return LineaTransportBus.getColor(linea);
    },
    getNumAscensorMetro() {
      let numAscensors = 0;
      for (const c in this.infoPanel.feature.getProperty('ACCESSOS')) {
        if (this.infoPanel.feature.getProperty('ACCESSOS')[c].NUM_ASCENSORS > 0) {
          numAscensors++;
        }
      }
      return numAscensors;
    },
    savePlace() {
      this.getPlace().then((place) => {
        this.$swal(this.$t('added_to_favorites'), this.infoPanel.title, 'success');
        GoogleRoutesApi.savePlace(place);
      });
    },
    getPlace() {
      const promise = new Promise((resolve, reject) => {
        const googlePlaceApi = new GoogleRoutesApi(this.$i18n.locale);
        let position = new google.maps.LatLng();
        this.infoPanel.feature.getGeometry().forEachLatLng((latlng) => {
          position = latlng;
        });
        googlePlaceApi.geocode(position).then((results) => {
          const val = results[0];
          const componentsObject = GoogleRoutesApi.addressComponentObject(val.address_components);
          const addressText = GoogleRoutesApi.getMainSecondaryAddresText(componentsObject).main_text;
          let secondary_text = addressText;
          if (this.infoPanel.type != 'info_point') {
            secondary_text = `Línia ${this.infoPanel.nom_linea} de ${this.infoPanel.type}`;
          }
          val.custom_text = {
            main_text: this.infoPanel.title,
            secondary_text,
          };
          resolve(val);
        });
      });
      return promise;
    },
    updateBusTime() {
      const { feature } = this.infoPanel;
      const codigo = feature.getProperty('Codigo');
      const timestamp = Date.now();
      api.get(`${getApiUri()}servicios/busestimes/${codigo}?${timestamp}`).then((response) => {
        const bustimes = [];
        for (const c in response.data) {
          const time = response.data[c];
          bustimes.push({
            line: time.line,
            destination: time,
            time: time['text-ca'],
          });
        }

        this.setInfoPanel({
          bustimes: null,
        });
        this.setInfoPanel({
          bustimes,
          visible: true,
        });
      });
    },
    updateTramTimes() {
      const { feature } = this.infoPanel;
      let codigo = feature.getProperty('COD_STOP_A');
      const timestamp = Date.now();
      const tramtimes = [];
      api.get(`${getApiUri()}servicios/tramtimes/${codigo}?${timestamp}`).then((response) => {
        for (const c in response.data.results) {
          const time = response.data.results[c];
          if (time.arrivalTime) {
            tramtimes.push({
              line: time.lineName,
              destination: time.destination,
              time: time.arrivalTime.split(' ')[1],
            });
          }
        }
        codigo = feature.getProperty('COD_STOP_T');
        api.get(`${getApiUri()}servicios/tramtimes/${codigo}?${timestamp}`).then((response) => {
          for (const c in response.data.results) {
            const time = response.data.results[c];
            if (time.arrivalTime) {
              tramtimes.push({
                line: time.lineName,
                destination: time.destination,
                time: time.arrivalTime.split(' ')[1],
              });
            }
          }
          var lines = {};
          for (var i = 0; i < tramtimes.length; i++) {
            const key = `${tramtimes[i].line} ${tramtimes[i].destination}`;
            // eslint-disable-next-line no-prototype-builtins
            if (!lines.hasOwnProperty(key)) {
              lines[key] = [tramtimes[i]];
            } else {
              lines[key].push(tramtimes[i]);
            }
          }
          this.setInfoPanel({
            tramtimes: null,
          });
          this.setInfoPanel({
            tramtimes: lines,
            visible: true,
          });
        });
      });
    },
    updateTrainsTime() {
      const { feature } = this.infoPanel;
      const codigo = feature.getProperty('codigo');
      // api.get(`${getApiUri()}servicios/traintimes/${codigo}`).then((response) => {
      //   const traintimes = [];
      //   for (const c in response.data.results) {
      //     const time = response.data.results[c];
      //     traintimes.push({
      //       line: time.line,
      //       train: time.train,
      //       destination_name: time.destination_name,
      //       leave: time.leave.slice(0, -3),
      //       arrive: time.arrive.slice(0, -3),
      //     });
      //   }
      //
      //   this.setInfoPanel({
      //     traintimes: null,
      //   });
      //   this.setInfoPanel({
      //     traintimes,
      //     visible: true,
      //   });
      // });
    },
    updateFGCTime() {
      const { feature } = this.infoPanel;
      const codigo = feature.getProperty('id');
      const timestamp = Date.now();
      api.get(`${getApiUri()}servicios/fgctimes/${codigo}?${timestamp}`).then((response) => {
        const fgctimes = [];
        for (const c in response.data) {
          const time = response.data[c];
          fgctimes.push({
            line: time.line,
            trip_headsign: time.trip_headsign,
            departure_time: time.departure_time.slice(0, -3),
            arrival_time: time.arrival_time.slice(0, -3),
            wheelchair_boarding: time.wheelchair_boarding,
            occupancy_status: time.occupancy_status,
            in_stop: time.in_stop,
            alerts: time.alerts,
          });
        }

        this.setInfoPanel({
          fgctimes: null,
        });
        this.setInfoPanel({
          fgctimes,
          visible: true,
        });
      });
    },
    visibleTime(index) {
      let cssClass = 'visible_time';
      if (index > 0) {
        const current_time = this.infoPanel.traintimes[index];
        const previous_time = this.infoPanel.traintimes[index - 1];
        if (current_time.train == previous_time.train) {
          cssClass = 'not_visible_time';
        }
      }
      return cssClass;
    },
    updateBicing() {
      const { feature } = this.infoPanel;
      const station = feature.getProperty('id');
      const timestamp = Date.now();
      api.get(`${getApiUri()}servicios/bikesstation/${station}?${timestamp}`).then((response) => {
        const data = response.data.results;
        this.infoPanel.datos = [
          { label: this.$t('free_bycicle_slots'), data: data.slots },
        ];
        if (data.mechanical_bikes > 0) {
          this.infoPanel.datos.push({ label: this.$t('mechanical_bycicles'), data: data.mechanical_bikes });
        }
        if (data.electrical_bikes > 0) {
          this.infoPanel.datos.push({ label: this.$t('electrical_bycicles'), data: data.electrical_bikes });
        }
        this.setInfoPanel(this.infoPanel);
      });
    },
    destacaMarker() {
      if (this.infoPanel.config && (this.infoPanel.config.name == 'team_headquarters' || this.infoPanel.config.name == 'pointinterest')) return;
      this.defaultMarker();
      if (this.infoPanel.config) {
        if (DataLayer.layers[this.infoPanel.config.name]) {
          const features = DataLayer.layers[this.infoPanel.config.name].getAllFeatures();
          let selectedFeature = null;
          for (var feature of features) {
            if (feature == toRaw(this.infoPanel.feature)) {
              selectedFeature = feature;
            }
          }
          const dataLayer = DataLayer.layers[this.infoPanel.config.name];
          dataLayer.setStyle((feature) => {
            const config = dataLayer.conf;
            const style = JSON.parse(JSON.stringify(config.style));
            let icon = style.marker;
            if (feature == selectedFeature) {
              if (config.name == 'near_public_transport') {
                icon = feature.getProperty('icon');
              }
              style.icon = {
                url: `${style.path}/selected/${icon}`,
                scaledSize: new google.maps.Size(28, 40), // scaled size
                // origin: new google.maps.Point(0,0), // origin
                // anchor: new google.maps.Point(20, 35) // anchor
              };
              style.zIndex = 1000;
            } else {
              if (config.name == 'near_public_transport') {
                icon = feature.getProperty('icon');
                style.icon = {
                  url: `${style.path}/${icon}`,
                };
              }
              if (dataLayer.layerName == 'bicifriends') {
                let icon = 'edifici_amicbici_certified.svg';
                if (feature.getProperty('any_certificacio') == '') {
                  icon = 'edifici_amicbici_in_process.svg';
                }
                style.icon = {
                  url: `${style.path}/${icon}`,
                };
              }
              if (dataLayer.layerName == 'bicing') {
                var capacity = feature.getProperty('slots');
                var bikes = feature.getProperty('bikes');
                var slots = capacity - bikes;
                var percentage = (bikes * 100) / capacity;
                let icon = 'bicing.svg';
                if (percentage < 90) icon = 'bicing-75.svg';
                if (percentage < 60) icon = 'bicing-50.svg';
                if (percentage < 35) icon = 'bicing-25.svg';
                if (percentage == 0) icon = 'bicing-0.svg';
                style.icon = {
                  url: `${style.path}/${icon}`,
                };
              }
              if (dataLayer.layerName == 'team_headquarters' || dataLayer.layerName == 'pointinterest') {
                var flag = feature.getProperty('flag');
                const icon = flag;
                style.marker = flag;
                style.icon = {
                  url: `${style.path}/${icon}`,
                };
              }
            }
            return style;
          });
        }
      }
    },
    defaultMarker() {
      for (const c in DataLayer.layers) {
        const dataLayer = DataLayer.layers[c];
        dataLayer.setStyle((feature) => {
          const config = dataLayer.conf;
          const style = JSON.parse(JSON.stringify(config.style));
          let icon = style.marker;
          if (config.name == 'near_public_transport') {
            icon = feature.getProperty('icon');
          }
          if (dataLayer.layerName == 'bicifriends') {
            icon = 'edifici_amicbici_certified.svg';
            if (feature.getProperty('any_certificacio') == '') {
              icon = 'edifici_amicbici_in_process.svg';
            }
          }
          if (dataLayer.layerName == 'bicing') {
            var capacity = feature.getProperty('slots');
            var bikes = feature.getProperty('bikes');
            var slots = capacity - bikes;
            var percentage = (bikes * 100) / capacity;
            icon = 'bicing.svg';
            if (percentage < 90) icon = 'bicing-75.svg';
            if (percentage < 60) icon = 'bicing-50.svg';
            if (percentage < 35) icon = 'bicing-25.svg';
            if (percentage == 0) icon = 'bicing-0.svg';
          }
          if (dataLayer.layerName == 'team_headquarters' || dataLayer.layerName == 'pointinterest') {
            var flag = feature.getProperty('flag');
            icon = flag;
          }
          style.icon = {
            url: `${style.path}/${icon}`,
          };
          return style;
        });
      }
    },
    getExamplesVisiblePlanificador() {
      const { planificador } = this.refs;
      if (planificador) {
        return planificador.getExamplesVisible();
      }
      return [];
    },
    toggleLine(example) {
      const { planificador } = this.refs;
      if (planificador) {
        planificador.toggleLine(example);
      }
    },
  },
};
</script>

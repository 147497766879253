<template>
  <div id="help">
    <div class="step step-1">
        <img src="/images/icons/fletxa-step-1.svg">
        <div class="text">
          {{ $t('help_planroute') }}
        </div>
    </div>
    <div class="step step-2">
      <img src="/images/icons/fletxa-step-2.svg">
      <div class="text">
        {{ $t('help_add_layers') }}
      </div>
    </div>
    <div class="step step-3">
      <img src="/images/icons/fletxa-step-3.svg">
      <div class="text">
        {{ $t('help_warnings') }}
      </div>
    </div>
    <div role="button" tabindex="0" @click="setShowHelp(false); stopHelp = true;" class="bot-tanca btn-color">{{ $t('close_presentation') }}</div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import gsap from 'gsap';

export default {
  name: 'Help',
  data() {
    return {
      stopHelp: false,
    };
  },
  computed: {
    ...mapState('help', [
      'opened',
    ]),
  },
  methods: {
    ...mapMutations({
      setShowHelp: 'help/setShowHelp',
    }),
    showStep(index) {
      if (this.stopHelp) {
        this.cleanSteps();
        return;
      }

      const steps = gsap.utils.toArray('.step');
      const timeDelay = 4;

      if (index + 1 == 1) {
        $('#traffic-incidents-b').css({ 'z-index': '', 'background-color': '' });
        $('#planificador .open-planificador .btn-planificador').css({ 'z-index': 1002, position: 'relative' });
      }
      if (index + 1 == 2) {
        $('#planificador .open-planificador .btn-planificador').css({ 'z-index': '', position: '' });
        $('#add-layers').css({ 'z-index': 1002 });
      }
      if (index + 1 == 3) {
        $('#add-layers').css({ 'z-index': '' });
        $('#traffic-incidents-b').css({ 'z-index': 1002, 'background-color': '#fff' });
      }

      gsap.to(
        steps[index],
        {
          opacity: 1,
          duration: 1,
          onComplete: () => {
            if (index < steps.length - 1) {
              // Oculta el paso anterior y muestra el siguiente
              gsap.delayedCall(timeDelay, () => { // Delay
                this.hideStep(index);
                this.showStep(index + 1);
              });
            } else {
              gsap.delayedCall(timeDelay, () => {
                this.hideStep(index);
                this.showStep(0);
              });
            }
          },
        },
      );
    },
    hideStep(index) {
      const steps = gsap.utils.toArray('.step');
      gsap.to(steps[index], { opacity: 0, duration: 1 });
    },
    cleanSteps() {
      $('#traffic-incidents-b').css({ 'z-index': '', 'background-color': '' });
      $('#planificador .open-planificador .btn-planificador').css({ 'z-index': '', position: '' });
      $('#add-layers').css({ 'z-index': '' });
    },
  },
  mounted() {
    gsap.to('.step', { clearProps: true });
    this.showStep(0);
  },
};
</script>

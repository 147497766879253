<template>

  <!-- Modal Selector Lines -->
  <b-modal
    id="modal-selector-lines"
    scrollable
    hide-footer
  >
    <template #modal-header>
      <div class="m-header">
        <div class="global-actions d-flex align-items-center">
          <img @click="closeLineModal()" class="back-arrow-icon" src="/images/icons/back-arrow.svg">
          <span @click="closeLineModal()" class="title">{{ $t('lines_stops') }}</span>
        </div>
        <div class="search-layer">
          <cerca-lines ref="cercalinies" :placeholder="$t('search_lines_stops')" layers="transport"></cerca-lines>
        </div>
      </div>
    </template>

    <b-container fluid>

      <div class="m-body">
        <div class="menu">
          <div v-if="!activeSearch">
            <div class="transportList" v-if="transportTypeSelected == ''">
              <div class="title">
                {{ $t('transports') }}
              </div>
              <ul>
                <li role="button" tabindex="0" @click="setTransportTypeSelected('metro')" @keyup.enter="setTransportTypeSelected('metro')">
                  <span class="icon">
                      <img src="/images/icons/metro.svg">
                  </span>
                  <span>{{ $t('metro') }}</span>
                </li>
                <li role="button" tabindex="0" @click="setTransportTypeSelected('bus')" @keyup.enter="setTransportTypeSelected('bus')">
                    <span class="icon">
                        <img class="gray" src="/images/icons/bus.svg">
                    </span>
                    <span>{{ $t('bus') }}</span>
                </li>
                <li role="button" tabindex="0" @click="setTransportTypeSelected('fgc')" @keyup.enter="setTransportTypeSelected('fgc')">
                    <span class="icon">
                        <img src="/images/logo-fgc.png">
                    </span>
                    <span>{{ $t('fgc_trains') }}</span>
                </li>
                <li role="button" tabindex="0" @click="setTransportTypeSelected('rodalies')" @keyup.enter="setTransportTypeSelected('rodalies')">
                    <span class="icon">
                        <img src="/images/logo-rodalies.png">
                    </span>
                    <span>{{ $t('local_trains') }}</span>
                </li>
                <li role="button" tabindex="0" @click="setTransportTypeSelected('tram')" @keyup.enter="setTransportTypeSelected('tram')">
                    <span class="icon">
                        <img src="/images/logo-tram.png">
                    </span>
                    <span>{{ $t('trams') }}</span>
                </li>
                <li role="button" tabindex="0" @click="setTransportTypeSelected('aerobus')" @keyup.enter="setTransportTypeSelected('aerobus')">
                    <span class="icon">
                        <img class="gray" src="/images/icons/aerobus.svg">
                    </span>
                    <span>{{ $t('aerobus') }}</span>
                </li>
                <li role="button" tabindex="0" @click="setTransportTypeSelected('funicular')" @keyup.enter="setTransportTypeSelected('funicular')">
                    <span class="icon">
                        <img class="gray" src="/images/icons/teleferic.svg">
                    </span>
                    <span>{{ $t('cableway') }}</span>
                </li>
                <li role="button" class="disable-after" tabindex="0">
                    <div class="layers-list-wrapper">
                      <div class="linea">
                          <div class="item-transport linea check-wrapper">
                              <input :id="'leftline-taxi'" :name="'leftline-taxi'" type="checkbox" :checked="isCheckedTaxi" @change="loadTaxi()">
                              <label :for="'leftline-taxi'" class="text">
                                  <span><img class="logo-metro" src="/images/icons/taxi.svg"></span>
                                  <span class="descripcio_linia">{{ $t('cab') }}</span>
                              </label>
                          </div>
                      </div>
                    </div>
                </li>
                <li role="button" class="disable-after" tabindex="0">
                    <div class="layers-list-wrapper">
                      <div class="linea">
                          <div class="item-transport linea check-wrapper">
                              <input :id="'leftline-bus-maritime'" :name="'leftline-bus-maritime'" type="checkbox" :checked="isCheckedBusMaritime" @change="loadBusMaritime()">
                              <label :for="'leftline-bus-maritime'" class="text">
                                  <span><img class="logo-metro" src="/images/layer-icons/busnautico.svg"></span>
                                  <span class="descripcio_linia">{{ $t('mar_bus') }}</span>
                              </label>
                          </div>
                      </div>
                    </div>
                </li>
              </ul>
            </div>
            <div class="linesList" v-if="transportTypeSelected == 'metro' && lineaSelected==''">
              <div class="titleTransortSelected">
                <img class="gray" src="/images/icons/back-2.svg" tabindex="0" role="button" @click="setTransportTypeSelected('')" @keyup.enter="setTransportTypeSelected('')">
                <span>{{ $t('metro') }}</span>
              </div>
              <ul>
                <li role="button" tabindex="0" class="linea" @click="selectLineaMetro(linea)" @keyup.enter="selectLineaMetro(linea)" v-for="(linea, index) in lineas.metro" v-bind:key="index">
                  <span>
                      <img class="logo-metro" :src="`/images/metro/${linea.linea}.png`">
                  </span>
                  <span class="descripcio_linia">{{linea.descripcio}}</span>
                </li>
              </ul>
            </div>
            <div class="linesList" v-if="transportTypeSelected == 'bus' && lineaSelected == ''">
              <div class="titleTransortSelected">
                <img class="gray" src="/images/icons/back-2.svg" role="button" tabindex="0" @click="setTransportTypeSelected('')" @keyup.enter="setTransportTypeSelected('')">
                <span>{{ $t('bus') }}</span>
              </div>
              <ul>
                <li role="button" tabindex="0" class="linea" @click="selectLineaBus(linea)" @keyup.enter="selectLineaBus(linea)" v-for="(linea, index) in lineas.bus" v-bind:key="index">
                  <div>
                  <span class="marca" :style="{'background-color': `#${linea.color}`}">{{ linea.linea }}</span>
                  </div>
                  <span class="descripcio_linia">{{ linea.descripcio }}</span>
                </li>
              </ul>
            </div>
            <div class="linesList" v-if="transportTypeSelected == 'fgc' && lineaSelected == ''">
              <div class="titleTransortSelected">
                <img class="gray" src="/images/icons/back-2.svg" role="button" tabindex="0" @click="setTransportTypeSelected('')" @keyup.enter="setTransportTypeSelected('')">
                <span>{{ $t('fgc_trains') }}</span>
              </div>
              <ul>
                <li role="button" tabindex="0" class="linea" @click="selectLineaFerrocarril(linea)" @keyup.enter="selectLineaFerrocarril(linea)" v-for="(linea, index) in lineas.ferrocarril" v-bind:key="index">
                  <span><img class="logo-fgc" :src="`/images/fgc/${linea.linea}.png`"></span>
                  <span class="descripcio_linia">{{ linea.descripcio }}</span>
                </li>
              </ul>
            </div>
            <div class="linesList" v-if="transportTypeSelected == 'rodalies' && lineaSelected == ''">
              <div class="titleTransortSelected">
                <img class="gray" src="/images/icons/back-2.svg" role="button" tabindex="0" @click="setTransportTypeSelected('')" @keyup.enter="setTransportTypeSelected('')">
                <span>{{ $t('local_trains') }}</span>
              </div>
              <ul>
                <li role="button" tabindex="0" class="linea" @click="selectLineaRodalies(linea)" @keyup.enter="selectLineaRodalies(linea)" v-for="(linea, index) in lineas.rodalies" v-bind:key="index">
                  <span><img class="logo-metro" :src="`/images/rodalies/${linea.linea}.png`"></span>
                  <span class="descripcio_linia">{{ linea.descripcio }}</span>
                </li>
              </ul>
            </div>
            <div class="linesList" v-if="transportTypeSelected == 'tram' && lineaSelected == ''">
              <div class="titleTransortSelected">
                <img class="gray" src="/images/icons/back-2.svg" role="button" tabindex="0" @click="setTransportTypeSelected('')" @keyup.enter="setTransportTypeSelected('')">
                <span>{{$t('trams')}}</span>
              </div>
              <ul>
                <li role="button" tabindex="0" class="linea" @click="selectLineaTram(linea)" @keyup.enter="selectLineaTram(linea)" v-for="(linea, index) in lineas.tram" v-bind:key="index">
                  <span><img class="logo-metro" src="/images/logo-tram.png"></span>
                  <span class="descripcio_linia"><strong>{{ linea.linea }}</strong> {{ linea.descripcio }}</span>
                </li>
              </ul>
            </div>
            <div class="linesList" v-if="transportTypeSelected == 'aerobus' && lineaSelected == ''">
                <div class="titleTransortSelected">
                    <img class="gray" src="/images/icons/back-2.svg" role="button" tabindex="0" @click="setTransportTypeSelected('')" @keyup.enter="setTransportTypeSelected('')">
                    <span>{{ $t('aerobus') }}</span>
                </div>
                <ul>
                    <li role="button" tabindex="0" class="linea" @click="selectLineaAerobus(linea)" @keyup.enter="selectLineaAerobus(linea)" v-for="(linea, index) in lineas.aerobus" v-bind:key="index">
                    <span class="marca" :style="{'background-color': '#'+linea.color}">{{ linea.linea }}</span>
                    <span class="descripcio_linia">{{ linea.descripcio }}</span>
                  </li>
                </ul>
            </div>
            <div class="linesList" v-if="transportTypeSelected == 'funicular' && lineaSelected == ''">
              <div class="titleTransortSelected">
                <img class="gray" src="/images/icons/back-2.svg" role="button" tabindex="0" @click="setTransportTypeSelected('')" @keyup.enter="setTransportTypeSelected('')">
                <span>{{ $t('cableway') }}</span>
              </div>
              <ul>
                <li role="button" tabindex="0" class="linea" @click="selectLineaFunicular(linea)" @keyup.enter="selectLineaFunicular(linea)" v-for="(linea, index) in lineas.funicular" v-bind:key="index">
                  <span><img class="logo-metro" src="/images/logo-funicular.png"></span>
                  <span class="descripcio_linia">{{ linea.descripcio }}</span>
                </li>
              </ul>
            </div>
            <div class="stationsList menu-detalle-linea"
                v-show="lineaSelected != ''" :class="getAnimationClassLine()">
                <div class="nom-linia">
                   <img class="gray2-filter mr-2" src="/images/icons/back-2.svg" role="button" @click="deselectLine">
                   <div v-if="lineaSelected.type == 'bus' || lineaSelected.type == 'aerobus'">
                     <span class="marca" :style="{'background-color': `#${lineaSelected.color}`}">{{ lineaSelected.linea }}</span>
                   </div>
                   <div v-if="lineaSelected.type == 'tram'">
                     <span class="marca" :style="{'background-color': `#${lineaSelected.color}`}">{{ lineaSelected.linea }}</span>
                   </div>
                   <img v-if="lineaSelected.type == 'metro'" class="logo-metro ml-2 mr-2" :src="`/images/metro/${lineaSelected.linea}.png`">
                   <img v-if="lineaSelected.type == 'ferrocarril'" class="logo-metro ml-2 mr-2" :src="`/images/fgc/${lineaSelected.linea}.png`">
                   <img v-if="lineaSelected.type == 'rodalies'" class="logo-metro ml-2 mr-2" :src="`/images/rodalies/${lineaSelected.linea}.png`">
                   <span class="desc">{{ getLineDesc() }}</span>
                   <img class="gray-filter float-end" src="/images/icons/change-direction.svg" role="button" @click="revertLine">
                </div>
                <hr class="small">
                <div class="link" v-if="false">
                    <a href="javascript://" @click="loadLineMap(lineaSelected.type, lineaSelected.linea)">
                        <i class="bcn-icon-mapa mr-1"></i>
                        <span>{{ $t('see_line_map') }}</span>
                    </a>
                </div>
                <ul class="estacions">
                  <li
                    role="button"
                    tabindex="0"
                    @click="loadStationMap(lineaSelected.type, lineaSelected.linea, estacio.codi, lineaSelected.sentido); closeLineModal()"
                    @keyup.enter="loadStationMap(lineaSelected.type, lineaSelected.linea, estacio.codi, lineaSelected.sentido); $bvModal.hide('modal-selector-lines')"
                    v-for="(estacio, key, index, isLast) in getSelectedStations()"
                    v-bind:key="index" class="station_item"
                    :class="{'first':index==0, 'last': isLast }"
                  >
                    <div class="color-line" :style="{backgroundColor: (lineaSelected.color.indexOf('#') == -1)?'#'+lineaSelected.color:lineaSelected.color}"></div>
                    <span class="nomestacio">{{ estacio.nom }}</span>
                    <span class="correspondencies" v-if="estacio.correspondencies">
                    <span v-for="(corr, index) in estacio.correspondencies.Metro" v-bind:key="index">
                        <img class="logo-metro me-1" :src="`/images/metro/${corr}.png`">
                    </span>
                    <span v-if="estacio.correspondencies.FGC">
                        <img class="logo-metro me-1" src="/images/logo-fgc.png">
                    </span>
                    <span v-if="estacio.correspondencies['Rodalies-BCN'] || estacio.correspondencies['Rodalies-Regionals']">
                        <img class="logo-metro me-1" src="/images/logo-rodalies.png">
                    </span>
                    <span v-if="estacio.correspondencies.TRAM">
                        <img class="logo-metro me-1" src="/images/logo-tram.png">
                    </span>
                    <span v-if="estacio.correspondencies['Metro-Funicular']">
                        <img class="logo-metro me-1" src="/images/logo-funicular.png">
                    </span>
                    </span>
                    <span class="accessos">
                    <span class="me-1" v-if="estacio.accessibilitat"><img src="/images/icons/wheelchair.svg"></span>
                    <span class="me-1" v-if="estacio.elevador"><img src="/images/icons/elevator.svg"></span>
                    </span>
                  </li>
                </ul>
            </div>
          </div>
          <hr v-if="!activeSearch">
          <div class="last-lines-viewed" v-if="recentLoadedLines.length > 0 && !activeSearch">
              <div class="title">{{ $t('last_lines_viewed') }}</div>
              <div class="last-line" v-for="(line, index) in recentLoadedLines" v-bind:key="index" role="button" tabindex="0" @click="loadRecentLine(line)" @keyup.enter="loadRecentLine(line)">
                  <img src="/images/icons/recent.svg" class="icon">
                  <div class="recent-text">{{ line.linea }} - {{ line.descripcio }}</div>
              </div>
          </div>
        </div>
      </div>

    </b-container>

  </b-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { sortBy, reverse } from 'lodash';
import { TransportMixins } from './mixins/TransportMixins';
import { LayerMixins } from './mixins/LayerMixins';
import CercaLines from './CercaLines.vue';

export default {
  name: 'Lines',
  components: {
    CercaLines,
  },
  mixins: [
    TransportMixins,
    LayerMixins,
  ],
  computed: {
    ...mapState('general', [
      'site',
    ]),
    ...mapState('transport', [
      'transportTypeSelected',
      'lineas',
    ]),
    ...mapState('search', [
      'activeSearch',
    ]),
    ...mapState('map', [
      'recentLoadedLines',
    ]),
    isCheckedTaxi() {
      const groupName = this.site == 'copa-america' ? 'transport' : 'public_transport';
      const taxiOpcion = this.getOpcion(groupName, 'taxi');
      if (taxiOpcion) {
        return taxiOpcion.selected;
      }
      return false;
    },
    isCheckedBusMaritime() {
      const groupName = this.site == 'copa-america' ? 'transport' : 'public_transport';
      const busOpcion = this.getOpcion(groupName, 'marbus');
      if (busOpcion) {
        return busOpcion.selected;
      }
      return false;
    },
  },
  methods: {
    ...mapMutations({
      setLineas: 'transport/setLineas',
      setTransportTypeSelected: 'transport/setTransportTypeSelected',
    }),
    closeLineModal() {
      this.$bvModal.hide('modal-selector-lines');
      this.setTransportTypeSelected('');
      this.deselectLine();
    },
    loadTaxi() {
      const groupName = this.site == 'copa-america' ? 'transport' : 'public_transport';
      const opcion = this.getOpcion(groupName, 'taxi');
      this.selectOpcion(opcion);
    },
    loadBusMaritime() {
      const groupName = this.site == 'copa-america' ? 'transport' : 'public_transport';
      const opcion = this.getOpcion(groupName, 'marbus');
      this.selectOpcion(opcion);
    },
    getAnimationClassLine() {
      if (this.lineaSelected != '') {
        return 'animate__animated animate__fadeIn animate__slideInLeft';
      }
      return 'animate__animated animate__fadeOut animate__slideOutLeft';
    },
    getLineDesc() {
      const desc = this.lineaSelected.descripcio;
      if (this.lineaSelected.inici && this.lineaSelected.fi && !this.lineaSelected.reverse) {
        return `${this.lineaSelected.inici} - ${this.lineaSelected.fi}`;
      }
      if (this.lineaSelected.inici && this.lineaSelected.fi && this.lineaSelected.reverse) {
        return `${this.lineaSelected.fi} - ${this.lineaSelected.inici}`;
      }
      return desc;
    },
    revertLine() {
      this.lineaSelected.reverse = !this.lineaSelected.reverse;
    },
    getSelectedStations() {
      if (!this.lineaSelected.estacions) return [];
      let stations = [];
      if (this.lineaSelected.type != 'bus') {
        for (const c in this.lineaSelected.estacions) {
          stations.push(this.lineaSelected.estacions[c]);
        }
        if (this.lineaSelected.reverse) {
          stations = reverse(stations);
        }
      } else {
        const origen_destinations = this.lineaSelected.descripcio.split('/');
        const sentido = (this.lineaSelected.reverse) ? origen_destinations[1] : origen_destinations[0];
        this.lineaSelected.sentido = sentido.trim();
        for (const c in this.lineaSelected.estacions) {
          if (this.lineaSelected.sentido.includes(this.lineaSelected.estacions[c].sentit)) {
            stations.push(this.lineaSelected.estacions[c]);
          }
        }
      }
      return stations;
    },
    loadRecentLine(line) {
      const type = (line.type == 'ferrocarril') ? 'fgc' : line.type;
      this.setTransportTypeSelected(type);
      const transport_types = ['aerobus', 'metro', 'bus', 'ferrocarril', 'rodalies', 'tram', 'funicular'];
      for (const transport_type of transport_types) {
        for (const c in this.lineas[transport_type]) {
          this.lineas[transport_type][c].selected = false;
        }
      }
      this.setLineas(this.lineas);
      switch (line.type) {
        case 'metro':
          this.selectLineaMetro(line);
          break;
        case 'bus':
          this.selectLineaBus(line);
          break;
        case 'ferrocarril':
          this.selectLineaFerrocarril(line);
          break;
        case 'rodalies':
          this.selectLineaRodalies(line);
          break;
        case 'tram':
          this.selectLineaTram(line);
          break;
        case 'aerobus':
          this.selectLineaAerobus(line);
          break;
        case 'funicular':
          this.selectLineaFunicular(line);
          break;
        default:
          console.warn('No line type');
      }
    },
  },
};
</script>

/* eslint-disable no-lonely-if */
/* eslint-disable prefer-template */
import { mapState, mapMutations } from 'vuex';
import {
  find,
  clone,
  cloneDeep,
  findIndex,
} from 'lodash';
import { DateTime } from 'luxon';
import { getApiUri } from '../../api/util';
import api from '../../api/api';
import { app, EventBus } from '../../main';
import { MapMixins } from './MapMixins';

export const LayerMixins = {
  mixins: [
    MapMixins,
  ],
  data() {
    return {
      refs: null,
    };
  },
  computed: {
    ...mapState('map', [
      'mapObj',
      'activeLayers',
      'futureTraffic',
      'isWidget',
      'nameFixedGroup',
      'directionIcons',
      'directionIconsShuttle',
    ]),
    ...mapState('menu', [
      'layerMenuStatus',
      'layerMenu',
    ]),
    ...mapState('transport', [
      'alterationMessages',
    ]),
    ...mapState('infopanel', [
      'infoPanel',
    ]),
    ...mapState('general', [
      'lastHistoryState',
    ]),
  },
  created() {
    this.refs = this.isWidget === true ? this.$root.$children[0].$children[0].$children[0].$refs : this.$root.$children[0].$children[0].$refs;
    if (this.mapObj) {
      google.maps.event.addListener(this.mapObj, 'zoom_changed', () => {
        const zoomLevel = this.mapObj.getZoom();
        const layers = [
          { code: 'carrilsbici', icons: this.directionIcons },
          { code: 'eixosverds', icons: this.directionIcons },
          { code: 'lanzaderaforumbogatell', icons: this.directionIconsShuttle, dataLayer: 'lanzaderaforumbogatelllines' },
        ];

        layers.forEach((layer) => {
          const activeLayer = find(this.activeLayers, (o) => o.code === layer.code);
          const dataLayer = layer.dataLayer ? DataLayer.getDataLayer(layer.dataLayer) : null;

          if (activeLayer !== undefined && (!layer.dataLayer || (dataLayer && dataLayer.visible))) {
            for (const c in layer.icons) {
              layer.icons[c].setMap(this.mapObj);
              layer.icons[c].visible = zoomLevel >= 15;
            }
          }
        });
      });
    }
  },
  methods: {
    ...mapMutations({
      setActiveLayers: 'map/setActiveLayers',
      resetActiveLayers: 'map/resetActiveLayers',
      addActiveLayer: 'map/addActiveLayer',
      setLayerMenu: 'menu/setLayerMenu',
      resetLayerMenuSelected: 'menu/resetLayerMenuSelected',
      setFutureTrafficHour: 'map/setFutureTrafficHour',
      setFutureTrafficDate: 'map/setFutureTrafficDate',
      setInfoPanel: 'infopanel/setInfoPanel',
      setWidgetCallback: 'map/setWidgetCallback',
      setDirectionIcons: 'map/setDirectionIcons',
      setDirectionIconsShuttle: 'map/setDirectionIconsShuttle',
      setLastHistoryState: 'general/setLastHistoryState',
    }),
    updateIconsVisibility(layerCode, icons, zoomLevel) {
      const layer = find(this.activeLayers, (o) => o.code === layerCode);
      let iconsVisible = null;
      if (zoomLevel >= 15 && layer !== undefined) {
        iconsVisible = this.mapObj;
      }
      for (const c in icons) {
        icons[c].setMap(iconsVisible);
        icons[c].visible = true;
      }
    },
    showTraffic(opt) {
      let option = {};
      const camera = {
        nombre: 'traffic_cameras',
        code: 'camarastrafico',
        url: 'camerestransit/',
        maxZoomClusteringLevel: 16,
        clusterMarker: 'camara.svg',
        layerIcon: 'camara.svg',
        layerColorBackground: '#5F5F5F',
        adaptativeMarkers: true,
        minZoomLevel: 10,
        iconFolder: 'trafico',
        markerIcon: 'camara.svg',
        displayInfo: true,
        infoTitle: 'carretera',
        info: [
          { label: 'municipio', value: 'municipi' },
          { label: 'font', value: 'font' },
        ],
      };
      const actual = {
        nombre: 'traffic_state',
        code: 'traficoactual',
        layerIcon: 'transit.svg',
        layerColorBackground: '#f0b14a',
        url: 'tramstransit/',
        idField: 'id',
        style: {
          strokeColor: '#cccccc',
          strokeOpacity: 1,
          strokeWeight: 3,
        },
      };

      switch (opt) {
        case 'cameras':
          // Unselected traffic
          option = { ...actual, selected: true };
          this.selectOpcion(option);
          option = camera;
          this.selectOpcion(option);

          break;
        case 'traffic':
          // Unselected camera
          option = { ...camera, selected: true };
          this.selectOpcion(option);
          // Unselected existing traffic layers
          if (findIndex(this.activeLayers, (o) => o.code === 'traficoactual') >= 0) {
            option = { ...actual, selected: true };
            this.selectOpcion(option);
          }
          // Add layer traffic
          option = { ...actual, selected: false };
          this.selectOpcion(option);

          break;
        default:
          break;
      }
    },
    selectOpcion(opcion) {
      if (opcion) {
        const grupo = this.getGrupo(opcion);
        if ((opcion.options && opcion.options.length > 0) || (grupo.code == 'transport' && opcion.code != 'taxi' && opcion.code != 'marbus')) return;
        opcion.selected = !opcion.selected;
        opcion.group = opcion?.fixed ? this.nameFixedGroup : grupo.grupo;
        if (opcion.code == 'near_public_transport') {
          this.refs.mapa.loadNearTransport('near_public_transport');
        }
        if (opcion.code == 'transport_accessible') {
          this.refs.mapa.loadNearTransport('transport_accessible');
        }

        if (opcion.layerType == 'neartransport' && opcion.selected) {
          const apiUri = `${getApiUri()}${opcion.url}`;
          this.addActiveLayer(opcion);
          this.requestNearTransport(apiUri, 'near_public_transport', opcion.code);
        } else {
          if (opcion.url == '') console.log('Datos no disponibles');
          this.activateLayer(opcion);
          this.$forceUpdate();
        }
      }
    },
    getOpcion(groupName, opcionCode) {
      const grupo = this.getGrupoByName(groupName);
      for (const d in grupo.options) {
        const opcion2 = grupo.options[d];
        if (opcion2.code == opcionCode) {
          return opcion2;
        }
        for (const e in opcion2.options) {
          const opcion3 = opcion2.options[e];
          if (opcion3.code == opcionCode) {
            return opcion3;
          }
        }
      }
    },
    getGrupo(opcion) {
      let grupo;
      if (opcion) {
        for (const c in this.layerMenu) {
          grupo = this.layerMenu[c];
          for (const d in grupo.options) {
            const opcion2 = grupo.options[d];
            if (opcion2.code == opcion.code) {
              return grupo;
            }
            for (const e in opcion2.options) {
              const opcion3 = opcion2.options[e];
              if (opcion3.code == opcion.code) {
                return grupo;
              }
            }
          }
        }
      }

      return grupo;
    },

    getGrupoByName(groupName) {
      return find(this.layerMenu, (o) => {
        return o.grupo === groupName;
      });
    },
    isAllGroupLayersSelectedBy(groupName) {
      const grupo = this.getGrupoByName(groupName);
      let num_layers_group = 0;
      let num_layers_group_option = 0;
      for (const d in grupo.options) {
        if (grupo.options[d]?.id) num_layers_group_option++;
        const opcion2 = grupo.options[d];
        if (findIndex(this.activeLayers, (o) => o.code == opcion2.code) >= 0) {
          num_layers_group++;
        }
        for (const e in opcion2.options) {
          if (opcion2.options[e]?.id) num_layers_group_option++;
          const opcion3 = opcion2.options[e];
          if (findIndex(this.activeLayers, (o) => o.code == opcion3.code) >= 0) {
            num_layers_group++;
          }
        }
      }
      return num_layers_group == num_layers_group_option;
    },
    getIcon(opcion) {
      const clase = {};
      let { icon } = opcion;
      if (opcion.icon === undefined) {
        for (const c in this.layerMenu) {
          const grupo = this.layerMenu[c];
          const icon_group = grupo.icon;
          for (const d in grupo.options) {
            const opcion2 = grupo.options[d];
            if (opcion2.code === opcion.code) {
              icon = icon_group;
              break;
            }
            for (const e in opcion2.options) {
              const opcion3 = opcion2.options[e];
              if (opcion3.code === opcion.code) {
                icon = icon_group;
                break;
              }
            }
          }
        }
      }
      clase[icon] = icon;
      if (icon) {
        return clase;
      }
    },
    getOpcionByProperty(value, property) {
      // eslint-disable-next-line prefer-const
      for (let grupo of this.layerMenu) {
        // eslint-disable-next-line prefer-const
        for (let opcion of grupo.options) {
          if (opcion[property] == value) {
            return opcion;
          }
          if (opcion.options) {
            // eslint-disable-next-line prefer-const
            for (let opcion2 of opcion.options) {
              if (opcion2[property] == value) {
                return opcion2;
              }
              if (opcion2.options) {
                // eslint-disable-next-line prefer-const
                for (let opcion3 of opcion.options2) {
                  if (opcion3[property] == value) {
                    return opcion3;
                  }
                }
              }
            }
          }
        }
      }
      return null;
    },
    updateUrl() {
      const transports = ['metro', 'bus', 'ferrocarril', 'rodalies', 'aerobus', 'tram', 'funicular'];
      const layerIDs = [];
      for (var c in this.activeLayers) {
        const layer = this.activeLayers[c];
        if (layer.type && transports.indexOf(layer.type) != -1) {
          layerIDs.push(layer.linea);
        } else {
          layerIDs.push(layer.id);
        }
      }

      let params = '';
      if (this.fromObject && this.toObject) {
        params += `action=planroute&origin_id=${this.fromObject.place_id}&origin=${encodeURIComponent(this.fromValue)}&destination_id=${this.toObject.place_id}&destination=${encodeURIComponent(this.toValue)}&travelMode=${this.modo}&`;
      }
      if (layerIDs.length > 0) {
        params += `layers=${layerIDs.join(',')}`;
      }

      if (params) {
        if (this.lastHistoryState != '?' + params) {
          this.setLastHistoryState('?' + params);
        }
      } else {
        if (this.lastHistoryState != '?') {
          this.setLastHistoryState('?');
        }
      }

      const url = new URL(window.location.href);
      const baseUrl = `${url.protocol}//${url.host}${this.$route.path}`;
      const newUrl = params ? `${baseUrl}?${params}` : baseUrl;
      if (newUrl != window.location.href) {
        window.history.pushState({}, '', newUrl);
      }
    },
    activateLayer(option) {
      if (option.selected) {
        this.addLayerUrl(option);
        if (findIndex(this.activeLayers, (o) => o.code === option.code) < 0) {
          this.addActiveLayer(option);
          if (option?.plus && option.plus.length > 0) {
            for (const c in option.plus) {
              this.loadLayer(option.plus[c]);
            }
          }
          this.loadLayer(option);
        }
      } else {
        this.deleteLayerUrl(option);
        const dataLayer = DataLayer.layers[option.code];
        if (!dataLayer) {
          console.warn(`Layer ${option.code} doesn't exist`);
          return;
        }
        dataLayer.hide();
        dataLayer.visible = false;

        const layerIndex = this.activeLayers.findIndex((o) => o.code === option.code);
        if (layerIndex !== -1) {
          this.activeLayers.splice(layerIndex, 1);
        }
        this.setActiveLayers(this.activeLayers);

        // Optimización 4: Agrupar operaciones comunes
        if (['afectacions', 'carrilsbici', 'eixosverds', 'marbus'].includes(option.code)) {
          this.deleteLayerUrl(option);
        }

        if (option.code === 'afectacions') {
          const dataLayerPoint = DataLayer.getDataLayer('incidencias3');
          dataLayerPoint.hide();
          dataLayerPoint.visible = false;
        } else if (option.code === 'carrilsbici') {
          Object.values(this.directionIcons).forEach((icon) => {
            icon.setMap(null);
            icon.visible = false;
          });
        } else if (option.code == 'lanzaderaforumbogatell') {
          Object.values(this.directionIconsShuttle).forEach((icon) => {
            icon.setMap(null);
            icon.visible = false;
          });
        } else if (option.code === 'eixosverds') {
          [...Object.values(this.directionIconsGreenHubsRequired), ...Object.values(this.directionIconsGreenHubsOptional)].forEach((icon) => {
            icon.setMap(null);
            icon.visible = false;
          });
          ['greenhubsrequired', 'greenhubsoptional'].forEach((layerId) => {
            const layer = DataLayer.getDataLayer(layerId);
            layer.hide();
            layer.visible = false;
          });
        }

        // Optimización 2: Cambiar bucles for-in por forEach para arrays
        if (option?.plus && option.plus.length > 0) {
          option.plus.forEach((point) => {
            const dataLayerPoint = DataLayer.getDataLayer(point.code);
            if (dataLayerPoint) {
              dataLayerPoint.hide();
              dataLayerPoint.visible = false;
            }
          });
        }
        this.mapObj.setZoom(this.mapObj.getZoom() - 0.001);
      }
    },
    deleteAllLayers() {
      for (const c in this.activeLayers) {
        const layer = this.activeLayers[c];
        if (layer) {
          if (layer.linea) {
            const linea = layer;
            let layerLineName = '';
            let layerStationsName = '';
            ({ layerLineName, layerStationsName } = this.selectNameClassTransportForType(linea.type));
            let dataLayer = DataLayer.getDataLayer(`${layerLineName}${linea.linea}`);
            if (dataLayer !== undefined) {
              dataLayer.hide();
              dataLayer.visible = false;
              dataLayer = DataLayer.getDataLayer(`${layerStationsName}${linea.linea}`);
              dataLayer.hide();
              dataLayer.visible = false;
              if (linea.type == 'metro') {
                dataLayer = DataLayer.getDataLayer(`${layerStationsName}${linea.linea}_accessos`);
                dataLayer.hide();
                dataLayer.visible = false;
              }
            }
          } else {
            const dataLayer = DataLayer.getDataLayer(layer.code);
            if (dataLayer) {
              dataLayer.hide();
              dataLayer.visible = false;
            }
            if (layer.code === 'afectacions') {
              const dataLayerPoint = DataLayer.getDataLayer('incidencias3');
              dataLayerPoint.hide();
              dataLayerPoint.visible = false;
            } else if (layer.code === 'carrilsbici') {
              Object.values(this.directionIcons).forEach((icon) => {
                icon.setMap(null);
                icon.visible = false;
              });
            } else if (layer.code === 'eixosverds') {
              [...Object.values(this.directionIconsGreenHubsRequired), ...Object.values(this.directionIconsGreenHubsOptional)].forEach((icon) => {
                icon.setMap(null);
                icon.visible = false;
              });
              ['greenhubsrequired', 'greenhubsoptional'].forEach((layerId) => {
                const layer = DataLayer.getDataLayer(layerId);
                layer.hide();
                layer.visible = false;
              });
            }
          }
          if (layer?.plus && layer.plus.length > 0) {
            layer.plus.forEach((point) => {
              const dataLayerPoint = DataLayer.getDataLayer(point.code);
              if (dataLayerPoint) {
                dataLayerPoint.hide();
                dataLayerPoint.visible = false;
              }
            });
          }
          this.deleteLayerUrl(layer);
        }
      }
      this.selectAllTransport(false);
      this.resetActiveLayers();
      this.resetLayerMenuSelected();
      this.$forceUpdate();
    },
    updateLayerUrl(option, action) {
      const url = new URL(window.location.href);
      const layer = url.searchParams.get('layers');
      let layerDecoded = decodeURIComponent(layer).split(',').filter((o) => o != 'null');

      const updateUrl = (value) => {
        const baseUrl = `${url.protocol}//${url.host}${url.pathname}`;
        const otherParams = [...url.searchParams].filter(([k]) => k !== 'layers').map(([k, v]) => `${k}=${v}`).join('&');
        let newUrl = baseUrl;
        if (value) {
          newUrl = `${baseUrl}?layers=${value}${otherParams ? `&${otherParams}` : ''}`;
        } else if (otherParams) {
          newUrl = `${baseUrl}?${otherParams}`;
        }
        // Update if the new url is different from the current url
        if (newUrl != window.location.href) {
          window.history.pushState({}, '', newUrl);
        }
      };

      if (action === 'add') {
        if (option.id && !layerDecoded.includes(String(option.id))) {
          layerDecoded.push(option.id);
        } else if (option.linea && !layerDecoded.includes(option.linea)) {
          layerDecoded.push(option.linea);
        }
        updateUrl(layerDecoded.filter((o) => o).toString());
      } else if (action === 'delete') {
        if (option.id) {
          layerDecoded = layerDecoded.filter((o) => o != option.id.toString());
        } else if (option.linea && layerDecoded.includes(option.linea)) {
          layerDecoded = layerDecoded.filter((o) => o != option.linea);
        }
        updateUrl(layerDecoded.join(','));
      }
    },
    addLayerUrl(option) {
      this.updateLayerUrl(option, 'add');
    },
    deleteLayerUrl(option) {
      this.updateLayerUrl(option, 'delete');
    },
    hasGroupLayers(group_code) {
      let num_layers_group = 0;
      let grupo;
      for (const c in this.layerMenu) {
        grupo = this.layerMenu[c];
        if (this.layerMenu[c].grupo == group_code) {
          for (const d in grupo.options) {
            const opcion2 = grupo.options[d];
            if (findIndex(this.activeLayers, (o) => o.code == opcion2.code) >= 0) {
              num_layers_group++;
            }
            for (const e in opcion2.options) {
              const opcion3 = opcion2.options[e];
              if (findIndex(this.activeLayers, (o) => o.code == opcion3.code) >= 0) {
                num_layers_group++;
              }
            }
          }
        }
      }
      if (group_code == 'public_transport') {
        for (const layer of this.activeLayers) {
          const transport_types = ['metro', 'bus', 'ferrocarril', 'rodalies', 'tram', 'aerobus', 'funicular'];
          if (findIndex(transport_types, (o) => o == layer.type) >= 0) {
            num_layers_group++;
          }
        }
      }
      return num_layers_group;
    },
    loadLayerByConfig(config, url) {
      let dataLayer = DataLayer.getDataLayer(config.name);
      if (dataLayer !== undefined) {
        delete DataLayer.layers[config.name];
      }
      dataLayer = new DataLayer(config, this.mapObj);
      dataLayer.addGeoJsonDataLayer(`${getApiUri()}${url}`).then(() => {
        setTimeout(() => {
          dataLayer.show();
          dataLayer.setVisibilityByZoom();
        }, 3000);
      });
    },
    showDisplayInfo(feature, opcion) {
      this.refs.infoPanel.initInfoPanel();
      let infoTitle = feature.getProperty(opcion.infoTitle);
      if (opcion.infoTitleTxt) {
        infoTitle = this.$t(opcion.infoTitleTxt);
      }
      if (feature.getProperty('ACT_DESCRIPCIO') !== undefined) {
        infoTitle = feature.getProperty('ACT_DESCRIPCIO');
        const datos = [];
        datos.push({
          label: '',
          data: `Des de ${this.getIncidenciaDate(feature.getProperty('ACT_DESDE'))}
            fins a ${this.getIncidenciaDate(feature.getProperty('ACT_FINS'))}`,
        });
        this.setInfoPanel({
          datos,
        });
      }
      if (opcion.markerIconFlag) {
        opcion.markerIcon = feature.getProperty('flag');
      }
      this.setInfoPanel({
        title: infoTitle,
        feature,
        code: opcion.code,
        icon_title: `markers/${opcion.iconFolder}/${opcion.markerIcon}`,
        type: 'info_point',
        datos: [],
      });

      let link = '';

      // Traffic cameras
      if (opcion.code === 'camarastrafico') {
        if (feature.getProperty('pk') !== '') {
          this.setInfoPanel({
            title: `${feature.getProperty('carretera')}(Km ${feature.getProperty('pk')})`,
          });
        }

        this.setInfoPanel({
          foto: `<img src="${feature.getProperty('link')}" class="w-100">`,
        });
      }
      for (const i in opcion.info) {
        const datos = opcion.info[i];
        let value = (feature.getProperty(datos.value) != null) ? feature.getProperty(datos.value) : '';
        const { label } = datos;
        if (value) {
          if (datos.type == 'url' && value) {
            link = (value.indexOf('https') != -1) ? value : `https://${value}`;
            value = `<a href="${link}" target="_blank">${value}</a>`;
          }
          if (feature.getProperty(datos.value) !== '') {
            if (opcion.code === 'bicing' && opcion.info[i].value === 'slots') {
              value -= feature.getProperty('bikes');
            }
            const datos = clone(this.infoPanel.datos);
            datos.push({
              label: this.$t(label),
              data: value,
            });
            this.setInfoPanel({
              datos,
            });
          }
        }
      }

      // Bici-friendly buildings
      if (opcion.code === 'bicifriends') {
        const datos = clone(this.infoPanel.datos);
        datos.push({
          label: (feature.getProperty('any_certificacio') === 0) ? this.$t('certified_process_building') : this.$t('certified_building'),
          data: '',
        });
        if (feature.getProperty('aparcaments_interiors_visitants') > 0) {
          datos.push({
            label: this.$t('indoor_visitor_parkings'),
            data: feature.getProperty('aparcaments_interiors_visitants'),
          });
        }
        if (feature.getProperty('aparcaments_exteriors_visitants') > 0) {
          datos.push({
            label: this.$t('outdoor_visitor_parkings'),
            data: feature.getProperty('aparcaments_exteriors_visitants'),
          });
        }
        this.setInfoPanel({
          datos,
        });
      }

      this.setInfoPanel({
        visible: true,
      });

      this.refs.infoPanel.initPanel();
    },

    loadLayer(opcion) {
      const grupo = this.getGrupo(opcion);
      let dataLayer = DataLayer.getDataLayer(opcion.code);
      if (dataLayer !== undefined) {
        delete DataLayer.layers[opcion.code];
      }
      const config = {
        name: opcion.code,
        minZoomLevel: opcion.minZoomLevel,
        visible: true,
      };

      if (opcion.adaptativeMarkers) {
        config.adaptativeMarkers = true;
        config.style = {
          path: `/images/markers/${opcion.iconFolder}`,
          marker: opcion.markerIcon,
          icon: opcion.markerIcon,
        };
      }
      if (opcion.style !== undefined) {
        config.style = opcion.style;
      }

      if (opcion.displayInfo !== undefined) {
        var self = this;
        config.displayInfo = true;
        config.callback = {
          displayInfo(feature, config, isWidgetMain) {
            const displayInfoCallback = () => {
              self.showDisplayInfo(feature, opcion);
            };

            self.setWidgetCallback(displayInfoCallback);

            if (!isWidgetMain) {
              displayInfoCallback();
            }
          },
        };
      }
      if (opcion.idField) {
        config.idField = opcion.idField;
      }
      if (opcion.maxZoomClusteringLevel) {
        config.maxZoomClusteringLevel = opcion.maxZoomClusteringLevel;
        config.clustering = true;
        config.clusterAnchor = [-10, 0];
        config.clusterTextSize = 11;
        config.clusterTextColor = '#ffffff';
        config.clusterFontWeigth = 'bold';
        config.clusterURL = '/cluster/';
        config.clusterGridSize = 60;
        config.clusterIconSize = [24, 35];
        config.style = {
          path: `/images/markers/${opcion.iconFolder}`,
          marker: opcion.markerIcon,
          clusterMarker: opcion.clusterMarker,
          icon: opcion.markerIcon,
        };
      }
      dataLayer = new DataLayer(config, this.mapObj);
      if (opcion.url) {
        dataLayer.addGeoJsonDataLayer(`${getApiUri()}${opcion.url}`).then(() => {
          bcn.statistics({ track: [encodeURI('Capa-dades'), 'Mobile', opcion.code] });
          if (dataLayer.layerName.indexOf('traficoactual') !== -1) {
            this.setTrafficState(dataLayer.layerName, this.futureTraffic);
          }
          if (dataLayer.layerName === 'afectacions') {
            this.loadIncidencias(config);
          }
          if (dataLayer.layerName === 'carrilsbici') {
            this.loadArrowDirections(dataLayer);
          }
          if (dataLayer.layerName == 'lanzaderaforumbogatelllines') {
            this.loadArrowDirectionsShuttle(dataLayer);
          }
          if (dataLayer.layerName === 'puntsancoratgebici') {
            this.loadBicing(dataLayer);
          }
          if (dataLayer.layerName === 'bicing') {
            this.loadBicing(dataLayer);
          }
          if (dataLayer.layerName == 'team_headquarters' || dataLayer.layerName == 'pointinterest') {
            this.loadTeamQuarters(dataLayer);
          }
          if (dataLayer.layerName === 'bicifriends') {
            this.loadBiciFriends(dataLayer);
          }
          if (dataLayer.layerName === 'eixosverds') {
            this.loadGreenHubsRequired(dataLayer);
            this.loadGreenHubsOptional(dataLayer);
            this.loadGreenHubsNew(dataLayer);
          }
          dataLayer.show();
          dataLayer.setVisibilityByZoom();

          // Matomo
          // eslint-disable-next-line no-underscore-dangle
          window._mtm.push({
            event: 'comshiva',
            eventcategory: 'ComShiVa',
            eventaction: 'Capes',
            eventname: this.$t(opcion.nombre), // (opcion.id)
          });
        });
      }
    },

    getIconActiveLayers(linea) {
      let icon = '';

      switch (linea.type) {
        case 'metro':
          icon = `metro/${linea.linea}.png`;
          break;
        case 'ferrocarril':
          icon = 'csvicon-metro';
          break;
        case 'rodalies':
          icon = 'csvicon-metro';
          break;
        case 'tram':
          icon = 'csvicon-tranvia';
          break;
        case 'aerobus':
          icon = 'csvicon-bus';
          break;
        case 'funicular':
          icon = 'csvicon-funicular';
          break;
        default:
          break;
      }
      return icon;
    },

    setTrafficState(dataLayerName, future) {
      var trafficService = 'servicios/traffic/';
      /*
      Estado del tráfico
       1 = muy fluido,{background-color: #33ffff}  // azul
       2 = fluido, {background-color: #66ff00}     // verde
       3 = denso, {background-color: #ffff99}      // amarillo
       4 = muy denso,  {background-color: #ff9966} // naranja
       5 = congestión, {background-color: #ff0066} // rojo
       0 = sin datos, {background-color: #cccccc}  // gris
       6 = cortado,{background-color: #000000}     // negro
       99 = no match {background-color: #cccccc}   // valor propio para pintar los step sin match con el fichero de tramos
      */
      var trafficStyles = [];
      trafficStyles[1] = {
        strokeColor: '#33ffff',
        strokeOpacity: 1.0,
        strokeWeight: 8,
        zIndex: 999,
      };
      trafficStyles[2] = {
        strokeColor: '#66ff00',
        strokeOpacity: 1.0,
        strokeWeight: 8,
        zIndex: 999,
      };
      trafficStyles[3] = {
        strokeColor: '#ffff99',
        strokeOpacity: 1.0,
        strokeWeight: 8,
        zIndex: 99,
      };
      trafficStyles[4] = {
        strokeColor: '#ff9966',
        strokeOpacity: 1.0,
        strokeWeight: 8,
        zIndex: 999,
      };
      trafficStyles[5] = {
        strokeColor: '#ff0066',
        strokeOpacity: 1.0,
        strokeWeight: 8,
        zIndex: 999,
      };
      trafficStyles[0] = {
        strokeColor: '#cccccc',
        strokeOpacity: 1.0,
        strokeWeight: 8,
        zIndex: 999,
      };
      trafficStyles[6] = {
        strokeColor: '#000000',
        strokeOpacity: 1.0,
        strokeWeight: 8,
        zIndex: 999,
      };
      trafficStyles[99] = { // no cambiar el índice
        strokeColor: '#cccccc',
        strokeOpacity: 1,
        strokeWeight: 8,
        zIndex: 999,
      };
      this.refs.infoPanel.initInfoPanel();
      const target = this;
      this.setInfoPanel({
        title: 'Llegenda',
        code: 'legend_traffict',
        datos: [
          { label: 'Molt fluid', data: trafficStyles[1].strokeColor },
          { label: 'Fluid', data: trafficStyles[2].strokeColor },
          { label: 'Dens', data: trafficStyles[3].strokeColor },
          { label: 'Molt dens', data: trafficStyles[4].strokeColor },
          { label: 'Congestió', data: trafficStyles[5].strokeColor },
          { label: 'Sense dades', data: trafficStyles[0].strokeColor },
          { label: 'Tallat', data: trafficStyles[6].strokeColor },
          { label: 'ZBE', data: '#009D7E' },
        ],
      });

      api.get(`${getApiUri()}${trafficService}`).then((response) => {
        const layer = DataLayer.getDataLayer(dataLayerName);
        for (let i = 0; i < response.data.results.length; i++) {
          const path = response.data.results[i];
          const id = path.path_id.trim();
          if (layer.getFeatureById(id)) {
            let hour = parseInt(path.date.substr(8, 2));
            let minutes = parseInt(path.date.substr(10, 2)) + 15;
            const year = path.date.substr(0, 4);
            const month = path.date.substr(4, 2);
            const day = path.date.substr(6, 2);
            const fecha = `${day}/${month}/${year}`;
            if (minutes >= 60) {
              minutes -= 60;
              hour += 1;
            }
            // eslint-disable-next-line prefer-template
            const hourTxt = hour.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
            this.setFutureTrafficHour(hourTxt);
            this.setFutureTrafficDate(fecha);

            let state = path.actual_state;
            if (future) state = path.future_state;
            const color = trafficStyles[state].strokeColor;
            layer.overrideStyle(layer.getFeatureById(id), { strokeColor: color });
          }
        }
      });
    },

    loadArrowGreenHubsDirections(dataLayerGreenHubs, color, directionIcons) {
      const configArrows = cloneDeep(dataLayerGreenHubs.conf);
      configArrows.name = `${dataLayerGreenHubs.get('layerName')}_arrows`;
      configArrows.minZoomLevel = 15;
      directionIcons = [];
      const featarr = dataLayerGreenHubs.getAllFeatures();
      for (var c in featarr) {
        var feature = featarr[c];
        const coordinates = [];
        const geometry = feature.getGeometry();
        geometry.forEachLatLng((latlng) => {
          coordinates.push(latlng);
        });
        var polyline = new google.maps.Polyline({
          path: coordinates,
          strokeColor: color,
          strokeOpacity: 0,
          strokeWeight: 1,
          geodesic: true,
          icons: [{
            icon: {
              path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
              strokeColor: color,
              strokeOpacity: 1,
              rotation: 0,
              fillOpacity: 0,
              scale: 2,
            },
            offset: '100%',
          }],
        });
        directionIcons.push(polyline);
      }
      for (const c in directionIcons) {
        if (this.mapObj.getZoom() >= 15) {
          directionIcons[c].setMap(this.mapObj);
          directionIcons[c].visible = true;
        }
      }
    },
    loadArrowDirectionsShuttle(dataLayerShuttle) {
      const configArrows = cloneDeep(dataLayerShuttle.conf);
      configArrows.name = `${dataLayerShuttle.get('layerName')}_arrows`;
      configArrows.minZoomLevel = 15;

      const featarr = dataLayerShuttle.getAllFeatures();
      // eslint-disable-next-line prefer-const
      let directionIcons = [];
      for (var c in featarr) {
        var feature = featarr[c];
        const coordinates = [];
        const geometry = feature.getGeometry();
        geometry.forEachLatLng((latlng) => {
          coordinates.push(latlng);
        });
        var left = 'M -26.25 0.3 L -34.35 10.275 L 0.075 56.1 L 34.875 10.275 L 26.85 0.3 L 0.075 36.15 z';
        var path = left;
        var rotation = 180;
        const polyline = new google.maps.Polyline({
          path: coordinates,
          strokeColor: '#D82323',
          strokeOpacity: 0,
          strokeWeight: 1,
          geodesic: true,
          icons: [
            {
              icon: {
                path,
                fillColor: '#D82323',
                rotation,
                fillOpacity: 1,
                scale: 0.14,
              },
              offset: '30px',
              repeat: '90px',
            },
          ],
        });
        directionIcons.push(polyline);
        for (const c in directionIcons) {
          if (this.mapObj.getZoom() >= 15) {
            directionIcons[c].setMap(this.mapObj);
            directionIcons[c].visible = true;
          }
        }
        this.setDirectionIconsShuttle(directionIcons);
      }
    },
    loadArrowDirections(dataLayerCicleLanes) {
      const configArrows = cloneDeep(dataLayerCicleLanes.conf);
      configArrows.name = `${dataLayerCicleLanes.get('layerName')}_arrows`;
      configArrows.minZoomLevel = 15;

      const dataLayerArrows = new DataLayer(configArrows, this.mapObj);
      const featarr = dataLayerCicleLanes.getAllFeatures();
      const directionTypes = ['UNIDIRECCIONAL', 'BIDIRECCIONAL'];
      const arrowsFeatures = [];
      // eslint-disable-next-line prefer-const
      let directionIcons = [];
      for (var c in featarr) {
        var feature = featarr[c];
        var type = '';
        if (feature.getProperty('ETIQUETA').indexOf('unidireccional') !== -1) type = 'UNIDIRECCIONAL';
        if (feature.getProperty('ETIQUETA').indexOf('bidireccional') !== -1) type = 'BIDIRECCIONAL';
        if (directionTypes.indexOf(type) !== -1) {
          const coordinates = [];
          const geometry = feature.getGeometry();
          geometry.forEachLatLng((latlng) => {
            coordinates.push(latlng);
          });
          var left = 'M -35,0.4 -45.8,13.7 0.1,74.8 46.5,13.7 35.8,0.4 0.1,48.2 z';
          var right = 'M 35.8,74.8 46.5,61.5 0.7,0.4 -45.8,61.5 -35,74.8 0.7,27 z';
          var path = left;
          var rotation = 180;
          const polyline = new google.maps.Polyline({
            path: coordinates,
            strokeColor: '#D82323',
            strokeOpacity: 0,
            strokeWeight: 1,
            geodesic: true,
            icons: [
              {
                icon: {
                  path,
                  fillColor: '#D82323',
                  rotation,
                  fillOpacity: 1,
                  scale: 0.14,
                },
                offset: '30px',
                repeat: '90px',
              },
            ],
          });
          directionIcons.push(polyline);
          if (type === 'BIDIRECCIONAL') {
            const polyline2 = new google.maps.Polyline({
              path: coordinates,
              strokeColor: '#D82323',
              strokeOpacity: 0,
              strokeWeight: 1,
              geodesic: true,
              icons: [
                {
                  icon: {
                    path: right,
                    fillColor: '#D82323',
                    rotation: 180,
                    fillOpacity: 1,
                    scale: 0.14,
                  },
                  offset: '10px',
                  repeat: '90px',
                },
              ],
            });
            directionIcons.push(polyline2);
          }
        }
      }
      for (const c in directionIcons) {
        if (this.mapObj.getZoom() >= 15) {
          directionIcons[c].setMap(this.mapObj);
          directionIcons[c].visible = true;
        }
      }
      this.setDirectionIcons(directionIcons);
    },

    loadIncidencias(config) {
      config.name = 'incidencias3';
      config.adaptativeMarkers = true;
      config.style = {
        path: '/images/markers/trafico',
        marker: 'obras.svg',
        icon: 'obras.svg',
      };
      const dataLayer = new DataLayer(config, this.mapObj);
      dataLayer.addGeoJsonDataLayer(`${getApiUri()}incidencies/3/`).then(() => {
        dataLayer.show();
      });
    },

    loadGreenHubsRequired(config) {
      config.name = 'greenhubsrequired';
      config.adaptativeMarkers = true;
      config.style = {
        strokeColor: '#00cc00',
        strokeOpacity: 1,
        strokeWeight: 3,
      };
      const dataLayer = new DataLayer(config, this.mapObj);
      dataLayer.addGeoJsonDataLayer(`${getApiUri()}eixosverdsobligatori/`).then(() => {
        dataLayer.show();
        this.loadArrowGreenHubsDirections(dataLayer, '#00cc00', this.directionIconsGreenHubsRequired);
      });
    },

    loadGreenHubsOptional(config) {
      config.name = 'greenhubsoptional';
      config.adaptativeMarkers = true;
      config.style = {
        strokeColor: '#5f8ed9',
        strokeOpacity: 1,
        strokeWeight: 3,
      };
      const dataLayer = new DataLayer(config, this.mapObj);
      dataLayer.addGeoJsonDataLayer(`${getApiUri()}eixosverdsopcional/`).then(() => {
        dataLayer.show();
        this.loadArrowGreenHubsDirections(dataLayer, '#5f8ed9', this.directionIconsGreenHubsOptional);
      });
    },

    loadGreenHubsNew(config) {
      config.name = 'greenhubsnew';
      config.adaptativeMarkers = true;
      config.style = {
        strokeColor: '#0b3d8c',
        strokeOpacity: 1,
        strokeWeight: 3,
      };
      const dataLayer = new DataLayer(config, this.mapObj);
      dataLayer.addGeoJsonDataLayer(`${getApiUri()}eixosverdsnougir/`).then(() => {
        dataLayer.show();
        this.loadArrowGreenHubsDirections(dataLayer, '#0b3d8c', this.directionIconsGreenHubsNew);
      });
    },

    getIncidenciaDate(date, input_format = 'yyyy-LL-dd HH:mm', output_format = DateTime.DATETIME_SHORT) {
      return DateTime.fromFormat(date, input_format).toLocaleString(output_format);
    },

    loadBicing(dataLayer) {
      dataLayer.setStyle((feature) => {
        var capacity = feature.getProperty('slots');
        var bikes = feature.getProperty('bikes');
        var slots = capacity - bikes;
        var percentage = (bikes * 100) / capacity;
        var icon = 'bicing.svg';
        if (percentage < 90) icon = 'bicing-75.svg';
        if (percentage < 60) icon = 'bicing-50.svg';
        if (percentage < 35) icon = 'bicing-25.svg';
        if (percentage === 0) icon = 'bicing-0.svg';
        const config = dataLayer.conf;
        var { style } = config;
        style.icon = {
          url: `${style.path}/${icon}`,
        };
        return style;
      });
    },
    loadTeamQuarters(dataLayer) {
      dataLayer.setStyle((feature) => {
        var flag = feature.getProperty('flag');
        const config = dataLayer.conf;
        var { style } = config;
        style.icon = {
          url: `${style.path}/${flag}`,
        };
        return style;
      });
    },
    loadBiciFriends(dataLayer) {
      dataLayer.setStyle((feature) => {
        let icon = 'edifici_amicbici_certified.svg';
        if (feature.getProperty('any_certificacio') === '') {
          icon = 'edifici_amicbici_in_process.svg';
        }
        const config = dataLayer.conf;
        var { style } = config;
        style.icon = {
          url: `${style.path}/${icon}`,
        };
        return style;
      });
    },

    loadTaxi() {
      const transportGroup = find(this.layerMenu, (o) => {
        return o.code === 'transport';
      });
      const optionTaxi = find(transportGroup.options, (o) => {
        return o.code === 'taxi';
      });
      this.selectOpcion(optionTaxi);
    },
    loadBusMaritime() {
      const transportGroup = find(this.layerMenu, (o) => {
        return o.code === 'transport';
      });
      const optionBusMar = find(transportGroup.options, (o) => {
        return o.code === 'marbus';
      });
      this.selectOpcion(optionBusMar);
    },
    alterationAlertVisible(line) {
      if (line === undefined) return false;
      for (const c in this.alterationMessages) {
        const incidencia = this.alterationMessages[c];
        if (incidencia.linia.toLowerCase() === line.toLowerCase()
          && new Date(incidencia.data_inici) < new Date()
          && new Date(incidencia.data_fi) > new Date()) {
          return incidencia;
        }
        if (incidencia.linea === 'T4' && line === 'T4'
          && new Date(incidencia.data_inici) < new Date()
          && new Date(incidencia.data_fi) > new Date()) {
          return incidencia;
        }
      }
      return false;
    },
    loadHighlights() {
      const target = this;
      const config = {
        name: 'highlights',
        minZoomLevel: 10,
        visible: true,
        adaptativeMarkers: true,
        style: {
          path: '/images/markers/publico',
          marker: 'highlight.svg',
          icon: 'highlight.svg',
          clusterMarker: 'highlight.svg',
        },
        clustering: true,
        maxZoomClusteringLevel: 16,
        clusterAnchor: [-10, 0],
        clusterTextSize: 11,
        clusterTextColor: '#ffffff',
        clusterFontWeigth: 'bold',
        clusterURL: '/cluster/',
        clusterGridSize: 60,
        clusterIconSize: [24, 35],
        displayInfo: true,
        infoTitle: 'Nom',
        info: [],
        callback: {
          displayInfo(feature, config) {
            target.refs.infoPanel.initInfoPanel();
            const infoTitle = feature.getProperty('Nom');
            target.setInfoPanel({
              title: infoTitle,
              feature,
              code: 'highlights',
              icon_title: 'markers/publico/highlight.svg',
              type: 'info_point',
              datos: [],
              visible: true,
            });
            target.refs.infoPanel.initPanel();
          },
        },
      };
      this.loadLayerByConfig(config, 'highlights/');
    },
    getLayerChip(type) {
      let chip = {};
      switch (type) {
        case 'walk':
          chip = {
            code: 'totapeu',
            group: 'walk',
            nombre: 'all_foot',
            layerColorBackground: '#fff',
            layerIcon: 'walking.svg',
            selected: true,
            selectAll: true,
          };
          break;
        case 'bici':
          chip = {
            code: 'totmobilitatbici',
            group: 'bici',
            nombre: 'all_bici',
            layerColorBackground: '#fff',
            layerIcon: 'bicycle.svg',
            selected: true,
            selectAll: true,
          };
          break;
        case 'transit':
          chip = {
            code: 'tottransit',
            group: 'transit',
            nombre: 'all_traffic',
            layerColorBackground: '#fff',
            layerIcon: 'car.svg',
            selected: true,
            selectAll: true,
          };
          break;
        default:
          break;
      }

      return chip;
    },
    getLayerChipPublicTransport(type) {
      let chip = {};
      switch (type) {
        case 'metro':
          chip = {
            color: '93248F',
            descripcio: 'all_metro',
            estacions: null,
            group: 'public_transport',
            icon: 'metro',
            linea: null,
            reverse: false,
            selected: true,
            type: 'metro',
            selectAll: true,
          };
          break;
        case 'bus':
          chip = {
            color: 'e00000',
            descripcio: 'all_bus',
            estacions: null,
            group: 'public_transport',
            icon: 'bus',
            linea: null,
            reverse: false,
            selected: true,
            type: 'bus',
            selectAll: true,
          };
          break;
        case 'ferrocarril':
          chip = {
            color: '88BB0B',
            descripcio: 'all_fgc',
            estacions: null,
            group: 'public_transport',
            icon: 'fgc-centrado',
            linea: null,
            reverse: false,
            selected: true,
            type: 'ferrocarril',
            selectAll: true,
          };
          break;
        case 'rodalies':
          chip = {
            color: 'EF7D00',
            descripcio: 'all_rodalies',
            estacions: null,
            group: 'public_transport',
            icon: 'rodalies-centrado',
            linea: null,
            reverse: false,
            selected: true,
            type: 'rodalies',
            selectAll: true,
          };
          break;
        case 'tram':
          chip = {
            color: '008173',
            descripcio: 'all_tram',
            estacions: null,
            group: 'public_transport',
            icon: 'tram-centrado',
            linea: null,
            reverse: false,
            selected: true,
            type: 'tram',
            selectAll: true,
          };
          break;
        default:
          break;
      }

      return chip;
    },
  },
};

export const dummy = {};

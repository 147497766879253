const state = () => ({
  isWidget: false,
  isWidgetRoute: false,
  widgetType: '',
  lastHistoryState: null,
  showHelp: false,
  helpViewed: false,
  stepHelp: 0,
  site: 'comshiva',
  notificationWarningViewed: false,
  notificationWarningVisible: false,
  messageReceivedVisible: false,
  messageReveived: null,
});

const mutations = {
  setIsWidget(state, widget) {
    state.isWidget = widget;
  },
  setIsWidgetRoute(state, widget) {
    state.isWidgetRoute = widget;
  },
  setWidgetType(state, type) {
    state.widgetType = type;
  },
  setLastHistoryState(state, historyState) {
    state.lastHistoryState = historyState;
  },
  setShowHelp(state, show) {
    state.showHelp = show;
  },
  setHelpViewed(state, show) {
    state.helpViewed = show;
  },
  setStepHelp(state, step) {
    state.stepHelp = step;
  },
  setSite(state, site) {
    state.site = site;
  },
  setNotificationWarningViewed(state, status) {
    state.notificationWarningViewed = status;
  },
  setNotificationWarningVisible(state, status) {
    state.notificationWarningVisible = status;
  },
  setMessageReceivedVisible(state, status) {
    state.messageReceivedVisible = status;
  },
  setMessageReceived(state, message) {
    state.messageReveived = message;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};

<template>
  <b-modal
    id="route-recents-modal"
    scrollable
    hide-footer>
    <template #modal-header>
      <div class="m-header">
        <img @click="$bvModal.hide('route-recents-modal')"
              src="/images/icons/back-arrow.svg" alt="">
        <span @click="$bvModal.hide('route-recents-modal')" class="title">{{ $t('recent_trips') }}</span>
      </div>
    </template>
    <b-container fluid>
      <div class="m-body my-2">
        <template v-if="recentRoutes.length">
          <div v-for="(item, index) in recentRoutes.slice(0, 7).reverse()" :key="index" @click="routeSelected(item)">
            <div class="routes-card my-8 row">
              <div class="col-1 align-self-center pl-0">
                <span class="row circle mx-0"></span>
                <span class="row line mx-0"></span>
                <span class="row circle mx-0"></span>
              </div>
              <div class="col-9 routes-name align-self-center mr-4">
                <span class="row mb-3">{{ item.fromValue }}</span>
                <span class="row">{{ item.toValue }}</span>
              </div>
              <div class="col-2 routes-icon-car align-self-center">
                <img :src="`/images/icons/${item.modo.toLowerCase()}.svg`" alt="">
              </div>
            </div>
            <div v-if="index != recentRoutes.length - 1" class="dropdown-divider"></div>
          </div>
        </template>
        <template v-else>
          <div class="text-center mt-5">
            <span>{{ $t('not_trips') }}</span>
          </div>
        </template>
      </div>
    </b-container>
  </b-modal>
</template>
<script>

import { mapState, mapMutations } from 'vuex';

export default {
  data() {
    return {
      refs: null,
    };
  },
  computed: {
    ...mapState('map', [
      'isWidget',
    ]),
    ...mapState('planroute', [
      'recentRoutes',
    ]),
  },
  methods: {
    ...mapMutations({
      setModo: 'planroute/setModo',
      setFromValue: 'planroute/setFromValue',
      setToValue: 'planroute/setToValue',
      setFromObject: 'planroute/setFromObject',
      setToObject: 'planroute/setToObject',
      setWhenToGo: 'planroute/setWhenToGo',
      setTransitDate: 'planroute/setTransitDate',
      setTransitTime: 'planroute/setTransitTime',
      setAvoidTolls: 'planroute/setAvoidTolls',
      setAvoidHighways: 'planroute/setAvoidHighways',
    }),
    routeSelected(route) {
      this.refs.planificador.resetPlanificador();

      this.setModo(route.modo);
      this.setFromValue(route.fromValue);
      this.setToValue(route.toValue);
      this.setFromObject(route.fromObject);
      this.setToObject(route.toObject);
      this.setWhenToGo(route.whenToGo);
      this.setTransitDate(route.transitDate);
      this.setTransitTime(route.transitTime);
      this.setAvoidTolls(route.avoidTolls);
      this.setAvoidHighways(route.avoidHighways);

      this.refs.planificador.planRoute();
      this.$bvModal.hide('route-recents-modal');
    },
  },
  created() {
    this.refs = this.isWidget === true ? this.$root.$children[0].$children[0].$children[0].$refs : this.$root.$children[0].$children[0].$refs;
  },
};
</script>

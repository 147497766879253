<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { isMobile } from 'mobile-device-detect';
import IncidentsMixins from './components/mixins/IncidentsMixins';
import './classes/maps/Util';
import './classes/GoogleRoutesApi';
import './classes/LineaTransportAerobus';
import './classes/LineaTransportBus';
import './classes/LineaTransportCercanias';
import './classes/LineaTransportFerrocarril';
import './classes/LineaTransportFunicular';
import './classes/LineaTransportMetro';
import './classes/LineaTransportTram';

import api, { getToken } from './api/api';

export default {
  components: {},
  data() {
    return {};
  },
  mounted() {
    window.addEventListener('beforeunload', (e) => {
      e.preventDefault();
      e.returnValue = '';
    });
  },
  mixins: [IncidentsMixins],
  computed: {
    ...mapState('planroute', [
      'selectedLocation',
    ]),
  },
  methods: {
    ...mapMutations({
      setToken: 'setToken',
      setRecentRoutes: 'planroute/setRecentRoutes',
    }),
    loadTransport() {
      const lineaTransportMetro = new LineaTransportMetro();
      lineaTransportMetro.loadTransport();
      const lineaTransportBus = new LineaTransportBus();
      lineaTransportBus.loadTransport();
      const lineaTransportAerobus = new LineaTransportAerobus();
      lineaTransportAerobus.loadTransport();
      const lineaTransportFunicular = new LineaTransportFunicular();
      lineaTransportFunicular.loadTransport();
      const lineaTransportTram = new LineaTransportTram();
      lineaTransportTram.loadTransport();
      var lineaTransportCercanias = new LineaTransportCercanias();
      lineaTransportCercanias.loadTransport();
      const lineaTransportFerrocarril = new LineaTransportFerrocarril();
      lineaTransportFerrocarril.loadTransport();
    },
  },
  async beforeCreate() {
    if (!isMobile) {
      const urlDesktop = `${process.env.VUE_APP_DESKTOP_BASE_URL}${window.location.pathname}`;
      window.location.href = urlDesktop;
    }

    const token = await getToken();
    this.setToken(token);
    this.loadTransport();
    if (localStorage.trafficIncidents !== undefined) {
      this.setTrafficIncidents(JSON.parse(localStorage.trafficIncidents));
    }
    if (localStorage.recentsRoutes !== undefined) {
      this.setRecentRoutes(JSON.parse(localStorage.recentsRoutes));
    }
    this.initLoadingIncidents();
    // cada 7200 segundos se refresca el token
    const tokenRefreshTimeSeconds = 3600 * 1000;
    setInterval(async () => {
      const token = await getToken();
      this.setToken(token);
    }, tokenRefreshTimeSeconds);
  },
};
</script>

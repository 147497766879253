const state = () => ({
  showHelp: false,
});

const mutations = {
  setShowHelp(state, show) {
    state.showHelp = show;
    localStorage.showHelp = show;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};

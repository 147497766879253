import { find, findIndex, cloneDeep } from 'lodash';
import { mapState } from 'vuex';
import { getApiUri } from '../../api/util';
import api from '../../api/api';
import { app } from '../../main';

export const MapMixins = {
  data() {
    return {
      refs: null,
    };
  },
  computed: {
    ...mapState('map', [
      'isWidget',
    ]),
  },
  created() {
    this.refs = this.isWidget === true ? this.$root.$children[0].$children[0].$children[0].$refs : this.$root.$children[0].$children[0].$refs;
  },
  methods: {
    loadNearTransport(type = 'near_public_transport') {
      if (findIndex(this.activeLayers, (o) => o.code == type) < 0) {
        if (DataLayer.layers[type] !== undefined) {
          const dataLayerNearTransport = DataLayer.layers[type];
          dataLayerNearTransport.set('visible', false);
          dataLayerNearTransport.removeAllFeatures();
          dataLayerNearTransport.hideClustering();
          dataLayerNearTransport.setMap(null);
          const cluster = dataLayerNearTransport.get('mc');
          if (cluster) {
            dataLayerNearTransport.get('mc').removeMarkers(dataLayerNearTransport.cluster);
          }
        }
      }

      if (this.mapObj == undefined) return;
      const currentPos = this.mapObj.getCenter();
      const distance = (type == 'near_public_transport') ? 500 : 10000;
      let apiUri = `${getApiUri()}near-transport/?distance=${distance}&lat=${currentPos.lat()}&lng=${currentPos.lng()}`;
      if (type == 'transport_accessible') apiUri += '&accessible=1';
      this.requestNearTransport(apiUri, type);
    },
    requestNearTransport(url, type, CAType = null) {
      const markerCluster = (type == 'near_public_transport') ? 'transport.svg' : 'accessible.svg';
      api.get(url).then((response) => {
        // Build features
        const features = this.getNearTransportFeatures(response.data.results, type);
        const visibleFeatures = [];
        for (let i = 0; i < features.length; i++) {
          if (features[i].getProperty('tipo') != 'bicing') {
            visibleFeatures.push(features[i]);
          }
        }

        const nameLayer = CAType || type;
        // Layer config
        const configNearTransport = {
          ZOOM: 17,
          zoomPoint: 17,
          setMarkerSearch: true,
          displayInfo: true,
          adaptativeMarkers: true,
          iconFolder: 'publico',
          iconURL: '/images/markers/publico',
          forceZoom: false,
          mapTypeControl: false,
          minZoomLevel: 12,
          visible: true,
          maxZoomClusteringLevel: 21,
          minZoomClusteringLevel: 13,
          clustering: true,
          clusterAnchor: [-10, 0],
          clusterTextSize: 11,
          clusterTextColor: '#ffffff',
          clusterFontWeigth: 'bold',
          clusterGridSize: 60,
          clusterIconSize: [24, 35],
          style: {
            path: '/images/markers/publico',
            marker: 'parada_bus.svg',
            clusterMarker: markerCluster,
          },
          name: nameLayer,
        };

        // Layer callback
        configNearTransport.callback = {
          displayInfo: (e, conf, event) => {
            e.transportClass.displayInfo(e);
          },
        };

        // Create layer
        let dataLayerNearTransport = DataLayer.layers[nameLayer];
        if (dataLayerNearTransport !== undefined) {
          dataLayerNearTransport.removeAllFeatures();
          dataLayerNearTransport.setMap(null);
          const cluster = dataLayerNearTransport.get('mc');
          if (cluster) {
            dataLayerNearTransport.get('mc').removeMarkers(dataLayerNearTransport.cluster);
          }
          dataLayerNearTransport.createCluster(configNearTransport, this.mapObj);
        } else {
          dataLayerNearTransport = new DataLayer(configNearTransport, this.mapObj);
        }
        dataLayerNearTransport.set('visible', true);
        dataLayerNearTransport.addFeatures(visibleFeatures);
        dataLayerNearTransport.setStyle((feature) => {
          const config = dataLayerNearTransport.conf;
          var { style } = config;
          style.icon = {
            url: `${style.path}/${feature.getProperty('icon')}`,
          };
          if (feature.getProperty('tipo') == 'bicing') {
            style.icon.visible = false;
          }
          return style;
        });
        DataLayer.layers[nameLayer] = dataLayerNearTransport;
        setTimeout(() => {
          dataLayerNearTransport.setVisibilityByZoom();
        }, 200);
      });
    },
    getNearTransportFeatures(results, type = 'near_public_transport') {
      const features = [];
      results.forEach((result) => {
        const { tipo } = result.properties;
        let img = 'marker_generic_black';
        let transportClass = null;
        if (tipo == 'bus') {
          img = 'parada_bus';
          transportClass = LineaTransportBus;
        }
        if (tipo == 'metro') {
          img = `metro_${result.properties.NOM_LINIA}`;
          transportClass = LineaTransportMetro;
          if (result.properties.NOM_LINIA == 'FM') {
            img = 'funicular';
            transportClass = LineaTransportFunicular;
          }
        }
        if (tipo == 'tram') {
          img = 'tramvia';
          transportClass = LineaTransportTram;
        }
        if (tipo == 'fgc') {
          img = `metro_${result.properties.line}`;
          // fgc-1.svg: green / fgc-2.svg: orange
          if (result.properties.line.indexOf('R') === 0 || result.properties.line.indexOf('S') === 0) {
            img = 'fgc-2';
          }
          transportClass = LineaTransportFerrocarril;
        }
        if (tipo == 'rodalies') {
          img = 'rodalies';
          transportClass = LineaTransportCercanias;
        }
        if (tipo == 'bicing') {
          img = 'bicing';
          transportClass = function () { };
          transportClass.displayInfo = function (feature) {
            const infoTitle = feature.getProperty('street');
            const target = this;
            this.refs.infoPanel.setInfoPanel({
              title: infoTitle,
              feature,
              code: 'bicing',
              icon_title: 'markers/publico/bicing.svg',
              type: 'info_point',
              datos: [],
              visible: true,
            });
            this.refs.infoPanel.initPanel();
          };
        }
        const markerImg = `/images/markers/publico/${img}.svg`;
        const position = new google.maps.LatLng(result.geometry.coordinates[1], result.geometry.coordinates[0]);
        result.geometry = position;
        result.properties.icon = `${img}.svg`;
        const feature = new google.maps.Data.Feature(result);
        feature.transportClass = transportClass;
        if (tipo !== 'metro' && type == 'near_public_transport') {
          features.push(feature);
          const marker = new google.maps.Marker({
            position,
            icon: {
              url: markerImg,
            },
          });
        }
        for (var a in result.properties.ACCESSOS) {
          const acceso = result.properties.ACCESSOS[a];
          const latLng = new google.maps.LatLng({ lat: acceso.COORDENADES[1], lng: acceso.COORDENADES[0] });
          const properties = acceso;
          properties.ACCESSOS_FISICS = acceso.ACCESSOS_FISICS.join(', ');
          properties.NOM_LINIA = result.properties.NOM_LINIA;
          properties.NOM_ESTACIO = result.properties.NOM_ESTACIO;
          for (var corr in result.properties.CORRESPONDENCIES) {
            if (corr == 'FGC' || corr == 'Rodalies-BCN' || corr == 'Rodalies-Regionals') {
              properties.CORRESPONDENCIES[corr] = result.properties.CORRESPONDENCIES[corr];
            }
          }
          const accesoFeature = new google.maps.Data.Feature({ geometry: latLng, properties });
          accesoFeature.transportClass = transportClass;
          let img = `metro_${properties.NOM_LINIA}`;
          if (result.properties.NOM_LINIA == 'FM') {
            img = 'funicular';
          }
          let markerImg = `'/images/markers/publico/${img}.svg`;
          properties.icon = `${img}.svg`;
          if (type == 'near_public_transport') {
            features.push(accesoFeature);
          }
          const markerAccess = new google.maps.Marker({
            position: latLng,
            icon: {
              url: markerImg,
            },
          });
          if (findIndex(this.activeLayers, (o) => o.code == 'transport_accessible') >= 0) {
            // Accesibility
            markerImg = '';
            // Don't removed conditions from old icons version, for easy restore if necessary
            // Old icons: wheelchair-elevator.png, wheelchair.png, elevator.png
            if (acceso.NOM_TIPUS_ACCESSIBILITAT == 'Accessible' && acceso.NUM_ASCENSORS > 0) {
              markerImg = '/images/markers/publico/accessible.svg';
              markerImg = 'accessible.svg';
            } else if (acceso.NOM_TIPUS_ACCESSIBILITAT == 'Accessible') {
              markerImg = '/images/markers/publico/accessible.svg';
              markerImg = 'accessible.svg';
            } else if (acceso.NUM_ASCENSORS > 0) {
              markerImg = '/images/markers/publico/accessible.svg';
              markerImg = 'accessible.svg';
            }
            if (markerImg != '') {
              const accessibilyFeature = cloneDeep(accesoFeature);
              accessibilyFeature.setProperty('icon', markerImg);
              accessibilyFeature.setProperty('icon_anchor', new google.maps.Point(40, 35));
              accessibilyFeature.transportClass = transportClass;
              features.push(accessibilyFeature);
            }
          }
        }
      });
      return features;
    },
    markerVisibility() {
      let visible = false;
      if (this.mapObj.getZoom() > 14) {
        visible = true;
      }
    },
  },
};

export const dummy = {};

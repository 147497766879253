<template>
  <div id="widget" class="widget-conainer" :style="{'background-image': 'url(/images/widget/img-comshiva-mobile.jpg)'}">
    <span class="title">{{ $t('get_around_barcelona') }}</span>
    <span class="description">{{ $t('choose_trip') }}</span>
    <div>
      <div class="input from" :class="{ 'error_input': suggestionsNoResultsFrom }">
        <img class="icon_pointer" src="/images/icons/marker-small.svg">
        <label :class="labelAutocomplete('from')" class="labelInput">
          {{ $t('select_origin') }}
        </label>
        <input :value="fromValue"
               type="text"
               class="autocomplete"
               @focus="focusInput('fromInput',true)"
               @keyup="getSuggestions('from',$event)"
               v-click-outside="hideResults"
               @input="updateFromValue">
        <div class="clear-input bcn-icon-tancar-medium" @click="clearInput('from')"
             v-show="fromValue !== ''">
          <img src="/images/icons/close.svg">
        </div>
        <div class="results" v-if="fromInput && showResultsPanel" :class="{ 'no-result': suggestionsNoResultsFrom }">
          <div v-if="fromValue === ''">

            <div @click="selectMyPos('from')" v-if="geolocationActive">
              <div class="group">
                <div class="ubicacio">
                  <img class="ico-ubicacio" src="/images/icons/gps-large.svg">
                </div>
                <div class="current-location" :class="{ 'last': places.length === 0 }">
                  {{ $t('my_current_location') }}
                </div>
              </div>
            </div>
            <div v-if="places.length > 0">
              <div class="result"
                v-for="(search, index) in places"
                v-bind:key="index"
                @click="selectSavedPlace('from', search)">
                <div class="ico-recent">
                  <img class="mr-2" src="/images/icons/recent.svg">
                </div>
                <div class="location" :class="{'last': index === places.length - 1}">
                  <div class="text">
                    {{ getPlaceText('main_text', search) }}
                  </div>
                  <div class="desc">
                    {{ getPlaceText('secondary_text', search) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="result" v-if="suggestionsNoResultsFrom">
            <div class="not-result">
              <div class="text font-600">
                {{ $t('no_matches_found') }}
              </div>
              <div class="desc">
                {{ $t('change_keyword') }}
              </div>
            </div>
          </div>
          <div class="result"
            v-for="(prediction, index) in predictions"
            v-bind:key="index"
            @click="selectPrediction('from', prediction)">
            <div class="w-55">
              <img src="/images/icons/marker-large.svg">
            </div>
            <div class="location" :class="{ 'last': index === predictions.length - 1 }">
              <div class="text">
                {{ prediction.structured_formatting.main_text }}
              </div>
              <div class="desc">
                {{ prediction.structured_formatting.secondary_text }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="suggestionsNoResultsFrom" class="no-valid-address">{{ $t('invalid_address') }}</div>
      </div>
      <div class="input to" :class="{'error_input': suggestionsNoResultsTo}">
        <img class="icon_pointer" src="/images/icons/marker-small.svg">
        <label :class="labelAutocomplete('to')" class="labelInput">
          {{ $t('select_destination') }}</label>
        <input :value="toValue"
               type="text"
               class="autocomplete"
               @focus="focusInput('toInput',true)"
               @keyup="getSuggestions('to',$event)"
               v-click-outside="hideResults"
               @input="updateToValue">
        <div class="clear-input bcn-icon-tancar-medium" @click="clearInput('to')"
             v-show="toValue !== ''">
          <img src="/images/icons/close.svg">
        </div>
        <div class="results" v-if="toInput && showResultsPanel" :class="{ 'no-result': suggestionsNoResultsTo }">
          <div v-if="toValue === ''">
            <div @click="selectMyPos('to')" v-if="geolocationActive">
              <div class="group">
                <div class="ubicacio">
                  <img class="ico-ubicacio" src="/images/icons/gps-large.svg">
                </div>
                <div class="current-location" :class="{ 'last': places.length === 0 }">
                  {{ $t('my_current_location') }}
                </div>
              </div>
            </div>
            <div v-if="places.length > 0">
              <div class="result"
                v-for="(search, index) in places"
                v-bind:key="index"
                @click="selectSavedPlace('to', search)">
                <div class="ico-recent">
                  <img class="mr-2" src="/images/icons/recent.svg">
                </div>
                <div class="location" :class="{'last': index === places.length - 1}">
                  <div class="text">
                    {{ getPlaceText('main_text', search) }}
                  </div>
                  <div class="desc">
                    {{ getPlaceText('secondary_text', search) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="result" v-if="suggestionsNoResultsTo">
            <div class="not-result">
              <div class="text font-600">
                {{ $t('no_matches_found') }}
              </div>
              <div class="desc">
                {{ $t('change_keyword') }}
              </div>
            </div>
          </div>
          <div class="result"
            v-for="(prediction, index) in predictions"
            v-bind:key="index"
            @click="selectPrediction('to', prediction)">
            <div class="w-55">
              <img src="/images/icons/marker-large.svg">
            </div>
            <div class="location" :class="{ 'last': index === predictions.length - 1 }">
              <div class="text">
                {{ prediction.structured_formatting.main_text }}
              </div>
              <div class="desc">
                {{ prediction.structured_formatting.secondary_text }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="suggestionsNoResultsTo" class="no-valid-address">{{ $t('invalid_address') }}</div>
      </div>
    </div>
    <b-button class="button" @click="planRoute()">{{ $t('plan_route') }}</b-button>
  </div>
</template>

<script>

import { mapMutations, mapState } from 'vuex';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'widget-barcelonacat',
  directives: {
    ClickOutside,
  },
  data() {
    return {
      suggestionsNoResultsFrom: false,
      suggestionsNoResultsTo: false,
    };
  },
  computed: {
    ...mapState('planroute', [
      'places',
      'selectedLocation',
      'fromInput',
      'toInput',
      'fromValue',
      'toValue',
      'fromObject',
      'toObject',
      'predictions',
      'showResultsPanel',
      'routes',
    ]),
    ...mapState('map', [
      'geolocationActive',
    ]),
  },
  created() {
    this.setGeolocationActive(true);
  },
  methods: {
    ...mapMutations({
      setPlaces: 'planroute/setPlaces',
      setSelectedLocation: 'planroute/setSelectedLocation',
      setFromInput: 'planroute/setFromInput',
      setToInput: 'planroute/setToInput',
      setFromValue: 'planroute/setFromValue',
      setToValue: 'planroute/setToValue',
      setFromObject: 'planroute/setFromObject',
      setToObject: 'planroute/setToObject',
      setPredictions: 'planroute/setPredictions',
      // setSuggestionsNoResults: 'planroute/setSuggestionsNoResults',
      setGeolocationActive: 'map/setGeolocationActive',
      setShowResultsPanel: 'planroute/setShowResultsPanel',
      setRoutes: 'planroute/setRoutes',
    }),
    updateFromValue(event) {
      this.setFromValue(event.target.value);
    },
    updateToValue(event) {
      this.setToValue(event.target.value);
    },
    setNoResultsFrom(value) {
      this.suggestionsNoResultsFrom = value;
    },
    setNoResultsTo(value) {
      this.suggestionsNoResultsTo = value;
    },
    labelAutocomplete(input) {
      return {
        focused: this[`${input}Input`],
        filling: this[`${input}Value`],
      };
    },
    hideResults(e) {
      if (e !== undefined && $(e.target).closest('.results').length > 0) return;
      this.setFromInput(false);
      this.setToInput(false);
      this.elementsVisibility(true);
      this.setShowResultsPanel(false);
    },
    focusInput(input, focus) {
      this.setPredictions([]);
      if (input === 'fromInput') {
        this.setFromInput(focus);
        this.setToInput(false);
      } else {
        this.setFromInput(false);
        this.setToInput(focus);
      }

      this.setShowResultsPanel(true);
      if (focus) {
        this.elementsVisibility(false);
      }
    },
    clearInput(input) {
      if (input === 'from') {
        this.setFromValue('');
        this.setFromObject(null);
        this.setNoResultsFrom(false);
      } else {
        this.setToValue('');
        this.setToObject(null);
        this.setSelectedLocation(null);
        this.setNoResultsTo(false);
      }
    },
    selectPrediction(input, prediction) {
      if (input === 'from') {
        this.setFromValue(prediction.description);
        this.setFromObject(prediction);
      } else {
        this.setToValue(prediction.description);
        this.setToObject(prediction);
      }
      // eslint-disable-next-line prefer-destructuring
      const places = this.places;
      places.push(prediction);
      this.setPlaces(places);
      this.setPredictions([]);
      this.hideResults();
    },
    selectMyPos(input) {
      const googleRoutesApi = new GoogleRoutesApi(this.$i18n.locale);
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        googleRoutesApi.geocode(pos).then((results) => {
          const val = results[0];
          if (input === 'from') {
            this.setFromValue(val.formatted_address);
            this.setFromObject(val);
          } else {
            this.setToValue(val.formatted_address);
            this.setToObject(val);
          }
          this.$forceUpdate();
          this.hideResults();
        });
      });
    },
    getPlaceText(property, place) {
      let componentsObject = null;
      let addressText = null;

      if (property === 'main_text' && place.custom_text) {
        return place.custom_text.main_text;
      }

      if (property === 'secondary_text' && place.custom_text) {
        return place.custom_text.secondary_text;
      }
      if (property === 'main_text') {
        if (place.structured_formatting) {
          return place.structured_formatting.main_text;
        }
        componentsObject = GoogleRoutesApi.addressComponentObject(place.address_components);
        addressText = GoogleRoutesApi.getMainSecondaryAddresText(componentsObject);
        return addressText.main_text;
      }
      if (property === 'secondary_text') {
        if (place.structured_formatting) {
          return place.structured_formatting.secondary_text;
        }
        componentsObject = GoogleRoutesApi.addressComponentObject(place.address_components);
        addressText = GoogleRoutesApi.getMainSecondaryAddresText(componentsObject);
        return addressText.secondary_text;
      }
      return '';
    },
    selectSavedPlace(input, search) {
      const value = `${this.getPlaceText('main_text', search)} ${this.getPlaceText('secondary_text', search)}`;

      if (input === 'from') {
        this.setFromValue(value);
        this.setFromObject(search);
      } else {
        this.setToValue(value);
        this.setToObject(search);
      }

      this.hideResults();
    },
    getSuggestions(input, e) {
      const word = e.target.value;
      if (word === '') return;

      const googleRoutesApi = new GoogleRoutesApi(this.$i18n.locale);
      googleRoutesApi.getSuggestions(word).then((result) => {
        this.setPredictions(result.predictions);
        this.setShowResultsPanel(true);
        if (input === 'from') {
          this.setNoResultsFrom(false);
          this.setFromValue(e.target.value);
          if (result.status !== 'OK') {
            this.setNoResultsFrom(true);
          }
        } else {
          this.setNoResultsTo(false);
          this.setToValue(e.target.value);
          if (result.status !== 'OK') {
            this.setNoResultsTo(true);
          }
        }
      });
    },
    elementsVisibility(visible) {
      if (visible) {
        $('#barra-ajuntament').show();
        $('.buttons').show();
        $('.mainHeader').show();
        $('#mainMenu').show();
      } else {
        $('#barra-ajuntament').hide();
        $('.buttons').hide();
        $('.mainHeader').hide();
        $('#mainMenu').hide();
      }
    },
    planRoute() {
      if (this.fromObject && this.toObject) {
        const url = `https://com-shi-va.dev.dtibcn.cat/ca?action=planroute&origin_id=${this.fromObject.place_id}&origin=${this.fromObject.description}&destination_id=${this.toObject.place_id}&destination=${this.toObject.description}&travelMode=BEST-ROUTE`;
        window.open(url, '_blank');
      }
    },
  },
};
</script>

<template>
  <div class="legendContainer">
    <div class="info">
      <div class="title">
        {{ infoPanel.title }}
      </div>
    </div>

    <b-row cols="4" class="legend_data">
      <b-col v-for="(dato, index) in infoPanel.datos" v-bind:key="index"
      class="p-1">
        <strong v-html="dato.label" class="label"></strong>
        <div class="dato" :style="{ borderColor: dato.data}"></div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'EmissionsPanel',
  computed: mapState('infopanel', [
    'infoPanel',
  ]),
  methods: {
    ...mapMutations({
      setInfoPanel: 'infopanel/setInfoPanel',
      initInfoPanel: 'infopanel/initInfoPanel',
    }),
  },
};
</script>

<template>
  <div id="routePanelWrapper" v-show="panelVisible()">
    <div class="swipeZone">
      <div class="swipeHelper">
        <div class="helper"></div>
        <div v-if="position != 1" class="view-map" @click="onSwipeDown(); onSwipeDown();">
          <img src="/images/icons/mapa.svg">
          {{ $t('map') }}
        </div>
      </div>
    </div>
    <div class="routePanelContainer" :class="{'opened': panelTop}">
      <div id="routePanel" v-if="routes && routes.length>0">
        <div class="suggested-route">
          <route-component
            :route="routes[0]"
            :routeIndex="0"
            :travelMode="getTravelMode(0)"
            :type="getType(0)"
            :title="getTitle(0)"
            :suggestedRoute="routes[1]"
            :selectedIndex="selectedRouteIndex"
            @changeSuggestedRoute="changeRoute"
          >
          </route-component>
        </div>
        <div class="route other-routes">
          <div v-for="(route, index) in routes.slice(1)" v-bind:key="index">
            <route-component
              :route="route"
              :routeIndex="index+1"
              :travelMode="getTravelMode(index+1)"
              :title="getTitle(index+1)"
              type="suggested"
              :selectedIndex="selectedRouteIndex"
              @changeSuggestedRoute="changeRoute"
            >
            </route-component>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="message-route">
          <div class="message-text">
            {{ $t('no_routes_available') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import RouteComponent from './RouteComponent.vue';
import { PanelMixins } from './mixins/PanelMixins';

export default {
  name: 'RoutePanel',
  mixins: [
    PanelMixins,
  ],
  components: {
    RouteComponent,
  },
  computed: {
    ...mapState('map', [
      'mapObj',
      'mapFullScreen',
    ]),
    ...mapState('planroute', {
      routes: 'routes',
      planificadorOpened: 'opened',
      opened: 'panelOpened',
      visible: 'panelVisible',
      panelTop: 'panelTop',
      travelMode: 'panelTravelMode',
      selectedLocation: 'selectedLocation',
      suggestedRoute: 'suggestedRoute',
      selectedRouteIndex: 'selectedRouteIndex',
    }),
    ...mapState('map', [
      'isWidget',
    ]),
  },
  data() {
    return {
      refs: null,
      touchstartY: 0,
      touchendY: 0,
      timeInteraction: 0,
      position: 0,
    };
  },
  loaded() {
    this.setSelectedRouteIndex(0);
  },
  methods: {
    ...mapMutations({
      setStoreRoutes: 'planroute/setRoutes',
      setTravelMode: 'planroute/setPanelTravelMode',
      setSuggestedRoute: 'planroute/setSuggestedRoute',
      setOpened: 'planroute/setPanelOpened',
      setVisible: 'planroute/setPanelVisible',
      setPanelTop: 'planroute/setPanelTop',
      setSelectedRouteIndex: 'planroute/setSelectedRouteIndex',
    }),
    panelVisible() {
      if (this.visible && !this.mapFullScreen && !this.planificadorOpened && this.selectedLocation != null) {
        return true;
      }
      return false;
    },
    setRoutes(routes, travelMode) {
      this.setTravelMode(travelMode);
      this.setStoreRoutes(routes);
      this.setSuggestedRoute(null);
      this.setOpened(true);
      this.setPanelTop(false);
      this.setVisible(true);
      this.$nextTick(() => {
        this.position = 2;
        this.posPanel();
        this.closePanels('routePanel');
      });
    },
    getTravelMode(routeIndex) {
      return this.routes[routeIndex].travelMode;
    },
    getType(routeIndex) {
      if (this.travelMode == 'DRIVING' && routeIndex == 0 && this.routes[routeIndex].travelMode == 'TRANSIT') {
        return 'sostenible';
      }
      return 'suggested';
    },
    getTitle(routeIndex) {
      if (this.travelMode == 'DRIVING' && routeIndex == 0 && this.routes[routeIndex].travelMode == 'TRANSIT') {
        return this.$t('sustainable_route');
      }
      if ((this.travelMode == 'DRIVING' && routeIndex == 1 && this.routes[0].travelMode == 'TRANSIT')
        || (routeIndex == 0)) {
        return this.$t('suggested_route');
      }
      return '';
    },
    onSwipeUp() {
      if (this.panelTop) return false;

      if (this.position == 1) {
        this.position = 2;
        this.posPanel();
        this.setPanelTop(false);
      } else if (this.position == 2) {
        let top = document.getElementById('c-flexible-data').clientHeight + 5;
        if (document.getElementById('car-selector-tabs')) {
          top += document.getElementById('car-selector-tabs').clientHeight;
        }

        $('#routePanelWrapper').animate(
          {
            top: `${top}px`,
            marginTop: 0,
          },
          {
            complete: () => {
              this.setOpened(true);
              this.position = 3;
            },
          },
        );
        this.setPanelTop(true);
      }
    },
    closePanel() {
      const top = window.innerHeight - 65;
      $('#routePanelWrapper').animate(
        {
          top: `${top}px`,
          marginTop: '25px',
        },
        {
          complete: () => { this.refs.mapa.moveMapButtons('down', 'infoPanelWrapper'); },
        },
      );
      this.position = 0;
      this.setOpened(false);
      this.setPanelTop(false);
    },
    onSwipeDown() {
      if (this.position == 2) {
        const top = window.innerHeight - (57 + 89);
        $('#routePanelWrapper').animate(
          {
            top: `${top}px`,
            marginTop: '25px',
          },
          {
            complete: () => { this.refs.mapa.moveMapButtons('down', 'infoPanelWrapper'); },
          },
        );
        this.position = 1;
      } else if (this.position == 3) {
        this.position = 2;
        this.posPanel();
        this.setPanelTop(false);
      }
    },
    posPanel() {
      let top = document.getElementById('map-canvas').clientHeight;
      top += document.getElementById('planificador').clientHeight + 50;
      const marginTop = (top / 3) * -1;
      $('#routePanelWrapper').animate(
        {
          top: `${this.isWidget ? top / 1.25 : top}px`,
          marginTop: `${marginTop}px`,
        },
        {
          complete: () => {
            this.refs.mapa.moveMapButtons('up', 'routePanelWrapper');
          },
        },
      );
      $('.routePanelContainer').scrollTop(0);
    },
    detectSlide() {
      var gesuredZone = document.getElementById('routePanelWrapper').children[0];
      gesuredZone.addEventListener('touchstart', (event) => {
        this.touchstartY = event.changedTouches[0].screenY;
      }, false);

      gesuredZone.addEventListener('touchend', (event) => {
        this.touchendY = event.changedTouches[0].screenY;
        if (((Date.now() - this.timeInteraction) / 1000) > 1) {
          this.handleGesure();
        }
      }, false);
    },
    handleGesure() {
      if (this.touchendY < this.touchstartY) {
        this.onSwipeUp();
      }
      if (this.touchendY > this.touchstartY) {
        this.onSwipeDown();
      }
      this.timeInteraction = Date.now();
    },
    changeRoute(indexRoute) {
      this.setSelectedRouteIndex(indexRoute);
    },
  },
  created() {
    this.refs = this.isWidget === true ? this.$root.$children[0].$children[0].$children[0].$refs : this.$root.$children[0].$children[0].$refs;
  },
  mounted() {
    this.detectSlide();
  },
};
</script>

<template>
  <div>
  <!-- Alert Copa America permission to receive notification  -->
  <div class="full-alert" v-if="site=='copa-america' && (!notificationWarningViewed || notificationWarningVisible) && !showHelp && showFirebase">
    <div class="alert-msg alert-notif">
      <div class="alert-header">{{ $t('ca_alerts') }}</div>
      <div class="alert-body">
        {{ $t('do_you_like_alerts') }}
      </div>
      <div class="alert-footer">
        <div class="btn btn-color" role="button" tabindex="0" @click="confirmNotificationWarning">{{ $t('yes_thanks') }}</div>
        <div class="btn btn-air link" role="button" tabindex="0" @click="closeNotificationWarning">{{ $t('not_at_the_moment') }}</div>
      </div>
    </div>
  </div>
  <!-- Alert Copa America message received  -->
  <div class="full-alert" v-if="messageReceivedVisible">
    <div class="alert-msg alert-notif">
        <div class="alert-header">{{ messageReveived.title }}</div>
        <div class="alert-body">
            <div  class="img-message" v-if="messageReveived.image">
                <img :src="messageReveived.image">
            </div>
            {{ messageReveived.body }}
        </div>
        <div class="alert-footer">
            <div class="btn btn-color" role="button" tabindex="0" @click="setMessageReceivedVisible(false)">{{ $t('continue') }}</div>
        </div>
    </div>
  </div>
</div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { initializeFirebase, onMessage } from '@/firebase';
import { LayerMixins } from './mixins/LayerMixins';
import { TransportMixins } from './mixins/TransportMixins';
import generalMixin from '../mixins/generalMixin';

export default {
  name: 'Alerts',
  mixins: [
    TransportMixins,
    LayerMixins,
    generalMixin,
  ],
  data() {
    return {
      showFirebase: false,
    };
  },
  mounted() {
    this.initializeMessaging();
  },
  computed: {
    ...mapState('widget', [
      'marathonWarningVisible',
    ]),
    ...mapState('transport', [
      'goToTransportLinesMsgVisible',
    ]),
    ...mapState('general', [
      'site',
      'notificationWarningViewed',
      'notificationWarningVisible',
      'messageReceivedVisible',
      'messageReveived',
    ]),
    ...mapState('help', [
      'showHelp',
    ]),
  },
  methods: {
    ...mapMutations({
      setIsWidget: 'general/setIsWidget',
      setSite: 'general/setSite',
      setWidgetType: 'general/setWidgetType',
      setMessageReceivedVisible: 'general/setMessageReceivedVisible',
      setMessageReceived: 'general/setMessageReceived',
      setNotificationWarningViewed: 'general/setNotificationWarningViewed',
      setNotificationWarningVisible: 'general/setNotificationWarningVisible',
      setGoToTransportLinesMsgVisible: 'transport/setGoToTransportLinesMsgVisible',
      setRightPanelOpened: 'rightPanel/setRightPanelOpened',
      setPanelActiveLayers: 'rightPanel/setPanelActiveLayers',
      setLeftPanelOpened: 'leftPanel/setLeftPanelOpened',
      setSelectedMenuItem: 'mainMenu/setSelectedMenuItem',
      setMsgLayers: 'map/setMsgLayers',
      setCurrentPreferencias: 'transport/setCurrentPreferencias',
      setMarathonWarningVisible: 'widget/setMarathonWarningVisible',
    }),
    /**
             * Open transport panel
             */
    goToTransportLines() {
      this.deselectAll();
      this.setRightPanelOpened(false);
      this.setSelectedMenuItem('transport');
      this.setGoToTransportLinesMsgVisible(false);
      this.setLeftPanelOpened(true);
    },
    /**
             * Confirm Copa America notification permission
             */
    confirmNotificationWarning() {
      this.setNotificationWarningVisible(false);
      this.setNotificationWarningViewed(true);
      localStorage.notificationWarningViewed = true;
      this.requestPermission();
    },
    /**
             * Cancel Copa America notification permission
             */
    closeNotificationWarning() {
      this.setNotificationWarningVisible(false);
      this.setNotificationWarningViewed(true);
    },
    /**
             * Implement Firebase messaging
             */
    async initializeMessaging() {
      const messaging = await initializeFirebase();
      if (!messaging) return;
      this.showFirebase = true;
      onMessage(messaging, (payload) => {
        this.notificationTextLocalization(payload.notification).then((ntf) => {
          if (!ntf) return;
          const notification = {
            title: ntf.title,
            body: ntf.body,
            image: payload.notification.image,
          };
          this.setMessageReceived(notification);
          this.setMessageReceivedVisible(true);
        });
      });
    },
  },
};
</script>

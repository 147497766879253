<template>
  <div id="main-menu-nav">
    <div class="option-nav" :class="{ 'active-option-menu': activeOption === 'comshiva' }"
         @click="changeOptionMenu('comshiva');goToComShiVa()">
      <div class="img-content">
        <div>
          <img :src="`/images/icons/comshiva${activeOption === 'comshiva' ? '' : '-off'}.svg`" class="logo" alt=""/>
        </div>
      </div>
      Com s'hi va
    </div>
    <div class="option-nav" @click="$bvModal.show('route-recents-modal')">
      <div class="img-content">
        <div>
          <img :src="`/images/icons/temps${activeOption === '' ? '' : '-off'}.svg`"
           class="logo" alt=""/>
        </div>
      </div>
      {{ $t('recent') }}
    </div>
    <div class="option-nav" :class="{ 'active-option-menu': activeOption === 'apropmeu' }"
         @click="changeOptionMenu('apropmeu');goToMyPosition()">
      <div class="img-content">
        <div>
          <img :src="`/images/icons/apropmeu${activeOption === 'apropmeu' ? '' : '-off'}.svg`"
           class="logo" alt=""/>
        </div>
      </div>
      {{ $t('near') }}
    </div>
    <div class="option-nav" :class="{ 'active-option-menu': activeOption === 'cotxe' }"
         @click="changeOptionMenu('cotxe')">
      <div class="img-content">
        <div>
          <img :src="`/images/icons/transit${activeOption === 'cotxe' ? '' : '-off'}.svg`"
           class="logo" alt=""/>
        </div>
      </div>
      {{ $t('transit') }}
    </div>
    <div class="option-nav" :class="{ 'active-option-menu': activeOption === 'linies' }"
          @click="showLines()">
      <div class="img-content">
        <div>
          <img :src="`/images/icons/liniesiparades-nav${activeOption === 'linies' ? '' : '-off'}.svg`"
           class="logo" alt=""/>
        </div>
      </div>
      {{ $t('lines') }}
    </div>
    <route-recents-modal></route-recents-modal>

  </div>

</template>
<script>

import RouteRecentsModal from '@/components/RouteRecentsModal.vue';
import { mapMutations, mapState } from 'vuex';
import { EventBus } from '../main';

export default {
  name: 'MainMenuNav',
  components: { RouteRecentsModal },
  data() {
    return {
      opened: false,
      activeOption: 'comshiva',
      refs: null,
    };
  },
  computed: {
    ...mapState('map', [
      'mapObj',
      'isWidget',
    ]),
  },
  created() {
    this.refs = this.isWidget === true ? this.$root.$children[0].$children[0].$children[0].$refs : this.$root.$children[0].$children[0].$refs;
  },
  methods: {
    ...mapMutations({
      setMenuNavigationApp: 'menu/setMenuNavigationApp',
    }),
    changeOptionMenu(option) {
      const dataLayerNearTransport = DataLayer.layers.near_public_transport;
      if (dataLayerNearTransport && option !== 'apropmeu') {
        dataLayerNearTransport.set('visible', false);
        dataLayerNearTransport.hideClustering();
      }
      this.activeOption = option;
      this.setMenuNavigationApp({
        optionSelected: option,
      });
    },
    goToMyPosition() {
      this.refs.layerMenu.deleteAllLayers();
      EventBus.$emit('show-tab-cotxe', false);
      this.refs.mapa.goToMyPosClick();
    },
    goToComShiVa() {
      this.refs.layerMenu.deleteAllLayers();
      this.refs.planificador.openPlanificador();
    },
    showLines() {
      this.$bvModal.show('modal-selector-lines');
    },
  },
};
</script>

/* eslint-disable */

import axios from 'axios';
import { getApiUri } from '../api/util';
import store from '../store/index';
import api from '../api/api';
import {sortBy} from "lodash";

window.LineaTransportCercanias = function() {

}

LineaTransportCercanias.prototype.loadTransport = function () {
  api.get(`${getApiUri()}rodalieslinies/`).then(function (response) {
    const results = response.data.features;
    for (let c in results) {
      const result = results[c];
      const linea = {
        linea: result.properties.line,
        descripcio: result.properties.description,
        color: result.properties.stroke,
        type: 'rodalies',
        icon: 'csvicon-metro',
        estacions: {},
        reverse: false,
        selected: false,
      }

      store.commit('transport/setLineas', {
        rodalies: {
          [result.properties.line]: linea,
        },
      });

    }
    const ordered = sortBy(store.state.transport.lineas.rodalies, [(o) => {
      return parseInt(o.linea.replace('R','').replace('S','').replace('N',''));
    }]);
    store.commit('transport/setLineas', {
      rodalies: null,
    });
    for (let c in ordered) {
      store.commit('transport/setLineas', {
        rodalies: {
          [ordered[c].linea]: ordered[c],
        },
      });
    }
  });
}

LineaTransportCercanias.prototype.loadStation = function (linea) {
  var promise = new Promise(function(resolve, reject) {
    api.get(`${getApiUri()}rodaliesestacions/${linea}/`).then(function (response) {
      const results = response.data.features;
      for (let c in results) {
        const result = results[c];
        const estacio = {
          nom: result.properties.name,
          line: result.properties.line,
          codi: result.properties.url,
          ordre: parseInt(result.properties.order),
          geometry: result.geometry
        }
        if (result.properties.line==linea) {
          store.commit('transport/setLineas', {
            rodalies: {
              [linea]: {
                estacions: {
                  [result.properties.order]: estacio,
                },
              },
            },
          });

        }
      }
      resolve(response);
    });
  });
  return promise;
}

LineaTransportCercanias.getColor = function(linea) {
  for (var c in store.state.transport.lineas.rodalies) {
    if (store.state.transport.lineas.rodalies[c].linea==linea) {
      return store.state.transport.lineas.rodalies[c].color;
    }
  }
  return '777777'
}

LineaTransportCercanias.getLinea = function(linea) {
  for (var c in store.state.transport.lineas.rodalies) {
    if (store.state.transport.lineas.rodalies[c].linea==linea) {
      return store.state.transport.lineas.rodalies[c];
    }
  }
  return {};
}

LineaTransportCercanias.getStation = function(linea, code) {
  for (var c in linea.estacions) {
    if (linea.estacions[c].codi==code) {
      return linea.estacions[c];
    }
  }
  return {};
}

LineaTransportCercanias.displayInfo = function(feature) {
  const refs = store.state.map.isWidget === true ? window.app.$children[0].$children[0].$children[0].$refs : window.app.$children[0].$children[0].$refs;
  refs.infoPanel.initInfoPanel();

  store.commit('infopanel/setInfoPanel', {
    datos: null,
  });

  const logoRenfe = '/images/logo-rodalies.png';
  var linea = LineaTransportCercanias.getLinea(feature.getProperty('line'));

  store.commit('infopanel/setInfoPanel', {
    title: feature.getProperty('name'),
    linea: `rodalies/${feature.getProperty('line')}.png`,
    nom_linea: feature.getProperty('line'),
    type: 'rodalies',
    feature: feature,
    datos: [
      {"label": `<img class="metro" src="${logoRenfe}">`, "data": `${linea.descripcio}`}
    ],
    visible: true,
  });
  refs.infoPanel.initPanel();
  refs.infoPanel.updateTrainsTime();
}

LineaTransportCercanias.loadLineMap = function(linea_name, zoom, station_name) {

  var linea = LineaTransportCercanias.getLinea(linea_name);

  window._mtm.push({
    'event': 'comshiva',
    'eventcategory': 'ComShiVa',
    'eventaction': 'Transport',
    'eventname': `Rodalies: ${linea.linea} ${linea.descripcio}`
  });

  var configStation = Object.assign({}, {
    ZOOM: 16,
    zoomPoint: 17,
    minZoomLevel: 14,
    CENTER: new google.maps.LatLng(41.404, 2.173),
    MAP_TYPE: google.maps.MapTypeId.ROADMAP,
    REGION: 'ca',
    BUFFER_DISTANCE: 0.2, // Km
    savePosition: true,
    displayInfo: true,
    setMarkerSearch: true,
    adaptativeMarkers: true,
    iconURL: "/images/markers/publico",
    forceZoom: false,
    mapTypeControl: false,
    visible: true,
    maxZoomClusteringLevel: 16,
    clusterIconSize: [24, 35],
  });

  configStation.callback = {
    displayInfo: LineaTransportCercanias.displayInfo,
  }

  configStation.style = {
    strokeColor: linea.color,
    fillColor: linea.color,
    strokeOpacity: 1,
    strokeWeight: 2,
    path: "/images/markers/publico",
    marker: "rodalies.svg",
    icon: "rodalies.svg",
    clusterMarker: "transport.svg",
  };

  configStation.name = "rodaliesestacions" + linea.linea;

  var configLine = Object.assign({}, {
    ZOOM: 16,
    zoomPoint: 17,
    CENTER: new google.maps.LatLng(41.404, 2.173),
    MAP_TYPE: google.maps.MapTypeId.ROADMAP,
    REGION: 'ca',
    BUFFER_DISTANCE: 0.2, // Km
    savePosition: true,
    setMarkerSearch: true,
    adaptativeMarkers: true,
    iconURL: "/images/markers/",
    forceZoom: false,
    mapTypeControl: false,
  });

  configLine.style = {
    strokeColor: linea.color,
    fillColor: linea.color,
    strokeOpacity: 1,
    strokeWeight: 2,
  };

  configLine.name = "rodalieslinies" + linea.linea;
  var dataLayerStation = new DataLayer(configStation, store.state.map.mapObj);
  var dataLayerLine = new DataLayer(configLine, store.state.map.mapObj);
  var layerUrlLine = `${getApiUri()}rodalieslinies/${linea.linea}/`;
  var layerUrlStacions = `${getApiUri()}rodaliesestacions/${linea.linea}/`;

  Promise.all([
    dataLayerLine.addGeoJsonDataLayer(layerUrlLine),
    dataLayerStation.addGeoJsonDataLayer(layerUrlStacions)
  ]).then(() => {
    if (station_name!=undefined) {
      var station = LineaTransportCercanias.getStation(linea, station_name);
      store.state.map.mapObj.setCenter(new google.maps.LatLng(station.geometry.coordinates[1],station.geometry.coordinates[0]));
      store.state.map.mapObj.setZoom(configStation.zoomPoint);
    }
    if (zoom=='line') {
      dataLayerLine.cropToBounds();
    }
    linea.selected = true;
    linea.group = "public_transport";
    store.commit('map/addActiveLayer', linea);
  });

}

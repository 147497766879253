import { mapState, mapMutations } from 'vuex';
import { differenceWith, isEqual } from 'lodash';
import { getApiUri } from '../../api/util';
import api from '../../api/api';

const IncidentsMixins = {
  data() {
    return {
      interval: null,
      cont_test: 0,
      mode: 'notest',
      socialOpened: false,
      incidents: [],
    };
  },
  computed: {
    ...mapState('incidents', [
      'trafficIncidents',
      'forceClose',
    ]),
    ...mapState('planroute', [
      'opened',
    ]),
    ...mapState('general', [
      'site',
    ]),
    getNewIncidents() {
      return this.trafficIncidents.incidents.filter((incident) => {
        return this.trafficIncidents.new_incidents.indexOf(incident) !== -1;
      });
    },
  },
  methods: {
    ...mapMutations({
      setTrafficIncidents: 'incidents/setTrafficIncidents',
      setTrafficIncidentsReaded: 'incidents/setTrafficIncidentsReaded',
      setTrafficIncidentsNew: 'incidents/setTrafficIncidentsNew',
      setTrafficIncidentsFirstRead: 'incidents/setTrafficIncidentsFirstRead',
    }),
    close() {
      this.setTrafficIncidentsReaded(true);
      this.setTrafficIncidentsNew(0);
      this.setTrafficIncidentsFirstRead(false);
    },
    initLoadingIncidents() {
      const timeToCheck = 60;
      this.interval = setInterval(() => {
        this.loadIncidents();
      }, 1000 * timeToCheck);
      this.loadIncidents();
    },
    getIncidents() {
      this.incidents = [];
      for (const c of this.trafficIncidents.incidents) {
        const item = {
          incident: c,
          socialOpened: false,
        };
        this.incidents.push(item);
      }
      return this.incidents;
    },
    isNew(incident) {
      for (var c in this.trafficIncidents.new_incidents) {
        if (this.trafficIncidents.new_incidents[c] == incident) {
          return true;
        }
      }
      return false;
    },
    loadIncidents(firstRead = false) {
      api.get(`${getApiUri()}services/trafficincidents/`).then((response) => {
        const all_incidents = response.data.results[this.$i18n.locale];
        let incidents = [];
        for (const c in all_incidents) {
          const incident = all_incidents[c];
          if (window.location.pathname.includes('copa-america') && /#ACE#?/g.test(incident)) {
            incidents.push(incident.replace(/#ACE#?/g, ''));
          } else if (!window.location.pathname.includes('copa-america') && !/#ACE#?/g.test(incident)) {
            incidents.push(incident);
          }
        }

        if (incidents) {
          if (!window.location.pathname.includes('copa-america')) {
            incidents.shift(); // Discard first element
          }
          // Incidents to test functionality
          if (process.env.NODE_ENV !== 'production' && this.mode == 'test') {
            incidents = this.getTestIncidents(this.cont_test, incidents);
            if (this.cont_test > 0) {
              this.setTrafficIncidentsFirstRead(false);
            }
            this.cont_test = (this.cont_test < 4) ? this.cont_test + 1 : 0;
          }
          this.setNewIncidents(incidents, firstRead);
        } else {
          this.setNewIncidents([], firstRead);
        }
        this.getIncidents();
      });
    },
    setNewIncidents(incidents = [], firstRead) {
      // Remove from "new_incidentes" items not in "incidents"
      const new_incidents = this.trafficIncidents.new_incidents
        .filter((incident) => {
          return incidents.indexOf(incident) !== -1;
        });
      // Add new incidents
      const diff = differenceWith(incidents, this.trafficIncidents.incidents, isEqual);
      if (diff.length > 0 || incidents.length == 0) {
        let cont = 0;
        incidents.forEach((incident) => {
          if (this.trafficIncidents.incidents.indexOf(incident) === -1) {
            cont++;
            new_incidents.push(incident);
          }
        });
        this.setTrafficIncidents({
          incidents,
          first_read: firstRead,
          new_incidents,
          readed: false,
          new: cont,
        });
      }
    },
  },
};

export default IncidentsMixins;

<template>
  <div class="active-layer-group" v-if="layersGroup.length > 0">
    <div class="title-group">{{ $t(group) }}</div>
    <div class="content-layers">
      <div v-for="(item, index) of layersGroup" v-bind:key="index" v-on="item.selected ? {} : { click: () => selectOpcion(item) }">
        <div v-if="showLayer(index)" :class="{'active-layer': true, 'disabled': !item.selected}">
          <div class="d-flex align-items-center" v-if="item.code != undefined">
            <div class="icon" :style="{ ...item.style, backgroundColor: item.layerColorBackground }">
              <img class="img-layer" v-if="item.layerIcon" :src="`/images/layer-icons/${item.layerIcon}`">
            </div>
            <div class="text">{{ $t(item.nombre) }}</div>
            <div v-if="item.selectAll == undefined" class="delete-layer">
              <img src="/images/icons/close-color.svg" @click="selectOpcion(item)">
            </div>
            <div v-else class="delete-layer">
              <img src="/images/icons/close-color.svg" @click="selectAll(getGrupoByName(item.group), item.group)">
            </div>
          </div>
          <div class="d-flex align-items-center" v-if="item.code == undefined">
            <div v-if="item.selectAll == undefined" class="d-flex align-items-center">
              <div class="icon" :style="`background-color: #${getTransportLabelColor(item)};`">
                <img v-if="item.type === 'metro'" class="img-line" :src="`/images/metro/${item.linea}.png`">
                <img v-if="item.type === 'ferrocarril'" class="img-line" src="/images/icons/fgc-centrado.svg">
                <img v-if="item.type === 'rodalies'" class="img-line" src="/images/icons/rodalies-centrado.svg">
                <img v-if="item.type === 'tram'" class="img-line" src="/images/icons/tram-centrado.svg">
                <img v-if="item.type === 'taxi'" class="img-line" src="/images/icons/taxi.svg">
                <img v-if="item.type === 'funicular'" class="img-line" src="/images/logo-funicular.png">
                <span v-if="item.type === 'bus'" class="num-bus">{{ item.linea }}</span>
                <span v-if="item.type === 'aerobus'" class="num-bus">{{ item.linea }}</span>
              </div>
              <div class="text">
                <span v-if="item.type=='ferrocarril'">FGC {{ item.linea }}</span>
                <span v-else>{{ capitalizeFirstLetter(item.type) }} {{ item.linea }}</span>
              </div>
              <div class="delete-layer">
                <img src="/images/icons/close-color.svg" @click="selectTransportLine(item, false)">
              </div>
            </div>
            <div v-else class="d-flex align-items-center">
              <div class="icon" :style="`background-color: #${getTransportLabelColor(item)};`">
                <img class="img-line" :src="`/images/icons/${item.icon}.svg`">
              </div>
              <div class="text">
                <span>{{ $t(item.descripcio) }}</span>
              </div>
              <div class="delete-layer">
                <img src="/images/icons/close-color.svg" @click="getOptionTransportByCode(item.type).select_all.selected = !getOptionTransportByCode(item.type).select_all.selected; selectLineAll(item.type, getOptionTransportByCode(item.type).select_all.selected)">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="see-more" v-if="layersGroup.length > 5" @click="showAllLayers = !showAllLayers">
      <span v-if="!showAllLayers">{{ $t('view_more') }}</span>
      <span v-if="!!showAllLayers">{{ $t('view_less') }}</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { TransportMixins } from './mixins/TransportMixins';
import { LayerMixins } from './mixins/LayerMixins';

export default {
  name: 'ActiveLayerGroup',
  mixins: [
    TransportMixins,
    LayerMixins,
  ],
  props: ['group', 'layers'],
  data() {
    return {
      showAllLayers: false,
      typesPublicTransports: ['metro', 'bus', 'ferrocarril', 'rodalies', 'tram'], // 'aerobus', 'funicular'
    };
  },
  computed: {
    ...mapState('transport', [
      'lineas',
      'transportTypeSelected',
    ]),
    layersGroup() {
      let layersFilter = this.layersFilterByGroup(this.layers);
      layersFilter = this.filterFullGroupSelection(layersFilter);
      return layersFilter;
    },
  },
  methods: {
    layersFilterByGroup(layers) {
      if (Array.isArray(layers)) {
        return layers.filter((layer) => layer.group_code === this.group || layer.group === this.group);
      }
      return [];
    },
    filterFullGroupSelection(layersFilter) {
      if (this.group == 'public_transport') {
        this.typesPublicTransports.forEach((transport) => {
          // Check selections layers
          if (Object.keys(this.lineas[transport]).length > 0 && Object.keys(this.lineas[transport]).length === layersFilter.filter((layer) => layer.type === transport).length) {
            const chip = this.getLayerChipPublicTransport(transport);
            layersFilter = layersFilter.filter((layer) => layer.type !== transport);
            layersFilter.push(chip);
          }
        });
      } else if (this.group == 'walk') {
        if (this.getGrupoByName('walk') && this.getGrupoByName('walk').options.length > 0 && this.getGrupoByName('walk').options.length === layersFilter.length) {
          const chip = this.getLayerChip(this.group);
          layersFilter = layersFilter.filter((layer) => layer.group !== 'walk');
          layersFilter.push(chip);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.getGrupoByName(this.group) && this.getGrupoByName(this.group).options.length > 0 && this.numOpcionsGroup(this.group) === layersFilter.length) {
          const chip = this.getLayerChip(this.group);
          layersFilter = layersFilter.filter((layer) => layer.group !== this.group);
          layersFilter.push(chip);
        }
      }
      return layersFilter;
    },
    selectAll(grupo, type) {
      if (grupo.select_all.type == 'general') {
        const allChecked = !this.isAllGroupLayersSelectedBy(type);
        grupo.select_all.selected = allChecked;
        grupo.options.forEach((option) => {
          if (option.url != undefined) {
            option.selected = allChecked;
            option.group = type;
            this.activateLayer(option);
          }
          if (option.options) {
            option.options.forEach((suboption) => {
              if (suboption.url != undefined) {
                suboption.selected = allChecked;
                suboption.group = type;
                this.activateLayer(suboption);
              }
            });
          }
        });
      } else {
        this.selectAllTransport(grupo.select_all.selected);
      }
    },
    numOpcionsGroup(type) {
      let counter = 0;
      this.getGrupoByName(type).options.forEach((opcion) => {
        if (opcion.options) {
          counter += opcion.options.length;
        } else {
          counter++;
        }
      });
      return counter;
    },
    showLayer(index) {
      let show = false;
      if (index <= 4) {
        show = true;
      } else if (index > 4 && this.showAllLayers == true) {
        show = true;
      }

      return show;
    },
    capitalizeFirstLetter(str) {
      return str[0].toUpperCase() + str.slice(1);
    },
    getTransportLabelColor(item) {
      if (item.code == 'taxi') {
        return 'ffcf00';
      }
      if (item.type == 'ferrocarril') {
        return '95c11f';
      }
      if (item.type == 'rodalies') {
        return 'ee761b';
      }
      return item.color;
    },
  },
};

</script>

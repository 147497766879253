import './assets/css/style.scss';
import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';

import $ from 'jquery';
import { findIndex } from 'lodash';
import VueI18n from 'vue-i18n';
import Vue2TouchEvents from 'vue2-touch-events';
import VueDatetime from 'vue-datetime';
import VueLuxon from 'vue-luxon';
import { Settings } from 'luxon';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import * as VueGoogleMaps from 'vue2-google-maps';
import PrettyCheckbox from 'pretty-checkbox-vue';
import vSelect from 'vue-select';
import TextHighlight from 'vue-text-highlight';
import VueSweetalert2 from 'vue-sweetalert2';
import carousel from 'vue-owl-carousel';
// Vant component
import { Popup } from 'vant';
// Vant styles
import 'vant/lib/index.css';

// Vue Dialog
import VuejsDialog from 'vuejs-dialog';
// eslint-disable-next-line import/extensions
import VuejsDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js';
// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

import api from './api/api';

import App from './App.vue';
// eslint-disable-next-line import/no-cycle
import router from './router';
import store from './store';

import messages from './locale/locales';

import layerMenu from './json/layerMenu';
import layerMenuCA from './json/layerMenuCA';
import alertsDrivingMessages from './json/alertsDrivingMessages';
import alterationMessages from './json/alterationMessages';
import './registerServiceWorker';

// eslint-disable-next-line import/order
import { MdField } from 'vue-material/dist/components';

import 'vue-material/dist/vue-material.min.css';
// eslint-disable-next-line import/order
import Dexie from 'dexie';

api.interceptors.request.use(
  (config) => {
    const url = new URL(config.url);
    if (store.state.token != null) {
      url.searchParams.append('access_token', store.state.token.access_token);
    } else {
      return new Promise(() => {});
    }
    config.url = url.href;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

(() => {
  const oldOpen = XMLHttpRequest.prototype.open;
  window.openHTTPs = 0;
  XMLHttpRequest.prototype.open = function (method, url, async, user, pass) {
    const excludedUrls = [
      'search',
      'near-transport',
      'trafficincidents',
      'api/place/queryautocomplete',
    ];
    const urlWithLoader = findIndex(excludedUrls, (o) => url.indexOf(o) >= 0) < 0;
    if (urlWithLoader) {
      window.openHTTPs++;
      $('.loader-container').show();
      this.addEventListener('readystatechange', function (e) {
        if (this.readyState === 4) {
          window.openHTTPs--;
          if (window.openHTTPs === 0) {
            $('.loader-container').hide();
          }
        }
      }, false);
    }
    oldOpen.call(this, method, url, async, user, pass);
  };
})();

Vue.use(VuejsDialog);
Vue.use(VuejsDialogMixin);
Vue.use(Vue2TouchEvents);
Vue.use(VueDatetime);
Vue.use(VueI18n);
Vue.use(BootstrapVue);
Vue.use(Popup);
Vue.use(IconsPlugin);
const VueSweetAlertOptions = {
  width: '80%',
};
Vue.use(VueSweetalert2, VueSweetAlertOptions);
// Vue Material Components
Vue.use(MdField);

Vue.use(carousel);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: 'places,geometry', // This is required if you use the Autocomplete plugin
    language: window.location.pathname.substr(1).split('/').pop(),
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    // If you want to set the version, you can do so:
    // v: '3.26',
  },
  // If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  // autobindAllEvents: false,

  // If you want to manually install components, e.g.
  // import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then disable the following:
  // installComponents: true,
});

Vue.use(PrettyCheckbox);
vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: (createElement) => createElement('span', { class: { 'bcn-icon-baix-medium': true } }),
  },
});
Vue.component('v-select', vSelect);
Vue.component('text-highlight', TextHighlight);

const i18n = new VueI18n({
  locale: 'ca',
  messages,
});

Vue.use(VueLuxon, {
  localeLang: i18n.locale,
});

window.$ = $;

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  i18n,
  data: {
    titulo: 'Home',
  },
  computed: {
    ...mapState('planroute', [
      'places',
    ]),
    ...mapState('transport', [
      'preferencias',
    ]),
    ...mapState('help', [
      'opened',
      'viewed',
    ]),
  },
  methods: {
    ...mapMutations({
      setLayerMenu: 'menu/setLayerMenu',
      setPreferencias: 'transport/setPreferencias',
      setPlaces: 'planroute/setPlaces',
      setAlterationMessages: 'transport/setAlterationMessages',
      setAlertsDrivingMessages: 'transport/setAlertsDrivingMessages',
      setWidget: 'map/setWidget',
      setIsAmericCups: 'map/setIsAmericCups',
      setShowHelp: 'help/setShowHelp',
      setCurrentPos: 'map/setCurrentPos',
      setIntZoom: 'map/setIntZoom',
      addFixedLayer: 'map/addFixedLayer',
      setNameFixedGroup: 'map/setNameFixedGroup',
      setSite: 'general/setSite',
      setNotificationWarningViewed: 'general/setNotificationWarningViewed',
    }),
  },
  created() {
    const db = new Dexie('bcn');
    db.version(1).stores({
      settings: 'key,value',
    });
    const layerMenuFile = layerMenu;
    i18n.locale = this.$route.params.lang;
    db.settings.put({ key: 'lang', value: this.$route.params.lang });
    if (this.$route.fullPath.split('/').includes('widget')) {
      this.setWidget(true);
    }

    // Old Americas Cup
    /* const routerParts = this.$route.fullPath.split('/');
    if (routerParts.includes('copa-america') || routerParts[routerParts.length - 1].split('?')[0] === 'copa-america') {
      this.setIsAmericCups(true);
      this.setSite('copa-america');
      const pos = {
        lat: 41.3727928,
        lng: 2.1807711,
      };
      this.setCurrentPos(pos);
      this.setIntZoom(14);
      setTimeout(() => {
        document.querySelectorAll('.step').forEach((element, index) => {
          if (index != 1) {
            element.style.top = `${parseFloat(getComputedStyle(element).top) + document.querySelector('.header-america-cups').clientHeight}px`;
          }
        });
      }, 1600);
      layerMenuFile = layerMenuCA;
      this.setNameFixedGroup('ca_spaces');
      if (!this.$route.query?.layers || this.$route.query?.layers == '') this.addFixedLayer(layerMenuFile);
    } */
    Settings.defaultLocale = i18n.locale;
    if (localStorage.preferencias !== undefined) {
      this.setPreferencias(JSON.parse(localStorage.preferencias));
    }
    if (localStorage.places !== undefined) {
      const places = JSON.parse(localStorage.places);
      const newPlaces = places.filter((place) => !place.geocoder_status);
      this.setPlaces(newPlaces);
    }
    if (localStorage.showHelp === undefined || localStorage.showHelp === 'true') {
      this.setShowHelp(true);
    }
    if (localStorage.notificationWarningViewed) {
      this.setNotificationWarningViewed(true);
    }
    layerMenuFile.map((items) => {
      items.options = items.options.filter((option) => !option?.isWidget || (option?.isWidget == this.$route.params.widget));
      return items;
    });
    this.setLayerMenu(layerMenuFile);
    this.setAlterationMessages(alterationMessages);
    this.setAlertsDrivingMessages(alertsDrivingMessages);

    this.$gmapApiPromiseLazy().then(() => {
      const classes = [
        import('./classes/maps/MapComponent'),
        import('./classes/maps/markerclusterer'),
        import('./classes/maps/DataLayer'),
      ];

      Promise.all(classes).then(() => {
        this.$mount('#app');
      });
    });
  },
  metaInfo() {
    return {
      title: "Com s'hi va",
      htmlAttrs: {
        lang: this.$route.params.lang,
      },
    };
  },
  render: (h) => h(App),
});
export const EventBus = new Vue();

window.app = app;

export {
  app,
  router,
  store,
  i18n,
};

/* eslint-disable */

import axios from 'axios';
import { getApiUri } from '../api/util';
import store from '../store/index';
import api from '../api/api';

window.LineaTransportFunicular = function() {

}

LineaTransportFunicular.prototype.loadTransport = function () {
  api.get(`${getApiUri()}metroliniestmb/`).then(function (response) {
    // Load funicular lines
    const results = response.data.features;
    for (let c in results) {
      const result = results[c];
      const linea = {
        linea: result.properties.NOM_LINIA,
        descripcio: result.properties.DESC_SERVEI,
        color: result.properties.COLOR_LINIA,
        codi: result.properties.CODI_LINIA,
        type: 'funicular',
        icon: 'csvicon-funicular',
        estacions: {},
        reverse: false,
        selected: false,
      }
      if (linea.linea == 'FM') {
        store.commit('transport/setLineas', {
          funicular: {
            [result.properties.CODI_LINIA]: linea,
          },
        });
      }
    }
    const ordered = {};
    Object.keys(store.state.transport.lineas.funicular).sort().forEach(function(key) {
      ordered[key] = store.state.transport.lineas.funicular[key];
    });

    store.commit('transport/setLineas', {
      funicular: null,
    });

    store.commit('transport/setLineas', {
      funicular: ordered,
    });
  });
}

LineaTransportFunicular.prototype.loadStation = function (linea) {
  var promise = new Promise(function(resolve, reject) {
    api.get(`${getApiUri()}metroestacionstmb/${linea}/`).then(function (response) {
      var results = response.data.features;
      for (var c in results) {
        var result = results[c];
        var estacio = {
          nom: result.properties.NOM_ESTACIO,
          codi: result.properties.CODI_ESTACIO,
          accessibilitat: (result.properties.NOM_TIPUS_ACCESSIBILITAT=='Accessible') ? true : false,
          accessos: result.properties.ACCESSOS,
          correspondencies: result.properties.CORRESPONDENCIES,
          elevador: false,
          geometry: result.geometry
        }
        for(var a in result.properties.ACCESSOS) {
          if (result.properties.ACCESSOS[a].ACCESSOS_FISICS.indexOf('Ascensor')!=-1) {
            estacio.elevador = true;
            break;
          }
        }

        store.commit('transport/setLineas', {
          funicular: {
            [result.properties.CODI_LINIA]: {
              estacions: {
                [result.properties.CODI_ESTACIO]: estacio,
              },
            },
          },
        });
      }
      resolve(response);
    });
  });
  return promise;
}

LineaTransportFunicular.getColor = function(linea) {
  for (var c in store.state.transport.lineas.funicular) {
    if (store.state.transport.lineas.funicular[c].linea==linea) {
      return store.transport.state.lineas.funicular[c].color;
    }
  }
  return '777777'
}

LineaTransportFunicular.getLinea = function(linea) {
  for (var c in store.state.transport.lineas.funicular) {
    if (store.state.transport.lineas.funicular[c].linea==linea) {
      return store.state.transport.lineas.funicular[c];
    }
  }
  return {};
}

LineaTransportFunicular.getStation = function(linea, code) {
  for (var c in linea.estacions) {
    if (linea.estacions[c].codi==code) {
      return linea.estacions[c];
    }
  }
  return {};
}

LineaTransportFunicular.displayInfo = function(feature) {
  const refs = store.state.map.isWidget === true ? window.app.$children[0].$children[0].$children[0].$refs : window.app.$children[0].$children[0].$refs;
  refs.infoPanel.initInfoPanel();

  store.commit('setInfoPanel', {
    datos: null,
  });

  var bus_codes = ['Convencionals','Horizontals','Proximitat','Verticals','Especials','BusTuristic','Diagonals'];
  var train_codes = ['Rodalies-BCN','Rodalies-Regionals','FGC'];
  var tram_codes = ['TRAM'];
  var funi_codes = ['Metro-Funicular'];

  let lineas = [];
  if (feature.getProperty('CORRESPONDENCIES').Metro!=undefined) {
    for(let c in feature.getProperty('CORRESPONDENCIES').Metro) {
      lineas.push(`metro/${feature.getProperty('CORRESPONDENCIES').Metro[c]}.png`);
    }
  }

  if (feature.getProperty('CORRESPONDENCIES').FGC!=undefined) {
    feature.getProperty('CORRESPONDENCIES').FGC.sort();
    for(let c in feature.getProperty('CORRESPONDENCIES').FGC) {
      lineas.push(`fgc/${feature.getProperty('CORRESPONDENCIES').FGC[c]}.png`);
    }
  }

  if (feature.getProperty('CORRESPONDENCIES')['Rodalies-BCN']!=undefined) {
    feature.getProperty('CORRESPONDENCIES')['Rodalies-BCN'].sort();
    for(let c in feature.getProperty('CORRESPONDENCIES')['Rodalies-BCN']) {
      lineas.push(`rodalies/${feature.getProperty('CORRESPONDENCIES')['Rodalies-BCN'][c]}.png`);
    }
  }
  if (feature.getProperty('CORRESPONDENCIES')['Rodalies-Regionals']!=undefined) {
    feature.getProperty('CORRESPONDENCIES')['Rodalies-Regionals'].sort();
    for(let c in feature.getProperty('CORRESPONDENCIES')['Rodalies-Regionals']) {
      lineas.push(`rodalies/${feature.getProperty('CORRESPONDENCIES')['Rodalies-Regionals'][c]}.png`);
    }
  }

  let lineas_bus = [];
  for (let b in bus_codes) {
    const bus_code = bus_codes[b];
    if (feature.getProperty('CORRESPONDENCIES')[bus_code]!=undefined) {
      for(let c in feature.getProperty('CORRESPONDENCIES')[bus_code]) {
        lineas_bus.push(feature.getProperty('CORRESPONDENCIES')[bus_code][c]);
      }
    }
  }

  let lineas_tram = [];
  if (feature.getProperty('CORRESPONDENCIES')['TRAM']!=undefined) {
    for(let c in feature.getProperty('CORRESPONDENCIES')['TRAM']) {
      lineas_tram.push(feature.getProperty('CORRESPONDENCIES')['TRAM'][c]);
    }
  }

  store.commit('infopanel/setInfoPanel', {
    title: feature.getProperty('NOM_ESTACIO'),
    linea: `logo-funicular.png`,
    nom_linea: feature.getProperty('NOM_LINIA'),
    lineas: lineas,
    lineas_bus: lineas_bus,
    lineas_tram: lineas_tram,
    type: 'funicular',
    feature: feature,
    accessible: (feature.getProperty('NOM_TIPUS_ACCESSIBILITAT') == 'Accessible') ? true : false,
    datos: [

    ],
  });

  store.commit('infopanel/setInfoPanel', {
    visible: true,
  });
  refs.infoPanel.initPanel();
}

LineaTransportFunicular.loadLineMap = function(linea_name, zoom, station_name) {

  var linea = LineaTransportFunicular.getLinea(linea_name);

  window._mtm.push({
    'event': 'comshiva',
    'eventcategory': 'ComShiVa',
    'eventaction': 'Transport',
    'eventname': `Funicular: ${linea.descripcio}`
  });

  var configStation = Object.assign({}, {
    ZOOM: 16,
    zoomPoint: 17,
    CENTER: new google.maps.LatLng(41.404, 2.173),
    MAP_TYPE: google.maps.MapTypeId.ROADMAP,
    REGION: 'ca',
    BUFFER_DISTANCE: 0.2, // Km
    savePosition: true,
    displayInfo: true,
    setMarkerSearch: true,
    adaptativeMarkers: true,
    iconURL: "/images/markers/publico",
    forceZoom: false,
    mapTypeControl: false,
    minZoomLevel: 14,
    visible: true,
    maxZoomClusteringLevel: 16,
    clusterIconSize: [24, 35],
  });

  configStation.callback = {
    displayInfo: LineaTransportFunicular.displayInfo,
  };

  configStation.style = {
    strokeColor: "#"+linea.color,
    fillColor: "#"+linea.color,
    strokeOpacity: 1,
    strokeWeight: 2,
    path: "/images/markers/publico",
    marker: "funicular.svg",
    icon: "funicular.svg",
    clusterMarker: "transport.svg",
  };

  configStation.name = `funicularparades${linea.linea}`;

  var configLine = Object.assign({}, {
    ZOOM: 16,
    zoomPoint: 17,
    CENTER: new google.maps.LatLng(41.404, 2.173),
    MAP_TYPE: google.maps.MapTypeId.ROADMAP,
    REGION: 'ca',
    BUFFER_DISTANCE: 0.2, // Km
    savePosition: true,
    setMarkerSearch: true,
    adaptativeMarkers: true,
    iconURL: "/images/markers/",
    forceZoom: false,
    mapTypeControl: false,
  });

  configLine.style = {
    strokeColor: "#"+linea.color,
    fillColor: "#"+linea.color,
    strokeOpacity: 1,
    strokeWeight: 2,
  };

  configLine.name = `funicularlinies${linea.linea}`;

  var dataLayerStation = new DataLayer(configStation, store.state.map.mapObj);
  var dataLayerLine = new DataLayer(configLine, store.state.map.mapObj);

  var layerUrlLine = `${getApiUri()}metroliniestmb/${linea.linea}/`;
  var layerUrlStacions = `${getApiUri()}metroestacionstmb/${linea.linea}/`;

  Promise.all([
    dataLayerLine.addGeoJsonDataLayer(layerUrlLine),
    dataLayerStation.addGeoJsonDataLayer(layerUrlStacions)
  ]).then(() => {
    if (station_name!=undefined) {
      var station = LineaTransportFunicular.getStation(linea, station_name);
      store.state.map.mapObj.setCenter(new google.maps.LatLng(station.geometry.coordinates[1],station.geometry.coordinates[0]));
      store.state.map.mapObj.setZoom(configStation.zoomPoint);
    }
    if (zoom=='line') {
      dataLayerLine.cropToBounds();
      if (store.state.map.mapObj.getZoom()<12) {
        store.state.map.mapObj.setZoom(12);
      }
    }
    linea.selected = true;
    linea.group = "public_transport";
    store.commit('map/addActiveLayer', linea);
  });

}

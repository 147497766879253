<template>
  <div class="map-component">
    <div id="map-canvas">
    </div>
    <div :class="[
      'map-buttons-top',
      optionWidget === 'traffic' ? 'map-buttons-top-traffic' : '',
      ]" v-if="isWidget">
      <div id="traffic-incidents-widget" class="map-button-notifications" @click="$emit('open-traffic-incidents')">
        <div class="content-bell-traffic">
          <div v-if="trafficIncidentsLength > 0" class="count-traffic-alerts">{{ trafficIncidentsLength }}</div>
          <img src="/images/icons/bell.svg">
        </div>
      </div>
    </div>
    <div v-if="!!mapFullScreen" class="exit-full-screen" @click="mapClicked()">
      <img src="/images/icons/exit-full-screen.svg">
    </div>
    <div id="car-selector-tabs" style="top: 0;" v-if="menuNavigationApp.optionSelected === 'cotxe' && !mapFullScreen">
      <b-tabs content-class="mt-3 content-tabs" nav-class="nav-tabs" active-tab-class="active-tabs" :style="shadow()">
        <b-tab title="Actual" active @click="showTrafficActual()"></b-tab>
        <b-tab title="Previst" @click="showTrafficPrevist()"></b-tab>
        <b-tab title="Càmeres" @click="showTrafficCamaras()"></b-tab>
      </b-tabs>
    </div>
    <div v-if="menuNavigationApp.optionSelected === 'apropmeu'" class="map-message">
      <b-card
        v-if="showMessageBus"
        style="max-width: 90%;"
        class="mx-auto mt-2">
        <div class="row m-0">
          <div class="col-1 p-0 align-self-center">
            <img src="/images/icons/liniesiparades.svg" class="logo" alt=""/>
          </div>
          <div class="col-10 p-0">
            <span style="font-size: 13px">{{ $t('click_bus') }}</span>
          </div>
          <div class="col-1 p-0">
            <img src="/images/icons/close.svg" class="logo"
                 @click="hiddenMessageBus()" alt=""/>
          </div>
        </div>
      </b-card>
    </div>
    <div class="map-message transit">
      <b-card
        v-if="showMessageTransit && trafficIncidents?.incidents"
        style="max-width: 90%; background-color: #FFF7F5; max-height: 350px; overflow: auto;"
        class="mx-auto mt-2">
        <div class="row m-0" v-if="getNewIncidents.length > 0">
          <div class="p-0 d-flex w-100 justify-content-between">
            <div>
              <img src="/images/icons/liniesiparades.svg"
                   class="logo logo-info-transit" alt=""/>
              <span class="ml-2 title-transit">{{ $t('traffic_alerts') }}</span>
            </div>
            <img src="/images/icons/close.svg" class="logo"
                 @click="hiddenMessagesTransit()" alt=""/>
          </div>
          <ul class="ul-transit">
            <li class="li-transit"
                v-for="(incident,index) of getNewIncidents"
                v-bind:key="index">
              {{ incident }}
            </li>
          </ul>
        </div>
      </b-card>

      <b-card
        v-if="showMessagePrevistTransit"
        style="max-width: 90%;"
        class="mx-auto mt-2">
        <div class="d-flex">
          <div class="d-flex align-items-center">
            <img src="/images/icons/liniesiparades.svg"
                   class="logo" alt=""/>
          </div>
          <div class="previst-time-traffic">
            <span>{{ $t('expected_traffic_msg', {futureTrafficHour: futureTrafficHour, futureTrafficDate: futureTrafficDate}) }}</span>
          </div>
          <div>
            <img src="/images/icons/close.svg" class="logo"
                 @click="hiddenMessagesPrevistTransit()" alt=""/>
          </div>
        </div>
      </b-card>
    </div>
    <div class="map-buttons">
      <div v-if="isWidget && optionWidget === 'zurich-marato'" @click="showWidgetCallback">
        <img :src="`/images/icons/info${isOpenInfoPanel ? '-active': ''}.svg`">
      </div>
      <div @click="openLegendTransit" v-if="menuNavigationApp.optionSelected === 'cotxe' && typeTabCotxe != 'cameras'">
        <img :src="`/images/icons/info-button${refs.infoPanel.opened ? '-active' : ''}.svg`">
      </div>
      <div @click="goToMyPosClick" :class="geolocationActiveClass">
        <img src="/images/icons/gps.svg">
      </div>
      <div @click="openLayerMenu" class="layers" :class="layersActive" id="add-layers">
        <div v-if="activeLayers && activeLayers.length > 0" class="num-layers" :class="{'open': layerMenuStatus.opened}"
          >{{ activeLayers.length }}</div>
        <img v-if="layerMenuStatus.opened" src="/images/icons/layers-active.svg">
        <img v-else src="/images/icons/layers.svg">
      </div>
      <div class="msg-layers" v-if="msgLayers && !showHelp">
        <img class="close-msg" src="/images/icons/close-bg-white.svg" @click="setMsgLayers(false)">
        {{ site == 'copa-america' ? $t('visualize_activate_layers_ca') : $t('visualize_activate_layers') }}
      </div>
      <div class="msg-layers" v-if="showLayerTooltip">
        <img class="close-msg" src="/images/icons/close-bg-white.svg" @click="setShowLayerTooltip(false)">
        {{ $t('activate_layer_planificador')}}
      </div>
    </div>
  </div>
</template>

<script>
import { find, findIndex, cloneDeep } from 'lodash';
import { mapState, mapMutations } from 'vuex';
import { gmapApi } from 'vue2-google-maps';
import { app, EventBus } from '@/main';
import { MapMixins } from './mixins/MapMixins';
import { LayerMixins } from './mixins/LayerMixins';
import { TransportMixins } from './mixins/TransportMixins';

export default {
  name: 'Mapa',
  mixins: [
    MapMixins,
    LayerMixins,
    TransportMixins,
  ],
  props: ['trafficIncidentsLength'],
  data() {
    return {
      mapOptions: null,
      showMessageBus: null,
      showMessageTransit: null,
      showMessagePrevistTransit: null,
      typeTabCotxe: 'actual',
      refs: null,
    };
  },
  computed: {
    google: gmapApi,
    layersActive() {
      return {
        'text-link': this.activeLayers.length > 0,
      };
    },
    geolocationActiveClass() {
      return {
        'text-link': this.geolocationActive,
      };
    },
    ...mapState('menu', [
      'layerMenuStatus',
      'layerMenu',
      'menuNavigationApp',
    ]),
    ...mapState('map', [
      'mapObj',
      'mapFullScreen',
      'currentPos',
      'markerCurrentPos',
      'geolocationActive',
      'activeLayers',
      'lastNearTransportPoint',
      'futureTrafficHour',
      'futureTrafficDate',
      'isWidget',
      'optionWidget',
      'widgetCallback',
      'isOpenInfoPanel',
      'intZoom',
      'msgLayers',
      'showLayerTooltip',
    ]),
    ...mapState('planroute', {
      planificadorOpened: 'opened',
    }),
    ...mapState('incidents', [
      'trafficIncidents',
    ]),
    ...mapState('transport', [
      'lineas',
    ]),
    ...mapState('help', [
      'showHelp',
    ]),
    ...mapState('infopanel', [
      'infoPanel',
    ]),
    ...mapState('general', [
      'site',
    ]),
    getNewIncidents() {
      return this.trafficIncidents.incidents.filter((incident) => {
        return this.trafficIncidents.new_incidents.indexOf(incident) !== -1;
      });
    },
  },
  watch: {
    menuNavigationApp(newVal, oldVal) {
      if (newVal.optionSelected === 'cotxe') {
        this.showTrafficActual();
        setTimeout(() => this.openLegendTransit(), 2000);
      }
    },
  },
  methods: {
    ...mapMutations({
      setMap: 'map/setMap',
      setCurrentPos: 'map/setCurrentPos',
      setMarkerCurrentPos: 'map/setMarkerCurrentPos',
      setGeolocationActive: 'map/setGeolocationActive',
      setMapFullScreen: 'map/setMapFullScreen',
      setLastNearTransportPoint: 'map/setLastNearTransportPoint',
      setLayerMenuStatus: 'menu/setLayerMenuStatus',
      setMenuNavigationApp: 'menu/setMenuNavigationApp',
      setPlanificadorOpened: 'planroute/setOpened',
      setFutureTraffic: 'map/setFutureTraffic',
      setWidget: 'map/setWidget',
      setOptionWidget: 'map/setOptionWidget',
      setTitle: 'warning/setTitle',
      setDescription: 'warning/setDescription',
      setOpen: 'warning/setOpen',
      setToValue: 'planroute/setToValue',
      setToObject: 'planroute/setToObject',
      setIsOpenInfoPanel: 'map/setIsOpenInfoPanel',
      setMsgLayers: 'map/setMsgLayers',
      setShowLayerTooltip: 'map/setShowLayerTooltip',
    }),
    showTrafficCamaras() {
      this.typeTabCotxe = 'cameras';
      this.showTraffic('cameras');
    },
    showTrafficActual() {
      this.setFutureTraffic(false);
      this.typeTabCotxe = 'actual';
      this.showTraffic('traffic');

      this.showMessageTransit = localStorage.showMessageTransit !== 'false'
      && this.menuNavigationApp.optionSelected === 'cotxe' && this.typeTabCotxe == 'actual';
    },
    showTrafficPrevist() {
      this.setFutureTraffic(true);
      this.typeTabCotxe = 'previst';
      this.showTraffic('traffic');

      this.showMessagePrevistTransit = localStorage.showMessagePrevistTransit !== 'false'
      && this.menuNavigationApp.optionSelected === 'cotxe' && this.typeTabCotxe == 'previst';
    },
    showWidgetCallback() {
      this.widgetCallback();
      this.setIsOpenInfoPanel(true);
    },
    openLayerMenu() {
      if (this.showHelp) return;

      if (!this.layerMenuStatus.opened) {
        if (this.site !== 'copa-america') {
          this.refs.layerMenu.initPanel();
          this.setMsgLayers(false);
        } else {
          this.$bvModal.show('modal-selector-layers');
        }
      } else {
        this.refs.layerMenu.closePanel();
      }
    },
    openLegendTransit() {
      if (this.isOpenInfoPanel) {
        this.refs.infoPanel.onSwipeDown();
      } else {
        this.refs.infoPanel.initPanel();
      }
    },
    loadMap() {
      const generalStyles = [
        {
          featureType: 'poi.business',
          elementType: 'all',
          stylers: [{
            visibility: 'off',
          }],
        },
        {
          featureType: 'poi.attraction',
          elementType: 'all',
          stylers: [{
            visibility: 'off',
          }],
        },
        {
          featureType: 'poi.government',
          elementType: 'all',
          stylers: [{
            visibility: 'off',
          }],
        },
        {
          featureType: 'transit',
          elementType: 'all',
          stylers: [{
            visibility: 'off',
          }],
        },
      ];

      this.mapOptions = {
        center: this.currentPos,
        zoom: this.intZoom,
        mapTypeControl: false,
        zoomControl: false,
        streetViewControl: false,
        disableDefaultUI: true,
        gestureHandling: 'greedy',
        styles: generalStyles,
      };

      const mapObj = new google.maps.Map(document.getElementById('map-canvas'), this.mapOptions);
      this.setMap(mapObj);
      google.maps.event.addListener(this.mapObj, 'click', (e) => {
        if (e.placeId !== undefined) return;
        if (this.planificadorOpened) {
          this.setPlanificadorOpened(false);
          return;
        }

        if (!this.isWidget) {
          this.setMapFullScreen(!this.mapFullScreen);
          const zIndex = this.mapFullScreen ? '0' : '1';
          document.getElementById('c-flexible-data').style.zIndex = zIndex;
          this.setLayerMenuStatus({
            opened: false,
          });
        }
      });
      google.maps.event.addListener(this.mapObj, 'zoom_changed', (e) => {
        if (findIndex(this.activeLayers, (o) => o.code === 'transport_accessible') !== -1) {
          this.markerVisibility();
        }
      });
      google.maps.event.addListener(this.mapObj, 'dragend', (e) => {
        let reloadNearTransport = false;
        if (this.lastNearTransportPoint == null) {
          this.setLastNearTransportPoint(this.mapObj.getCenter());
          reloadNearTransport = true;
        }
        const meters = google.maps.geometry.spherical.computeDistanceBetween(this.lastNearTransportPoint, this.mapObj.getCenter());

        if (meters > 200) {
          this.setLastNearTransportPoint(this.mapObj.getCenter());
          reloadNearTransport = true;
        }
        if (findIndex(this.activeLayers, (o) => o.code === 'near_public_transport') !== -1 && reloadNearTransport) {
          this.loadNearTransport('near_public_transport');
        }
        if (findIndex(this.activeLayers, (o) => o.code === 'transport_accessible') !== -1 && reloadNearTransport) {
          this.loadNearTransport('transport_accessible');
        }
      });
      google.maps.event.addListenerOnce(this.mapObj, 'idle', () => {
        this.refs.layerMenu.initialLayers();
        if (this.$route.params.widget) {
          this.setWidget(true);
          this.setOptionWidget(this.$route.params.widget);
          switch (this.$route.params.widget) {
            case 'bici':
              this.widgetBike();
              break;
            case 'traffic':
              this.widgetTraffic();
              break;
            case 'mobilitat':
              this.widgetMobility();
              break;
            case 'zurich-marato':
              this.widgetMarathon();
              break;
            default:
              break;
          }
        } else if (this.$route.query.hasta) {
          this.goToPositionURL(this.$route.query.hasta);
        } else if (this.$route.query.layers) {
          this.layerUrl(this.$route.query.layers);
        } else {
          const optionNearTransport = find(this.layerMenu[0].options, (o) => {
            return o.code === 'near_public_transport';
          });
          this.refs.layerMenu.selectOpcion(optionNearTransport);
        }
      });
      // TO DO
      // this.loadHighlights();
    },
    loadMarkerCurrentPos() {
      if (this.markerCurrentPos != null) {
        this.markerCurrentPos.setMap(null);
      }
      const icon = {
        url: '/images/current-pos.png',
      };
      this.setMarkerCurrentPos(new google.maps.Marker({
        position: this.currentPos,
        icon,
        map: this.mapObj,
      }));
      this.markerCurrentPos.setMap(this.mapObj);
    },
    goToMyPosClick() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.goToMyPos(position);
        }, (error) => {
          this.$swal(this.$t('geolocation_msg'), '', 'warning');
          this.setGeolocationActive(false);
        });
      } else {
        this.setGeolocationActive(false);
        this.setLastNearTransportPoint(this.mapObj.getCenter());
      }
    },
    goToMyPos(position) {
      if (position) {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.setCurrentPos(pos);
        this.setGeolocationActive(true);
        this.mapObj.setCenter({ lat: pos.lat, lng: pos.lng });
        this.mapObj.setZoom(17);
        this.loadMarkerCurrentPos();
        this.loadNearTransport();
        this.setLastNearTransportPoint(this.mapObj.getCenter());
      } else {
        this.setGeolocationActive(false);
        this.setLastNearTransportPoint(this.mapObj.getCenter());
      }
    },
    mapClicked() {
      this.setMapFullScreen(!this.mapFullScreen);
      const zIndex = this.mapFullScreen ? '0' : '1';
      document.getElementById('c-flexible-data').style.zIndex = zIndex;
    },
    hiddenMessageBus() {
      this.showMessageBus = false;
      localStorage.showMessageBus = false;
    },
    hiddenMessagesTransit() {
      this.showMessageTransit = false;
      localStorage.showMessageTransit = false;
    },
    hiddenMessagesPrevistTransit() {
      this.showMessagePrevistTransit = false;
      localStorage.showMessagePrevistTransit = false;
    },
    shadow() {
      const shadow = this.menuNavigationApp.optionSelected === 'cotxe' || this.isWidget ? '0px 5px 6px #0000001F' : 'none';
      return { 'box-shadow': shadow };
    },
    widgetBike() {
      const bikeLayer = find(this.layerMenu, (o) => o.code === 'bici');
      const bikeLayerGroup = find(bikeLayer.options, (o) => o.code === 'carrilbicigrupo');
      const carrilsbici = find(bikeLayerGroup.options, (o) => o.code === 'carrilsbici');
      const carrers30 = find(bikeLayerGroup.options, (o) => o.code === 'carrers30');
      setTimeout(() => {
        this.refs.layerMenu.selectOpcion(carrilsbici);
        this.refs.layerMenu.activateLayer(carrilsbici);
        this.refs.layerMenu.selectOpcion(carrers30);
        this.refs.layerMenu.activateLayer(carrers30);
      }, 2000);
    },
    widgetTraffic() {
      setTimeout(() => {
        this.showTrafficActual();
        this.setMenuNavigationApp({
          optionSelected: 'cotxe',
        });
      }, 2000);
    },
    widgetMobility() {
      const bikeLayer = find(this.layerMenu, (o) => o.code === 'bici');
      const bikeLayerGroup = find(bikeLayer.options, (o) => o.code === 'carrilbicigrupo');
      const carrilsbici = find(bikeLayerGroup.options, (o) => o.code === 'carrilsbici');

      const walk = find(this.layerMenu, (o) => o.code === 'walk');
      const zonaspeatonales = find(walk.options, (o) => o.code === 'zonaspeatonales');
      setTimeout(() => {
        this.refs.layerMenu.selectOpcion(zonaspeatonales);
        this.refs.layerMenu.activateLayer(zonaspeatonales);
        this.refs.layerMenu.selectOpcion(carrilsbici);
        this.refs.layerMenu.activateLayer(carrilsbici);
      }, 2000);
    },
    widgetMarathon() {
      const walk = find(this.layerMenu, (o) => o.code === 'walk');
      const marathon = find(walk.options, (o) => o.code === 'marathon');
      setTimeout(() => {
        this.refs.layerMenu.selectOpcion(marathon);
        this.refs.layerMenu.activateLayer(marathon);
        this.setTitle(this.$t('notice'));
        this.setDescription(this.$t('marathon_description'));
        this.setOpen(true);
      }, 2000);
    },
    goToPositionURL(position) {
      if (position) {
        const pos = position.split(',');
        this.mapObj.setCenter({ lat: parseFloat(pos[0]), lng: parseFloat(pos[1]) });
        this.mapObj.setZoom(17);
        this.loadMarkerCurrentPos();
        this.setLastNearTransportPoint(this.mapObj.getCenter());
        const posGoogle = {
          lat: Number(pos[0]),
          lng: Number(pos[1]),
        };
        const googleRoutesApi = new GoogleRoutesApi(this.$i18n.locale);
        googleRoutesApi.geocode(posGoogle)
          .then((results) => {
            const val = results[0];
            this.setToValue(val.formatted_address);
            this.setToObject(val);
            this.$forceUpdate();
            this.$bvModal.show('modal-planificador');
          })
          .catch((error) => {
            console.error('Error occurred:', error);
          });
      }
    },
    getTransportPublic(layersId) {
      if (this.lineas && typeof this.lineas === 'object') {
        Object.keys(this.lineas).forEach((outerKey) => {
          const innerMap = this.lineas[outerKey];
          if (innerMap && typeof innerMap === 'object') {
            Object.keys(innerMap).forEach((key) => {
              const value = innerMap[key];
              if (layersId.includes(value?.linea)) {
                this.selectTransportLine(value, true);
              }
            });
          }
        });
      }
    },
    processOptions(options, layersId) {
      options.forEach((option) => {
        if (option?.id && layersId.includes(String(option.id))) {
          if (this.activeLayers.findIndex((o) => o.id === option.id) === -1) {
            this.refs.layerMenu.selectOpcion(option);
          }
        } else if (option?.options) {
          this.processOptions(option.options, layersId);
        }
      });
    },
    layerUrl(layersUrl) {
      if (layersUrl) {
        const layerDecoded = decodeURIComponent(layersUrl);
        const layersId = layerDecoded.split(',');
        setTimeout(() => {
          this.layerMenu.forEach((layer) => {
            if (layer?.options) {
              this.processOptions(layer.options, layersId);
            }
          });
          this.getTransportPublic(layersId);
        }, 1000);
      }
    },
    moveMapButtons(direction, panelId) {
      if (direction === 'up') {
        setTimeout(() => {
          const mapCanvas = document.getElementById('map-canvas');
          const panel = document.getElementById(panelId);
          const mapButtons = document.getElementsByClassName('map-buttons')[0];

          if (!mapCanvas || !panel || !mapButtons) return;

          const mapCanvasHeight = mapCanvas.clientHeight;
          const menuHeight = this.mapFullScreen ? 0 : 57;
          let panelHeight = panel.clientHeight - menuHeight + 20;

          if (panelHeight >= mapCanvasHeight) {
            panelHeight = mapCanvasHeight - mapButtons.clientHeight - 20;
          }

          $('.map-buttons').css('bottom', `${panelHeight}px`);
        }, 100);
      } else {
        $('.map-buttons').css('bottom', '20px');
      }
    },
  },
  beforeMount() {
    this.showMessageBus = localStorage.showMessageBus !== 'false';
  },
  beforeUpdate() {
    this.showMessageTransit = localStorage.showMessageTransit !== 'false'
      && this.menuNavigationApp.optionSelected === 'cotxe' && this.typeTabCotxe == 'actual';

    this.showMessagePrevistTransit = localStorage.showMessagePrevistTransit !== 'false'
      && this.menuNavigationApp.optionSelected === 'cotxe' && this.typeTabCotxe == 'previst';
  },
  mounted() {
    this.loadMap();
    if (localStorage.msgLayers) {
      this.setMsgLayers(JSON.parse(localStorage.msgLayers));
    }
  },
  created() {
    this.refs = this.isWidget === true ? this.$root.$children[0].$children[0].$children[0].$refs : this.$root.$children[0].$children[0].$refs;
  },
};
</script>

<template>
  <div class="route" :class="travelMode">
    <!-- Route header transit -->
    <div
      class="header"
      :class="{
        'active-header': routeIndex == selectedIndex && type != 'sostenible',
        'sustainable-route': routeIndex == 0 && type == 'sostenible',
        'first-header': routeIndex == 0
      }"
      :id="`routeHeader_${routeIndex}`"
      v-if="travelMode=='TRANSIT'">
      <div class="content-step" :class="routeIndex == selectedIndex ? 'active-step' : ''" :style="[type == 'sostenible' ? {'padding-top': '10px'} : {}]">
        <div v-if="title" class="tit">
          <div v-if="type == 'sostenible' && opened == true">
            <img style="height: 9px; margin-right: 4px;" src="/images/icons/sustainable-route.svg">
            {{ title }}
          </div>
          <div v-if="type == 'suggested'">
            <img style="height: 9px; margin-right: 4px;" src="/images/icons/suggested-route.svg">
            {{ title }}
          </div>
        </div>
        <div class="d-flex">
          <div style="width: 73%; display: flex; align-items: center; flex-wrap: wrap;" @click="toggleRoute()">
            <div v-if="title && type == 'sostenible' && opened == false" class="tit" style="margin-botton: 7px;">
              <img style="height: 9px; margin-right: 4px;" src="/images/icons/sustainable-route.svg">
              {{ title }}
            </div>
            <div v-if="type !== 'sostenible' || (type == 'sostenible' && opened == true)" class="d-flex flex-wrap">
              <div
                class="step"
                v-for="(step, index_step) in route.legs[0].steps"
                v-bind:key="index_step"
                :class="{'first': index_step==0}"
              >
                <span v-if="step.travel_mode=='TRANSIT'">
                  <img class="icon" :src="getTransitIcon(step)" v-if="step.transit.line.vehicle.type!='BUS'">
                  <img class="icon" :src="step.transit.line.vehicle.icon" v-if="step.transit.line.vehicle.type=='BUS'">
                  <div class="line" :style="{'background-color':step.transit.line.color, 'color':step.transit.line?.text_color}">{{ step.transit.line.short_name }}</div>
                </span>
                <span v-if="step.travel_mode=='WALKING'">
                  <img class="icon walking" src="/images/icons/walk-dark.svg">
                </span>
                <span v-if="index_step + 1 < route.legs[0].steps.length">
                  <img class="icon" src="/images/icons/arrow-right.svg">
                </span>
              </div>
            </div>
          </div>
          <div>
            <div class="route-duration">{{ route.legs[0].duration?.text }}</div>
            <div class="arrival_time" v-if="route.legs[0].arrival_time && showTimesRoute">
              <div class="img-time">
                <img src="/images/icons/time.svg">
              </div>
              <div class="hours">
                <span>{{ route.legs[0].departure_time?.text }}</span>
                <span>{{ route.legs[0].arrival_time?.text }}</span>
              </div>
            </div>
          </div>
          <div class="share-icon">
            <img src="/images/icons/share.svg" @click="changeSocialOpened()">
            <div class="social" v-if="socialOpened">
              <a :href="getUrl('email')" target="_blank"><i class="bcn-icon-correu-email"></i></a>
              <a :href="getUrl('facebook')" target="_blank"><i class="bcn-icon-facebook"></i></a>
              <a :href="getUrl('twitter')" target="_blank"><i class="bcn-icon-x"></i></a>
            </div>
          </div>
        </div>
        <div v-if="routeIndex == selectedIndex" class="preferences-link" :class="{ 'open': opened }" @click="detailRoute()">
          {{ $t('details') }}
        </div>
      </div>
      <!-- Route detail transit -->
      <div class="route-detail transit" :class="{'collapse': !opened}" v-if="travelMode=='TRANSIT'">
        <div class="sostenible-zone" v-if="type == 'sostenible'">
          <div class="sostenible" v-if="diffTime <= 30">{{ $t('sustainable_message_1') }} {{ getDiffTime() }} {{ $t('sustainable_message_2') }}.</div>
          <div class="sostenible" v-if="diffTime > 30">{{ $t('sustainable_message_default') }}</div>
        </div>
        <div
          class="step"
          v-for="(step, index_step) in route.legs[0].steps"
          v-bind:key="index_step"
          :class="getStepStatus(step, index_step)"
          :style="getStepBorder(step)"
        >
          <div :style="marginStepTitle(index_step)" class="w-100">
            <div class="tit" v-if="step.travel_mode=='WALKING' && index_step==0">{{ route.legs[0].start_address }}<span class="time">{{route.legs[0].departure_time?.text}}</span></div>
            <div class="walking_desc d-flex" v-if="step.travel_mode=='WALKING'">
              <div class="d-flex align-items-center mr-1">
                <img class="icon walking" src="/images/icons/walk-dark.svg">
              </div>
              <div>
                <div class="instructions">{{ step.instructions }}</div>
                <div class="walking_time_step" v-if="step.travel_mode=='WALKING'">{{ step.duration?.text }} ({{ step.distance?.text }})</div>
              </div>
            </div>
            <div class="tit d-flex justify-content-between" v-if="step.travel_mode=='TRANSIT'">{{ step.transit.departure_stop.name }}<span class="time">{{step.transit.departure_time?.text}}</span></div>
            <div v-if="step.travel_mode=='TRANSIT'" class="transit-info">
              <div :class="{'point-before': showBeforePoint(index_step)}">
                <div class="d-flex mr-1 nowrap">
                  <img class="icon mr-1" :src="step.transit.line.vehicle.local_icon" v-if="step.transit.line.vehicle.type!='BUS'">
                  <img class="icon" :src="step.transit.line.vehicle.icon" v-if="step.transit.line.vehicle.type=='BUS'">
                  <div class="line" :style="{'background-color':step.transit.line.color, 'color':step.transit.line?.text_color}">{{ step.transit.line.short_name }}</div>
                </div>
                <div>
                  <span class="instructions">
                    {{step.instructions}}
                    <div class="transit_time_step">{{ step.duration?.text }} ({{ step.transit.num_stops }} parades)</div>
                    <div class="alert-alteration" v-if="alterationAlertVisible(step.transit.line.short_name)">
                      <span class="csvicon-alerta"></span>
                      <a :href="alterationAlertVisible(step.transit.line.short_name).links[$i18n.locale]" target="_blank" v-html="alterationAlertVisible(step.transit.line.short_name).missatges[$i18n.locale]">
                      </a>
                    </div>
                  </span>
              </div>
            </div>
            </div>
            <div class="tit d-flex justify-content-between"
            v-if="step.travel_mode=='TRANSIT'">{{step.transit.arrival_stop.name}}<span class="time">{{step.transit.arrival_time?.text}}</span></div>
            <div class="tit last" v-if="step.travel_mode=='WALKING' && index_step==route.legs[0].steps.length-1">
              {{ route.legs[0].end_address }}
              <span class="time">{{ route.legs[0].arrival_time?.text }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Route header not transit -->
    <div
      class="header not_transit"
      :class="{ 'active-header': routeIndex == selectedIndex, 'first-header': routeIndex == 0 }"
      :id="`routeHeader_${routeIndex}`"
      v-if="travelMode!='TRANSIT'"
    >
      <div class="content-step" :class="routeIndex == selectedIndex ? 'active-step' : ''">
        <div v-if="title" class="tit">
          <img style="height: 9px; margin-right: 4px;" src="/images/icons/suggested-route.svg">
          {{ title }}
        </div>
        <div class="d-flex">
          <div style="width: 73%; display: flex; align-items: center;" @click="toggleRoute()">
            <div class="desc">
              <div>
                <img class="icon" :src="`/images/icons/${getIconTravelMode()}.svg`">
              </div>
              <div>
                <div class="route-summary">
                  <span>{{ route.summary }}</span>
                </div>
                <div>
                  <span class="distance">{{ route.legs[0].distance?.text }}</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="route-duration">{{ route.legs[0].duration?.text }}</div>
            <div class="arrival_time">
              <div class="img-time">
                <img src="/images/icons/time.svg">
              </div>
              <div class="hours">
                <span>{{ getArrivalTime(1) }}</span>
                <span>{{ getArrivalTime(route.legs[0].duration.value) }}</span>
              </div>
            </div>
          </div>
          <div class="share-icon">
            <img src="/images/icons/share.svg" @click="changeSocialOpened()">
            <div class="social" v-if="socialOpened">
              <a :href="getUrl('email')" @click="initShare" target="_blank"><i class="bcn-icon-correu-email"></i></a>
              <a :href="getUrl('facebook')" @click="initShare" target="_blank"><i class="bcn-icon-facebook"></i></a>
              <a :href="getUrl('twitter')" @click="initShare" target="_blank"><i class="bcn-icon-x"></i></a>
            </div>
          </div>
        </div>
        <div v-if="routeIndex == selectedIndex" class="preferences-link" :class="{ 'open': opened }" @click="detailRoute()">
          {{ $t('details') }}
        </div>
      </div>
      <!-- Route detail not transit -->
      <div class="route-detail no-transit" v-if="travelMode != 'TRANSIT' && !!opened">
        <div class="direction-one"></div>
        <div>
          <div class="step first route-directions">
            <div style="margin-left: 15px;">{{ route.legs[0].start_address }}</div>
          </div>
          <div
            class="step step-intermediate"
            v-for="(step, index_step) in route.legs[0].steps"
            v-bind:key="index_step"
          >
            <div style="margin-left: 15px;">
              <img v-if="step.maneuver" class="adp-maneuver" :src="`/images/icons/${getManeuverIcon(step)}.svg`">
              <div class="instructions">
                <span>{{ index_step + 1  }}. </span>
                <span v-html="step.instructions"></span>
              </div>
            </div>
            <div class="separator">
              <div class="s-line"></div>
              <div class="distance">{{ step.distance?.text }}</div>
            </div>
          </div>
          <div class="step last route-directions">
            <div style="margin-left: 15px;">{{ route.legs[0].end_address }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Duration, DateTime } from 'luxon';
import { find } from 'lodash';
import { app } from '../main';
import { getApiUri } from '../api/util';
import api from '../api/api';

export default {
  name: 'RouteComponent',
  props: ['route', 'routeIndex', 'travelMode', 'title', 'type', 'suggestedRoute', 'selectedIndex'],
  emits: {
    changeSuggestedRoute: null,
  },
  data() {
    return {
      opened: false,
      diffTime: 0,
      refs: null,
      socialOpened: false,
    };
  },
  computed: {
    ...mapState('menu', [
      'layerMenu',
    ]),
    ...mapState('planroute', [
      'toObject',
      'toValue',
      'fromObject',
      'fromValue',
    ]),
    ...mapState('transport', [
      'alterationMessages',
      'alertsDrivingMessages',
    ]),
    ...mapState('map', [
      'isWidget',
    ]),
    showTimesRoute() {
      if (this.type == 'sostenible') {
        return this.opened == true;
      // eslint-disable-next-line no-else-return
      } else {
        return true;
      }
    },
  },
  watch: {
    $props: {
      handler() {
        this.opened = false;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    marginStepTitle(index_step) {
      if (index_step >= this.route.legs[0].steps.length - 2) return 'margin-top: -1px;';
      return 'margin-top: -1px; margin-bottom: -15px;';
    },
    showBeforePoint(index_step) {
      if (index_step == this.route.legs[0].steps.length - 2) return true;
      return false;
      // return index_step + 1 < this.route.legs[0].steps.length && this.route.legs[0].steps[index_step + 1].travel_mode == 'TRANSIT';
    },
    changeSocialOpened() {
      this.socialOpened = !this.socialOpened;
    },
    getStepBorder(step) {
      let borderColor = '#5D90FC';
      if (step.travel_mode == 'TRANSIT' && step.transit.line.color != '#ffffff') borderColor = step.transit.line.color;
      return {
        'border-left-style': (step.travel_mode == 'WALKING') ? 'dotted' : 'solid',
        'border-left-color': borderColor,
      };
    },
    getStepStatus(step, index_step) {
      return {
        first: index_step == 0,
        last: index_step == this.route.legs[0].steps.length - 1,
      };
    },
    getTransitIcon(step) {
      if (step.transit.line.vehicle.local_icon) {
        return step.transit.line.vehicle.local_icon;
      }
      return step.transit.line.vehicle.icon;
    },
    getIconTravelMode() {
      let icon = 'walk-dark';
      if (this.travelMode == 'DRIVING') {
        icon = 'car';
      } else if (this.travelMode == 'BICYCLING') {
        icon = 'bicycle';
      } else if (this.travelMode == 'TRANSIT') {
        icon = 'transit';
      }
      return icon;
    },
    getArrivalTime(duration) {
      const now = DateTime.local();
      const arrivalTime = now.plus({ seconds: duration });
      return arrivalTime.toLocaleString(DateTime.TIME_24_SIMPLE);
    },
    getManeuverIcon(step) {
      return `adp-${step.maneuver}`;
    },
    toggleRoute() {
      const { planificador } = this.refs;
      planificador.drawRoute(this.routeIndex);
      this.$emit('changeSuggestedRoute', this.routeIndex);
    },
    detailRoute() {
      this.opened = !this.opened;
      if (this.opened && this.refs.routePanel.position < 3) {
        this.refs.routePanel.onSwipeUp();
      }
    },
    alterationAlertVisible(line) {
      if (line == undefined) return false;
      for (const c in this.alterationMessages) {
        const incidencia = this.alterationMessages[c];
        if (incidencia.linia.toLowerCase() == line.toLowerCase()
          && new Date(incidencia.data_inici) < new Date()
          && new Date(incidencia.data_fi) > new Date()) {
          return incidencia;
        }
      }
      return false;
    },
    drivingAlertVisible() {
      for (const c in this.alertsDrivingMessages) {
        const incidencia = this.alertsDrivingMessages[c];
        if (new Date(incidencia.data_inici) < new Date()
          && new Date(incidencia.data_fi) > new Date()) {
          return incidencia;
        }
      }
      return false;
    },
    getUrl(type) {
      let baseUrl = process.env.VUE_APP_BASE_URL;
      if (baseUrl.charAt(baseUrl.length - 1) != '/') {
        baseUrl += '/';
      }
      baseUrl += `${this.$i18n.locale}?`;
      const transports = ['metro', 'bus', 'ferrocarril', 'rodalies', 'aerobus', 'tram', 'funicular'];
      // eslint-disable-next-line prefer-const
      let layerIDs = [];
      for (var c in this.activeLayers) {
        const layer = this.activeLayers[c];
        if (layer.type && transports.indexOf(layer.type) != -1) {
          layerIDs.push(layer.linea);
        } else {
          layerIDs.push(layer.id);
        }
      }
      let url = encodeURIComponent(`${baseUrl}action=planroute&origin_id=${this.fromObject.place_id}&origin=${this.fromValue}&destination_id=${this.toObject.place_id}&destination=${this.toValue}&travelMode=${this.travelMode}`);
      const title = "Com s'hi va";
      const text = 'Aquesta ruta et pot interesar';
      switch (type) {
        case 'facebook':
          url = `https://www.facebook.com/sharer/sharer.php?u=${url}&title=${title}&description=${text}`;
          break;
        case 'twitter':
          url = `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
          break;
        case 'email':
          url = `mailto:?subject=${title}&body=${this.createMailToBody(url)}`;
          break;
        default:
          console.warn('Error share route');
      }

      return url;
    },
    createMailToBody(urlRoute) {
      var link = document.createElement('a');
      link.href = urlRoute;
      const messageBody = `Aquesta ruta de ${this.fromValue.split(',').at(0)} a ${this.toValue.split(',').at(0)} (${link}) et pot interessar.`;
      return messageBody;
    },
    getDiffTime() {
      const durationRoute = this.suggestedRoute.legs[0].duration.value;
      const durationSostenibleRoute = this.route.legs[0].duration.value;
      const diff = durationSostenibleRoute - durationRoute;
      this.diffTime = diff / 60;
      const diffDuration = Duration.fromMillis(Math.abs(diff) * 1000).shiftTo('hours', 'minutes', 'seconds');
      let { hours } = diffDuration;
      let { minutes } = diffDuration;
      if (diffDuration.seconds > 30) {
        minutes += 1;
      }
      if (diffDuration.minutes == 60) {
        minutes = 0;
        hours += 1;
      }
      let textos = (diff > 0) ? `${this.$t('only')} ` : '';
      if (minutes > 0) {
        textos += `${minutes} ${this.$t('minutes')}`;
      }
      if (hours > 0) textos = `${hours} ${this.$t('hours')} ${this.$t('and')} ${textos}`;
      const moreOrLess = (diff > 0) ? this.$t('longer_than') : this.$t('less_than');
      textos = `${textos} ${moreOrLess}`;
      if (hours == 0 && minutes == 0) {
        textos = this.$t('same_time');
      }
      return textos;
    },
    initShare() {
      // eslint-disable-next-line no-underscore-dangle
      window._mtm.push({
        event: 'comshiva',
        eventcategory: 'ComShiVa',
        eventaction: 'Compartir ruta',
        eventname: 2000,
      });
    },
  },
  mounted() {
    // noop
  },
  created() {
    this.refs = this.isWidget === true ? this.$root.$children[0].$children[0].$children[0].$refs : this.$root.$children[0].$children[0].$refs;
  },
};
</script>

import { mapState } from 'vuex';

export const PanelMixins = {
  data() {
    return {
      refs: null,
      panels: [
        'infoPanel',
        'layerMenu',
        'routePanel',
        'trafficAlerts',
      ],
    };
  },
  computed: {
    ...mapState('map', [
      'isWidget',
    ]),
  },
  methods: {
    closePanels(typePanel) {
      this.panels.forEach((panel) => {
        let counter = 0;
        if (panel != typePanel) {
          while ((this.refs[panel].opened || this.refs[panel].open) && counter < 3) {
            this.refs[panel].onSwipeDown();
            counter++;
          }
        }
      });
    },
  },
  created() {
    this.refs = this.isWidget === true ? this.$root.$children[0].$children[0].$children[0].$refs : this.$root.$children[0].$children[0].$refs;
  },
};

export const dummy = {};

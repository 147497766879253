<template>
  <widget-barcelonacat v-if="widgetId === 'route'"></widget-barcelonacat>
  <home v-else></home>
</template>

<script>

import WidgetBarcelonacat from '@/components/widget/Barcelonacat.vue';
import Home from '@/components/Home.vue';

export default {
  name: 'WidgetView',
  components: { WidgetBarcelonacat, Home },
  data() {
    return {
      widgetId: null,
    };
  },
  created() {
    console.error('Created WIDGET');
    this.widgetId = this.$route.params.widget;
  },
};
</script>

import { mapState, mapMutations } from 'vuex';
import { clone } from 'lodash';
import '../../classes/LineaTransportAerobus';
import '../../classes/LineaTransportBus';
import '../../classes/LineaTransportCercanias';
import '../../classes/LineaTransportFerrocarril';
import '../../classes/LineaTransportFunicular';
import '../../classes/LineaTransportMetro';
import '../../classes/LineaTransportTram';

export const TransportMixins = {
  data() {
    return {
      refs: null,
    };
  },
  computed: {
    ...mapState('map', [
      'isWidget',
    ]),
    ...mapState('transport', [
      'lineaSelected',
    ]),
    ...mapState('planroute', {
      panelOpened: 'panelOpened',
      panelVisible: 'panelVisible',
    }),
    ...mapState('menu', [
      'layerMenuStatus',
      'layerMenu',
    ]),
    menuHeight() {
      const height = window.innerHeight - this.refs.barraAjuntament.$el.clientHeight;
      return { height: `${height}px` };
    },
    level2Height() {
      const height = window.innerHeight - this.refs.barraAjuntament.$el.clientHeight;
      return { height: `${height}px` };
    },
  },
  created() {
    this.refs = this.isWidget === true ? this.$root.$children[0].$children[0].$children[0].$refs : this.$root.$children[0].$children[0].$refs;
  },
  methods: {
    ...mapMutations({
      setLineaSelected: 'transport/setLineaSelected',
      setPanelOpened: 'planroute/setPanelOpened',
      setPanelVisible: 'planroute/setPanelVisible',
      setActiveLayers: 'map/setActiveLayers',
      cleanActiveLayers: 'map/cleanActiveLayers',
    }),
    selectLineaMetro(linea) {
      const lineaTransportMetro = new LineaTransportMetro();
      lineaTransportMetro.loadStation(linea.linea).then(() => {
        bcn.statistics({ track: [encodeURI('Capa-dades'), 'Mobile', `Línia metro: ${linea.linea}`] });
        this.setLineaSelected(LineaTransportMetro.getLinea(linea.linea));
      });
    },

    selectLineaBus(linea) {
      const lineaTransportBus = new LineaTransportBus();
      lineaTransportBus.loadStation(linea.linea).then(() => {
        bcn.statistics({ track: [encodeURI('Capa-dades'), 'Mobile', `Línia bus: ${linea.linea}`] });
        this.setLineaSelected(LineaTransportBus.getLinea(linea.linea));
      });
    },

    selectLineaAerobus(linea) {
      const lineaTransportAerobus = new LineaTransportAerobus();
      lineaTransportAerobus.loadStation(linea.linea).then(() => {
        bcn.statistics({ track: [encodeURI('Capa-dades'), 'Mobile', `Línia aerobus: ${linea.linea}`] });
        this.setLineaSelected(LineaTransportAerobus.getLinea(linea.linea));
      });
    },

    selectLineaRodalies(linea) {
      const lineaTransportCercanias = new LineaTransportCercanias();
      lineaTransportCercanias.loadStation(linea.linea).then(() => {
        bcn.statistics({ track: [encodeURI('Capa-dades'), 'Mobile', `Línia Rodalies: ${linea.linea}`] });
        this.setLineaSelected(LineaTransportCercanias.getLinea(linea.linea));
      });
    },

    selectLineaFerrocarril(linea) {
      const lineaTransportFerrocarril = new LineaTransportFerrocarril();
      lineaTransportFerrocarril.loadStation(linea.linea).then(() => {
        bcn.statistics({ track: [encodeURI('Capa-dades'), 'Mobile', `Línia FGC: ${linea.linea}`] });
        this.setLineaSelected(LineaTransportFerrocarril.getLinea(linea.linea));
      });
    },

    selectLineaTram(linea) {
      const lineaTransportTram = new LineaTransportTram();
      lineaTransportTram.loadStation(linea.linea).then(() => {
        bcn.statistics({ track: [encodeURI('Capa-dades'), 'Mobile', `Línia Tram: ${linea.linea}`] });
        this.setLineaSelected(LineaTransportTram.getLinea(linea.linea));
      });
    },

    selectLineaFunicular(linea) {
      const lineaTransportFunicular = new LineaTransportFunicular();
      lineaTransportFunicular.loadStation(linea.linea).then(() => {
        bcn.statistics({ track: [encodeURI('Capa-dades'), 'Mobile', `Línia funicular: ${linea.linea}`] });
        this.setLineaSelected(LineaTransportFunicular.getLinea(linea.linea));
      });
    },

    deselectLine() {
      this.setLineaSelected('');
    },

    getLinea(tipo, name) {
      if (tipo == 'metro') {
        return LineaTransportMetro.getLinea(name);
      }
      if (tipo == 'bus') {
        return LineaTransportBus.getLinea(name);
      }
      if (tipo == 'aerobus') {
        return LineaTransportAerobus.getLinea(name);
      }
      if (tipo == 'ferrocarril') {
        return LineaTransportFerrocarril.getLinea(name);
      }
      if (tipo == 'rodalies') {
        return LineaTransportCercanias.getLinea(name);
      }
    },

    getColor(linea) {
      if (linea.type === 'metro') {
        return LineaTransportMetro.getColor(linea.linea);
      }
      if (linea.type === 'bus') {
        return LineaTransportBus.getColor(linea.linea);
      }
      return '';
    },

    loadLineMap(tipo, linea) {
      DataLayer.hideAllDataLayers();
      this.cleanActiveLayers(false);
      if (tipo == 'metro') {
        LineaTransportMetro.loadLineMap(linea, 'line');
      } else if (tipo == 'bus') {
        LineaTransportBus.loadLineMap(linea, 'line');
      } else if (tipo == 'aerobus') {
        LineaTransportAerobus.loadLineMap(linea, 'line');
      } else if (tipo == 'tram') {
        LineaTransportTram.loadLineMap(linea, 'line');
      } else if (tipo == 'funicular') {
        LineaTransportFunicular.loadLineMap(linea, 'line');
      } else if (tipo == 'rodalies') {
        LineaTransportCercanias.loadLineMap(linea, 'line');
      } else if (tipo == 'ferrocarril') {
        LineaTransportFerrocarril.loadLineMap(linea, 'line');
      }
    },

    loadStationMap(tipo, linea_name, station_name, station_direction) {
      DataLayer.hideAllDataLayers();
      this.cleanActiveLayers(false);
      // this.selectAllTransport(false);
      let TransportClass = null;
      switch (tipo) {
        case 'metro':
          TransportClass = LineaTransportMetro;
          break;
        case 'bus':
          TransportClass = LineaTransportBus;
          break;
        case 'aerobus':
          TransportClass = LineaTransportAerobus;
          break;
        case 'funicular':
          TransportClass = LineaTransportFunicular;
          break;
        case 'tram':
          TransportClass = LineaTransportTram;
          break;
        case 'rodalies':
          TransportClass = LineaTransportCercanias;
          break;
        case 'ferrocarril':
          TransportClass = LineaTransportFerrocarril;
          break;
        default:
          break;
      }
      const lineaTransport = new TransportClass();
      const linea = TransportClass.getLinea(linea_name);
      const station = TransportClass.getStation(linea, station_name);
      if (station == null || station.code == undefined) {
        lineaTransport.loadStation(linea_name).then(() => {
          TransportClass.loadLineMap(linea_name, 'station', station_name, station_direction);
          bcn.statistics({ track: [encodeURI('Capa-dades'), 'Mobile', `Línia ${tipo}: ${linea_name}, Estació: ${station.nom}`] });
        });
      } else {
        this.refs.planificador.setStationDestination(linea, station);
        TransportClass.loadLineMap(linea_name, 'station', station_name, station_direction);
      }
      this.refs.planificador.resetPlanificador();
      this.setPanelVisible(false);
      this.setPanelOpened(false);
    },

    selectAllTransport(selected) {
      this.selectLineAll('metro', selected);
      this.selectLineAll('bus', selected);
      this.selectLineAll('ferrocarril', selected);
      this.selectLineAll('rodalies', selected);
      this.selectLineAll('tram', selected);
      this.selectLineAll('aerobus', selected);
      this.selectLineAll('funicular', selected);
    },
    selectLineAll(type, selected) {
      for (const c in this.lineas[type]) {
        this.lineas[type][c].selected = selected;
        this.selectTransportLine(this.lineas[type][c], selected);
      }
    },
    getGrupoLine(typeLine) {
      let grupo;
      for (const c in this.layerMenu) {
        grupo = this.layerMenu[c];
        for (const d in grupo.options) {
          const opcion2 = grupo.options[d];

          if (opcion2.code == typeLine) {
            return grupo;
          }
        }
      }
      return grupo;
    },
    isAllSelected(typeLine) {
      let allSelected = false;
      const layers = clone(this.activeLayers).filter((line) => line.type == typeLine);
      const lines = this.lineas[typeLine];

      // Determine if lines is an array or a hashmap
      const isHashMap = !Array.isArray(lines);
      let linesCount;

      if (isHashMap) {
        // If lines is a hashmap, get the count of keys
        linesCount = Object.keys(lines).length;
      } else {
        // If lines is an array, get its length
        linesCount = lines.length;
      }

      if (layers.length == linesCount) {
        allSelected = true;
      }
      this.getOptionTransportByCode(typeLine).select_all.selected = allSelected;
      return allSelected;
    },
    getOptionTransportByCode(code) {
      const grupo = this.getGrupoByName('public_transport');
      for (const c in grupo.options) {
        if (grupo.options[c].code == code) {
          return grupo.options[c];
        }
      }
      return null;
    },
    selectNameClassTransportForType(type) {
      let TransportClass = null;
      let layerLineName = '';
      let layerStationsName = '';
      switch (type) {
        case 'metro':
          TransportClass = LineaTransportMetro;
          layerLineName = 'metrolinies';
          layerStationsName = 'metroestacions';
          break;
        case 'bus':
          TransportClass = LineaTransportBus;
          layerLineName = 'buslinies';
          layerStationsName = 'busparades';
          break;
        case 'ferrocarril':
          TransportClass = LineaTransportFerrocarril;
          layerLineName = 'ferrocarrilslinies';
          layerStationsName = 'ferrocarrilsestacions';
          break;
        case 'rodalies':
          TransportClass = LineaTransportCercanias;
          layerLineName = 'rodalieslinies';
          layerStationsName = 'rodaliesestacions';
          break;
        case 'tram':
          TransportClass = LineaTransportTram;
          layerLineName = 'tramlinies';
          layerStationsName = 'tramestacions';
          break;
        case 'aerobus':
          TransportClass = LineaTransportAerobus;
          layerLineName = 'otherbuslinies';
          layerStationsName = 'otherbusparades';
          break;
        case 'funicular':
          TransportClass = LineaTransportFunicular;
          layerLineName = 'funicularlinies';
          layerStationsName = 'funicularparades';
          break;
        default:
          break;
      }
      return { TransportClass, layerLineName, layerStationsName };
    },
    selectTransportLine(linea, selected) {
      const grupo = this.getGrupoLine(linea.type);

      if (selected != undefined) {
        linea.selected = selected;
      }
      linea.group = grupo.grupo;
      let TransportClass = null;
      let layerLineName = '';
      let layerStationsName = '';
      ({ TransportClass, layerLineName, layerStationsName } = this.selectNameClassTransportForType(linea.type));
      if (linea.selected) {
        this.addLayerUrl(linea);
        TransportClass.loadLineMap(linea.linea);
        setTimeout(() => {
          const pos = {
            lat: 41.385,
            lng: 2.173,
          };
          this.mapObj.setCenter({ lat: pos.lat, lng: pos.lng });
          this.mapObj.setZoom(12);
        }, 800);
      } else {
        const layers = clone(this.activeLayers);
        layers.splice(layers.indexOf(linea), 1);
        this.deleteLayerUrl(linea);
        this.setActiveLayers(layers);
        let dataLayer = DataLayer.getDataLayer(`${layerLineName}${linea.linea}`);
        if (dataLayer !== undefined) {
          dataLayer.hide();
          dataLayer.visible = false;
          dataLayer = DataLayer.getDataLayer(`${layerStationsName}${linea.linea}`);
          dataLayer.hide();
          dataLayer.visible = false;
          if (linea.type == 'metro') {
            dataLayer = DataLayer.getDataLayer(`${layerStationsName}${linea.linea}_accessos`);
            dataLayer.hide();
            dataLayer.visible = false;
          }
        }
      }
    },

    getScheduleUrl(line) {
      if (line.type == 'metro') {
        window.open(`https://www.tmb.cat/${this.$i18n.locale}/barcelona/metro/-/lineametro/${line.linea}`, '_blank');
      } else if (line.type == 'bus') {
        window.open(`https://www.tmb.cat/${this.$i18n.locale}/barcelona/autobuses/-/lineabus/${line.linea}`, '_blank');
      } else if (line.type == 'tram') {
        window.open(`https://www.tram.cat/${this.$i18n.locale}/linies-i-horaris`, '_blank');
      } else if (line.type == 'aerobus') {
        window.open(`https://www.aerobusbcn.com/${this.$i18n.locale}/horarios_covid`, '_blank');
      } else if (line.type == 'funicular') {
        window.open(`https://www.tmb.cat/${this.$i18n.locale}/sobre-tmb/transportes-tmb/funicular-montjuic`, '_blank');
      }
    },
  },
};

export const dummy = {};

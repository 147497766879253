export default [
  {
    missatges: {
      ca: 'La circulació als eixos verds i les places de l’Eixample té afectacions per canvis de sentit que pot ser que no es vegin reflectits en els resultats. Consulta <b>superilla.barcelona</b> per tenir-ne més informació.',
      es: 'La circulación en los ejes verdes y las plazas de L’Eixample tiene afectaciones por cambios de sentido que es posible que no se vean reflejados en los resultados. Consulta <b>superilla.barcelona</b> para tener más información.',
      en: 'Traffic in Eixample\'s green hubs and squares is being affected by changes in direction which might not be reflected in the results. Check out <b>superilla.barcelona</b> for further information.',
    },
    links: {
      ca: 'https://www.barcelona.cat/pla-superilla-barcelona/ca/eixos-verds-i-places-de-leixample#mapa-afectacions',
      es: 'https://www.barcelona.cat/pla-superilla-barcelona/es/ejes-verdes-y-plazas-de-leixample#mapa-afectacions',
      en: 'https://www.barcelona.cat/pla-superilla-barcelona/en/green-hubs-and-squares-eixample-district#mapa-afectacions',
    },
    data_inici: '2022-11-18T00:00:00.000Z',
    data_fi: '2030-11-31T00:00:00.000Z',
  },
];

<template>
  <div>
    <div class="cercadorWrapper" :style="{ 'padding-left': internalpadding + 'px', 'padding-right': internalpadding + 'px', }">
      <div class="cercador" :class="{transport: layers == 'transport', layers: layers == 'layers', searching: searchInputFocus || linesSearchWord || searchInputLayerFocus || linesSearchLayerWord}">
        <div class="linies_txt" v-if="layers == 'transport' && (searchInputFocus || linesSearchWord)">{{ $t('lines_stops') }}</div>
        <div class="linies_txt" v-if="layers == 'layers' && (searchInputLayerFocus || linesSearchLayerWord)">{{ $t('layers') }}</div>
        <input type="text" :placeholder="placeholder" :class="{searching: searchInputFocus || linesSearchWord}"
          @keyup="linesSearchChange" @blur="searchFocus(false)" @focus="searchFocus(true)" v-model="linesSearchWord" v-if="layers == 'transport'">
        <input type="text" :placeholder="placeholder" :class="{searching: searchInputLayerFocus || linesSearchLayerWord}"
            @keyup="linesSearchLayerChange" @blur="searchFocus(false)" @focus="searchFocus(true)" v-model="linesSearchLayerWord" v-if="layers == 'layers'">
        <div class="icons">
            <img src="/images/icons/search.svg" role="button" class="icon-cerca" @click="linesSearchChange" v-if="layers == 'transport'">
            <img src="/images/icons/search.svg" role="button" class="icon-cerca" @click="linesSearchLayerChange" v-if="layers == 'layers'">
            <img src="/images/icons/close.svg" role="button" class="icon-close" v-if="linesSearchWord && layers == 'transport'" @click="cancelSearch">
            <img src="/images/icons/close.svg" role="button" class="icon-close" v-if="linesSearchLayerWord && layers == 'layers'" @click="cancelSearch">
        </div>
      </div>

      <div class="recents" v-if="layers == 'transport' && searchInputFocus && !linesSearchWord && recentsLines.length > 0" :class="{higher: recentsLines.length > 4}">
        <div class="recent-item" v-for="(recentLine, index) in recentsLines" v-bind:key="index" role="button" @click="loadRecent(recentLine)">
          <img src="/images/icons/recents.svg">
          <div class="recent-text">{{ recentLine }}</div>
        </div>
      </div>
      <div class="recents" v-if="layers == 'layers' && (searchInputLayerFocus || awaitingLayerSearch) && !linesSearchLayerWord && recentsLayers.length > 0" :class="{higher: recentsLayers.length > 4}">
        <div v-if="!linesSearchLayerWord">
          <div class="recent-item" v-for="(recentLine, index) in recentsLayers" v-bind:key="index" role="button" @click="loadRecent(recentLine)">
            <img src="/images/icons/recents.svg">
            <div class="recent-text">{{ recentLine }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Search results-->
    <!-- Cerca transport -->
    <transition enter-active-class="animated fadeIn slideInLeft" leave-active-class="animated fadeOut slideOutLeft"
      v-if="layers=='transport' && activeSearch">
      <div class="search-results">
        <ul>
          <li v-if="!hasResults() && layers=='transport'" class="no-results">
            <div class="icon"><img src="/images/icons/warning.svg"></div>
            <div class="text">
              <strong>{{ $t('no_results') }}</strong>
              <div>{{ $t('try_write_new_word') }}</div>
            </div>
          </li>
          <li class="buttons-filter" v-if="hasResults() && layers == 'transport'">
            <div>
              <button @click="setMode('linies')">{{ $t('lines') }} ({{ num_linies }})</button>
              <button @click="setMode('parades')">{{ $t('stops') }} ({{ num_parades }})</button>
              <div class="title" v-if="mode == 'linies'">{{ $t('lines') }} ({{ num_linies }})</div>
              <div class="title" v-if="mode == 'parades'">{{ $t('stops') }} ({{ num_parades }})</div>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li role="button" class="search-result" v-for="(item, index) in getMetroLines(searchResults.metro.lines)" v-bind:key="'metro'+index" v-if="mode=='linies' && layers=='transport'">
            <div @click="selectLine(item, 'metro', item.properties.NOM_LINIA, item.properties.DESC_SERVEI)">
              <div class="icon">
                <img class="logo-metro" :src="`/images/metro/${item.properties.NOM_LINIA}.png`">
              </div>
              <div class="info">
                <text-highlight :queries="[linesSearchWord]">{{ item.properties.DESC_SERVEI }}</text-highlight>
              </div>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li role="button" class="search-result" v-for="(item, index) in getMetroStations(searchResults.metro.stations)" v-bind:key="'metrostation'+index" v-show="layers=='transport'" v-if="mode=='parades' && layers=='transport'">
            <div @click="selectStation('metro', item.properties.NOM_LINIA, item.properties.CODI_ESTACIO, item.properties.NOM_ESTACIO)">
              <div class="icon">
                <img class="logo-metro" :src="`/images/metro/${item.properties.NOM_LINIA}.png`">
              </div>
              <div class="info">
                <text-highlight :queries="[linesSearchWord]">{{ item.properties.NOM_ESTACIO }}</text-highlight>
              </div>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li role="button" class="search-result parada" v-for="(item, index) in searchResults.bus.lines" v-bind:key="'bus'+index" v-if="mode=='linies' && layers=='transport'">
            <div @click="selectLine(item, 'bus', item.properties.Nom, item.properties.DescIntern)" class="parada">
              <div class="icon">
                  <img src="/images/icons/bus.svg" :class="getColorBus(item.properties.Nom)">
              </div>
              <div class="info">
                  <span><text-highlight :queries="[linesSearchWord]">{{ item.properties.Nom }}</text-highlight></span> -
                  <span>
                    <text-highlight :queries="[linesSearchWord]">{{ item.properties.DescIntern }}</text-highlight>
                  </span>
              </div>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li role="button" class="search-result" v-for="(item, index) in searchResults.bus.stations" v-bind:key="'busstation'+index" v-show="layers=='transport'" v-if="mode=='parades' && layers=='transport'">
            <div @click="selectStation('bus',item.properties.Linea, item.properties.Codigo, item.properties.Descripcio)" class="parada">
              <div class="icon">
                  <img src="/images/icons/bus.svg" :class="getColorBus(item.properties.Linea)">
              </div>
              <div class="info">
                  <div>
                    <span><text-highlight :queries="[linesSearchWord]">{{ item.properties.Linea }}</text-highlight></span> -
                    <span>
                      <text-highlight :queries="[linesSearchWord]">{{ item.properties.Descripcio }} ({{item.properties.Codigo}})</text-highlight>
                    </span>
                  </div>
                  <div>
                    <text-highlight :queries="[linesSearchWord]">{{ getLinea('bus', item.properties.Linea).descripcio }}</text-highlight>
                  </div>
              </div>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li role="button" class="search-result parada" v-for="(item, index) in searchResults.otherbus.lines" v-bind:key="'aerobuslines'+index" v-if="mode=='linies' && layers=='transport'">
            <div @click="selectLine(item, 'aerobus', item.properties.Nom, item.properties.DescIntern)" class="parada">
              <div class="icon">
                  <img src="/images/icons/bus.svg" :class="getColorBus(item.properties.Nom)">
              </div>
              <div class="info">
                  <span><text-highlight :queries="[linesSearchWord]">{{ item.properties.Nom }}</text-highlight></span> -
                  <span>
                    <text-highlight :queries="[linesSearchWord]">{{ item.properties.DescIntern }}</text-highlight>
                  </span>
              </div>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li role="button" class="search-result" v-for="(item, index) in searchResults.otherbus.stations" v-bind:key="'aerobusstation'+index" v-show="layers=='transport'" v-if="mode=='parades' && layers=='transport'">
            <div @click="selectStation('aerobus', item.properties.Linea, item.properties.Codigo, item.properties.Descripcio)" class="parada">
              <div class="icon">
                  <img src="/images/icons/bus.svg" :class="getColorBus(item.properties.Linea)">
              </div>
              <div class="info">
                  <div>
                      <span><text-highlight :queries="[linesSearchWord]">{{ item.properties.Linea }}</text-highlight></span> -
                      <span>
                        <text-highlight :queries="[linesSearchWord]">{{ item.properties.Descripcio }} ({{item.properties.Codigo}})</text-highlight>
                      </span>
                  </div>
                  <div>
                    <text-highlight :queries="[linesSearchWord]">{{ getLinea('aerobus', item.properties.Linea).descripcio }}</text-highlight>
                  </div>
              </div>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li role="button" class="search-result" v-for="(item, index) in searchResults.rodalies.lines" v-bind:key="'rodalies'+index" v-if="mode=='linies' && layers=='transport'">
            <div @click="selectLine(item, 'rodalies', item.properties.line, item.properties.description)">
              <div class="icon">
                  <img class="logo-metro" :src="`/images/rodalies/${item.properties.line}.png`">
              </div>
              <div class="info">
                <text-highlight :queries="[linesSearchWord]">{{ item.properties.description }}</text-highlight>
              </div>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li role="button" class="search-result" v-for="(item, index) in searchResults.rodalies.stations" v-bind:key="'rodaliesstation'+index" v-show="layers=='transport'" v-if="mode=='parades' && layers=='transport'">
            <div @click="selectStation('rodalies', item.properties.line, item.properties.codigo, item.properties.name)">
              <div class="icon">
                <img class="logo-metro" :src="`/images/rodalies/${item.properties.line}.png`">
              </div>
              <div class="info">
                <text-highlight :queries="[linesSearchWord]">{{ item.properties.name }}</text-highlight>
              </div>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li role="button" class="search-result" v-for="(item, index) in searchResults.ferrocarril.lines" v-bind:key="'ferrocarril'+index" v-if="mode=='linies' && layers=='transport'">
            <div @click="selectLine(item, 'ferrocarril', item.properties.line, item.properties.description)">
              <div class="icon">
                  <img class="logo-metro" :src="`/images/fgc/${item.properties.line}.png`">
              </div>
              <div class="info">
                <text-highlight :queries="[linesSearchWord]">{{ item.properties.description }}</text-highlight>
              </div>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li role="button" class="search-result" v-for="(item, index) in searchResults.ferrocarril.stations" v-bind:key="'ferrocarrilstation'+index" v-show="layers=='transport'" v-if="mode=='parades' && layers=='transport'">
            <div @click="selectStation('ferrocarril', item.properties.line, item.properties.id)">
              <div class="icon">
                <img class="logo-metro" :src="`/images/fgc/${item.properties.line}.png`">
              </div>
              <div class="info">
                <text-highlight :queries="[linesSearchWord]">{{ item.properties.description }}</text-highlight>
              </div>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li role="button" class="search-result" v-for="(item, index) in searchResults.tram.stations" v-bind:key="'tramstation'+index" v-show="layers=='transport'" v-if="mode=='parades' && layers=='transport'">
            <div @click="selectStation('tram', item.properties.LINIA.split('-')[0], item.properties.ID, item.properties.NOM_ESTACI)">
              <div class="icon">
                  <img class="logo-metro" src="/images/logo-tram.png">
              </div>
              <div class="info">
                <text-highlight :queries="[linesSearchWord]">{{ item.properties.LINIA }} - {{ item.properties.NOM_ESTACI }}</text-highlight>
              </div>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li role="button" class="search-result" v-for="(item, index) in searchResults.funicular.lines" v-bind:key="'funicular'+index" v-if="mode=='linies' && layers=='transport'">
            <div @click="selectLine(item, 'funicular', item.properties.line, item.properties.description)">
              <div class="icon">
                  <img class="logo-metro" :src="`/images/logo-funicular.png`">
              </div>
              <div class="info">
                <text-highlight :queries="[linesSearchWord]">{{ item.properties.description }}</text-highlight>
              </div>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li role="button" class="search-result" v-for="(item, index) in searchResults.rodalies.stations" v-bind:key="'funicular'+index" v-show="layers=='transport'" v-if="mode=='parades' && layers=='transport'">
            <div @click="selectStation('funicular', item.properties.line, item.properties.codigo, item.properties.name)">
              <div class="icon">
                <img class="logo-metro" :src="`/images/rodalies/logo-funicular.png`">
              </div>
              <div class="info">
                <text-highlight :queries="[linesSearchWord]">{{ item.properties.name }}</text-highlight>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </transition>
    <!-- Fin Cerca transport -->

    <!-- Cerca layers + transport -->
    <transition enter-active-class="animated fadeIn slideInLeft" leave-active-class="animated fadeOut slideOutLeft"
      v-if="activeLayerSearch && layers == 'layers'">
      <div class="search-results">
        <ul class="layer-results">
          <li v-if="!hasResults() && !awaitingLayerSearch" class="no-results">
            <div class="icon"><img src="/images/icons/warning.svg"></div>
            <div class="text">
                <strong>{{ $t('no_results') }}</strong>
                <div>{{ $t('try_write_new_word') }}</div>
            </div>
          </li>
          <li v-for="(item, index) in getMetroLines(searchLayerResults.metro.lines)" v-bind:key="'metro'+index" class="search-result">
            <div class="item-transport linea check-wrapper">
              <input :id="'rightline-'+index" :name="'rightline-'+index" :checked="item.selected" type="checkbox" @click="selectLine(item, 'metro', item.properties.NOM_LINIA)">
              <label :for="'rightline-'+index" class="text">
                <text-highlight :queries="[linesSearchLayerWord]">Metro {{ item.properties.NOM_LINIA }} {{ item.properties.DESC_SERVEI }}</text-highlight>
              </label>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li v-for="(item, index) in lineas.metro" v-bind:key="'metro'+index" class="search-result" v-if="isTrasportSynonym(['metro'])">
            <div class="item-transport linea check-wrapper">
              <input :id="'rightline-'+index" :name="'rightline-'+index" :checked="item.selected" type="checkbox" @click="toggleLine(item)">
              <label :for="'rightline-'+index" class="text">
                <text-highlight :queries="[linesSearchLayerWord]">Metro {{item.linea}} {{ item.descripcio }}</text-highlight>
              </label>
            </div>
          </li>
          <li v-for="(item, index) in searchLayerResults.bus.lines" v-bind:key="'bus'+index" class="search-result">
            <div class="item-transport linea check-wrapper">
              <input :id="'rightline-'+index" :name="'rightline-'+index" :checked="item.selected" type="checkbox" @click="selectLine(item, 'bus', item.properties.Nom)">
              <label :for="'rightline-'+index" class="text">
                <text-highlight :queries="[linesSearchLayerWord]">Bus {{ item.properties.Nom }} {{ item.properties.DescIntern }}</text-highlight>
              </label>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li v-for="(item, index) in lineas.bus" v-bind:key="'bus'+index" class="search-result" v-if="isTrasportSynonym(['bus'])">
            <div class="item-transport linea check-wrapper">
              <input :id="'rightline-'+index" :name="'rightline-'+index" :checked="item.selected" type="checkbox" @click="toggleLine(item)">
              <label :for="'rightline-'+index" class="text">
                <text-highlight :queries="[linesSearchLayerWord]">Bus {{item.linea}} {{ item.descripcio }}</text-highlight>
              </label>
            </div>
          </li>
          <li v-for="(item, index) in searchLayerResults.otherbus.lines" v-bind:key="'aerobus'+index" class="search-result">
            <div class="check" :class="{'checked': item.selected}" @click="selectLine(item, 'aerobus', item.properties.Nom)"></div>
            <text-highlight :queries="[linesSearchLayerWord]">Aerobus {{item.properties.Nom}} {{item.properties.DescIntern}}</text-highlight>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li v-for="(item, index) in lineas.aerobus" v-bind:key="'aerobus'+index" class="search-result" v-if="isTrasportSynonym(['aerobus'])">
            <div class="item-transport linea check-wrapper">
              <input :id="'rightline-'+index" :name="'rightline-'+index" :checked="item.selected" type="checkbox" @click="toggleLine(item)">
              <label :for="'rightline-'+index" class="text">
                <text-highlight :queries="[linesSearchLayerWord]">Aerobus {{item.linea}} {{ item.descripcio }}</text-highlight>
              </label>
            </div>
          </li>
          <li v-for="(item, index) in searchLayerResults.ferrocarril.lines" v-bind:key="'fgc'+index" class="search-result">
            <div class="item-transport linea check-wrapper">
              <input :id="'rightline-'+index" :name="'rightline-'+index" :checked="item.selected" type="checkbox" @click="selectLine(item, 'ferrocarril', item.properties.line)">
              <label :for="'rightline-'+index" class="text">
                <text-highlight :queries="[linesSearchLayerWord]">FGC {{ item.properties.line }} {{ item.properties.description }}</text-highlight>
              </label>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li v-for="(item, index) in lineas.ferrocarril" v-bind:key="'ferrocarril'+index" class="search-result"  v-if="isTrasportSynonym(['ferrocarril', 'fgc', 'ferrocarriles', 'ferrocarrils'])">
            <div class="item-transport linea check-wrapper">
              <input :id="'rightline-'+index" :name="'rightline-'+index" :checked="item.selected" type="checkbox" @click="toggleLine(item)">
              <label :for="'rightline-'+index" class="text">
                <text-highlight :queries="[linesSearchLayerWord]">FGC {{item.linea}} {{ item.descripcio }}</text-highlight>
              </label>
            </div>
          </li>
          <li v-for="(item, index) in searchLayerResults.rodalies.lines" v-bind:key="'rodalies'+index" class="search-result">
            <div class="item-transport linea check-wrapper">
              <input :id="'rightline-'+index" :name="'rightline-'+index" :checked="item.selected" type="checkbox" @click="selectLine(item, 'rodalies', item.properties.line)">
              <label :for="'rightline-'+index" class="text">
                <text-highlight :queries="[linesSearchLayerWord]">{{ $t('local_trains') }} {{ item.properties.line }} {{ item.properties.description }}</text-highlight>
              </label>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li v-for="(item, index) in lineas.rodalies" v-bind:key="'rodalies'+index" class="search-result"  v-if="isTrasportSynonym(['rodalies', 'cercanias', 'local trains'])">
            <div class="item-transport linea check-wrapper">
              <input :id="'rightline-'+index" :name="'rightline-'+index" :checked="item.selected" type="checkbox" @click="toggleLine(item)">
              <label :for="'rightline-'+index" class="text">
                <text-highlight :queries="[linesSearchLayerWord]">{{ $t('local_trains') }} {{item.linea}} {{ item.descripcio }}</text-highlight>
              </label>
            </div>
          </li>
          <li v-for="(item, index) in searchLayerResults.tram.lines" v-bind:key="'tram'+index" class="search-result">
            <div class="item-transport linea check-wrapper">
              <input :id="'rightline-'+index" :name="'rightline-'+index" :checked="item.selected" type="checkbox" @click="selectLine(item, 'tram', item.linea)">
              <label :for="'rightline-'+index" class="text">
                <text-highlight :queries="[linesSearchLayerWord]">{{ $t('trams') }} {{ item.properties.line }} {{ item.properties.description }}</text-highlight>
              </label>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li v-for="(item, index) in lineas.tram" v-bind:key="'tram'+index" class="search-result"  v-if="isTrasportSynonym(['tram', 'tranvia', 'tramvia', 'trams'])">
            <div class="item-transport linea check-wrapper">
              <input :id="'rightline-'+index" :name="'rightline-'+index" :checked="item.selected" type="checkbox" @click="toggleLine(item)">
              <label :for="'rightline-'+index" class="text">
                <text-highlight :queries="[linesSearchLayerWord]">{{ $t('trams') }} {{item.linea}} {{ item.descripcio }}</text-highlight>
              </label>
            </div>
          </li>
          <li v-for="(item, index) in searchLayerResults.funicular.lines" v-bind:key="'funicular'+index" class="search-result">
            <div class="item-transport linea check-wrapper">
              <input :id="'rightline-'+index" :name="'rightline-'+index" :checked="item.selected" type="checkbox" @click="selectLine(item, 'funicular', item.linea)">
              <label :for="'rightline-'+index" class="text">
                <text-highlight :queries="[linesSearchLayerWord]">Funicular {{ item.properties.line }} {{ item.properties.description }}</text-highlight>
              </label>
            </div>
          </li>
          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
          <li v-for="(item, index) in lineas.funicular" v-bind:key="'tram'+index" class="search-result" v-if="isTrasportSynonym(['funicular', 'teleferico', 'teleferic'])">
            <div class="item-transport linea check-wrapper">
              <input :id="'rightline-'+index" :name="'rightline-'+index" :checked="item.selected" type="checkbox" @click="toggleLine(item)">
              <label :for="'rightline-'+index" class="text">
                <text-highlight :queries="[linesSearchLayerWord]">Funicular {{item.linea}} {{ item.descripcio }}</text-highlight>
              </label>
            </div>
          </li>
          <li v-for="(item, index) in getLayerMenuOptions()" v-bind:key="'menuoptions'+index" class="search-result">
            <div class="check" :class="{'checked': item.selected}" @click="selectOpcion(item)"></div>
            <text-highlight :queries="[linesSearchLayerWord]">{{ $t(item.nombre) }}</text-highlight>
          </li>
        </ul>
        <div class="incloure">
          <button class="button-incloure" @click="includeSearch()">{{ $t('view_map') }}</button>
        </div>
      </div>
    </transition>
    <!-- Fin Cerca layers + transport -->
  </div>
</template>
<script>

import { mapState, mapMutations } from 'vuex';
import {
  clone,
  find,
  findIndex,
} from 'lodash';
import $ from 'jquery';
import { TransportMixins } from './mixins/TransportMixins';
import { LayerMixins } from './mixins/LayerMixins';
import api from '../api/api';
import { getApiUri } from '../api/util';

export default {
  name: 'Search',
  props: ['placeholder', 'layers', 'internalpadding'],
  emits: {
    closeSearch: null,
  },
  mixins: [
    TransportMixins,
    LayerMixins,
  ],
  components: {},
  data() {
    return {
      timer: null,
      awaitingSearch: false,
      awaitingLayerSearch: false,
      searchInputFocus: false,
      searchInputLayerFocus: false,
      mode: 'linies',
      num_linies: 0,
      num_parades: 0,
      recentsLines: [],
      recentsLayers: [],
    };
  },
  computed: {
    ...mapState('menu', [
      'layerMenu',
    ]),
    ...mapState('search', [
      'searchResults',
      'searchLayerResults',
      'linesSearchWord',
      'linesSearchLayerWord',
      'activeSearch',
      'activeLayerSearch',
      'panelSelected',
    ]),
    ...mapState('map', [
      'activeLayers',
    ]),
    ...mapState('transport', [
      'lineas',
    ]),
    ...mapState('general', [
      'site',
    ]),
    linesSearchWord: {
      get() {
        return this.$store.state.search.linesSearchWord;
      },
      set(value) {
        this.setLinesSearchWord(value);
      },
    },
    linesSearchLayerWord: {
      get() {
        return this.$store.state.search.linesSearchLayerWord;
      },
      set(value) {
        this.setLinesSearchLayerWord(value);
      },
    },
  },
  methods: {
    ...mapMutations({
      setLineas: 'transport/setLineas',
      setSearchResults: 'search/setSearchResults',
      setSearchLayerResults: 'search/setSearchLayerResults',
      setActiveSearch: 'search/setActiveSearch',
      setActiveLayerSearch: 'search/setActiveLayerSearch',
      setLinesSearchWord: 'search/setLinesSearchWord',
      setLinesSearchLayerWord: 'search/setLinesSearchLayerWord',
      cleanActiveLayers: 'map/cleanActiveLayers',
      setTransportTypeSelected: 'transport/setTransportTypeSelected',
    }),
    setMode(mode) {
      this.mode = mode;
    },
    searchFocus(focus) {
      setTimeout(() => {
        if (this.layers == 'transport') {
          this.searchInputFocus = focus;
        } else {
          this.searchInputLayerFocus = focus;
        }
      }, 150);
    },
    addToRecents(linesSearchWord) {
      if (!linesSearchWord) return;
      if (this.layers == 'transport') {
        for (const c in this.recentsLines) {
          if (this.recentsLines[c] == linesSearchWord) {
            return;
          }
        }
        this.recentsLines.unshift(linesSearchWord);
        localStorage.recentsLines = JSON.stringify(this.recentsLines);
      }
      if (this.layers == 'layers') {
        for (const c in this.recentsLayers) {
          if (this.recentsLayers[c] == linesSearchWord) {
            return;
          }
        }
        this.recentsLayers.unshift(linesSearchWord);
        localStorage.recentsLayers = JSON.stringify(this.recentsLayers);
      }
    },
    loadRecent(recentLine) {
      if (this.layers == 'transport') {
        this.setLinesSearchWord(recentLine);
        this.linesSearchChange();
      }
      if (this.layers == 'layers') {
        this.setLinesSearchLayerWord(recentLine);
        this.linesSearchLayerChange();
      }
    },
    linesSearchChange() {
      this.setLinesSearchWord(this.linesSearchWord);
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.linesSearchWord.length > 1) {
          if (!this.awaitingSearch) {
            this.setSearchResults({
              metro: {
                lines: [],
                stations: [],
              },
              bus: {
                lines: [],
                stations: [],
              },
              otherbus: {
                lines: [],
                stations: [],
              },
              rodalies: {
                lines: [],
                stations: [],
              },
              ferrocarril: {
                lines: [],
                stations: [],
              },
              tram: {
                stations: [],
              },
              funicular: {
                lines: [],
                stations: [],
              },
            });
            this.awaitingSearch = true;
            this.search(this.linesSearchWord).then((response) => {
              // eslint-disable-next-line no-underscore-dangle
              window._mtm.push({
                event: 'comshiva',
                eventcategory: 'ComShiVa',
                eventaction: 'Cercador de línies',
                eventname: this.linesSearchWord,
              });
              this.setMode('linies');
              this.setSearchResults(response.data.results);
              this.awaitingSearch = false;
              for (const c in this.searchResults.bus.stations) {
                const result = this.searchResults.bus.stations[c];
                const parada = {
                  nom: result.properties.Descripcio,
                  codi: result.properties.Codigo,
                  ordre: parseInt(result.properties.Ordre),
                  lineas: result.properties.Linies,
                  municipi: result.properties.Municipi,
                  geometry: result.geometry,
                };
                if (this.lineas.bus[result.properties.Linea] != undefined) {
                  this.setLineas({
                    bus: {
                      [result.properties.Linea]: {
                        estacions: {
                          [parada.ordre]: parada,
                        },
                      },
                    },
                  });
                }
              }
              if (this.searchLayerResults.otherbus) {
                for (const c in this.searchResults.otherbus.stations) {
                  const result = this.searchResults.otherbus.stations[c];
                  const parada = {
                    nom: result.properties.Descripcio,
                    codi: result.properties.Codigo,
                    ordre: parseInt(result.properties.Ordre),
                    lineas: result.properties.Linies,
                    municipi: result.properties.Municipi,
                    geometry: result.geometry,
                  };
                  if (this.lineas.aerobus[result.properties.Linea] != undefined) {
                    this.setLineas({
                      aerobus: {
                        [result.properties.Linea]: {
                          estacions: {
                            [parada.ordre]: parada,
                          },
                        },
                      },
                    });
                  }
                }
              }
              this.num_linies = 0;
              this.num_parades = 0;
              for (const c in this.searchResults) {
                const item = this.searchResults[c];
                for (var l in item.lines) {
                  if (!item.lines[l].properties.NOM_LINIA || ['FM', 'TM'].indexOf(item.lines[l].properties.NOM_LINIA) == -1) {
                    this.num_linies++;
                  }
                }
                for (var s in item.stations) {
                  if (!item.stations[s].properties.NOM_LINIA || ['FM', 'TM'].indexOf(item.stations[s].properties.NOM_LINIA) == -1) {
                    this.num_parades++;
                  }
                }
              }
              if (this.num_linies == 0 && this.num_parades > 0) {
                this.setMode('parades');
              }
              if (this.num_linies > 0 || this.num_parades > 0) {
                // this.addToRecents(this.linesSearchWord);
              }
              this.setActiveSearch(true);
            });
          }
        }
        if (this.linesSearchWord == '') {
          this.setActiveSearch(false);
        }
      }, 400);
    },
    linesSearchLayerChange() {
      this.setLinesSearchLayerWord(this.linesSearchLayerWord);
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.linesSearchLayerWord.length > 1) {
          if (!this.awaitingLayerSearch) {
            this.setSearchLayerResults({
              metro: {
                lines: [],
                stations: [],
              },
              bus: {
                lines: [],
                stations: [],
              },
              otherbus: {
                lines: [],
                stations: [],
              },
              rodalies: {
                lines: [],
                stations: [],
              },
              ferrocarril: {
                lines: [],
                stations: [],
              },
              tram: {
                stations: [],
              },
              funicular: {
                lines: [],
                stations: [],
              },
            });
            this.awaitingLayerSearch = true;
            this.search(this.linesSearchLayerWord).then((response) => {
              // eslint-disable-next-line no-underscore-dangle
              window._mtm.push({
                event: 'comshiva',
                eventcategory: 'ComShiVa',
                eventaction: 'Cercador de capes',
                eventname: this.linesSearchLayerWord,
              });
              this.setMode('linies');
              this.setSearchLayerResults(response.data.results);
              this.awaitingLayerSearch = false;
              for (const c in this.searchLayerResults.bus.stations) {
                const result = this.searchLayerResults.bus.stations[c];
                const parada = {
                  nom: result.properties.Descripcio,
                  codi: result.properties.Codigo,
                  ordre: parseInt(result.properties.Ordre),
                  lineas: result.properties.Linies,
                  municipi: result.properties.Municipi,
                  geometry: result.geometry,
                };

                if (this.lineas.bus[result.properties.Linea] != undefined) {
                  this.setLineas({
                    bus: {
                      [result.properties.Linea]: {
                        estacions: {
                          [parada.ordre]: parada,
                        },
                      },
                    },
                  });
                }
              }
              if (this.searchLayerResults.otherbus) {
                for (const c in this.searchLayerResults.otherbus.stations) {
                  const result = this.searchLayerResults.otherbus.stations[c];
                  const parada = {
                    nom: result.properties.Descripcio,
                    codi: result.properties.Codigo,
                    ordre: parseInt(result.properties.Ordre),
                    lineas: result.properties.Linies,
                    municipi: result.properties.Municipi,
                    geometry: result.geometry,
                  };
                  if (this.lineas.aerobus[result.properties.Linea] != undefined) {
                    this.setLineas({
                      aerobus: {
                        [result.properties.Linea]: {
                          estacions: {
                            [parada.ordre]: parada,
                          },
                        },
                      },
                    });
                  }
                }
              }
              this.num_linies = 0;
              this.num_parades = 0;
              for (const c in this.searchLayerResults) {
                const item = this.searchLayerResults[c];
                for (var l in item.lines) {
                  if (!item.lines[l].properties.NOM_LINIA || ['FM', 'TM'].indexOf(item.lines[l].properties.NOM_LINIA) == -1) {
                    this.num_linies++;
                    this.searchLayerResults[c].lines[l].selected = false;
                  }
                }
                for (var s in item.stations) {
                  if (!item.stations[s].properties.NOM_LINIA || ['FM', 'TM'].indexOf(item.stations[s].properties.NOM_LINIA) == -1) {
                    this.num_parades++;
                  }
                }
                /* for(var l in item.lines) {
                    this.num_linies++;
                }
                for(var s in item.stations) {
                    this.num_parades++;
                } */
              }
              if (this.num_linies > 0 || this.getLayerMenuOptions().length > 0) {
                // this.addToRecents(this.linesSearchLayerWord);
              }
              this.setActiveLayerSearch(true);
            });
          }
        }
        if (this.linesSearchLayerWord == '') {
          this.setActiveLayerSearch(false);
        }
        setTimeout(() => {
          this.setRecentsHeight();
        });
      }, 400);
    },
    selectStation(type, line, station_id, station_name) {
      this.addToRecents(station_name);
      this.loadStationMap(type, line, station_id);
      if (this.layers == 'transport') {
        this.$bvModal.hide('modal-selector-lines');
      }
    },
    selectLine(item, type, linea_name, linea_text) {
      item.selected = !item.selected;
      let transportMethod = null;
      let TransportClass = null;
      let linea = null;
      switch (type) {
        case 'metro':
          transportMethod = this.selectLineaMetro;
          TransportClass = LineaTransportMetro;
          break;
        case 'bus':
          transportMethod = this.selectLineaBus;
          TransportClass = LineaTransportBus;
          break;
        case 'aerobus':
          transportMethod = this.selectLineaAerobus;
          TransportClass = LineaTransportAerobus;
          break;
        case 'ferrocarril':
          transportMethod = this.selectLineaFerrocarril;
          TransportClass = LineaTransportFerrocarril;
          break;
        case 'rodalies':
          transportMethod = this.selectLineaRodalies;
          TransportClass = LineaTransportCercanias;
          break;
        case 'tram':
          transportMethod = this.selectLineaTram;
          TransportClass = LineaTransportTram;
          break;
        case 'funicular':
          transportMethod = this.selectLineaFunicular;
          TransportClass = LineaTransportFunicular;
          break;
        default:
          break;
      }
      this.addToRecents(linea_text);
      linea = this.getLinea(type, linea_name);

      if (item.selected) {
        TransportClass.loadLineMap(linea_name);
      } else {
        const linea = this.getLinea(type, linea_name);
        this.selectTransportLine(linea, false);
      }

      if (this.layers == 'transport') {
        this.$bvModal.hide('modal-selector-lines');
      }
    },
    search(word) {
      const promise = new Promise((resolve, reject) => {
        api.get(`${getApiUri()}search/?word=${word}`).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      });
      return promise;
    },
    cancelSearch() {
      if (this.layers == 'transport') {
        this.setLinesSearchWord('');
        this.setActiveSearch(false);
        this.setTransportTypeSelected('');
      } else {
        this.setLinesSearchLayerWord('');
        this.setActiveLayerSearch(false);
      }
    },
    toggleLine(linea) {
      linea.selected = !linea.selected;
      this.selectTransportLine(linea);
    },
    getColorBus(line) {
      return {
        bus_v: line.indexOf('V') != -1,
        bus_h: line.indexOf('H') != -1,
        bus_d: line.indexOf('D') != -1,
        bus: line.indexOf('H') == -1 && line.indexOf('V') == -1 && line.indexOf('D') == -1,
      };
    },
    getLayerMenuOptions() {
      const excluded = ['metro', 'bus', 'fgc_trains', 'local_trains', 'trams', 'aerobus', 'funicular'];
      const options = [];
      if (!this.activeLayerSearch) return options;
      const group_codes = this.site == 'copa-america' ? ['parkings', 'transport_ca', 'generalservices', 'caspaces', 'access', 'other', 'car'] : ['walk', 'bici', 'car', 'transport'];
      for (const g in this.layerMenu) {
        const group = this.layerMenu[g];
        if (group_codes.indexOf(group.code) !== -1) {
          for (const o in group.options) {
            const option = group.options[o];
            if (option.options !== undefined) {
              for (const s in option.options) {
                const suboption = option.options[s];
                suboption.group = group.grupo;
                suboption.group_code = group.code;
                if (excluded.indexOf(suboption.nombre) == -1) {
                  options.push(suboption);
                }
              }
            } else {
              option.group = group.grupo;
              option.group_code = group.code;
              if (excluded.indexOf(option.nombre) == -1) {
                options.push(option);
              }
            }
          }
        }
      }
      const options_all = options.filter((o) => this.normalizedContains(this.$t(o.nombre), this.linesSearchLayerWord));
      const options_no_repeated = [];
      for (const option of options_all) {
        if (!find(options_no_repeated, (o) => o.nombre == option.nombre)) {
          options_no_repeated.push(option);
        }
      }
      const options_synonymous_all = options.filter((o) => o.synonymous && this.normalizedContains(o.synonymous[this.$i18n.locale], this.linesSearchLayerWord));
      for (const option of options_synonymous_all) {
        if (!find(options_no_repeated, (o) => o.nombre == option.nombre)) {
          options_no_repeated.push(option);
        }
      }
      return options_no_repeated;
    },
    getMetroLines(results) {
      const metroLines = [];
      for (const result of results) {
        if (result.properties.NOM_LINIA != 'FM' && result.properties.NOM_LINIA != 'TM') {
          metroLines.push(result);
        }
      }
      return metroLines;
    },
    getMetroStations(results) {
      const metroStations = [];
      for (const result of results) {
        if (result.properties.NOM_LINIA != 'FM' && result.properties.NOM_LINIA != 'TM') {
          metroStations.push(result);
        }
      }
      return metroStations;
    },
    hasResults() {
      let listsToCheck = [];
      if (this.layers == 'transport') {
        listsToCheck = [
          this.searchResults.metro.lines,
          this.searchResults.bus.lines,
          this.searchResults.ferrocarril.lines,
          this.searchResults.rodalies.lines,
        ];
        listsToCheck.push(this.searchResults.metro.stations);
        listsToCheck.push(this.searchResults.bus.stations);
        listsToCheck.push(this.searchResults.ferrocarril.stations);
        listsToCheck.push(this.searchResults.rodalies.stations);
        listsToCheck.push(this.searchResults.tram.stations);
      }
      if (this.layers == 'layers') {
        listsToCheck = [
          this.searchLayerResults.metro.lines,
          this.searchLayerResults.bus.lines,
          this.searchLayerResults.ferrocarril.lines,
          this.searchLayerResults.rodalies.lines,
        ];
      }

      if (
        this.isTrasportSynonym(['metro'])
        || this.isTrasportSynonym(['bus'])
        || this.isTrasportSynonym(['aerobus'])
        || this.isTrasportSynonym(['ferrocarril', 'fgc', 'ferrocarriles', 'ferrocarrils'])
        || this.isTrasportSynonym(['rodalies', 'cercanias', 'local trains'])
        || this.isTrasportSynonym(['tram', 'tranvia', 'tramvia', 'trams'])
        || this.isTrasportSynonym(['funicular', 'teleferico', 'teleferic'])) {
        return true;
      }
      for (const c in listsToCheck) {
        if (listsToCheck[c].length > 0) {
          return true;
        }
      }
      if (this.layers == 'layers') {
        if (this.getLayerMenuOptions().length > 0) {
          return true;
        }
      }
      return false;
    },
    isTrasportSynonym(words) {
      let coincidences = false;
      words.forEach((word) => {
        if (this.linesSearchLayerWord.length > 2 && word.search(this.removeDiacritics(this.linesSearchLayerWord.toLowerCase())) != -1) {
          coincidences = true;
          this.addToRecents(this.linesSearchLayerWord);
        }
      });
      return coincidences;
    },
    removeDiacritics(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    selectOpcion(opcion) {
      opcion.selected = !opcion.selected;
      this.activateLayer(opcion);
      this.addToRecents(this.$t(opcion.nombre));
      /* this.setLinesSearchWord('');
      this.setActiveSearch(false); */
    },
    normalizedContains(haystack, needle) {
      const regExp = new RegExp(this.removeDiacritics(needle), 'gi');
      return regExp.test(this.removeDiacritics(haystack));
    },
    setRecentsHeight() {
      /* setTimeout(() => {
        const totalHeight = $('#right-panel').outerHeight();
        const grayHeaderHeight = $('.gray-header').outerHeight();
        const footerHeight = $('.footer-buttons').outerHeight();
        const result = totalHeight - (grayHeaderHeight + footerHeight + 9);
        $('#right-panel .layer-results').height(result);
        $('#right-panel .recents').height(result+90);
      }); */
    },
    includeSearch() {
      this.cancelSearch();
      this.$emit('closeSearch');
    },
  },
  updated() {
    this.setRecentsHeight();
  },
  mounted() {
    if (!localStorage.recentsLines) localStorage.recentsLines = JSON.stringify([]);
    this.recentsLines = JSON.parse(localStorage.recentsLines);
    if (!localStorage.recentsLayers) localStorage.recentsLayers = JSON.stringify([]);
    this.recentsLayers = JSON.parse(localStorage.recentsLayers);
  },
};
</script>

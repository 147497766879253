<template>
  <div style="height: -webkit-fill-available;">
    <shortcut-banner ref="shortcut" :show="!showHelp"></shortcut-banner>
    <help v-if="showHelp" ref="help"></help>
    <div id="c-barra-ajuntament">
      <barra-ajuntament ref="barraAjuntament" v-show="!isWidget"></barra-ajuntament>
    </div>
    <div class="content-app" :style="appStyle()">
      <div id="c-flexible-data" :style="shadow()">
        <div class="header-america-cups" v-if="isAmericCups">{{ $t('america_cup_title') }} </div>
        <header ref="mainHeader" class="mainHeader" v-show="!isWidget">
          <div class="d-flex justify-content-between">
            <div>
              <span class="comshiva" v-if="menuNavigationApp.optionSelected === 'comshiva' || menuNavigationApp.optionSelected === 'apropmeu'">Com s'hi va</span>
              <span v-if="menuNavigationApp.optionSelected === 'cotxe'">{{ $t('traffic_state') }}</span>
              <span v-if="menuNavigationApp.optionSelected === 'linies'">{{ $t('lines_stops') }}</span>
            </div>
            <div class="d-flex align-items-center">
              <div class="header-search" :class="{'active-option-lateral-menu': infoPanel.show_search}" @click="searchLayer">
                <img src="/images/icons/search.svg" role="button">
              </div>
              <div id="traffic-incidents-b" class="content-bell-traffic">
                <div v-if="getNewIncidents.length > 0" class="count-traffic-alerts">{{ getNewIncidents.length }}</div>
                <img src="/images/icons/bell.svg" @click="openTrafficIncidents()">
              </div>
              <preferences></preferences>
              <lateral-menu></lateral-menu>
            </div>
          </div>
        </header>
        <planificador ref="planificador"></planificador>
      </div>
      <layer-menu ref="layerMenu"></layer-menu>
      <lines></lines>
      <mapa ref="mapa" @open-traffic-incidents="openTrafficIncidents" :trafficIncidentsLength="getNewIncidents.length"></mapa>
      <route-panel ref="routePanel"></route-panel>
      <info-panel ref="infoPanel"></info-panel>
      <traffic-incidents ref="incidents"></traffic-incidents>
      <traffic-alerts ref="trafficAlerts" :open="openTrafficAlerts" @closeTrafficAlerts="closeAlerts"></traffic-alerts>
      <div id="c-main-menu">
        <main-menu-nav ref="mainMenuNav" v-if="!isWidget"></main-menu-nav>
      </div>
      <warning></warning>
      <alerts></alerts>
    </div>
    <Loader ref="loader" />
  </div>
</template>

<script>

import { mapState, mapMutations } from 'vuex';
import { gsap, Expo } from 'gsap';
import TrafficIncidents from './TrafficIncidents.vue';
import BarraAjuntament from './BarraAjuntament.vue';
import Planificador from './Planificador.vue';
import MainMenuNav from './MainMenuNav.vue';
import LayerMenu from './LayerMenu.vue';
import Lines from './Lines.vue';
import Mapa from './Mapa.vue';
import RoutePanel from './RoutePanel.vue';
import InfoPanel from './InfoPanel.vue';
import TrafficAlerts from './TrafficAlerts.vue';
import Help from './Help.vue';
import Preferences from './Preferences.vue';
import Warning from './Warning.vue';
import LateralMenu from './LateralMenu.vue';
import Alerts from './Alerts.vue';
import Loader from './Loader.vue';
import ShortcutBanner from './ShortcutBanner.vue';

export default {
  name: 'Home',
  components: {
    BarraAjuntament,
    Planificador,
    MainMenuNav,
    LayerMenu,
    Lines,
    Mapa,
    RoutePanel,
    InfoPanel,
    TrafficAlerts,
    Help,
    Preferences,
    TrafficIncidents,
    Warning,
    LateralMenu,
    Alerts,
    Loader,
    ShortcutBanner,
  },
  data() {
    return {
      openTrafficAlerts: false,
      openInfoModal: false,
    };
  },
  computed: {
    ...mapState('incidents', [
      'trafficIncidents',
    ]),
    ...mapState('infopanel', [
      'infoPanel',
    ]),
    ...mapState('menu', [
      'layerMenuStatus',
      'menuNavigationApp',
    ]),
    ...mapState('map', [
      'mapFullScreen',
      'isWidget',
      'isAmericCups',
    ]),
    ...mapState('help', [
      'showHelp',
    ]),
    getNewIncidents() {
      return this.trafficIncidents.incidents.filter((incident) => {
        return this.trafficIncidents.new_incidents.indexOf(incident) !== -1;
      });
    },
  },
  watch: {
    mapFullScreen(newVal, oldVal) {
      this.hideComponentsFullScreen(newVal);
    },
  },
  methods: {
    ...mapMutations({
      setTrafficIncidentsFirstRead: 'incidents/setTrafficIncidentsFirstRead',
      setTrafficIncidentsNotificationReaded: 'incidents/setTrafficIncidentsNotificationReaded',
      setTrafficIncidentsNew: 'incidents/setTrafficIncidentsNew',
      setTrafficIncidentsReaded: 'incidents/setTrafficIncidentsReaded',
      setInfoPanel: 'infopanel/setInfoPanel',
    }),
    appStyle() {
      const heightBCNBar = this.isWidget === true ? 0 : 41;
      const heightShortcut = !this.showHelp && this.$refs.shortcut && this.$refs.shortcut.showInstallButton ? 85 : 0;
      const heightApp = window.innerHeight - (heightBCNBar + heightShortcut);
      return { height: `${heightApp}px` };
    },
    hideComponentsFullScreen(status) {
      if (status) {
        gsap.to('#c-barra-ajuntament', 1.2, { height: 0, ease: Expo.easeOut });
        gsap.to('#c-flexible-data', 1.2, { height: 0, ease: Expo.easeOut });
        gsap.to('#c-main-menu', 1.2, { height: 0, ease: Expo.easeOut });
        $('.content-app').css('height', 'revert-layer');
      } else {
        gsap.to('#c-barra-ajuntament', 1.2, { height: 'auto', ease: Expo.easeOut });
        gsap.to('#c-flexible-data', 1.2, { height: 'auto', ease: Expo.easeOut });
        gsap.to('#c-main-menu', 1.2, { height: 'auto', ease: Expo.easeOut });
        const heightBCNBar = this.isWidget === true ? 0 : 41;
        const heightApp = window.innerHeight - heightBCNBar;
        $('.content-app').css('height', `${heightApp}px`);
      }
    },
    openTrafficIncidents() {
      if (this.showHelp) return;
      this.openTrafficAlerts = !this.openTrafficAlerts;
      const selector = this.isWidget ? '#traffic-incidents-widget' : '#traffic-incidents-b';
      if (this.openTrafficAlerts == true) {
        $(selector).addClass('active-option-lateral-menu');
      } else {
        this.setTrafficIncidentsReaded(true);
        this.setTrafficIncidentsNew(0);
        this.setTrafficIncidentsFirstRead(false);
        $(selector).removeClass('active-option-lateral-menu');
        $('.social').addClass('d-none');
      }
    },
    closeAlerts() {
      this.openTrafficAlerts = false;
      this.setTrafficIncidentsReaded(true);
      this.setTrafficIncidentsNew(0);
      this.setTrafficIncidentsFirstRead(false);
      const selector = this.isWidget ? '#traffic-incidents-widget' : '#traffic-incidents-b';
      $(selector).removeClass('active-option-lateral-menu');
    },
    shadow() {
      const shadow = this.menuNavigationApp.optionSelected !== 'cotxe' && !this.isWidget ? '0px 3px 6px #0000001F' : 'none';
      return { 'box-shadow': shadow };
    },
    searchLayer() {
      if (this.infoPanel.show_search) {
        this.refs.infoPanel.onSwipeDown();
      } else {
        this.setInfoPanel({
          visible: false,
          show_search: true,
        });
        this.refs.infoPanel.initPanel();
      }
    },
  },
  mounted() {},
  created() {
    this.refs = this.isWidget === true ? this.$root.$children[0].$children[0].$children[0].$refs : this.$root.$children[0].$children[0].$refs;
  },
};
</script>

<template>
  <div>
    <div id="layerPanelWrapper">
      <div class="swipeZone">
        <div class="swipeHelper">
          <div class="helper"></div>
        </div>
        <div class="header-panel">
          <div class="counter-layers">
            <div class="num-layers">{{ activeLayers.length }}</div>
            <img src="/images/icons/capes.svg">
            {{ $t('map_options') }}
          </div>
          <div class="delete-layers" v-if="activeLayers && activeLayers.length" @click="deleteAllLayers()">
            {{ $t('delete_all') }}
          </div>
        </div>
      </div>
      <div class="layerPanelContainer" :class="{'opened': panelTop}">
        <!-- Información activa -->
        <div class="content-active-layers">
          <div class="no-active-layers" v-if="(!activeLayers || activeLayers.length == 0) && fixedLayers.length == 0">
            {{ $t('no_layers_active') }}
          </div>
          <div v-for="(item, index) of typeGroupLayers" v-bind:key="index">
            <active-layer-group :group="item" :layers="activeLayers"></active-layer-group>
          </div>
            <active-layer-group v-if="fixedLayers.length > 0" :group="nameFixedGroup" :layers="fixedLayers"></active-layer-group>
        </div>
        <div v-if="opened" class="footer-panel">
          <button v-if="panelTop" class="btn-see-map" @click="closeViewMap">{{ $t('view_map') }}</button>
          <button class="btn-add-layers" @click="$bvModal.show('modal-selector-layers')">{{ site == 'copa-america' ? $t('include_more_layers_ca') : $t('include_more_layers') }}</button>
        </div>
      </div>
    </div>

    <!-- Selector List Layers -->
    <layer-selector v-if="!isAmericCups" ref="layerSelector"></layer-selector>
    <layer-selector-CA v-if="isAmericCups" ref="layerSelector"></layer-selector-CA>
  </div>
</template>

<script>
import {
  find,
  clone,
  cloneDeep,
  findIndex,
} from 'lodash';
import { mapState, mapMutations } from 'vuex';
import { TransportMixins } from './mixins/TransportMixins';
import { LayerMixins } from './mixins/LayerMixins';
import { PanelMixins } from './mixins/PanelMixins';
import ActiveLayerGroup from './ActiveLayerGroup.vue';
import { app } from '../main';

export default {
  name: 'LayerMenu',
  mixins: [
    TransportMixins,
    LayerMixins,
    PanelMixins,
  ],
  components: {
    ActiveLayerGroup,
    LayerSelector: () => import('./LayerSelector.vue'),
    LayerSelectorCA: () => import('./LayerSelectorCA.vue'),
  },
  data() {
    return {
      opened: false,
      searchWord: '',
      awaitingSearch: false,
      directionIconsGreenHubsRequired: [],
      directionIconsGreenHubsOptional: [],
      directionIconsGreenHubsNew: [],
      panelTop: false,
      typeGroupLayers: [
        'walk',
        'bici',
        'public_transport',
        'transit',
        'general_interest',
        'parkings',
        'generalservices',
        'access',
        'other',
        'caspaces',
        'transport',
      ],
      touchstartY: 0,
      touchendY: 0,
      timeInteraction: 0,
    };
  },
  computed: {
    menuHeight() {
      const height = $(window).height() - $('#brand').height();
      return { height: `${height}px` };
    },
    level2Height() {
      const height = $(window).height() - $('#brand').height() - 30;
      return { height: `${height}px` };
    },
    ...mapState('infopanel', [
      'infoPanel',
    ]),
    ...mapState('menu', [
      'layerMenuStatus',
      'layerMenu',
    ]),
    ...mapState('search', [
      'searchResults',
      'activeSearch',
    ]),
    ...mapState('transport', [
      'lineas',
    ]),
    ...mapState('map', [
      'mapObj',
      'activeLayers',
      'fixedLayers',
      'isWidget',
      'isAmericCups',
      'nameFixedGroup',
      'mapFullScreen',
    ]),
    ...mapState('general', [
      'site',
    ]),
  },
  watch: {
    opened(newOpen, oldOpen) {
      this.setLayerMenuStatus({
        opened: newOpen,
      });
    },
  },
  methods: {
    ...mapMutations({
      setActiveLayers: 'map/setActiveLayers',
      addActiveLayer: 'map/addActiveLayer',
      setInfoPanel: 'infopanel/setInfoPanel',
      setLineas: 'transport/setLineas',
      setLayerMenuStatus: 'menu/setLayerMenuStatus',
      setForceClose: 'incidents/setForceClose',
    }),
    initialLayers() {
      if (app.$route.params.layer) {
        if (app.$route.params.layer == 'transit') {
          const grupo = this.getGrupoByName('transit');
          const opcion = find(grupo.options, (o) => { return o.code == 'traficoactual'; });
          setTimeout(() => {
            const pos = {
              lat: 41.385,
              lng: 2.173,
            };
            this.mapObj.setCenter({ lat: pos.lat, lng: pos.lng });
            this.mapObj.setZoom(12);
            this.setForceClose(true);
            this.selectOpcion(opcion);
            this.activateLayer(opcion);
          }, 800);
        }
      }
      if (this.fixedLayers.length > 0) {
        setTimeout(() => this.fixedLayers.forEach((layer) => this.selectOpcion(layer)), 800);
      }
    },
    initPanel() {
      this.posPanel();
      this.setPanelHeight();
      this.opened = true;
      this.detectSlide();
      this.closePanels('layerMenu');
    },
    closePanel() {
      while (this.opened != false) {
        this.onSwipeDown();
      }
    },
    posPanel() {
      const mapCanvas = document.getElementById('map-canvas');
      const top = this.isWidget || this.mapFullScreen ? (mapCanvas.clientHeight / 1.35) - 100 : mapCanvas.clientHeight - 100;

      $('#layerPanelWrapper').animate(
        {
          top: `${top}px`,
        },
        {
          complete: () => {
            this.refs.mapa.moveMapButtons('up', 'layerPanelWrapper');
          },
        },
      );
      $('.layerPanelContainer').scrollTop(0);
    },
    onSwipeUp() {
      if (this.panelTop) return false;

      let top = 0;
      if (!this.mapFullScreen) {
        top = document.getElementById('c-flexible-data').clientHeight + 5;
        if (document.getElementById('car-selector-tabs')) {
          top += document.getElementById('car-selector-tabs').clientHeight;
        }
      } else {
        const mapCanvas = document.getElementById('map-canvas');
        top = 100;
      }

      $('#layerPanelWrapper').animate(
        {
          top: `${top}px`,
          marginTop: 0,
        },
        {
          complete: () => {
            this.opened = true;
          },
        },
      );
      this.panelTop = true;
      this.setPanelHeight();
    },
    onSwipeDown() {
      if (!this.panelTop) {
        const top = window.innerHeight - 65;
        this.refs.mapa.moveMapButtons('down', 'layerPanelWrapper');
        this.opened = false;
        $('#layerPanelWrapper').animate(
          {
            top: `${top}px`,
            marginTop: '25px',
          },
          {
            complete: () => {
              $('#layerPanelWrapper').css('top', '');
            },
          },
        );
      } else {
        this.panelTop = false;
        this.posPanel();
        this.setPanelHeight();
      }
    },
    closeViewMap() {
      this.panelTop = false;
      this.onSwipeDown();
    },
    detectSlide() {
      var gesuredZone = document.getElementById('layerPanelWrapper').children[0];

      gesuredZone.addEventListener('touchstart', (event) => {
        this.touchstartY = event.changedTouches[0].screenY;
      }, false);

      gesuredZone.addEventListener('touchend', (event) => {
        this.touchendY = event.changedTouches[0].screenY;
        if (((Date.now() - this.timeInteraction) / 1000) > 1) {
          this.handleGesure();
        }
      }, false);
    },
    handleGesure() {
      if (this.touchendY < this.touchstartY) {
        this.onSwipeUp();
      }
      if (this.touchendY > this.touchstartY) {
        this.onSwipeDown();
      }
      this.timeInteraction = Date.now();
    },
    setPanelHeight() {
      $('.layerPanelContainer').css('height', `${document.getElementById('map-canvas').clientHeight - 20}px`);
    },
  },
};

</script>

import Vue from 'vue';
import VueRouter from 'vue-router';
// eslint-disable-next-line import/no-cycle
import { i18n } from '../main';
import Home from '../components/Home.vue';
import WidgetView from '../views/WidgetView.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', redirect: '/ca' },
  {
    path: '/:lang/',
    component: Home,
    children: [
      { path: ':layer', component: Home },
      { path: 'planifica_la_teva_ruta', component: Home },
      { path: 'planifica_tu_ruta', component: Home },
      { path: 'plan_your_route', component: Home },
    ],
  },
  { path: '/:lang/api/widget/:widget', component: WidgetView },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path.includes('copa-america')) next({ path: `/${to.params.lang}` });
  const title = i18n.t('title_home');
  const description = i18n.t('description_home');

  document.title = `${title} | Barcelona`;
  var meta = document.createElement('meta');
  meta.name = 'description';
  meta.content = description;
  document.getElementsByTagName('head')[0].appendChild(meta);
  next();
});

router.afterEach((to) => {
  const ga = window.ga || (() => {});
  ga('set', 'page', to.fullPath);
  ga('send', 'pageview');
});

export default router;

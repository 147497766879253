<template>
  <div id="barra-ajuntament" @click="clicked"></div>
</template>

<script>

import { mapState, mapMutations } from 'vuex';

export default {
  name: 'BarraAjuntament',
  computed: {
    ...mapState('map', [
      'isAmericCups',
    ]),
  },
  methods: {
    initBar() {
      bcn.lang = this.$route.params.lang;
      const version = this.isAmericCups ? 'v2024' : 'v2017';
      const htmlBanner = this.isAmericCups ? 'copa-america' : 'com-shi-va2015';
      bcn.brandInjection({
        version,
        urls: {
          ca: `https://www.barcelona.cat/brand/banner/ca/${htmlBanner}.html`,
          es: `https://www.barcelona.cat/brand/banner/es/${htmlBanner}.html`,
          en: `https://www.barcelona.cat/brand/banner/en/${htmlBanner}.html`,
        },
        // keys: ['UA-46029296-16'],
        callback() {
          $('#brand').prependTo('#barra-ajuntament');
          // eslint-disable-next-line no-underscore-dangle
          window._mtm = window._mtm || [];
        },
      });
    },
    clicked() {
      let display = 'block';
      if ($('.bcnbrand-shortcuts').hasClass('bcnbrand-open-nav') || $('.bcnbrand-lang').hasClass('bcnbrand-open-nav')) {
        display = 'none';
      }
      $('#mainMenu').css('display', display);
    },
  },
  mounted() {
    this.initBar();
  },
};
</script>

<template>
  <div id="planificador">
    <div v-if="!selectedLocation" :class="[
      isWidget && optionWidget !== 'traffic' ? 'open-planificador-widget' : 'open-planificador',
      optionWidget === 'traffic' ? 'open-planificador-traffics' : '',
    ]">
      <button class="btn-planificador" @click="openPlanificador()">{{ $t('plan_new_route') }}</button>
    </div>

    <!-- Route summary -->
    <div v-if="selectedLocation" class="route-summary">
      <div class="route">
        <div class="address">
          <div class="line-route">
            <div class="point-location" style="margin-bottom: -2px;"></div>
            <div class="line"></div>
            <div class="point-location" style="margin-top: -2px;"></div>
          </div>
          <div class="locations">
            <div>{{ cutOffDirection(selectedLocation.main_text) }}</div>
            <div>{{ cutOffDirection(selectedLocation.secondary_text) }}</div>
          </div>
        </div>
        <div class="modify">
          <button class="btn-modify-route" @click="openPlanificador()">{{ $t('modify_route') }}</button>
        </div>
      </div>
      <div class="content-modos">
        <div class="d-flex justify-content-between monified">
          <div v-for="(item, index) of modos" v-bind:key="index" class="modo"
            @click="recalculateRouteMode(item.modo)">
            <div :class="checkedModo(item)" >
              <img :src="`/images/icons/${item.icon + (modo == item.modo ? '-black' : '')}.svg`">
            </div>
            <div class="text">{{ $t(item.text) }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Planificador  -->
    <b-modal id="modal-planificador" scrollable hide-footer>
      <template #modal-header>
        <div class="m-header">
          <div class="d-flex align-items-center">
            <img @click="$bvModal.hide('modal-planificador')" src="/images/icons/back-arrow.svg">
            <span @click="$bvModal.hide('modal-planificador')" class="comshiva">Com s'hi va</span>
          </div>
          <div v-if="!isWidget">
            <preferences></preferences>
          </div>
        </div>
      </template>

      <b-container fluid style="padding: 0">
        <div class="m-body">
          <div class="d-flex address-selector" id="plan-route">
            <div class="route-line">
              <img :src="`/images/icons/${typeIconRoute('origin')}.svg`">
              <div class="line"></div>
              <img :src="`/images/icons/${typeIconRoute('destination')}.svg`">
            </div>
            <div class="selectors">
              <!-- Origin -->
              <div>
                <md-field>
                  <label>{{ $t('origin') }}</label>
                  <md-input ref="refOriginInput" v-model="fromValueModel" @focus="inputFocus('from')"></md-input>
                </md-field>
              </div>
              <!-- Destination -->
              <div>
                <md-field>
                  <label>{{ $t('destination') }}</label>
                  <md-input ref="refDestinationInput" v-model="toValueModel" @focus="inputFocus('to')"></md-input>
                </md-field>
              </div>
            </div>
            <div class="d-flex justify-content-center swap-address">
              <img src="/images/icons/change-direction-large.svg" @click="swapFields()">
            </div>
          </div>
          <div class="content-modos">
            <div class="d-flex justify-content-between maxified">
              <div v-for="(item, index) of modos" v-bind:key="index" class="modo"
                @click="changeModo(item.modo)">
                <div :class="checkedModo(item)" >
                  <img :src="`/images/icons/${item.icon + (modo == item.modo ? '-black' : '')}.svg`">
                </div>
                <div class="text">{{ $t(item.text) }}</div>
              </div>
            </div>
          </div>
          <div class="opcions-route">
            <!-- Departure Time -->
            <div v-if="modo == 'TRANSIT'">
              <div class="type-departure-info" @click="activeWhenToGo">
                {{ $t('want_leave') }} {{ getWhenToGoText(whenToGo) }}
              </div>

              <van-popup id="popup-departure-time" v-model="openWhenToGo" position="bottom"
                :style="{ boxShadow: '0px -3px 6px #0000001F' }">
                <div class="w-100">
                  <div class="d-flex justify-content-between">
                    <div v-for="(item, index) of whenToGoOptions" v-bind:key="index" class="selector-departure"
                      :class="item.value == departureType ? 'active-departure' : ''"
                      @click="departureType = item.value">
                      {{ item.label }}
                    </div>
                  </div>
                  <div class="config-departure">
                    <div v-if="departureType == 'now'"
                      class="d-flex justify-content-center align-items-center config-now">
                      {{ $t('set_departure_current_time') }}
                    </div>
                    <div v-if="departureType != 'now'"
                      class="d-flex justify-content-between align-items-center config-date">
                      <div class="input-grp hora">
                        <span class="bcn-icon-rellotge"></span>
                        <datetime :placeholder="$t('time')" :value="transitTime" @input="setTransitTime" type="time"
                          :phrases="dateButtons" format="HH:mm" :title="$t('time')"></datetime>
                      </div>
                      <div class="input-grp fecha">
                        <span class="bcn-icon-calendari"></span>
                        <datetime :placeholder="$t('date')" :value="transitDate" @input="setTransitDate" type="date"
                          :phrases="dateButtons" format="dd/MM/yyyy"></datetime>
                      </div>
                    </div>
                  </div>
                  <div class="actions-departure">
                    <button class="btn-apply" @click="applyOutpuConfigurations(departureType)">{{ $t('apply')
                      }}</button>
                    <button class="btn-cancel" @click="openWhenToGo = false">{{ $t('back') }}</button>
                  </div>
                </div>
              </van-popup>
            </div>
            <!-- Preferences Car Route -->
            <div class="car-options" v-if="modo == 'DRIVING'">
              <div class="preferences-link" @click="togglePreferences()" :class="{ 'open': preferencesOpened }">{{
                $t('preferences') }}</div>
              <div class="preferences" v-if="preferencesOpened">
                <p-check class="p-icon" :value="avoidHighways" @change="setAvoidHighways">
                  <i class="icon bcn-icon-ok-bold" slot="extra"></i>
                  {{ $t('avoid_highways') }}
                </p-check>
                <p-check class="p-icon" :value="avoidTolls" @change="setAvoidTolls">
                  <i class="icon bcn-icon-ok-bold" slot="extra"></i>
                  {{ $t('avoid_tolls') }}
                </p-check>
              </div>
            </div>
          </div>
          <div class="confirm-route">
            <button class="btn-apply" @click="planRoute()">{{ $t('plan_route') }}</button>
            <button class="btn-delete" @click="clearInput('from'); clearInput('to'); clearRoute();">
              <img src="/images/icons/paperera.svg">
              {{ $t('clean_route') }}
            </button>
          </div>
          <div class="planificador-capas">
            <h2>
              {{ $t('show_map') }}
              <small> {{ $t('add_interest_info') }}</small>
            </h2>
            <cerca-lines ref="cercacapes" :placeholder="$t('search_examples')" layers="layers"
              internalpadding="15"></cerca-lines>
            <div class="example-txt">{{ $t('examples') }}:</div>
            <div class="layers">
              <div class="layer" v-for="(example, index) in getExamplesVisible()" :key="index">
                <div class="check-wrapper" v-if="example.code">
                  <input :id="'sample-' + index" :checked="example.selected" :name="'sample-' + index" type="checkbox"
                    @change="selectOpcion(example)">
                  <label :for="'sample-' + index">
                    <span>{{ $t(example.nombre) }}</span>
                  </label>
                </div>
                <div class="check-wrapper" v-if="example.linea">
                  <input :id="'sample-' + index" :checked="example.selected" :name="'sample-' + index" type="checkbox"
                    @change="toggleLine(example)">
                  <label :for="'sample-' + index">
                    <span>{{ $t(example.linea) }}</span>
                  </label>
                </div>
              </div>
            </div>
            <!-- <div class="more-data" @click="setViewAllExamplesVisible(true)" role="button" tabindex="0" v-if="getExamples().length > 5 && !viewAllExamplesVisible">
              {{ $t('view_more') }}
            </div>
            <div class="more-data" @click="setViewAllExamplesVisible(false)" role="button" tabindex="0" v-if="getExamples().length > 5 && viewAllExamplesVisible">
              {{ $t('view_less') }}
            </div> -->
          </div>

          <div class="footer" id="footer-error">
            <div v-if="!!errorAddress" class="error-route">
              <div class="d-flex justify-content-center">
                <div class="alert-icon">
                  <span>!</span>
                </div>
              </div>
              <div class="info">
                {{ $t('error_choose_proposal') }}
              </div>
              <div>
                <button class="btn-error" @click="closeErrorMessage()">{{ $t('correct_address') }}</button>
              </div>
            </div>
          </div>
        </div>

      </b-container>

    </b-modal>

    <!-- Search Address -->
    <search-address :type-location="typeLocation"
      :value-address="typeLocation == 'from' ? someFromValue : someValue"></search-address>

  </div>
</template>

<script>
import {
  take,
  clone,
  find,
  orderBy,
} from 'lodash';
import { toRaw } from 'vue';
import { mapState, mapMutations } from 'vuex';
import ClickOutside from 'vue-click-outside';
import Preferences from './Preferences.vue';
import CercaLines from './CercaLines.vue';
import '../classes/LineaTransportMetro';
import { LayerMixins } from './mixins/LayerMixins';
import { TransportMixins } from './mixins/TransportMixins';
import generalMixin from '../mixins/generalMixin';

export default {
  name: 'Planificador',
  mixins: [
    TransportMixins,
    LayerMixins,
    generalMixin,
  ],
  directives: {
    ClickOutside,
  },
  components: {
    Preferences,
    CercaLines,
    SearchAddress: () => import('./SearchAddress.vue'),
  },
  data() {
    return {
      modos: [
        { icon: 'best-route', modo: 'BEST-ROUTE', text: 'best_mode' },
        { icon: 'public-transport', modo: 'TRANSIT', text: 'public_mode' },
        { icon: 'car', modo: 'DRIVING', text: 'car_mode' },
        { icon: 'bicycle', modo: 'BICYCLING', text: 'bici' },
        { icon: 'walking', modo: 'WALKING', text: 'walk' },
      ],
      whenToGoOptions: [
        { label: this.$t('leave_now'), value: 'now' },
        { label: this.$t('leave_at'), value: 'leave' },
        { label: this.$t('arrive_at'), value: 'arrive' },
      ],
      departureType: '',
      openWhenToGo: false,
      dateButtons: { ok: 'Ok', cancel: this.$t('cancel') },
      typeLocation: '',
      errorAddress: false,
      bestRoutes: [],
      skeletonBestRouteRequest: {},
      refs: null,
      someValue: '',
      someFromValue: '',
    };
  },
  computed: {
    ...mapState('incidents', [
      'trafficIncidents',
      'forceClose',
    ]),
    ...mapState('planroute', [
      'places',
      'modo',
      'displayMode',
      'whenToGo',
      'opened',
      'selectedLocation',
      'preferencesOpened',
      'fromInput',
      'toInput',
      'fromValue',
      'toValue',
      'fromObject',
      'toObject',
      'predictions',
      'suggestionsNoResults',
      'showResultsPanel',
      'routes',
      'directionsRenderer',
      'avoidTolls',
      'avoidHighways',
      'transitDate',
      'transitTime',
      'viewAllExamplesVisible',
      'routeMapElements',
    ]),
    ...mapState('map', [
      'mapObj',
      'geolocationActive',
      'isWidget',
      'optionWidget',
    ]),
    ...mapState('transport', [
      'preferencias',
    ]),
    ...mapState('menu', [
      'layerMenu',
    ]),
    ...mapState('help', [
      'showHelp',
    ]),
    ...mapState('general', [
      'site',
    ]),
    toValueModel: {
      get() {
        return this.toValue;
      },
      set(newValue) {
        this.someValue = newValue;
      },
    },
    fromValueModel: {
      get() {
        return this.fromValue;
      },
      set(newValue) {
        this.someFromValue = newValue;
      },
    },
  },
  watch: {
    whenToGo(newVal, oldVal) {
      this.departureType = newVal;
    },
  },
  methods: {
    ...mapMutations({
      setLayerMenu: 'menu/setLayerMenu',
      setModo: 'planroute/setModo',
      setDisplayMode: 'planroute/setDisplayMode',
      setWhenToGo: 'planroute/setWhenToGo',
      setOpened: 'planroute/setOpened',
      setSelectedLocation: 'planroute/setSelectedLocation',
      setPreferencesOpened: 'planroute/setPreferencesOpened',
      setFromInput: 'planroute/setFromInput',
      setToInput: 'planroute/setToInput',
      setFromValue: 'planroute/setFromValue',
      setToValue: 'planroute/setToValue',
      setFromObject: 'planroute/setFromObject',
      setToObject: 'planroute/setToObject',
      setPredictions: 'planroute/setPredictions',
      setSuggestionsNoResults: 'planroute/setSuggestionsNoResults',
      setShowResultsPanel: 'planroute/setShowResultsPanel',
      setRoutes: 'planroute/setRoutes',
      setAvoidTolls: 'planroute/setAvoidTolls',
      setAvoidHighways: 'planroute/setAvoidHighways',
      setPanelOpened: 'planroute/setPanelOpened',
      setTransitDate: 'planroute/setTransitDate',
      setTransitTime: 'planroute/setTransitTime',
      setViewAllExamplesVisible: 'planroute/setViewAllExamplesVisible',
      setRouteMapElements: 'planroute/setRouteMapElements',
      setSelectedRouteIndex: 'planroute/setSelectedRouteIndex',
      setRecentRoutes: 'planroute/setRecentRoutes',
      setTrafficIncidentsNew: 'incidents/setTrafficIncidentsNew',
      setTrafficIncidentsReaded: 'incidents/setTrafficIncidentsReaded',
      setTrafficIncidentsFirstRead: 'incidents/setTrafficIncidentsFirstRead',
      setShowLayerTooltip: 'map/setShowLayerTooltip',
      setMsgLayers: 'map/setMsgLayers',
    }),
    changeModo(modo) {
      this.setModo(modo);
      const layerMenu = clone(this.layerMenu);
      let index;
      for (let c = 0; c < layerMenu.length; c++) {
        if (layerMenu[c].modo === this.modo) {
          index = c;
          break;
        }
      }
      layerMenu.splice(1, 0, layerMenu.splice(index, 1)[0]);
      this.setLayerMenu(layerMenu);
    },
    typeIconRoute(placeRoute) {
      let img = 'marker-small';
      if (placeRoute == 'origin') {
        if (this.fromObject) {
          if (this.fromObject.address_components) {
            img = 'gps-small';
          }
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.toObject) {
          if (this.toObject.address_components) {
            img = 'gps-small';
          }
        }
      }
      return img;
    },
    closeErrorMessage() {
      this.errorAddress = false;
      const element = document.getElementById('plan-route');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    inputFocus(typeLocation) {
      if (typeLocation == 'from') {
        this.$refs.refOriginInput.$el.blur();
      } else {
        this.$refs.refDestinationInput.$el.blur();
      }

      this.typeLocation = typeLocation;
      this.$bvModal.show('modal-search-address');
    },
    applyOutpuConfigurations(config) {
      this.setWhenToGo(config);
      this.openWhenToGo = false;
    },
    planningClose() {
      this.setOpened(false);
      this.elementsVisibility(true);
      if (this.selectedLocation !== null) {
        this.setDisplayMode('route');
      }
    },
    focusInput(input, focus) {
      this.setPredictions([]);
      if (input === 'fromInput') {
        this.setFromInput(focus);
        this.setToInput(false);
      } else {
        this.setFromInput(false);
        this.setToInput(focus);
      }
      this.setSuggestionsNoResults(false);
      this.setShowResultsPanel(true);
      if (focus) {
        this.elementsVisibility(false);
      }
    },
    clearInput(input) {
      if (input === 'from') {
        this.setFromValue('');
        this.setFromObject(null);
      } else {
        this.setToValue('');
        this.setToObject(null);
        this.setSelectedLocation(null);
      }
      this.setSuggestionsNoResults(false);
      this.closePanel();
      this.refs.layerMenu.deleteAllLayers();
    },
    labelAutocomplete(input) {
      return {
        focused: this[`${input}Input`],
        filling: this[`${input}Value`],
      };
    },
    hideResults(e) {
      if (e !== undefined && $(e.target).closest('.results').length > 0) return;
      this.setFromInput(false);
      this.setToInput(false);
      this.setSuggestionsNoResults(false);
      this.elementsVisibility(true);
      this.setShowResultsPanel(false);
    },
    elementsVisibility(visible) {
      if (visible) {
        $('#barra-ajuntament').show();
        $('.buttons').show();
        $('.mainHeader').show();
        $('#mainMenu').show();
      } else {
        $('#barra-ajuntament').hide();
        $('.buttons').hide();
        $('.mainHeader').hide();
        $('#mainMenu').hide();
      }
    },
    swapFields() {
      const {
        fromValue,
        toValue,
        fromObject,
        toObject,
      } = this;
      this.setFromValue(toValue);
      this.setToValue(fromValue);
      this.setFromObject(toObject);
      this.setToObject(fromObject);
    },
    checkedModo(item) {
      return {
        checked: item.modo === this.modo,
      };
    },
    getClassButton(item, index) {
      let clase = item.icon;
      if (index === 0) clase += ' first';
      if (index === this.modos.length - 1) clase += ' last';
      return clase;
    },
    openPlanificador() {
      if (this.showHelp) return;
      this.setPreferencesOpened(false);
      this.$bvModal.show('modal-planificador');
    },
    togglePlanificador() {
      this.setOpened(!this.opened);
      this.setDisplayMode('');
      if (this.opened && (!this.fromValue || this.fromValue == '') && this.geolocationActive) {
        this.selectMyPos('from');
      }
    },
    togglePreferences() {
      this.setPreferencesOpened(!this.preferencesOpened);
    },
    getSuggestions(input, e) {
      const word = e.target.value;
      if (word === '') return;
      const googleRoutesApi = new GoogleRoutesApi(this.$i18n.locale);
      googleRoutesApi.getSuggestions(word).then((result) => {
        if (result.status !== 'OK') {
          this.setSuggestionsNoResults(true);
        }
        this.setPredictions(result.predictions);
        this.setShowResultsPanel(true);
        if (input === 'from') {
          this.setFromValue(e.target.value);
        } else {
          this.setToValue(e.target.value);
        }
      });
    },
    selectPrediction(input, prediction) {
      if (input === 'from') {
        this.setFromValue(prediction.description);
        this.setFromObject(prediction);
      } else {
        this.setToValue(prediction.description);
        this.setToObject(prediction);
      }
      this.setPredictions([]);
    },
    setStationDestination(linea, station) {
      const googleRoutesApi = new GoogleRoutesApi(this.$i18n.locale);
      const position = new google.maps.LatLng(station.geometry.coordinates[1], station.geometry.coordinates[0]);
      googleRoutesApi.geocode(position).then((results) => {
        const val = results[0];
        this.toValue = `${linea.linea} - ${station.nom}`;
        val.custom_text = {
          main_text: station.nom,
          secondary_text: `${linea.linea} - ${linea.descripcio}`,
        };
        this.toObject = val;
        this.selectedLocation = {
          icon: linea.icon,
          main_text: station.nom,
          secondary_text: `${linea.linea} - ${linea.descripcio}`,
        };
        this.$forceUpdate();
        this.hideResults();
      });
    },
    selectMyPos(input) {
      const googleRoutesApi = new GoogleRoutesApi(this.$i18n.locale);
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        googleRoutesApi.geocode(pos).then((results) => {
          const val = results[0];
          if (input === 'from') {
            this.setFromValue(val.formatted_address);
            this.setFromObject(val);
          } else {
            this.setToValue(val.formatted_address);
            this.setToObject(val);
          }
          this.$forceUpdate();
          this.hideResults();
        });
      });
    },
    selectSavedPlace(input, search) {
      const value = `${this.getPlaceText('main_text', search)} ${this.getPlaceText('secondary_text', search)}`;
      if (input === 'from') {
        this.setFromValue(value);
        this.setFromObject(search);
      } else {
        this.setToValue(value);
        this.setToObject(search);
      }
      this.hideResults();
    },
    getWhenToGoText(typeDeparture) {
      return this.whenToGoOptions.find((option) => option.value == typeDeparture).label;
    },
    getPlaceText(property, place) {
      if (!property || !place) return '';

      if (place?.custom_text) {
        if (property === 'main_text') return place.custom_text.main_text;
        if (property === 'secondary_text') return place.custom_text.secondary_text;
      }

      if (property === 'main_text' || property === 'secondary_text') {
        if (place?.structured_formatting) {
          return property === 'main_text' ? place.structured_formatting.main_text : place.structured_formatting.secondary_text;
        }
        const componentsObject = GoogleRoutesApi.addressComponentObject(place.address_components);
        const addressText = GoogleRoutesApi.getMainSecondaryAddresText(componentsObject);
        if (addressText) {
          return property === 'main_text' ? addressText.main_text : addressText.secondary_text;
        }
        if (place?.main_text) return place.main_text;
        if (place?.secondary_text) return place.secondary_text;
      }

      return '';
    },
    async getBestRoute() {
      let bestRoutes = [];
      bestRoutes = await this.processorBestRoutes();
      bestRoutes = this.orderBestRoutes(bestRoutes);
      this.skeletonBestRouteRequest.routes = take(bestRoutes, 6);
      return { bestMode: bestRoutes[0].travelMode, routes: this.skeletonBestRouteRequest };
    },
    async processorBestRoutes() {
      const routes = [];
      const modes = ['TRANSIT', 'DRIVING', 'BICYCLING', 'WALKING'];
      for (const mode of modes) {
        // eslint-disable-next-line no-await-in-loop
        const result = await this.googleGetRoute(mode);
        result.routes.forEach((route) => { route.travelMode = mode; });
        routes.push(...result.routes);
        if (mode === 'TRANSIT') {
          this.skeletonBestRouteRequest = result;
          this.skeletonBestRouteRequest.routes = [];
        }
      }

      return routes;
    },
    googleGetRoute(mode) {
      const googleRoutesApi = new GoogleRoutesApi(this.$i18n.locale);
      return new Promise((resolve, reject) => {
        const options = {
          travelMode: mode,
        };
        const result = googleRoutesApi.route(this.fromObject.place_id, this.toObject.place_id, options);

        resolve(result);
      });
    },
    orderBestRoutes(bestRoutes) {
      return orderBy(bestRoutes, (route) => route.legs[0].duration.value, ['asc']);
    },
    recalculateRouteMode(modo) {
      this.changeModo(modo);
      if (this.routes?.length > 0) this.planRoute();
    },
    async planRoute() {
      if (this.toObject == null || this.fromObject == null) {
        const element = document.getElementById('footer-error');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
        this.errorAddress = true;
        return;
      }
      this.refs.routePanel.onSwipeDown();

      if (this.modo == 'BEST-ROUTE') {
        const bestRoute = await this.getBestRoute();
        this.directionRender(bestRoute.routes, bestRoute.bestMode);
        return;
      }

      const googleRoutesApi = new GoogleRoutesApi(this.$i18n.locale);
      const options = {
        travelMode: this.modo,
      };

      if (this.modo === 'TRANSIT') {
        options.transitOptions = { modes: ['SUBWAY', 'BUS', 'RAIL', 'TRAIN', 'TRAM'] };
        if (this.preferencias.tipoRuta === 'FEWER_TRANSFERS' || this.preferencias.tipoRuta === 'LESS_WALKING') {
          options.transitOptions.routingPreference = this.preferencias.tipoRuta;
        }
        if (this.preferencias.transitModes.length > 0) {
          options.transitOptions.modes = this.preferencias.transitModes;
        }
      }
      let hourMin = `${new Date().getHours()}:${new Date().getMinutes()}`;
      if (this.modo === 'TRANSIT' && this.whenToGo !== 'now') {
        const date = new Date(this.transitDate);
        const hour = new Date(this.transitTime).getHours();
        const minute = new Date(this.transitTime).getMinutes();
        hourMin = `${hour}:${minute}`;
        date.setHours(hour);
        date.setMinutes(minute);

        if (this.whenToGo === 'leave') {
          options.transitOptions.departureTime = date;
        }
        if (this.whenToGo === 'arrive') {
          options.transitOptions.arrivalTime = date;
        }
      }
      if (this.modo === 'DRIVING') {
        if (this.avoidHighways) options.avoidHighways = true;
        if (this.avoidTolls) options.avoidTolls = true;
      }
      // eslint-disable-next-line no-underscore-dangle
      window._mtm.push({
        event: 'planol',
        eventcategory: 'comshiva',
        eventaction: 'Planifica la ruta',
        eventname: `Origen: ${this.fromValue} - Destí: ${this.toValue} - Hora: ${hourMin} - Transport: ${this.modo}`,
      });
      this.clearRoute();
      googleRoutesApi.route(this.fromObject.place_id, this.toObject.place_id, options).then((response, status) => {
        response.routes.forEach((route) => {
          route.travelMode = this.modo;
        });
        this.directionRender(response, this.modo);
      });
    },
    toggleCycleLaneLayer(travelMode) {
      const bicycleGroup = this.layerMenu.find((g) => g.code == 'bici');
      if (bicycleGroup) {
        const cycleLaneGroup = bicycleGroup.options.find((g) => g.code == 'carrilbicigrupo');
        const cycleLaneLayer = cycleLaneGroup.options.find((o) => o.code == 'carrilsbici');
        if (travelMode == 'BICYCLING') {
          this.setShowLayerTooltip(true);
          this.setMsgLayers(false);
          cycleLaneLayer.selected = false;
        } else {
          cycleLaneLayer.selected = true;
          this.setShowLayerTooltip(false);
        }
        const { layerMenu } = this.refs;
        layerMenu.selectOpcion(cycleLaneLayer);
      }
    },
    directionRender(response, travelMode) {
      if (response.status == 'OK') {
        response = this.deleteDuplicateRouteGoogleApi(response);
        this.generateRecentRoutes(response);
        GoogleRoutesApi.savePlace(this.fromObject);
        GoogleRoutesApi.savePlace(this.toObject);
        this.setDisplayMode('route');
        this.setOpened(false);
        const main_text = this.getPlaceText('main_text', this.fromObject);
        const secondary_text = this.getPlaceText('main_text', this.toObject);
        this.setSelectedLocation({
          main_text: main_text && main_text != '' ? main_text : this.$route.query.origin,
          secondary_text: secondary_text && secondary_text != '' ? secondary_text : this.$route.query.destination,
        });
        if (travelMode == 'TRANSIT' && this.preferencias.tipoRuta == 'ACCESSIBLE-ROUTES') {
          GoogleRoutesApi.orderByAccessibily(response).then((response) => {
            this.refs.routePanel.setRoutes(response.routes);
          });
        } else if (travelMode !== 'DRIVING') {
          this.refs.routePanel.setRoutes(response.routes);
        } else if (travelMode === 'DRIVING') {
          this.getSostenibleRoute(response);
          return;
        } else {
          this.refs.routePanel.setRoutes(response.routes);
        }
        this.setSelectedRouteIndex(0);
        this.drawRoute(0);
        this.$bvModal.hide('modal-planificador');
      }
      if (response.status != 'OK') {
        this.$swal(this.$t('no_routes_available'), '', 'warning');
      }
    },
    deleteDuplicateRouteGoogleApi(response) {
      const routes = [];
      response.routes.forEach((route) => {
        if (route && route?.overview_polyline) {
          const index = routes.findIndex((r) => r?.overview_polyline === route.overview_polyline);
          if (index === -1) {
            routes.push(route);
          }
        }
      });
      routes.sort((a, b) => {
        const dateA = new Date(a.legs[0]?.arrival_time?.value);
        const dateB = new Date(b.legs[0]?.arrival_time?.value);
        return dateA - dateB;
      });
      return {
        ...response,
        routes,
      };
    },
    getSostenibleRoute(carResponse) {
      const googleRoutesApi = new GoogleRoutesApi(this.$i18n.locale);
      const options = {
        travelMode: 'TRANSIT',
      };
      googleRoutesApi.route(this.fromObject.place_id, this.toObject.place_id, options).then((response, status) => {
        if (response.status === 'OK') {
          carResponse.routes.unshift(this.getFirstRouteWithTransit(response.routes));
          carResponse = this.deleteDuplicateRouteGoogleApi(carResponse);
          this.refs.routePanel.setRoutes(carResponse.routes, 'DRIVING');
          this.setSelectedRouteIndex(1);
          this.drawRoute(1);
          this.$bvModal.hide('modal-planificador');
        }
      });
    },
    getFirstRouteWithTransit(routes) {
      let routeWithTransit = null;
      routes.forEach((route) => {
        const { steps } = route.legs[0];
        steps.forEach((step) => {
          if (step.travel_mode === 'TRANSIT') {
            routeWithTransit = route;
            routeWithTransit.travelMode = 'TRANSIT';
          }
        });
      });
      return routeWithTransit;
    },
    generateRecentRoutes() {
      const recentRoutes = localStorage.recentsRoutes ? JSON.parse(localStorage.recentsRoutes) : [];
      recentRoutes.push({
        fromValue: this.fromValue,
        fromObject: this.fromObject,
        toValue: this.toValue,
        toObject: this.toObject,
        whenToGo: this.whenToGo,
        transitDate: this.transitDate,
        transitTime: this.transitTime,
        avoidTolls: this.avoidTolls,
        avoidHighways: this.avoidHighways,
        modo: this.modo,
      });
      this.setRecentRoutes(recentRoutes);
    },
    closePanel() {
      this.setPanelOpened(false);
    },
    resetPlanificador(response) {
      this.setOpened(false);
      this.setSelectedLocation(null);
      this.setPreferencesOpened(false);
      this.setFromInput(false);
      this.setToInput(false);
      this.setFromValue('');
      this.setToValue('');
      this.setFromObject(null);
      this.setToObject(null);
      this.clearRoute();
      this.refs.layerMenu.deleteAllLayers();
    },
    showHelpClear() {
      this.clearRoute();
      this.resetPlanificador();
      this.closePanel();
    },
    async openPlan(origin_id, destination_id, origin, destination, origin_txt, destination_txt, travelMode) {
      this.setOpened(true);
      const googleRoutesApi = new GoogleRoutesApi(this.$i18n.locale);
      let travelModeGoogle = travelMode;
      if (travelMode == 'BEST-ROUTE') {
        this.setFromObject({ place_id: origin_id });
        this.setToObject({ place_id: destination_id });
        const bestRoute = await this.getBestRoute();
        travelModeGoogle = bestRoute.bestMode;
        this.setSelectedRouteIndex(0);
        this.drawRoute(0);
      } else {
        travelModeGoogle = travelMode;
      }
      this.changeModo(travelModeGoogle);
      const options = {
        travelMode: travelModeGoogle,
      };
      if (origin_id && destination_id) {
        googleRoutesApi.getAddresByPlaceId(origin_id, destination_id).then((results) => {
          const fromObject = {
            ...results.placeFrom,
            main_text: `${results.placeFrom.name}`,
            secondary_text: `${results.placeFrom.formatted_address}`,
          };
          this.setFromObject(fromObject);
          this.setToValue(destination);
          const destinationObject = {
            ...results.placeTo,
            main_text: `${results.placeTo.name}`,
            secondary_text: `${results.placeTo.formatted_address}`,
          };
          this.setToObject(destinationObject);
          this.setFromValue(origin);
          this.setToValue(destination);
          this.recalculateRouteMode(travelModeGoogle);
          this.planRoute();
        });
      } else {
        if (origin) {
          const posOrigin = {
            lat: parseFloat(origin.split(',')[0]),
            lng: parseFloat(origin.split(',')[1]),
          };
          googleRoutesApi.geocode(posOrigin).then((results) => {
            const val = results[0];
            this.setFromValue(origin_txt);
            val.custom_text = {
              main_text: origin_txt,
              secondary_text: `${val.formatted_address}`,
            };
            if (val.custom_text.main_text === val.custom_text.secondary_text) {
              delete val.custom_text;
              val.custom_text = {
                main_text: this.getPlaceText('main_text', val),
                secondary_text: this.getPlaceText('secondary_text', val),
              };
            }
            this.setFromObject(val);
          });
        }
        if (destination) {
          const posDestination = {
            lat: parseFloat(destination.split(',')[0]),
            lng: parseFloat(destination.split(',')[1]),
          };
          googleRoutesApi.geocode(posDestination).then((results) => {
            const val = results[0];
            val.custom_text = {
              main_text: destination_txt,
              secondary_text: `${val.formatted_address}`,
            };
            if (val.custom_text.main_text === val.custom_text.secondary_text) {
              delete val.custom_text;
              val.custom_text = {
                main_text: this.getPlaceText('main_text', val),
                secondary_text: this.getPlaceText('secondary_text', val),
              };
            }
            this.setToValue(destination_txt);
            this.setToObject(val);
          });
        }
      }
      this.$forceUpdate();
      this.hideResults();
    },
    openModalPlafinicador() {
      this.$bvModal.show('modal-planificador');
    },
    cutOffDirection(direction) {
      if (typeof direction === 'string') {
        if (direction.length >= 30) {
          return `${direction.substring(0, 30)}...`;
        }
        return direction;
      }
      return direction;
    },
    activeWhenToGo() {
      this.openWhenToGo = true;
      if (this.transitDate == null && this.transitTime == null) {
        const now = new Date();
        this.setTransitDate(`${now.toISOString().split('T')[0]}T00:00:00.000Z`);
        const hora = now.getHours();
        let minutos = now.getMinutes();
        const redondeo = minutos % 15;
        // eslint-disable-next-line operator-assignment
        minutos = minutos + (15 - redondeo);
        if (minutos == 0) minutos = '00';
        if (minutos == 60) minutos = '00';
        this.setTransitTime(`${hora}:${minutos}`);
      }
    },
    toggleLine(linea) {
      linea.selected = !linea.selected;
      this.selectTransportLine(linea);
    },
    getExamples() {
      const examples = [
        this.getOpcionByProperty('taxi', 'code'),
        this.getOpcionByProperty('marbus', 'code'),
        this.getOpcionByProperty('bicing', 'code'),
        LineaTransportMetro.getLinea('L1'),
        this.getOpcionByProperty('traficoactual', 'code'),
        this.getOpcionByProperty('aparcamientos', 'code'),
      ];
      return examples;
    },
    getExamplesVisible() {
      if (this.viewAllExamplesVisible) return this.getExamples();
      return take(this.getExamples(), 6);
    },
    clearRoute() {
      this.routeMapElements.forEach((element) => {
        toRaw(element).setMap(null);
      });
      this.setRouteMapElements([]);
      this.updateUrl();
    },
    /*
        * Draw route parts manually
        */
    drawRoute(routeIndex) {
      this.clearRoute();
      if (!this.routes || this.routes.length === 0 || this.routes[routeIndex] === undefined) return;
      const selectedRoute = this.routes[routeIndex];
      console.log('selectedRoute', selectedRoute);
      this.toggleCycleLaneLayer(selectedRoute.travelMode);
      this.mapObj.fitBounds(selectedRoute.bounds);
      this.mapObj.setZoom(this.mapObj.getZoom());
      this.mapObj.setCenter({
        lat: selectedRoute.bounds.getCenter().lat(),
        lng: selectedRoute.bounds.getCenter().lng(),
      });
      const { steps } = selectedRoute.legs[0];
      const mapElements = [];
      let zIndex = 1000000;
      steps.forEach((step) => {
        // travel_mode (WALKING, TRANSIT, BICYCLING, DRIVING)
        let strokeColor = '#5D90FC';
        let strokeWeight = 5;
        if (step.travel_mode == 'TRANSIT') {
          strokeColor = step.transit.line.color;
          strokeWeight = 7;
        }
        let stepPath = new google.maps.Polyline({
          path: step.path,
          geodesic: true,
          strokeColor,
          strokeOpacity: 1.0,
          strokeWeight,
          zIndex: zIndex++,
        });
        if (step.travel_mode == 'WALKING') {
          const lineSymbol = {
            path: google.maps.SymbolPath.CIRCLE,
            fillOpacity: 1,
            scale: 3,
          };
          stepPath = new google.maps.Polyline({
            path: step.path,
            icons: [
              {
                icon: lineSymbol,
                offset: '0',
                repeat: '10px',
              },
            ],
            geodesic: true,
            strokeColor: '#5D90FC',
            strokeOpacity: 0,
            strokeWeight: 4,
            zIndex: zIndex++,
          });
        }
        stepPath.setMap(this.mapObj);
        mapElements.push(stepPath);
        const point = new google.maps.Marker({
          position: step.start_point,
          icon: {
            url: '/images/icons/checkpoint.svg',
          },
          zIndex: zIndex++,
          map: this.mapObj,
        });
        mapElements.push(point);
      });
      const marker_a = new google.maps.Marker({
        position: steps[0].start_point,
        icon: {
          url: '/images/markers/marker_a.svg',
          anchor: new google.maps.Point(15, 50),
        },
        zIndex: zIndex++,
        map: this.mapObj,
      });
      mapElements.push(marker_a);
      const infowindowMarkerA = new google.maps.InfoWindow({
        content: this.textInfoMarker(selectedRoute.legs[0].start_address, 'origin'),
        ariaLabel: 'markerA',
      });
      marker_a.addListener('click', () => {
        infowindowMarkerA.open({
          anchor: marker_a,
          map: this.mapObj,
        });
      });
      const marker_b = new google.maps.Marker({
        position: steps[steps.length - 1].end_point,
        icon: {
          url: '/images/markers/marker_b.svg',
          anchor: new google.maps.Point(15, 50),
        },
        zIndex: zIndex++,
        map: this.mapObj,
      });
      mapElements.push(marker_b);
      const infowindowMarkerB = new google.maps.InfoWindow({
        content: this.textInfoMarker(selectedRoute.legs[0].end_address, 'destination'),
        ariaLabel: 'markerB',
      });
      marker_b.addListener('click', () => {
        infowindowMarkerB.open({
          anchor: marker_b,
          map: this.mapObj,
        });
      });
      const point = new google.maps.Marker({
        position: steps[steps.length - 1].end_point,
        icon: {
          url: '/images/icons/checkpoint.svg',
        },
        zIndex: zIndex++,
        map: this.mapObj,
      });
      mapElements.push(point);
      this.setRouteMapElements(mapElements);
    },
    textInfoMarker(address, point) {
      const contentString = '<div>'
      + '<div id="siteNotice">'
      + '</div>'
      + `<h4 id="firstHeading" class="firstHeading">${this.$t(point)}</h4>`
      + '<div>'
      + `<p>${address}</p>`
      + '</div>'
      + '</div>';
      return contentString;
    },
  },
  created() {
    this.refs = this.isWidget === true ? this.$root.$children[0].$children[0].$children[0].$refs : this.$root.$children[0].$children[0].$refs;
    if (this.preferencias?.tipoMobilitat) this.setModo(this.preferencias.tipoMobilitat);
    if (this.$route.query.destination !== undefined || this.$route.query.action !== undefined) {
      this.setTrafficIncidentsReaded(true);
      this.setTrafficIncidentsNew(0);
      this.setTrafficIncidentsFirstRead(false);
      const travelMode = (this.$route.query.travelMode) ? this.$route.query.travelMode : 'TRANSIT';
      this.openPlan(
        this.$route.query.origin_id,
        this.$route.query.destination_id,
        decodeURIComponent(this.$route.query.origin),
        decodeURIComponent(this.$route.query.destination),
        this.$route.query.origin_txt,
        this.$route.query.destination_txt,
        travelMode,
      );
      this.selectMyPos('from');
    }
  },
  mounted() {
    this.departureType = this.whenToGo;
  },
};
</script>

import { findIndex, remove, take } from 'lodash';

const state = () => ({
  mapObj: null,
  mapFullScreen: false,
  isWidget: false,
  isAmericCups: false,
  optionWidget: null,
  widgetCallback: null,
  isOpenInfoPanel: false,
  geolocationActive: false,
  currentPos: { lat: 41.385, lng: 2.173 },
  intZoom: 15,
  lastNearTransportPoint: null,
  markerCurrentPos: null,
  dataLayers: {
    active: false,
  },
  activeLayers: [],
  fixedLayers: [],
  nameFixedGroup: '',
  futureTrafficDate: '',
  futureTrafficHour: '',
  futureTraffic: false,
  recentLoadedLayers: [],
  recentLoadedLines: [],
  directionIcons: [],
  directionIconsShuttle: [],
  msgLayers: true,
  showLayerTooltip: false,
});

const mutations = {
  setMap(state, map) {
    state.mapObj = map;
  },
  setMapFullScreen(state, mapFullScreen) {
    state.mapFullScreen = mapFullScreen;
  },
  setWidget(state, isWidget) {
    state.isWidget = isWidget;
  },
  setOptionWidget(state, optionWidget) {
    state.optionWidget = optionWidget;
  },
  setWidgetCallback(state, widgetCallback) {
    state.widgetCallback = widgetCallback;
  },
  setIsOpenInfoPanel(state, isOpenInfoPanel) {
    state.isOpenInfoPanel = isOpenInfoPanel;
  },
  setGeolocationActive(state, geolocationActive) {
    state.geolocationActive = geolocationActive;
  },
  setCurrentPos(state, newPos) {
    state.currentPos = newPos;
  },
  setMarkerCurrentPos(state, markerCurrentPos) {
    state.markerCurrentPos = markerCurrentPos;
  },
  setLastNearTransportPoint(state, lastNearTransportPoint) {
    state.lastNearTransportPoint = lastNearTransportPoint;
  },
  setIsAmericCups(state, isAmericCups) {
    state.isAmericCups = isAmericCups;
  },
  setIntZoom(state, intZoom) {
    state.intZoom = intZoom;
  },
  // setDataLayers
  // setActiveLayers
  cleanActiveLayers(state, clearInterestLayers = true) {
    const interestLayers = [
      'traficoactual_gen',
      'near_public_transport',
      'bajasemisiones_general',
      'transport_accessible',
    ];
    for (const c in state.activeLayers) {
      if (interestLayers.indexOf(state.activeLayers[c].code) < 0 || clearInterestLayers) {
        state.activeLayers.splice(c, 1);
      }
    }
  },
  addActiveLayer(state, newLayer) {
    if (findIndex(state.activeLayers, (o) => o == newLayer) < 0) {
      state.activeLayers.push(newLayer);
      if (findIndex(state.recentLoadedLayers, (o) => o == newLayer) < 0) {
        state.recentLoadedLayers.unshift(newLayer);
        state.recentLoadedLayers = take(state.recentLoadedLayers, 10);
        localStorage.recentLoadedLayers = JSON.stringify(state.recentLoadedLayers);
      }
      if (newLayer.linea) {
        remove(state.recentLoadedLines, (o) => {
          return (newLayer.linea && o.linea == newLayer.linea);
        });
        state.recentLoadedLines.unshift(newLayer);
        localStorage.recentLoadedLines = JSON.stringify(take(state.recentLoadedLines, 5));
      }
    }
  },
  addFixedLayer(state, layerMenu) {
    for (const c in layerMenu) {
      const grupo = layerMenu[c];
      for (const d in grupo.options) {
        const opcion2 = grupo.options[d];
        if (opcion2?.fixed) {
          opcion2.group = state.nameFixedGroup;
          opcion2.selected = false;
          state.fixedLayers.push(opcion2);
        }
        for (const e in opcion2.options) {
          const opcion3 = opcion2.options[e];
          if (opcion3?.fixed) {
            opcion3.group = state.nameFixedGroup;
            opcion3.selected = false;
            state.fixedLayers.push(opcion3);
          }
        }
      }
    }
  },
  setNameFixedGroup(state, nameFixedGroup) {
    state.nameFixedGroup = nameFixedGroup;
  },
  setActiveLayers(state, layers) {
    state.activeLayers = layers;
  },
  resetActiveLayers(state) {
    state.activeLayers = [];
  },
  setFutureTraffic(state, future) {
    state.futureTraffic = future;
  },
  setFutureTrafficDate(state, date) {
    state.futureTrafficDate = date;
  },
  setFutureTrafficHour(state, hour) {
    state.futureTrafficHour = hour;
  },
  setRecentLoadedLayers(state, layers) {
    state.recentLoadedLayers = layers;
  },
  setRecentLoadedLines(state, lines) {
    lines = take(lines, 5);
    localStorage.recentLoadedLines = JSON.stringify(lines);
    state.recentLoadedLines = lines;
  },
  setDirectionIcons(state, directionIcons) {
    state.directionIcons = directionIcons;
  },
  setDirectionIconsShuttle(state, directionIconsShuttle) {
    state.directionIconsShuttle = directionIconsShuttle;
  },
  setMsgLayers(state, visible) {
    state.msgLayers = visible;
    localStorage.msgLayers = visible;
  },
  setShowLayerTooltip(state, visible) {
    state.showLayerTooltip = visible;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};

<template>
  <div>
    <div class="open-preferences">
      <img @click="$bvModal.show('modal-preferences');" src="/images/icons/preferences.svg">
    </div>

    <!-- Modal Preferences  -->
    <b-modal
      id="modal-preferences"
      scrollable
    >
      <template #modal-header>
        <div class="m-header">
          <img @click="$bvModal.hide('modal-preferences')" class="back-arrow-icon" src="/images/icons/back-arrow.svg">
          <span @click="$bvModal.hide('modal-preferences')" class="title">{{ $t('route_preferences') }}</span>
        </div>
      </template>

      <b-container fluid>

        <div class="m-body">
          <div class="info-preferences">
            {{ $t('info_preferences') }}
          </div>
          <!-- Type Route -->
          <div class="content-selector">
            <div class="subtitle">{{ $t('by_type_of_route') }}</div>
            <ul>
              <li>
                <p-radio class="p-default p-round" name="tipoRuta" v-model="preferencias.tipoRuta" value="BEST-ROUTE">{{ $t('best_route') }}</p-radio>
              </li>
              <li>
                <p-radio class="p-default p-round" name="tipoRuta" v-model="preferencias.tipoRuta" value="LESS_WALKING">{{ $t('less_walking') }}</p-radio>
              </li>
              <li>
                <p-radio class="p-default p-round" name="tipoRuta" v-model="preferencias.tipoRuta" value="FEWER_TRANSFERS" color="primary-o">{{ $t('fewer_transfers') }}</p-radio>
              </li>
              <li>
                <p-radio class="p-default p-round" name="tipoRuta" v-model="preferencias.tipoRuta" value="ACCESSIBLE-ROUTES" color="primary-o">{{ $t('accessible_routes') }}</p-radio>
                <div class="text-description">
                  {{ $t('transport_public_priority') }}
                </div>
              </li>
            </ul>
          </div>
          <div class="separator"></div>
          <!-- Type Transport -->
          <div class="content-selector">
            <div class="subtitle">{{ $t('by_type_of_transport') }}</div>
            <ul>
              <li>
                <p-radio
                  class="p-default p-round"
                  name="tipomobilitat"
                  v-model="preferencias.tipoMobilitat"
                  value="TRANSIT"
                  color="primary-o">
                    {{ $t('public_transport') }}
                </p-radio>
                <div class="content-selector-checks" v-if="preferencias.tipoMobilitat === 'TRANSIT'">
                  <ul>
                    <li>
                      <p-check class="p-icon" v-model="preferencias.transitModes" value="SUBWAY">
                        <i class="icon bcn-icon-ok-bold" slot="extra"></i>
                        {{ $t('metro') }}
                      </p-check>
                    </li>
                    <li>
                      <p-check class="p-icon" v-model="preferencias.transitModes" value="BUS">
                        <i class="icon bcn-icon-ok-bold" slot="extra"></i>
                        {{ $t('bus') }}
                      </p-check>
                    </li>
                    <li>
                      <p-check class="p-icon" v-model="preferencias.transitModes" value="TRAIN">
                        <i class="icon bcn-icon-ok-bold" slot="extra"></i>
                        {{ $t('fgc_trains') }}
                      </p-check>
                    </li>
                    <li>
                      <p-check class="p-icon" v-model="preferencias.transitModes" value="RAIL">
                        <i class="icon bcn-icon-ok-bold" slot="extra"></i>
                        {{ $t('local_trains') }}
                      </p-check>
                    </li>
                    <li>
                      <p-check class="p-icon" v-model="preferencias.transitModes" value="TRAM">
                        <i class="icon bcn-icon-ok-bold" slot="extra"></i>
                        {{ $t('trams') }}
                      </p-check>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <p-radio class="p-default p-round" name="tipomobilitat" v-model="preferencias.tipoMobilitat" value="DRIVING" color="primary-o">{{ $t('car_moto') }}</p-radio>
              </li>
              <li>
                <p-radio class="p-default p-round" name="tipomobilitat" v-model="preferencias.tipoMobilitat" value="BICYCLING" color="primary-o">{{ $t('bici') }}</p-radio>
              </li>
              <li>
                <p-radio class="p-default p-round" name="tipomobilitat" v-model="preferencias.tipoMobilitat" value="WALKING" color="primary-o">{{ $t('walk') }}</p-radio>
              </li>
            </ul>
          </div>
        </div>

      </b-container>

      <template #modal-footer>
        <div class="m-footer">
          <button class="btn-apply" @click="savePreferences()">{{ $t('apply') }}</button>
          <button class="btn-cancel" @click="$bvModal.hide('modal-preferences')">{{ $t('clean') }}</button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { find } from 'lodash';

export default {
  name: 'PreferencesModal',
  data() {
    return {};
  },
  created() {
    this.refs = this.isWidget === true ? this.$root.$children[0].$children[0].$children[0].$refs : this.$root.$children[0].$children[0].$refs;
  },
  computed: {
    ...mapState('transport', [
      'preferencias',
    ]),
  },
  methods: {
    ...mapMutations({
      setPreferencias: 'transport/setPreferencias',
    }),
    savePreferences() {
      if (this.preferencias.tipoMobilitat != 'TRANSIT') {
        this.preferencias.transitModes = [];
      }

      this.setPreferencias(this.preferencias);
      const modo = this.preferencias.tipoRuta === 'BEST-ROUTE' ? this.preferencias.tipoRuta : this.preferencias.tipoMobilitat;
      this.refs.planificador.recalculateRouteMode(modo);
      this.$bvModal.hide('modal-preferences');
    },
  },
};
</script>
<style>
  .modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    position: fixed;
    z-index: 100000;
  }
  .modal-dialog-scrollable {
    max-height: 100% !important;
  }
  .modal-dialog-scrollable .modal-content {
    max-height: 100% !important;
  }
  .modal-content {
    background-color: #F6F6F5;
    border: none;
    border-radius: inherit;
  }
  .modal-body {
    padding: 0;
  }
</style>

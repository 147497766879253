<template>
  <div></div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import IncidentsMixins from './mixins/IncidentsMixins';

export default {
  name: 'TrafficIncidents',
  mixins: [
    IncidentsMixins,
  ],
  data() {
    return {
      interval: null,
      cont_test: 0,
      mode: 'notest',
    };
  },
  computed: {
    ...mapState('incidents', [
      'trafficIncidents',
      'forceClose',
    ]),
    ...mapState('planroute', [
      'opened',
    ]),
  },
  methods: {
    ...mapMutations({
      setTrafficIncidents: 'incidents/setTrafficIncidents',
      setTrafficIncidentsReaded: 'incidents/setTrafficIncidentsReaded',
      setTrafficIncidentsNew: 'incidents/setTrafficIncidentsNew',
      setTrafficIncidentsFirstRead: 'incidents/setTrafficIncidentsFirstRead',
    }),
    close() {
      this.close();
    },
  },
  mounted() {
    this.getIncidents();
  },
  unmounted() {
    this.setTrafficIncidentsReaded(true);
    this.setTrafficIncidentsNew(0);
    this.setTrafficIncidentsFirstRead(false);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

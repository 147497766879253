<template>
    <div>
        <div id="confirmation-navigate-lines-parades" v-if="open">
            <div class="message">
                <div class="title">{{ title }}</div>
                <div class="text">
                    <p>{{ description }}</p>
                </div>
                <div class="actions">
                    <button @click="close()">{{$t('continue')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'warning',
  data() {
    return {};
  },
  computed: {
    ...mapState('warning', [
      'title',
      'description',
      'open',
    ]),
  },
  methods: {
    ...mapMutations({
      setOpen: 'warning/setOpen',
    }),
    close() {
      this.setOpen(false);
    },
  },
};
</script>

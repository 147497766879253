<template>
  <div id="shortcut-banner" v-if="show && showInstallButton" class="shortcut-banner">
    <div class="icon">
      <img src="/images/icons/short-cut.svg" />
    </div>
    <div class="content">
      <p class="title">{{ $t('home_page_title') }}</p>
      <p class="subtitle">{{ $t('home_page_description') }}</p>
    </div>
    <div class="action">
      <img @click="closeBanner" class="btn-close" src="/images/icons/close.svg" />
      <button @click="installPWA" class="btn">{{ $t('home_page_button') }}</button>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      showInstallButton: false,
      deferredPrompt: null,
      clicked: false,
    };
  },
  props: ['show'],
  methods: {
    closeBanner() {
      this.showInstallButton = false;
      localStorage.setItem('installPWA', 'false');
    },
    createShortcut() {
      if (localStorage.getItem('installPWA')) {
        this.showInstallButton = false;
        return;
      }

      if (!('onbeforeinstallprompt' in window)) {
        this.showInstallButton = true;
      } else {
        window.addEventListener('beforeinstallprompt', (e) => {
          e.preventDefault();
          this.deferredPrompt = e;
          this.showInstallButton = !this.clicked;
        });
      }
    },
    installPWA() {
      this.clicked = true;
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            localStorage.setItem('installPWA', 'true');
          }
          this.resetInstallState();
        });
      } else {
        this.addToHomeScreenHelp();
        this.closeBanner();
      }
    },
    addToHomeScreenHelp() {
      const device = this.isIOS() ? 'ios' : 'android';
      const icon = `/images/help/action-icon-${device}.png`;
      const msg = `<p class="mt-3"><b>1.</b> ${this.$t(`add_to_home_screen_${device}_1`)}<img class="ml-2 action-icon" src="${icon}"></p><p><b>2.</b> ${this.$t(`add_to_home_screen_${device}_2`)}</p>`;
      this.$swal({
        html: msg,
        title: this.$t('add_to_home_screen_how'),
      });
    },
    isIOS() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    },
    resetInstallState() {
      this.showInstallButton = false;
      this.deferredPrompt = null;
    },
  },
  mounted() {
    this.createShortcut();
  },
};
</script>

const state = () => ({
  description: '',
  open: false,
  type: '',
  title: 'Avís',
  icon: '',
});

const mutations = {
  setTitle(state, title) {
    state.title = title;
  },
  setDescription(state, description) {
    state.description = description;
  },
  setOpen(state, open) {
    state.open = open;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};

/* eslint-disable */

import axios from 'axios';
import { getApiUri } from '../api/util';
import store from '../store/index';
import api from '../api/api';

window.LineaTransportAerobus = function() {

}

LineaTransportAerobus.prototype.loadTransport = function () {
  api.get(`${getApiUri()}otherbuslinies/?RECORRIDO=0`).then(function (response) {
    var results = response.data.features;
    for (var c in results) {
      var result = results[c];
      var linea = {
        linea: result.properties.Nom,
        descripcio: result.properties.DescIntern,
        color: LineaTransportAerobus.getColor(result.properties.Nom),
        type: 'aerobus',
        icon: 'csvicon-bus-stop',
        estacions: {},
        reverse: false,
        selected: false,
      }
      if (linea.linea.indexOf("A") == 0) {
        store.commit('transport/setLineas', {
          aerobus: {
            [result.properties.Nom]: linea,
          },
        });
      }
    }
    const ordered = {};
    Object.keys(store.state.transport.lineas.aerobus).sort().forEach(function(key) {
      ordered[key] = store.state.transport.lineas.aerobus[key];
    });

    store.commit('transport/setLineas', {
      aerobus: null,
    });

    store.commit('transport/setLineas', {
      aerobus: ordered,
    });
  });


}

LineaTransportAerobus.prototype.loadStation = function (linea) {
  var promise = new Promise(function(resolve, reject) {
    api.get(`${getApiUri()}otherbusparades/${linea}/`).then(function (response) {
      var results = response.data.features;
      for (var c in results) {
        var result = results[c];
        var parada = {
          nom: result.properties.Descripcio,
          codi: result.properties.Codigo,
          ordre: parseInt(result.properties.Ordre),
          lineas: result.properties.Linies,
          municipi: result.properties.Municipi,
          geometry: result.geometry
        }

        store.commit('transport/setLineas', {
          aerobus: {
            [linea]: {
              estacions: {
                [result.properties.Ordre]: parada,
              },
            },
          },
        });
      }
      resolve(response);
    });
  });
  return promise;
}

LineaTransportAerobus.getLinea = function(linea) {
  for (var c in store.state.transport.lineas.aerobus) {
    if (store.state.transport.lineas.aerobus[c].linea==linea) {
      return store.state.transport.lineas.aerobus[c];
    }
  }
  return {};
}

LineaTransportAerobus.getStation = function(linea, code) {
  for (var c in linea.estacions) {
    if (linea.estacions[c].codi==code) {
      return linea.estacions[c];
    }
  }
  return {};
}

LineaTransportAerobus.getColor = function(linea) {
  return 'e00000'
}

LineaTransportAerobus.displayInfo = function(feature) {
  const refs = store.state.map.isWidget === true ? window.app.$children[0].$children[0].$children[0].$refs : window.app.$children[0].$children[0].$refs;
  refs.infoPanel.initInfoPanel();
  store.commit('infopanel/setInfoPanel', {
    datos: null,
  });

  store.commit('infopanel/setInfoPanel', {
    //title: `(${feature.getProperty('Codigo')}) ${feature.getProperty('Descripcio')}`,
    title: feature.getProperty('Descripcio'),
    linea: feature.getProperty('Linea'),
    nom_linea: feature.getProperty('Linea'),
    feature: feature,
    type: 'aerobus',
    visible: true,
    datos: [
      {'label': window.app.$t('stop'), 'data': feature.getProperty('Codigo')},
      {'label': window.app.$t('lines'), 'data': feature.getProperty('Linies')}
    ],
  });

  refs.infoPanel.initPanel();
}

LineaTransportAerobus.loadLineMap = function(linea_name, zoom, station_name) {

  var linea = LineaTransportAerobus.getLinea(linea_name);

  window._mtm.push({
    'event': 'comshiva',
    'eventcategory': 'ComShiVa',
    'eventaction': 'Transport',
    'eventname': `Aerobus: ${linea.linea} ${linea.descripcio}`
  });

  var configStation = Object.assign({}, {
    ZOOM: 16,
    zoomPoint: 17,
    CENTER: new google.maps.LatLng(41.404, 2.173),
    MAP_TYPE: google.maps.MapTypeId.ROADMAP,
    REGION: 'ca',
    BUFFER_DISTANCE: 0.2, // Km
    savePosition: true,
    displayInfo: true,
    setMarkerSearch: true,
    adaptativeMarkers: true,
    iconURL: "/images/markers/publico",
    forceZoom: false,
    mapTypeControl: false,
    minZoomLevel: 14,
    visible: true,
    maxZoomClusteringLevel: 16,
    clusterIconSize: [24, 35],
  });

  configStation.callback = {
    displayInfo: LineaTransportAerobus.displayInfo,
  }

  configStation.style = {
    strokeColor: "#"+linea.color,
    fillColor: "#"+linea.color,
    strokeOpacity: 1,
    strokeWeight: 2,
    path: "/images/markers/publico",
    marker: "parada_bus.svg",
    icon: "parada_bus.svg",
    clusterMarker: "transport.svg",
  };

  configStation.name = "otherbusparades" + linea.linea;

  var configLine = Object.assign({}, {
    ZOOM: 16,
    zoomPoint: 17,
    CENTER: new google.maps.LatLng(41.404, 2.173),
    MAP_TYPE: google.maps.MapTypeId.ROADMAP,
    REGION: 'ca',
    BUFFER_DISTANCE: 0.2, // Km
    savePosition: true,
    setMarkerSearch: true,
    adaptativeMarkers: true,
    iconURL: "/images/markers/",
    forceZoom: false,
    mapTypeControl: false,
  });

  configLine.style = {
    strokeColor: "#"+linea.color,
    fillColor: "#"+linea.color,
    strokeOpacity: 1,
    strokeWeight: 2,
  };

  configLine.name = "otherbuslinies" + linea.linea;

  var dataLayerStation = new DataLayer(configStation, store.state.map.mapObj);
  var dataLayerLine = new DataLayer(configLine, store.state.map.mapObj);

  var layerUrlLine = `${getApiUri()}otherbuslinies/${linea.linea}/`;
  var layerUrlStacions = `${getApiUri()}otherbusparades/${linea.linea}/`;

  Promise.all([
    dataLayerLine.addGeoJsonDataLayer(layerUrlLine),
    dataLayerStation.addGeoJsonDataLayer(layerUrlStacions)
  ]).then(() => {
    if (station_name!=undefined) {
      var station = LineaTransportAerobus.getStation(linea, station_name);
      store.state.map.mapObj.setCenter(new google.maps.LatLng(station.geometry.coordinates[1],station.geometry.coordinates[0]));
      store.state.map.mapObj.setZoom(configStation.zoomPoint);
    }
    if (zoom=='line') {
      dataLayerLine.cropToBounds();
      if (store.state.map.mapObj.getZoom()<12) {
        store.state.map.mapObj.setZoom(12);
      }
    }
    linea.selected = true;
    linea.group = "public_transport";
    store.commit('map/addActiveLayer', linea);
  });

}

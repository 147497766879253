export default [
  {
    linia: 'L4',
    missatges: {
      ca: 'Tall a l\'L2 i L4 per obres de millora. Del 25 de juny al 25 dl\'agost.',
      es: 'Corte en la L2 y la L4 por obras de mejora. Del 25 de junio al 25 de agosto.',
      en: 'L2 and L4 metro services closed for improvement work. L4 metro line. From 25 June to 25 August.',
    },
    links: {
      ca: 'https://www.tmb.cat/ca/-/avis-talls-estiu-2024',
      es: 'https://www.tmb.cat/es/-/avis-talls-estiu-2024',
      en: 'https://www.tmb.cat/en/-/avis-talls-estiu-2024 ',
    },
    data_inici: '2024-06-24T00:00:00.000Z',
    data_fi: '2024-08-25T23:59:59.000Z',
  },
  {
    linia: 'L2',
    missatges: {
      ca: 'Tall a l\'L2 i L4 per obres de millora. Del 25 de juny al 25 dl\'agost.',
      es: 'Corte en la L2 y la L4 por obras de mejora. Del 25 de junio al 25 de agosto.',
      en: 'L2 and L4 metro services closed for improvement work. L4 metro line. From 25 June to 25 August.',
    },
    links: {
      ca: 'https://www.tmb.cat/ca/-/avis-talls-estiu-2024',
      es: 'https://www.tmb.cat/es/-/avis-talls-estiu-2024',
      en: 'https://www.tmb.cat/en/-/avis-talls-estiu-2024 ',
    },
    data_inici: '2024-06-24T00:00:00.000Z',
    data_fi: '2024-08-25T23:59:59.000Z',
  },
  {
    linia: 'T4',
    missatges: {
      ca: 'Alteracions a l\'T4 del TRAM. Consulta alternatives de transport.',
      es: 'Alteraciones a la T4 del tranvía. Consulta alternativas de transporte.',
      en: 'Public transport disruptions. T4 Tram line. Check out their alternatives. ',
    },
    links: {
      ca: 'https://www.atm.cat/ca/w/np_dues-obres-ferrovi%C3%A0ries-coincidiran-aquest-estiu-la-renovaci%C3%B3-de-via-a-l-l4-de-metro-per-garantir-un-servei-de-m%C3%A0xima-qualitat-i-la-connexi%C3%B3-del-tramvia-que-afectar%C3%A0-la-t4?p_l_back_url=%2Fca%2Fcomunicacio%2Fsala-de-premsa%2Fnotes-de-premsa',
      es: 'https://www.atm.cat/es/w/-camp-tall-l%C3%ADnies-l4-i-t4-1?p_l_back_url=%2Fca%2Fcomunicacio%2Fcampanyes',
      en: 'https://www.atm.cat/en/w/-camp-tall-l%C3%ADnies-l4-i-t4-2?p_l_back_url=%2Fca%2Fcomunicacio%2Fcampanyes',
    },
    data_inici: '2023-06-26T00:00:00.000Z',
    data_fi: '2023-08-31T00:00:00.000Z',
  },
];

export default {
  ca: {
    // General
    loader_message: 'La informació<br>s\'està carregant.<br>Espereu un moment.',
    cancel: 'Cancel·la',
    link_contact: 'https://w10.bcn.cat/APPS/irsconsultesWeb/continuar.executant.do?i=c&origen=WEB_BARCELONA_CAT&tema=81&element=1121',
    link_accessibility: 'https://www.barcelona.cat/ca/accessibilitat',
    link_legal: 'https://www.barcelona.cat/ca/avis-legal',
    link_travel_card: 'https://www.holabarcelona.com/ca/tickets/hola-bcn-barcelona-travel-card',
    yes: 'Si',
    geolocation_msg: 'No s\'ha pogut determinar la seva localització. Ha de tenir activada la geolocalització al seu dispositiu mòbil i autoritzar al Com s\'hi va per ubicar-li.',
    // Transports
    metro: 'Metro',
    bus: 'Bus',
    local_trains: 'Rodalies',
    fgc_trains: 'Ferrocarrils',
    trams: 'Tramvia',
    funicular: 'Funicular',
    aerobus: 'Aerobús',
    // Main Menu
    route_preferences: 'Preferències',
    maps_and_lines: 'Mapes i línies',
    traffic_incidents: 'Incidències',
    help: 'Ajuda',
    help_planroute_demo: 'Com navegar',
    contact: 'Contacte',
    legal_disclaimer: 'Avís legal',
    accessibility: 'Accessibilitat',
    recommendations: 'Recomanacions',
    see_line_map: 'Mostra la línia al mapa',
    recent: 'Recents',
    near: 'A prop meu',
    // Search
    layers: 'Capes',
    search_layers: 'Cerca capes',
    search_line: 'Cerca una línia',
    search_info: 'Cerca una informació',
    station: 'Estació',
    bus_stop: 'Parada de bus',
    local_train_station: 'Estació de Rodalies',
    fgc_train_station: 'Estació de FGC',
    tram_stop: 'Parada del Tram',
    no_results: 'No s\'han trobat resultats.',
    try_write_new_word: "Prova d'escriure-ho de nou o cerca una nova paraula",
    search_examples: 'Parades de taxi, carrils bici...',
    search_interest_items: 'Cerca elements que siguin del teu interès:',
    // Layer menu
    active_information: 'Informació activa',
    select_all: 'Selecciona-ho tot',
    see_all_metro_lines: 'Mostra totes les línies de metro',
    see_all_bus_lines: 'Mostra totes les línies de bus',
    see_all_fgc_train_lines: 'Mostra totes les línies de ferrocarrils',
    see_all_local_train_lines: 'Mostra totes les línies de rodalies',
    see_all_trams_lines: 'Mostra totes les línies de tramvia',
    shows_on_map: 'Mostra al mapa',
    general_interest: 'Interès general',
    traffic_state: 'Estat del trànsit',
    near_public_transport: 'Transport públic a la vora',
    low_emissions_zone: 'Zona de baixes emissions',
    accessible_transport: 'Transport accessible',
    recover_recent_layers: 'Recupera les capes recents',
    view_more: 'Veure més',
    view_less: 'Veure menys',
    active_layers: 'Capes actives',
    delete_all: 'Eliminar totes',
    no_layers_active: 'Afegeix elements del teu interès',
    include_more_layers: 'Incloure',
    notice_consult_lines: 'Estàs deixant d’incloure capes al mapa per anar a consultar la informació de línies i parades de transport públic.',
    show_layers: 'Opcions del mapa',
    all_layers: 'Totes les capes',
    by_car: 'En cotxe',
    bicycle_mobility: 'Mobilidat en bici',
    mobility_foot: 'Mobilitat a peu',
    cab: 'Taxi',
    cableway: 'Telefèric',
    locate_lines_stops: 'Localitza línies i parades',
    turisme: 'Informació turística',
    all_traffic: 'Tot el trànsit',
    all_bici: 'Tot mobilitat en bicicleta',
    all_foot: 'Tot a peu',
    all_metro: 'Tot metro',
    all_bus: 'Tot bus',
    all_fgc: 'Tot ferrocarrils',
    all_rodalies: 'Tot rodalies',
    all_tram: 'Tot tramvia',
    map_options: 'Opcions del mapa',
    // Walk layers
    walk: 'A peu',
    pedestrian_zones: 'Zones de vianants',
    zones_30: 'Zones 30',
    escalators: 'Escales mecàniques',
    traffic_calmed_streets: 'Carrers pacificats',
    widened_pavements: 'Ampliació de voreres',
    sides_roads_closed_traffic: 'Laterals tallats al tránsit',
    // Bici layers
    bici: 'Bici',
    cycle_lanes: 'Carrils bici',
    aparacment_segur_bici: 'Aparcaments segurs Bicis',
    carrilsbicimunicipis: 'Altres municipis',
    new_bicycle_lanes: 'Bicienllaç',
    cycle_lanes_under_construction: 'Carrils bici en construcció',
    roads_suitable_for_cycling: 'Vies pedalables',
    green_route: 'Ronda verda',
    car_parks: 'Aparcaments',
    car_park_CA: 'Aparcaments i transport privat',
    bicycle_anchoring_points: 'Punts d\'ancoratge bicicletes',
    car_parks_with_bicycle_places: 'Aparcaments amb places per a bicicletes',
    establishments: 'Establiments',
    bicycle_hire: 'Lloguer de bicicletes',
    bike_repairs: 'Reparació de bicicletes',
    public_transport: 'Transport públic',
    public_mode: 'Trans. públic',
    bici_friends: 'Edificis amics de la bici',
    certified_building: 'Edifici municipal certificat',
    certified_process_building: 'Edifici municipal en procés de certificació',
    indoor_visitor_parkings: 'Aparcaments interiors visitants',
    outdoor_visitor_parkings: 'Aparcaments exteriors visitants',
    carrers_30: 'Carrers 30',
    pedestrian_priority_streets: 'Carrers prioritat vianants',
    streets_without_motorized_vehicles: 'Carrers sense vehicles motoritzats',
    bosses_estacionament_motos: "Borses d'aparcaments per motos",
    // Car layers
    car_moto: 'Cotxe o moto',
    car_mode: 'Cotxe',
    traffic_jams: 'Retencions',
    traffic_restrictions: 'Afectacions al trànsit',
    sections_with_cones: 'Trams amb cons',
    ring_roads: 'Rondes',
    ring_road_entrances: 'Entrades a la ronda',
    ring_road_exits: 'Sortides de la ronda',
    blue_area: 'Àrea blava',
    green_area: 'Àrea verda',
    exclusive_green_area: 'Àrea verda exclusiva',
    sings_and_other_factors: 'Senyals i altres elements',
    traffic_cameras: 'Càmeres de trànsit',
    loading_and_unloading_zones: 'Zones de càrrega i descàrrega',
    controlled_access_zones: 'Zones d\'accés controlat',
    electric_vehicle_recharging_points: 'Punts de recàrrega de vehicles elèctric',
    petrol_stations: 'Benzineres',
    parkings: 'Aparcaments i transport privat',
    // Route preferences
    apply: 'Aplicar',
    clean: 'Netejar',
    info_preferences: 'Escull les teves preferències per automatitzar les cerques',
    by_type_of_route: 'Per tipus de ruta',
    by_type_of_transport: 'Per tipus de mobilitat',
    best_route: 'Millor ruta',
    best_mode: 'Millor',
    less_walking: 'Caminar menys',
    fewer_transfers: 'Menys transbordaments',
    accessible_routes: 'Rutes accessibles',
    transport_public_priority: 'Prioritza les rutes amb transport públic accessible, per a persones amb mobilitat reduïda',
    green_hubs: 'Eixos verds i places de l’Eixample',
    // Planificador
    plan_new_route: 'Planificar Nova Ruta',
    where_to_go: 'On vols anar?',
    select_origin: 'Selecciona l\'origen',
    origin: 'Origen',
    select_destination: 'Selecciona el destí',
    destination: 'Destí',
    plan_route: 'Planifica la ruta',
    clean_route: 'Eliminar',
    want_leave: 'Vull sortir',
    leave_now: 'Ara mateix',
    leave_at: 'Surt a les',
    arrive_at: 'Arriba a les',
    set_departure_current_time: 'Establir la sortida amb l’hora actual.',
    preferences: 'Preferències',
    avoid_tolls: 'Evita peatges',
    avoid_highways: 'Evita autopistes',
    no_matches_found: 'No s\'han trobat coincidències',
    change_keyword: 'Prova a fer la cerca canviant la paraula clau',
    my_current_location: 'La meva ubicació actual',
    recent_searches: 'Recerques recents',
    by_position: 'Per posició',
    date: 'Data',
    time: 'Hora',
    go_from: 'Des de',
    to: 'fins a',
    suggested_route: 'Ruta suggerida',
    sustainable_route: 'Et suggerim una ruta més sostenible',
    other_routes: 'Altres rutes',
    no_routes_available: 'No s\'han trobat rutes disponibles per al mitjà de transport seleccionat',
    sustainable_message_1: 'Si viatges en transport públic trigaries',
    sustainable_message_2: 'amb vehicle privat. T\'estalvies les cues de trànsit i l\'aparcament i estàs col·laborant a fer una ciutat més verda i neta.',
    sustainable_message_default: 'T\'oferim una opció per desplaçar-te en transport públic. Si la escull estalviaràs les cues de trànsit i l\'aparcament i estàs col·laborant a fer una ciutat més verda i neta.',
    less_than: 'menys que',
    longer_than: 'més que',
    same_time: 'el mateix temps que',
    hours: 'hores',
    minutes: 'minuts',
    and: 'i',
    only: 'només',
    accept_settings: 'Accepta Ajustaments',
    error_choose_proposal: 'És necessari escollir una adreça de les proposades mentre escrius',
    correct_address: 'Corregir Direcció',
    modify_route: 'Modificar ruta',
    details: 'Detalls',
    show_map: 'Mostra al mapa',
    add_interest_info: 'Afegeix elements a la ruta que siguin del teu interès:',
    examples: 'Exemples',
    // Route panel
    view_map: 'Veure el mapa',
    map: 'Mapa',
    recent_trips: 'Viatges recents',
    // Info panel
    how_to_arrive: 'Com hi arribo',
    add_to_favorites: 'Desa a preferits',
    next_exits: 'Properes sortides',
    upcoming_trains: 'Propers trens',
    arrival: 'Arriba',
    departure: 'Surt',
    no_time_table_available: 'En aquest moment, no n\'hi ha informació d\'horaris disponible',
    adapted_access: 'Accés adaptat',
    adapted_station: 'Estació adaptada',
    lines: 'Línies',
    stop: 'Parada',
    stops: 'Paradas',
    schedule: 'Horari',
    frequency: 'Freqüència de pas',
    travel_time: 'Durada del trajecte',
    max_capacity: 'Capacitat màxima',
    ticket: 'Tiquets',
    price: 'Preu',
    correspondence: 'Correspondències',
    added_to_favorites: 'Desat a preferits',
    imminent: 'Imminent',
    mechanical_bycicles: 'Bicicletes mecàniques',
    electrical_bycicles: 'Bicicletes eléctriques',
    free_bycicle_slots: 'Places lliures',
    address: 'Adreça',
    district: 'Districte',
    phonenumber: 'Telèfon',
    url: 'Web',
    entry: 'Entrada',
    exit: 'Sortida',
    municipality: 'Municipi',
    source: 'Font',
    description: 'Descripció',
    located_at: 'Ara està a',
    occupancy: 'Ocupació',
    low_emissions: 'Zona de baixes emissions',
    // Help
    in_your_phone: '"Com s\'hi va" al mòbil',
    search_best_route: 'Cerca la millor ruta per moure\'t a Barcelona. Tria on vols anar i com!',
    look_map: 'Mira el mapa',
    click_fullscreen: 'Amb un clic fes el mapa gran. Per tornar a la navegació, toca la pantalla de nou.',
    customize_travel: 'Personalitza el camí ',
    how_to_move: 'indicant com t\'agrada moure\'t',
    all_tranport: 'Tot el transport públic',
    find_line_easy: 'Localitza una línia o una parada i situa-la al mapa amb facilitat.',
    to_your_mesure: 'A la teva mida',
    choose_you_want_to_see: 'Tria que vols veure en qualsevol moment: carrils bici, escales mecàniques, transport accessible, estat del trànsit... Només amb un clic!',
    add_to_home_screen_help: 'Afegeix-lo a la pantalla d\'inici del teu dispositiu i consulta\'l allà on siguis.',
    add_to_home_screen_ios_1: 'Obrir el menú Compartir a Safari',
    add_to_home_screen_ios_2: 'Fer clic a l\'opció Afegir a la pantalla d\'inici.',
    add_to_home_screen_how: 'Com afegir?',
    add_to_home_screen_android_1: 'Obrir el menú de Configuració',
    add_to_home_screen_android_2: 'Fer clic a la opció Afegir a la pantalla d\'inici.',
    add: 'Afegir',
    continue: 'Continua',
    back: 'Enrere',
    to_exit: 'Surt',
    help_planroute: 'Planifica la teva ruta a Barcelona',
    help_add_layers: "Afegeix capes d'informació sobre el mapa",
    help_search: "Cerca ràpida de capes d'informació populars",
    help_warnings: 'Mira els avisos de mobilitat',
    close_presentation: 'Tanca presentació',
    // Incidents
    traffic_alerts: 'Alertes de trànsit',
    no_incidents: 'Actualment, no hi han incidències a la ciutat.',
    expected_traffic_msg: 'Aquest és el trànsit estimat per a les {futureTrafficHour}, el dia {futureTrafficDate}',
    // Alert Exit
    leave_comshiva: 'Sortint de Com s’hi va',
    info_text_leave: 'Amb aquesta acció estàs tornant a una altra pàgina i abandonant la funcionalitat del com s’hi va. Segur que vols continuar?',
    cancel_leave: 'Cancellar',
    confirm_leave: 'Continuar',
    // Recommendations
    recommendation_1: 'Al transport públic és obligatori l\'ús de mascareta tapant nas i boca.',
    recommendation_2: 'Renteu-vos bé les mans amb aigua i sabó o amb un gel hidroalcohòlic.',
    recommendation_3: 'Cal seguir en tot moment les recomanacions establertes per les autoritats sanitàries.',
    places: 'Places',
    transit: 'Trànsit',
    from: 'Des de',
    type: 'Tipus',
    subtype: 'Subtipus',
    vehicle_involvement: 'Vehicles',
    pedestrian_involvement: 'Peatons',
    public_involvement: 'Transport públic',
    // Menu 'A prop menu'
    click_bus: 'Clica sobre les parades de bus o FGC per saber-ne els horaris de pas en temps real.',
    // Lines
    last_lines_viewed: 'Últimes línies consultades',
    search_lines_stops: 'Cerca línies i parades',
    lines_stops: 'Línies i parades',
    transports: 'Transports',
    // Widget marathon
    marathon: 'Marató',
    marathon_description: 'Recorda que alguns recorreguts poden veure’s afectats pels talls de la Marató.',
    notice: 'Avís',
    // Barcelonacat
    get_around_barcelona: 'Mou-te per Barcelona',
    choose_trip: 'Escull on vols anar I t’ajudem a arribar-hi',
    invalid_address: 'Direcció no valguda',
    // Mapa
    exit_full_screen: 'Sortir de pantalla completa',
    visualize_activate_layers: 'Visualitzar i activar capes sobre el mapa',
    // BCN Travel Card
    hello_bcn_travel: 'Hola Barcelona Travel Card',
    info_travel_card: 'Viatgeu sense límits en metro, autobús (TMB) i altres xarxes del transport públic de Barcelona fent servir un únic billet. Podeu escollir el format que millor s\'adapti a les vostres necesitats:',
    days: 'Dies',
    consecutive_days: 'dies consecutius',
    more_information: 'Més informació',
    // Go Airport
    go_airport: 'Anar a l´aeroport',
    subway_airport: 'Metro aeroport',
    local_trains_airport: 'Rodalies aeroport',
    // Beyond BCN
    beyond_bcn: 'Més enllà de Barcelona',
    // Metatags
    title_home: 'Com s\'hi va',
    description_home: 'Descobreix en els nostres mapes com moure\'t per Barcelona',
    title_planifica: 'Planifica la teva ruta',
    description_planifica: "Cerca la millor ruta per moure't a Barcelona",
    // America Cups
    title_copaamerica: "37ª AMERICA'S CUP",
    america_cup: 'Copa Amèrica',
    america_cup_title: 'Mobilitat a Barcelona durant la Copa Amèrica',
    description_copaamerica: "LOUIS VUITTON 37ª AMERICA'S CUP, BARCELONA",
    general_services: 'Serveis generals',
    ca_spaces: 'Espais Copa Amèrica',
    access: 'Accessos',
    other: 'Altres',
    external_parking: "Zones d'aparcaments exteriors",
    closed_streets: 'Carrers tallats',
    bus_parkings: "Zona aparcament d'autocars",
    estacionament_dissuasiu: 'Bossa d’estacionament cotxes',
    new_bus: 'Nou bus intern',
    mar_bus: 'Bus nàutic',
    mar_hospital: 'Hospital del Mar',
    emergency_zones: "Zones d'emergències",
    train_bus: 'Estacions de tren i autocars',
    cruise_headquarters: 'Seus de creuers',
    heliport: 'Heliport',
    airoport: 'Aeroport',
    fan_zones: 'Fan zones',
    team_headquarters: 'Seus dels equips',
    location_screens: 'Pantalles gegants',
    points_to_view_regattas: "Zones d'espectadors",
    control_points_teams: "Punts de control d'accès equips i aficionats",
    control_points_neighbor: "Punts de control d'accès veïns",
    control_points_services: "Punts de control d'accès a serveis",
    buses_points: "Punt d'autocars/minibusos pels equips",
    point_interest: "Punts d'interès de ciutat",
    beyond_barcelona: 'Més enllà de Barcelona',
    cultural_regatta: 'Regata cultural',
    team_headquarter: 'Seu de equip',
    team: 'Equip',
    screen: 'Pantalla',
    zone: 'Zona',
    ca_alerts: 'Alertes de mobilitat a Barcelona durant la Copa Amèrica',
    do_you_like_alerts: 'Vols rebre al teu navegador les alertes de mobilitat relacionades amb la Copa Amèrica?',
    yes_thanks: 'Si, gràcies',
    not_at_the_moment: 'De moment, no',
    wait_time: 'Temps espera màxim servei',
    country: 'País',
    station_frança: 'Estació de França',
    station_sants: 'Estació de Sants',
    station_nord: 'Estació del Nord',
    station_pgracia: 'Estació de P. de Gràcia',
    station_fabra_puig: 'Estació de Fabra i Puig',
    shuttle_bogatell: 'Llançadora Forum-Bogatell',
    shuttle_rius_i_taulet: 'Bus llençadora Rius i Taulet',
    shuttle_sant_marti: 'Bus llençadora Sant Martí',
    cup_experience: 'America\'s cup experience',
    race_village: 'Race Village',
    royal_nautical_club: 'Reial Club Nautic',
    estacionament_cotxes_av_litoral: 'Estacionament cotxes Av. Litoral',
    estacionament_cotxes_miquel_tarradell: 'Estacionament cotxes Miquel Tarradell',
    estacionament_cotxes_nova_bocana: 'Estacionament cotxes Nova Bocana',
    estacionament_cotxes_pau_vila: 'Estacionament cotxes Pau Vila',
    estacionament_cotxes_pg_maritim: 'Estacionament cotxes Pg. Marítim',
    estacionament_motos: 'Bossa d’estacionament motos',
    estacionament_bicis: 'Bossa d’estacionament bicis',
    aparcament_sant_marti: 'Aparcament Sant Martí',
    aparcament_rius_i_taulet: 'Aparcament Rius i Taulet',
    aparcament_forum: 'Aparcament Fòrum',
    shuttle: 'Llençadora',
    estacionament_cotxes: 'Estacionament cotxes',
    activate: 'Espai amb diferents activitats',
    location: 'Localització',
    verregata_secondary: 'Altres zones d’espectadors',
    verregara_special: 'Zones principals d’espectadors',
    youth_and_women: 'Youth & Women',
    visualize_activate_layers_ca: 'Veure informació Copa Amèrica',
    include_more_layers_ca: 'Incloure transports Copa Amèrica',
    unsubscribe_notifications: 'Desactivar notificacions',
    disabled_notifications: 'Ja t\'has donat de baixa de les notificacions',
    home_page_title: 'Al teu mòbil',
    home_page_description: 'Afegeix el Com s’hi va a la teva pantalla d’inici',
    home_page_button: 'Afegir',
    special_america_cup: 'Especial Copa Amèrica',
    special_mobility_america_cup: 'Especial Mobilitat Copa Amèrica',
    bike_parking_close: 'Aparcaments tancats bicis',
    capacity: 'Capacitat',
    activate_layer_planificador: 'S\'han afegit els carrils bici sobre el mapa',
  },
  es: {
    // General
    loader_message: 'Cargando<br>información.<br>Por favor, espere.',
    cancel: 'Cancelar',
    link_contact: 'https://w10.bcn.cat/APPS/irsconsultesWeb/continuar.executant.do?i=e&origen=WEB_BARCELONA_CAT&tema=81&element=1121',
    link_accessibility: 'https://www.barcelona.cat/es/accesibilidad',
    link_legal: 'https://www.barcelona.cat/es/aviso-legal',
    link_travel_card: 'https://www.holabarcelona.com/es/tickets/hola-bcn-barcelona-travel-card',
    yes: 'Sí',
    geolocation_msg: 'No se ha podido determinar su localización. Debe tener activada la geolocalización en su dispositivo móvil y autorizar al Com s\'hi va para ubicar le.',
    // Transports
    metro: 'Metro',
    bus: 'Bus',
    local_trains: 'Cercanías',
    fgc_trains: 'Ferrocarriles',
    trams: 'Tranvia',
    funicular: 'Funicular',
    aerobus: 'Aerobús',
    // Main Menu
    route_preferences: 'Preferencias',
    maps_and_lines: 'Mapas y lineas',
    traffic_incidents: 'Incidencias del tráfico',
    help: 'Ayuda',
    help_planroute_demo: 'Cómo navegar',
    contact: 'Contacto',
    legal_disclaimer: 'Aviso legal',
    accessibility: 'Accesibilidad',
    recommendations: 'Recomendaciones',
    see_line_map: 'Ver línea en el mapa',
    recent: 'Recientes',
    near: 'Cerca mio',
    // Search
    layers: 'Capas',
    search_layers: 'Busca capas',
    search_line: 'Busca una línea',
    search_info: 'Busca una información',
    station: 'Estación',
    bus_stop: 'Parada bus',
    local_train_station: 'Estación Cercanías',
    fgc_train_station: 'Estación FGC',
    tram_stop: 'Estació TRAM',
    no_results: 'No se han encontrado resultados.',
    try_write_new_word: 'Prueba escribir de nuevo o busca una nueva palabra',
    search_examples: 'Paradas de taxi, carriles bici...',
    search_interest_items: 'Busca elementos que sean de tu interes:',
    // Layer menu
    active_information: 'Información activa',
    select_all: 'Seleccionar todo',
    see_all_metro_lines: 'Ver todas las líneas de metro',
    see_all_bus_lines: 'Ver todas las líneas de bus',
    see_all_fgc_train_lines: 'Ver todas las líneas de ferrocarriles',
    see_all_local_train_lines: 'Ver todas las líneas de cercanías',
    see_all_trams_lines: 'Ver todas las líneas de tranvía',
    shows_on_map: 'Muestra en el mapa',
    general_interest: 'Interés general',
    traffic_state: 'Estado del tráfico',
    near_public_transport: 'Transporte público cercano',
    low_emissions_zone: 'Zona bajas emisiones',
    accessible_transport: 'Transporte accesible',
    recover_recent_layers: 'Recupera las capas recientes',
    view_more: 'Ver mas',
    view_less: 'Ver menos',
    active_layers: 'Capas activas',
    delete_all: 'Eliminar todas',
    no_layers_active: 'Añade elementos de tu interés',
    include_more_layers: 'Incluir',
    notice_consult_lines: 'Estás dejando de incluir capas en el mapa para ir a consultar la información de líneas y paradas de transporte público.',
    show_layers: 'Opciones del mapa',
    all_layers: 'Todas las capas',
    by_car: 'En coche',
    bicycle_mobility: 'Mobilidad en bici',
    mobility_foot: 'Mobilidad a pie',
    cab: 'Taxi',
    cableway: 'Teleférico',
    locate_lines_stops: 'Localiza lineas y paradas',
    turisme: 'Información turística',
    all_traffic: 'Todo el tráfico',
    all_bici: 'Todo movilidad en bicicleta',
    all_metro: 'Todo metro',
    all_foot: 'Todo a pie',
    all_bus: 'Todo bus',
    all_fgc: 'Todo ferrocarriles',
    all_rodalies: 'Todo cercanías',
    all_tram: 'Todo tranvía',
    map_options: 'Opciones del mapa',
    // Walk layers
    walk: 'A pie',
    pedestrian_zones: 'Zonas de peatones',
    zones_30: 'Zonas 30',
    escalators: 'Escaleras mecánicas',
    traffic_calmed_streets: 'Calles pacificadas',
    widened_pavements: 'Ampliación de aceras',
    sides_roads_closed_traffic: 'Laterales cerrados al tráfico',
    // Bici layers
    bici: 'Bicicleta',
    cycle_lanes: 'Carriles bici',
    aparacment_segur_bici: 'Aparcamientos seguros Bicis',
    carrilsbicimunicipis: 'Otros municipios',
    new_bicycle_lanes: 'Bicienlace',
    cycle_lanes_under_construction: 'Carriles bici en construcción',
    roads_suitable_for_cycling: 'Vies ciclables',
    green_route: 'Ronda verde',
    car_parks: 'Aparcamientos',
    car_park_CA: 'Aparcamientos y transporte privado',
    bicycle_anchoring_points: 'Puntos de anclaje de bicicletas',
    car_parks_with_bicycle_places: 'Aparcamientos con plazas para bicicletas',
    establishments: 'Establecimientos',
    bicycle_hire: 'Alquiler de bicicletas',
    bike_repairs: 'Reparación de bicicletas',
    public_transport: 'Transporte público',
    public_mode: 'Trans. público',
    bici_friends: 'Edificios amigos de la bici',
    certified_building: 'Edificio municipal certificado',
    certified_process_building: 'Edificio municipal en proceso de certificación',
    indoor_visitor_parkings: 'Aparcamientos interiores para visitantes',
    outdoor_visitor_parkings: 'Aparcamientos exteriores para visitantes',
    carrers_30: 'Calles 30',
    pedestrian_priority_streets: 'Calles con prioridad peatones',
    streets_without_motorized_vehicles: 'Calles sin vehículos motorizados',
    bosses_estacionament_motos: 'Bolsas de aparcamientos para motos',
    // Car layers
    car_moto: 'Coche o moto',
    car_mode: 'Coche',
    traffic_jams: 'Retenciones',
    traffic_restrictions: 'Alteraciones en el tráfico',
    sections_with_cones: 'Tramos con conos',
    ring_roads: 'Rondas',
    ring_road_entrances: 'Entradas rondas',
    ring_road_exits: 'Salidas de la ronda',
    blue_area: 'Área azul',
    green_area: 'Área verde',
    exclusive_green_area: 'Área verde exclusiva',
    sings_and_other_factors: 'Señales y otros elementos',
    traffic_cameras: 'Cámeres de tráfico',
    loading_and_unloading_zones: 'Zonas de carga y descarga',
    controlled_access_zones: 'Zonas de acceso controlado',
    electric_vehicle_recharging_points: 'Puntos de recarga vehículos eléctricos',
    petrol_stations: 'Gasolineras',
    parkings: 'Aparcamientos y transporte privado',
    green_hubs: 'Ejes verdes y plazas del Eixample',
    // Route preferences
    apply: 'Aplicar',
    clean: 'Limpiar',
    info_preferences: 'Escoge tus preferencias para automatizar las búsquedas',
    by_type_of_route: 'Por tipo de ruta',
    by_type_of_transport: 'Por tipo de transporte',
    best_route: 'Mejor ruta',
    best_mode: 'Mejor',
    less_walking: 'Caminar menos',
    fewer_transfers: 'Menos transbordos',
    accessible_routes: 'Rutas accesibles',
    transport_public_priority: 'Prioriza las rutas con transporte público accesible, para personas con movilidad reducida',
    // Planificador
    plan_new_route: 'Planificar Nueva Ruta',
    where_to_go: '¿Donde quieres ir?',
    select_origin: 'Selecciona el origen',
    origin: 'Origen',
    select_destination: 'Selecciona el destino',
    destination: 'Destino',
    plan_route: 'Planifica ruta',
    clean_route: 'Eliminar',
    want_leave: 'Quiero salir',
    leave_now: 'Ahora mismo',
    leave_at: 'Salir a las',
    arrive_at: 'Llegar a las',
    set_departure_current_time: 'Establecer la salida con la hora actual.',
    preferences: 'Preferencias',
    avoid_tolls: 'Evitar peajes',
    avoid_highways: 'Evitar autopistas',
    no_matches_found: 'No hay coincidencias',
    change_keyword: 'Prueba realizar la búsqueda cambiando la palabra clave',
    my_current_location: 'Mi ubicación actual',
    recent_searches: 'Búsquedas recientes',
    by_position: 'Por posición',
    date: 'Fecha',
    time: 'Hora',
    go_from: 'Ir desde',
    to: 'hasta',
    suggested_route: 'Ruta sugerida',
    sustainable_route: 'Te sugerimos una ruta más sostenible',
    other_routes: 'Otras rutas',
    no_routes_available: 'No se han encontrado rutas disponibles en el medio de transporte seleccionado',
    sustainable_message_1: 'Si viajas en transporte público tardarás',
    sustainable_message_2: 'en vehículo privado. Te ahorrarás las colas de tráfico y el aparcamiento y estás colaborando a hacer una ciudad más verde y limpia.',
    sustainable_message_default: 'Te ofrecemos una opción para desplazarte en transporte público. Si la eliges te ahorrarás atascos de tráfico y aparcamiento y está colaborando en hacer una ciudad más verde y limpia.',
    less_than: 'menos que',
    longer_than: 'más que',
    same_time: 'el mismo tiempo que',
    hours: 'horas',
    minutes: 'minutos',
    and: 'y',
    only: 'solo',
    accept_settings: 'Aplicar Ajustes',
    error_choose_proposal: 'Es necesario escoger una dirección de las propuestas mientras escribes',
    correct_address: 'Corregir Dirección',
    modify_route: 'Modificar ruta',
    details: 'Detalles',
    show_map: 'Muestra en el mapa',
    add_interest_info: 'Añade elementos a la ruta que sean de tu interés',
    examples: 'Ejemplos',
    // Route panel
    view_map: 'Ver mapa',
    map: 'Mapa',
    recent_trips: 'Viages recientes',
    // Info panel
    how_to_arrive: 'Cómo llego',
    add_to_favorites: 'Guardar en favoritos',
    next_exits: 'Próximas salidas',
    upcoming_trains: 'Próximos trenes',
    arrival: 'Llegada',
    departure: 'Salida',
    no_time_table_available: 'En este momento no hay informació de horarios disponible',
    adapted_access: 'Acceso adaptado',
    adapted_station: 'Estación adaptada',
    lines: 'Líneas',
    stop: 'Parada',
    stops: 'Paradas',
    schedule: 'Horario',
    frequency: 'Frecuencia de paso',
    travel_time: 'Duración del trayecto',
    ticket: 'Billetes',
    max_capacity: 'Capacidad máxima',
    price: 'Precio',
    correspondence: 'Correspondencias',
    added_to_favorites: 'Guardado en favoritos',
    imminent: 'Inminente',
    mechanical_bycicles: 'Bicicletas mecánicas',
    electrical_bycicles: 'Bicicletas eléctricas',
    free_bycicle_slots: 'Plazas libres',
    address: 'Dirección',
    district: 'Distrito',
    phonenumber: 'Teléfono',
    url: 'Web',
    entry: 'Entrada',
    exit: 'Sortida',
    municipality: 'Muncipio',
    source: 'Fuente',
    description: 'Descripción',
    located_at: 'Ahora está en',
    occupancy: 'Ocupación',
    low_emissions: 'Zona de bajas emisiones',
    // Help
    in_your_phone: 'Com s\'hi va en tu móvil',
    search_best_route: 'Busca la mejor ruta para moverte en Barcelona. ¡Elige dónde quieres ir y cómo!',
    look_map: 'Mira el mapa',
    click_fullscreen: 'Con un click haz el mapa grande. Para volver a la navegación toca la pantalla de nuevo.',
    customize_travel: 'Personaliza tu camino',
    how_to_move: 'Indicando cómo te gustaría moverte.',
    all_tranport: 'Todo el transporte público.',
    find_line_easy: 'Localiza una línea o una parada y situala en el mapa con facilidad.',
    to_your_mesure: 'A tu medida',
    choose_you_want_to_see: 'Elige qué quires ver en cualquier momento: carriles bici, escalera mecánicas, transporte accesible, estado del tráfico... ¡Sólo con un click!',
    add_to_home_screen_help: 'Añádelo a la pantalla de inicio de tu dispositivo y consulta allá donde estés.',
    add_to_home_screen_ios_1: 'Abrir el menú Compartir en Safari',
    add_to_home_screen_ios_2: 'Hacer click en la opción Añadir a la pantalla de inicio.',
    add_to_home_screen_how: '¿Cómo añadirlo?',
    add_to_home_screen_android_1: 'Abrir el menú de Configuración',
    add_to_home_screen_android_2: 'Hacer click en la opción Añadir a la pantalla de inicio.',
    add: 'Añadir',
    continue: 'Continuar',
    back: 'Atrás',
    to_exit: 'Salir',
    help_planroute: 'Planifica tu ruta en Barcelona',
    help_add_layers: 'Añade capas de información sobre el mapa',
    help_search: 'Búsqueda rápida de capas de información populares',
    help_warnings: 'Mira los avisos de movilidad',
    close_presentation: 'Cerrar presentación',
    // Incidents
    traffic_alerts: 'Alertas de tráfico',
    no_incidents: 'Actualmente, no hay incidencias en la ciudad.',
    expected_traffic_msg: 'Este es el tráfico estimado para las {futureTrafficHour}, el día {futureTrafficDate}',
    // Alert Exit
    leave_comshiva: 'Saliendo de Com s’hi va',
    info_text_leave: 'Con esta acción estás saliendo a otra página y abandonando la funcionalidad. Seguro que quieres continuar?',
    cancel_leave: 'Cancelar',
    confirm_leave: 'Continuar',
    // Recommendations
    recommendation_1: 'En el transporte público es obligatorio el uso de mascarilla tapando nariz y boca.',
    recommendation_2: 'Lavaos bien las manos con agua y jabón o con gel hidroalcohólico.',
    recommendation_3: 'Seguir en todo momento las recomendaciones establecidas por las autoridades sanitarias.',
    places: 'Plazas',
    transit: 'Tráfico',
    from: 'Desde',
    type: 'Tipo',
    subtype: 'Subtipo',
    vehicle_involvement: 'Vehículos',
    pedestrian_involvement: 'Peatones',
    public_involvement: 'Transport público',
    // Menu 'A prop menu'
    click_bus: 'Clica sobre las paradas de bus o FGC para saber los horarios en tiempo real.',
    // Lines
    last_lines_viewed: 'Últimas líneas consultadas',
    search_lines_stops: 'Busca líneas y paradas',
    lines_stops: 'Líneas y paradas',
    transports: 'Transportes',
    // Widget marathon
    marathon: 'Maratón',
    marathon_description: 'Recuerda que algunos recorridos pueden verse afectados por los cortes del Maratón.',
    notice: 'Aviso',
    // Barcelonacat
    get_around_barcelona: 'Muévete por Barcelona',
    choose_trip: 'Escoge donde quieres ir y te ayudamos a llegar',
    invalid_address: 'Dirección no valida',
    // Mapa
    exit_full_screen: 'Salir de pantalla completa',
    visualize_activate_layers: 'Visualizar y activar capas sobre el mapa',
    // BCN Travel Card
    hello_bcn_travel: 'Hola Barcelona Travel Card',
    info_travel_card: 'Viajáis sin límites en metro, autobús (TMB) y otras redes del transporte público de Barcelona usando un único billete. Podéis escoger el formato que mejor se adapte a vuestras necesidades:',
    days: 'Días',
    consecutive_days: 'días consecutivos',
    more_information: 'Más información',
    // Go Airport
    go_airport: 'Ir al aeropuerto',
    subway_airport: 'Metro aeropuerto',
    local_trains_airport: 'Cercanías aeroport',
    // Beyond BCN
    beyond_bcn: 'Más allá de Barcelona',
    // Metatags
    title_home: 'Cómo llegar',
    description_home: 'Descubre en nuestros mapas como moverte por Barcelona',
    title_planifica: 'Planifica tu ruta',
    description_planifica: 'Busca la mejor ruta para moverte por Barcelona',
    // America Cups
    title_copaamerica: "37ª AMERICA'S CUP",
    america_cup: 'Copa Amèrica',
    america_cup_title: 'Movilidad en Barcelona durante la Copa Amèrica',
    description_copaamerica: "LOUIS VUITTON 37ª AMERICA'S CUP, BARCELONA",
    general_services: 'Servicios generales',
    ca_spaces: 'Espacios Copa Amèrica',
    access: 'Accesos',
    other: 'Otros',
    external_parking: 'Zonas de aparcamiento exteriores',
    closed_streets: 'Calles cerradas',
    bus_parkings: 'Zona de aparcamiento de autocares',
    estacionament_dissuasiu: 'Bolsa de estacionamiento coches',
    new_bus: 'Nuevo bus interno',
    mar_bus: 'Bus náutico',
    mar_hospital: 'Hospital del Mar',
    emergency_zones: 'Zonas de emergencias',
    train_bus: 'Estaciones de tren y autocares',
    cruise_headquarters: 'Sedes de cruceros',
    heliport: 'Helipuerto',
    airoport: 'Aeropuerto',
    fan_zones: 'Fan zones',
    team_headquarters: 'Sedes de los equipos',
    location_screens: 'Pantallas gigantes',
    points_to_view_regattas: 'Zonas de espectadores',
    control_points_teams: 'Puntos de control de acceso equipos y aficionados',
    control_points_neighbor: 'Puntos de control de acceso vecinos',
    control_points_services: 'Puntos de control de acceso a servicios',
    buses_points: 'Punto de autocares/minibuses para los equipos',
    point_interest: 'Puntos de interes de la ciudad',
    beyond_barcelona: 'Más allá de Barcelona',
    cultural_regatta: 'Regata cultural',
    team_headquarter: 'Sede de equipo',
    team: 'Equipo',
    screen: 'Pantalla',
    zone: 'Zona',
    ca_alerts: 'Alertas de movilidad en Barcelona durante la Copa América',
    do_you_like_alerts: 'Quieres recibir en tu navegador las alertas de movilidad relacionadas con la Copa América?',
    yes_thanks: 'Sí, gracias',
    not_at_the_moment: 'De momento, no',
    wait_time: 'Tiempo espera máximo servicio',
    country: 'País',
    station_frança: 'Estación de Francia',
    station_sants: 'Estación de Sants',
    station_nord: 'Estación del Norte',
    station_pgracia: 'Estación de P. de Gràcia',
    station_fabra_puig: 'Estación de Fabra i Puig',
    shuttle_bogatell: 'Lanzadera Forum-Bogatell',
    shuttle_rius_i_taulet: 'Bus lanzadera Rius i Taulet',
    shuttle_sant_marti: 'Bus lanzadera Sant Martí',
    cup_experience: 'America\'s cup experience',
    race_village: 'Race Village',
    royal_nautical_club: 'Real Club Nautico',
    estacionament_cotxes_av_litoral: 'Estacionamiento coches Av. Litoral',
    estacionament_cotxes_miquel_tarradell: 'Estacionamiento coches Miquel Tarradell',
    estacionament_cotxes_nova_bocana: 'Estacionamiento coches Nova Bocana',
    estacionament_cotxes_pau_vila: 'Estacionamiento coches Pau Vila',
    estacionament_cotxes_pg_maritim: 'Estacionamiento coches Pg. Maritim',
    estacionament_motos: 'Bolsa de estacionamiento motos',
    estacionament_bicis: 'Bolsa de estacionamiento bicis',
    aparcament_sant_marti: 'Aparcamiento Sant Martí',
    aparcament_rius_i_taulet: 'Aparcamiento Rius i Taulet',
    aparcament_forum: 'Aparcamiento Fòrum',
    shuttle: 'Lanzadera',
    estacionament_cotxes: 'Estacionamiento coches',
    activate: 'Espacio con distintas actividades',
    location: 'Localización',
    verregata_secondary: 'Otras zonas de espectadores',
    verregara_special: 'Zonas principales de espectadores',
    youth_and_women: 'Youth & Women',
    visualize_activate_layers_ca: 'Ver información Copa América',
    include_more_layers_ca: 'Incluir transportes Copa América',
    unsubscribe_notifications: 'Desactivar notificaciones',
    disabled_notifications: 'Ya te has dado de baja de las notificaciones',
    home_page_title: 'En tu móvil',
    home_page_description: 'Añade el Com s’hi va a tu pantalla de inicio',
    home_page_button: 'Añadir',
    special_america_cup: 'Especial Copa América',
    special_mobility_america_cup: 'Especial Mobilidad Copa Amèrica',
    bike_parking_close: 'Aparcamientos cerrados bicis',
    capacity: 'Capacidad',
    activate_layer_planificador: 'Se han añadido los carriles bici sobre el mapa',
  },
  en: {
    // General
    loader_message: 'Loading<br>information<br>Please, wait.',
    cancel: 'Cancel',
    link_contact: 'https://w10.bcn.cat/APPS/irsconsultesWeb/continuar.executant.do?i=i&origen=WEB_BARCELONA_CAT&tema=81&element=1121',
    link_accessibility: 'https://www.barcelona.cat/en/accessibility',
    link_legal: 'https://www.barcelona.cat/en/legal-notice',
    link_travel_card: 'https://www.holabarcelona.com/tickets/hola-bcn-barcelona-travel-card',
    yes: 'Yes',
    geolocation_msg: 'Your location could not be determined. You must have geolocation enabled on your mobile device and authorize Com s\'hi va.',
    // Transports
    metro: 'Metro',
    bus: 'Bus',
    local_trains: 'Local trains',
    fgc_trains: 'FGC trains',
    trams: 'Trams',
    funicular: 'Funicular',
    aerobus: 'Aerobus',
    // Main Menu
    route_preferences: 'Preferences',
    maps_and_lines: 'Maps and lines',
    traffic_incidents: 'Traffic incidents',
    help: 'Help',
    help_planroute_demo: 'How to plan a route',
    contact: 'Contact',
    legal_disclaimer: 'Legal disclaimer',
    accessibility: 'Accessibility',
    recommendations: 'Recommendations',
    see_line_map: 'See line on the map',
    recent: 'Recent',
    near: 'Near me',
    // Search
    layers: 'Layers',
    search_layers: 'Look for layers',
    search_line: 'Search line',
    search_info: 'Search information',
    station: 'Station',
    bus_stop: 'Bus stop',
    local_train_station: 'Local train station',
    fgc_train_station: 'FGC station',
    tram_stop: 'Tram stop',
    no_results: 'No results found.',
    try_write_new_word: 'Try typing again or look up a new word',
    search_examples: 'Taxi ranks, bicycle lanes...',
    search_interest_items: 'Look for elements that are of interest to you:',
    // Layer menu
    active_information: 'Active information',
    select_all: 'Select all',
    see_all_metro_lines: 'See all metro lines',
    see_all_bus_lines: 'See all bus lines',
    see_all_fgc_train_lines: 'See all train FGC lines',
    see_all_local_train_lines: 'See all train local lines',
    see_all_trams_lines: 'See all tram lines',
    shows_on_map: 'Shows on map',
    general_interest: 'General interest',
    traffic_state: 'Traffic situation',
    near_public_transport: 'Near public transport',
    low_emissions_zone: 'Low emission zone',
    accessible_transport: 'Accessible transport',
    recover_recent_layers: 'Recover recent layers',
    view_more: 'View more',
    view_less: 'View less',
    active_layers: 'Active layers',
    delete_all: 'Delete all',
    no_layers_active: 'Add elements of your interest',
    include_more_layers: 'Include',
    notice_consult_lines: 'You are leaving layers on the map to go to consult the information of lines and stops of public transport.',
    show_layers: 'Map opcions',
    all_layers: 'All layers',
    by_car: 'By car',
    bicycle_mobility: 'Bicycle mobility',
    mobility_foot: 'Mobility on foot',
    cab: 'Cab',
    cableway: 'Cableway',
    locate_lines_stops: 'Locate lines and stops',
    turisme: 'Tourist information',
    all_traffic: 'All traffic',
    all_bici: 'All bicycle mobility',
    all_foot: 'All on foot',
    all_metro: 'All metro',
    all_bus: 'All bus',
    all_fgc: 'All FGC',
    all_rodalies: 'All local trains',
    all_tram: 'All trams',
    map_options: 'Map options',
    // Walk layers
    walk: 'Walk',
    pedestrian_zones: 'Pedestrian zones',
    zones_30: '30 kph zones',
    escalators: 'Escalators',
    traffic_calmed_streets: 'Traffic calmed streets',
    widened_pavements: 'Widened pavements',
    sides_roads_closed_traffic: 'Side roads closed to traffic',
    // Bici layers
    bici: 'Bycicle',
    cycle_lanes: 'Cicle lanes',
    aparacment_segur_bici: 'Secure bike parking',
    carrilsbicimunicipis: 'Other towns',
    new_bicycle_lanes: 'Bici links',
    cycle_lanes_under_construction: 'Cicle lanes under construction',
    roads_suitable_for_cycling: 'Roads suitable for cycling',
    green_route: 'Green route',
    car_parks: 'Car parks',
    car_park_CA: 'Car parks and private transport',
    bicycle_anchoring_points: 'Bicycle anchoring points',
    car_parks_with_bicycle_places: 'Car parks with bicycle places',
    establishments: 'Establishments',
    bicycle_hire: 'Bicycle hire',
    bike_repairs: 'Bike repairs',
    public_transport: 'Public transport',
    public_mode: 'Trans. public',
    bici_friends: 'Bicycle-friendly buildings',
    certified_building: 'Certified municipal building',
    certified_process_building: 'Municipal building in process of certification',
    indoor_visitor_parkings: 'Covered car parking for visitors',
    outdoor_visitor_parkings: 'Outdoor car parking for visitors',
    carrers_30: 'Streets 30',
    pedestrian_priority_streets: 'Pedestrian priority streets',
    streets_without_motorized_vehicles: 'Streets free of motor vehicles',
    bosses_estacionament_motos: 'Motorcycle parking',
    // Car layers
    car_moto: 'Car or motorbike',
    car_mode: 'Car',
    traffic_jams: 'Traffic jams',
    traffic_restrictions: 'Trafics restrictions',
    sections_with_cones: 'Sections with cones',
    ring_roads: 'Ring roads',
    ring_road_entrances: 'Ring-road entrances',
    ring_road_exits: 'Ring-road entrances exits',
    blue_area: 'Blue area',
    green_area: 'Green area',
    exclusive_green_area: 'Exclusive green area',
    sings_and_other_factors: 'Signs and other factors',
    traffic_cameras: 'Traffic cameras',
    loading_and_unloading_zones: 'Loading and unloading zones',
    controlled_access_zones: 'Controlled access zones',
    electric_vehicle_recharging_points: 'Electric-vehicle recharging points',
    petrol_stations: 'Petrol stations',
    parkings: 'Parking and private transport',
    green_hubs: 'Green hubs and squares in The Eixample',
    // Route preferences
    apply: 'Apply',
    clean: 'Clean',
    info_preferences: 'Choose your preferences to automate searches',
    by_type_of_route: 'By type of route',
    by_type_of_transport: 'By type of transport',
    best_route: 'Best route',
    best_mode: 'Best',
    less_walking: 'Less walking',
    fewer_transfers: 'Fewer transfers',
    accessible_routes: 'Accessible routes',
    transport_public_priority: 'Prioritize routes with accessible public transport, for people with reduced mobility',
    // Planificador
    plan_new_route: 'Plan New Route',
    where_to_go: 'Where to go?',
    select_origin: 'Select the origin',
    origin: 'Origin',
    select_destination: 'Select destination',
    destination: 'Destination',
    plan_route: 'Plan your route',
    clean_route: 'Delete',
    want_leave: 'I want to go',
    leave_now: 'Right now',
    leave_at: 'Leave at',
    arrive_at: 'Arrive at',
    set_departure_current_time: 'Set the output with the current time.',
    preferences: 'Preferences',
    avoid_tolls: 'Avoid tolls',
    avoid_highways: 'Avoid highways',
    no_matches_found: 'No matches found',
    change_keyword: 'Try searching by changing your keyword',
    my_current_location: 'My current location',
    recent_searches: 'Recent searches',
    by_position: 'By position',
    date: 'Date',
    time: 'Time',
    go_from: 'Go from',
    to: 'to',
    suggested_route: 'Suggested route',
    sustainable_route: 'We suggest a more sustainable route',
    other_routes: 'Other routes',
    no_routes_available: 'No available routes were found on the selected means of transport',
    sustainable_message_1: 'If you travel by public transport it will take',
    sustainable_message_2: 'by private vehicle. You will save on traffic queues and parking and you are collaborating to make a greener and cleaner city.',
    sustainable_message_default: 'We offer you an option to get around by public transport. If you choose it, you will save traffic and parking jams and it is helping to make a greener and cleaner city.',
    less_than: 'less than',
    longer_than: 'longer than',
    same_time: 'the same time as',
    hours: 'hours',
    minutes: 'minutes',
    and: 'and',
    only: 'only',
    accept_settings: 'Accept Settings',
    error_choose_proposal: 'It is necessary to choose one of the proposed addresses while you are writing',
    correct_address: 'Correct Address',
    modify_route: 'Modify route',
    details: 'Details',
    show_map: 'Show on map',
    add_interest_info: 'Add elements to the route that are of your interest:',
    examples: 'Examples',
    // Route panel
    view_map: 'View map',
    map: 'Map',
    recent_trips: 'Recent trips',
    // Info panel
    how_to_arrive: 'How to arrive',
    add_to_favorites: 'Add to favorites',
    next_exits: 'Next exits',
    upcoming_trains: 'Upcoming trains',
    arrival: 'Arrival',
    departure: 'Departure',
    no_time_table_available: 'No timetable information is available at this time',
    adapted_access: 'Adapted access',
    adapted_station: 'Adapted station',
    lines: 'Line',
    stop: 'Stop',
    stops: 'Stops',
    schedule: 'Schedule',
    frequency: 'Frequency',
    travel_time: 'Travel time',
    max_capacity: 'Max capacity',
    ticket: 'Ticket',
    price: 'Price',
    correspondence: 'Correspondence',
    added_to_favorites: 'Added to favorites',
    imminent: 'Inminent',
    mechanical_bycicles: 'Mechanical bycicles',
    electrical_bycicles: 'Electrical bycicles',
    free_bycicle_slots: 'Free bycicle slots',
    address: 'Address',
    district: 'District',
    phonenumber: 'Phone number',
    url: 'Web',
    entry: 'Entry',
    exit: 'Exit',
    municipality: 'Municipality',
    source: 'Source',
    description: 'Description',
    located_at: 'Now it\'s in',
    occupancy: 'Occupancy',
    low_emissions: 'Low emission zone',
    // Help
    in_your_phone: 'Com s\'hi va on your mobile',
    search_best_route: 'Find the best route to move around Barcelona. Choose where you want to go and how!',
    look_map: 'Look at the map',
    click_fullscreen: 'With one click, make the map large. To return to navigation, touch the screen again.',
    customize_travel: 'Customize your way',
    how_to_move: 'Indicating how you would like to move.',
    all_tranport: 'All public transport',
    find_line_easy: 'Find a line or stop and easily locate it on the map.',
    to_your_mesure: 'Tailored to your needs',
    choose_you_want_to_see: 'Choose what you want to see at any time: bike lanes, escalators, accessible transport, traffic state ... Just with one click!',
    add_to_home_screen_help: 'Add it to the home screen of your device and consult wherever you are.',
    add_to_home_screen_ios_1: 'Open Safari share menu',
    add_to_home_screen_ios_2: 'Tap on Add to Home Screen.',
    add_to_home_screen_how: 'How to add?',
    add_to_home_screen_android_1: 'Open Browser settings button',
    add_to_home_screen_android_2: 'Tap on Add to Home Screen.',
    add: 'Add',
    continue: 'Continue',
    back: 'Previous',
    to_exit: 'Exit',
    help_planroute: 'Plan your route in Barcelona',
    help_add_layers: 'Add layers of map information',
    help_search: 'Quick search for popular information layers',
    help_warnings: 'See the mobility notices',
    close_presentation: 'Close presentation',
    // Incidents
    traffic_alerts: 'Traffic alerts',
    no_incidents: 'Currently, there are no incidents in the city.',
    expected_traffic_msg: 'This is the estimated traffic for {futureTrafficHour}, on {futureTrafficDate}',
    // Alert Exit
    leave_comshiva: 'Leaving Com s’hi va',
    info_text_leave: 'With this action you are returning to another page and leaving the functionality. Are you sure you want to continue?',
    cancel_leave: 'Cancel',
    confirm_leave: 'Continue',
    // Recommendations
    recommendation_1: 'In public transport, the use of a mask covering the nose and mouth is mandatory.',
    recommendation_2: 'Wash your hands well with soap and water or hydroalcoholic gel.',
    recommendation_3: 'Always follow the recommendations established by the health authorities.',
    places: 'Places',
    transit: 'Traffic',
    from: 'From',
    type: 'Type',
    subtype: 'Subtype',
    vehicle_involvement: 'Cars',
    pedestrian_involvement: 'Pedestrian',
    public_involvement: 'Public transport',
    // Modal recent trips
    not_trips: 'No hi ha viatges recents',
    // Menu 'A prop menu'
    click_bus: 'Click on the diver or FGC stops to know the timetables in real time.',
    // Lines
    last_lines_viewed: 'Last lines consulted',
    search_lines_stops: 'Search for lines and stops',
    lines_stops: 'Lines and stops',
    transports: 'Transports',
    // Widget marathon
    marathon: 'Marathon',
    marathon_description: 'Remember that some routes may be affected by the Marathon cuts.',
    notice: 'Warning',
    // Barcelonacat
    get_around_barcelona: 'Move around Barcelona',
    choose_trip: 'Choose where you want to go and we will help you get there',
    invalid_address: 'Invalid address',
    // Mapa
    exit_full_screen: 'Exit full screen',
    visualize_activate_layers: 'Visualize and activate layers on the map',
    // BCN Travel Card
    hello_bcn_travel: 'Hello Barcelona Travel Card',
    info_travel_card: 'You travel without limits on metro, bus (TMB) and other public transport networks in Barcelona using a single ticket. You can choose the format that best suits your needs.',
    days: 'Days',
    consecutive_days: 'Consecutive days',
    more_information: 'More information',
    // Go Airport
    go_airport: 'Go to airport',
    subway_airport: 'Subway airport',
    local_trains_airport: 'Local train airport',
    // Beyond BCN
    beyond_bcn: 'Beyond Barcelona',
    // Metatags
    title_home: 'Getting there',
    description_home: 'Find out how to move around Barcelona with our maps',
    title_planifica: 'Plan your route',
    description_planifica: 'Find the best route to get around Barcelona',
    // America Cups
    title_copaamerica: "37ª AMERICA'S CUP",
    america_cup: 'America Cup',
    america_cup_title: 'Mobility in Barcelona during the America Cup',
    description_copaamerica: "LOUIS VUITTON 37ª AMERICA'S CUP, BARCELONA",
    general_services: 'General services',
    ca_spaces: 'Copa Amèrica spaces',
    access: 'Access',
    other: 'Other',
    external_parking: 'Outdoor parking areas',
    closed_streets: 'Streets closed',
    bus_parkings: 'Coach parking area',
    estacionament_dissuasiu: 'Car parking',
    new_bus: 'New internal bus',
    mar_bus: 'Nautical bus',
    mar_hospital: 'Hospital del Mar',
    emergency_zones: 'Emergency zones',
    train_bus: 'Train and coach stations',
    cruise_headquarters: 'Cruise headquarters',
    heliport: 'Heliport',
    airoport: 'Airport',
    fan_zones: 'Fan zones',
    team_headquarters: 'Team headquarters',
    location_screens: 'Screen location',
    points_to_view_regattas: 'Spectator areas',
    control_points_teams: 'Access control points for teams and fans',
    control_points_neighbor: 'Neighboring access control points',
    control_points_services: 'Service access control points',
    buses_points: 'Coach/minibus point for teams',
    point_interest: 'Points of interest in the city',
    beyond_barcelona: 'Beyond Barcelona',
    cultural_regatta: 'Cultural regatta',
    team_headquarter: 'Team headquarter',
    team: 'Team',
    screen: 'Screen',
    zone: 'Zone',
    ca_alerts: 'Mobility alerts in Barcelona during America Cup',
    do_you_like_alerts: 'Do you want to receive mobility alerts related to the America Cup in your browser?',
    yes_thanks: 'Yes, thanks',
    not_at_the_moment: 'Not for the moment',
    wait_time: 'Maximum waiting time',
    country: 'Country',
    station_frança: 'France station',
    station_sants: 'Sants station',
    station_nord: 'Nord station',
    station_pgracia: 'P. de Gràcia station',
    station_fabra_puig: 'Fabra i Puig station',
    shuttle_bogatell: 'Forum-Bogatell shuttle',
    shuttle_rius_i_taulet: 'Shuttle bus Rius i Taulet',
    shuttle_sant_marti: 'Shuttle bus Sant Martí',
    cup_experience: 'America\'s cup experience',
    race_village: 'Race Village',
    royal_nautical_club: 'Royal Nautical Club',
    estacionament_cotxes_av_litoral: 'Car parking Av. Litoral',
    estacionament_cotxes_miquel_tarradell: 'Car parking Miquel Tarradell',
    estacionament_cotxes_nova_bocana: 'Car parking Nova Bocana',
    estacionament_cotxes_pau_vila: 'Car parking Pau Vila',
    estacionament_cotxes_pg_maritim: 'Car parking Pg. Maritim',
    estacionament_motos: 'Motorcycle parking',
    estacionament_bicis: 'Bicycle parking',
    aparcament_sant_marti: 'Car park Sant Martí',
    aparcament_rius_i_taulet: 'Car park Rius i Taulet',
    aparcament_forum: 'Car park Fòrum',
    shuttle: 'Shuttle',
    estacionament_cotxes: 'Car parking',
    activate: 'Space with different activities',
    location: 'Location',
    verregata_secondary: 'Other spectator areas',
    verregara_special: 'Main spectator areas',
    youth_and_women: 'Youth & Women',
    visualize_activate_layers_ca: 'Check American\'s Cup information',
    include_more_layers_ca: 'Add American\'s Cup transport',
    unsubscribe_notifications: 'Disable notifications',
    disabled_notifications: 'The notifications have been disabled',
    home_page_title: 'On your mobile',
    home_page_description: 'Add Com s’hi va to your home screen',
    home_page_button: 'Add',
    special_america_cup: 'America’s Cup Special',
    special_mobility_america_cup: 'Especial Mobilitat Copa Amèrica',
    bike_parking_close: 'Closed bicycle parking',
    capacity: 'Capacity',
    activate_layer_planificador: 'Bicycle lanes have been added on the map',
  },
};

/* eslint-disable */

import axios from 'axios';
import { cloneDeep } from 'lodash';
import { getApiUri } from '../api/util';
import store from '../store/index';
import { findIndex, sortBy, uniq } from 'lodash';
import api from '../api/api';

window.LineaTransportMetro = function() {

}

LineaTransportMetro.prototype.loadTransport = function () {
  api.get(`${getApiUri()}metroliniestmb/`).then(function (response) {
    // Load metro lines
    const results = response.data.features;
    for (let c in results) {
      const result = results[c];
      const linea = {
        linea: result.properties.NOM_LINIA,
        descripcio: result.properties.DESC_SERVEI,
        color: result.properties.COLOR_LINIA,
        codi: result.properties.CODI_LINIA,
        icon: 'csvicon-metro',
        type: 'metro',
        estacions: {},
        reverse: false,
        selected: false,
      }
      if (linea.linea !== 'FM' && linea.linea !== 'TM') {
        store.commit('transport/setLineas', {
          metro: {
            [result.properties.CODI_LINIA]: linea,
          },
        });
      }
    }
    const ordered = sortBy(store.state.transport.lineas.metro, [(o) => {
      return parseInt(o.linea.replace('L','').replace('S','').replace('N',''));
    }]);

    store.commit('transport/setLineas', {
      metro: null,
    });

    store.commit('transport/setLineas', {
      metro: ordered,
    });
  });
}

LineaTransportMetro.prototype.loadStation = function (linea) {
  const promise = new Promise(function(resolve, reject) {
    api.get(`${getApiUri()}metroestacionstmb/${linea}/`).then(function (response) {
      const results = response.data.features;
      for (let c in results) {
        const result = results[c];
        const estacio = {
          nom: result.properties.NOM_ESTACIO,
          codi: result.properties.CODI_ESTACIO,
          accessibilitat: (result.properties.NOM_TIPUS_ACCESSIBILITAT==='Accessible') ? true : false,
          accessos: result.properties.ACCESSOS,
          correspondencies: result.properties.CORRESPONDENCIES,
          elevador: false,
          geometry: result.geometry
        }
        for(let a in result.properties.ACCESSOS) {
          if (result.properties.ACCESSOS[a].ACCESSOS_FISICS.indexOf('Ascensor')!==-1) {
            estacio.elevador = true;
            break;
          }
        }
        var objLinea = LineaTransportMetro.getLinea(result.properties.NOM_LINIA);
        objLinea.estacions[result.properties.CODI_ESTACIO] = estacio;
        store.commit('transport/setLineas', {
          metro: {
            objLinea
          },
        });

      }
      resolve(response);
    });
  });
  return promise;
}

LineaTransportMetro.getColor = function(linea_name) {
  for (let c in store.state.transport.lineas.metro) {
    if (store.state.transport.lineas.metro[c].linea===linea_name) {
      return store.state.transport.lineas.metro[c].color;
    }
  }
  return '777777'
}

LineaTransportMetro.getLinea = function(linea_name) {
  for (let c in store.state.transport.lineas.metro) {
    if (store.state.transport.lineas.metro[c].linea===linea_name) {
      return store.state.transport.lineas.metro[c];
    }
  }
  return {};
}

LineaTransportMetro.getStation = function(linea, code) {
  for (let c in linea.estacions) {
    if (linea.estacions[c].codi===code) {
      return linea.estacions[c];
    }
  }
  return null;
}

LineaTransportMetro.displayInfo = function (feature) {
  const refs = store.state.map.isWidget === true ? window.app.$children[0].$children[0].$children[0].$refs : window.app.$children[0].$children[0].$refs;
  refs.infoPanel.initInfoPanel();

  var bus_codes = ['Convencionals','Horizontals','Proximitat','Verticals','Especials','BusTuristic','Diagonals'];
  var train_codes = ['Rodalies-BCN','Rodalies-Regionals','FGC'];
  var tram_codes = ['TRAM'];
  var funi_codes = ['Metro-Funicular'];

  let lineas = [];
  if (feature.getProperty('CORRESPONDENCIES').Metro!==undefined) {
    for(let c in feature.getProperty('CORRESPONDENCIES').Metro) {
      lineas.push(`metro/${feature.getProperty('CORRESPONDENCIES').Metro[c]}.png`);
    }
  }

  if (feature.getProperty('CORRESPONDENCIES').FGC!==undefined) {
    feature.getProperty('CORRESPONDENCIES').FGC.sort();
    for(let c in feature.getProperty('CORRESPONDENCIES').FGC) {
      lineas.push(`fgc/${feature.getProperty('CORRESPONDENCIES').FGC[c]}.png`);
    }
  }

  if (feature.getProperty('CORRESPONDENCIES')['Rodalies-BCN']!==undefined) {
    feature.getProperty('CORRESPONDENCIES')['Rodalies-BCN'].sort();
    for(let c in feature.getProperty('CORRESPONDENCIES')['Rodalies-BCN']) {
      lineas.push(`rodalies/${feature.getProperty('CORRESPONDENCIES')['Rodalies-BCN'][c]}.png`);
    }
  }
  if (feature.getProperty('CORRESPONDENCIES')['Rodalies-Regionals']!==undefined) {
    feature.getProperty('CORRESPONDENCIES')['Rodalies-Regionals'].sort();
    for(let c in feature.getProperty('CORRESPONDENCIES')['Rodalies-Regionals']) {
      lineas.push(`rodalies/${feature.getProperty('CORRESPONDENCIES')['Rodalies-Regionals'][c]}.png`);
    }
  }

  let lineas_bus = [];
  for (let b in bus_codes) {
    const bus_code = bus_codes[b];
    if (feature.getProperty('CORRESPONDENCIES')[bus_code]!==undefined) {
      for(let c in feature.getProperty('CORRESPONDENCIES')[bus_code]) {
        lineas_bus.push(feature.getProperty('CORRESPONDENCIES')[bus_code][c]);
      }
    }
  }

  let lineas_tram = [];
  if (feature.getProperty('CORRESPONDENCIES')['TRAM']!==undefined) {
    for(let c in feature.getProperty('CORRESPONDENCIES')['TRAM']) {
      lineas_tram.push(feature.getProperty('CORRESPONDENCIES')['TRAM'][c]);
    }
  }

  store.commit('infopanel/setInfoPanel', {
    title: feature.getProperty('NOM_ESTACIO'),
    linea: `metro/${feature.getProperty('NOM_LINIA')}.png`,
    nom_linea: feature.getProperty('NOM_LINIA'),
    lineas: uniq(lineas),
    lineas_bus: uniq(lineas_bus),
    lineas_tram: uniq(lineas_tram),
    type: 'metro',
    feature: feature,
    accessible: (feature.getProperty('NOM_TIPUS_ACCESSIBILITAT') === 'Accessible') ? true : false,
    datos: [

    ],
  });

  store.commit('infopanel/setInfoPanel', {
    visible: true,
  });
  refs.infoPanel.initPanel();
}

LineaTransportMetro.loadLineMap = function(linea_name, zoom, station_name) {

  const linea = LineaTransportMetro.getLinea(linea_name);

  window._mtm.push({
    'event': 'comshiva',
    'eventcategory': 'ComShiVa',
    'eventaction': 'Transport',
    'eventname': `Metro: ${linea.linea} ${linea.descripcio}`
  });
  
  const configStation = Object.assign({}, {
    ZOOM: 16,
    zoomPoint: 17,
    CENTER: new google.maps.LatLng(41.404, 2.173),
    MAP_TYPE: google.maps.MapTypeId.ROADMAP,
    REGION: 'ca',
    BUFFER_DISTANCE: 0.2, // Km
    savePosition: true,
    displayInfo: true,
    setMarkerSearch: true,
    adaptativeMarkers: true,
    iconURL: "/images/markers/publico",
    forceZoom: false,
    mapTypeControl: false,
    minZoomLevel: 12,
    maxZoomLevel: 14,
    visible: true,
  });

  configStation.callback = {
    displayInfo: LineaTransportMetro.displayInfo
  }

  configStation.style = {
    strokeColor: "#"+linea.color,
    fillColor: "#"+linea.color,
    strokeOpacity: 1,
    strokeWeight: 2,
    path: "/images/markers/publico",
    marker: "metro_"+linea.linea+".svg",
    icon: "metro_"+linea.linea+".svg",
  };

  configStation.name = "metroestacions" + linea.linea;

  const configLine = Object.assign({}, {
    ZOOM: 16,
    zoomPoint: 17,
    CENTER: new google.maps.LatLng(41.404, 2.173),
    MAP_TYPE: google.maps.MapTypeId.ROADMAP,
    REGION: 'ca',
    BUFFER_DISTANCE: 0.2, // Km
    savePosition: true,
    setMarkerSearch: true,
    adaptativeMarkers: true,
    iconURL: "/images/markers/",
    forceZoom: false,
    mapTypeControl: false,
  });

  configLine.style = {
    strokeColor: "#"+linea.color,
    fillColor: "#"+linea.color,
    strokeOpacity: 1,
    strokeWeight: 2,
  };

  configLine.name = "metrolinies" + linea.linea;

  const dataLayerStation = new DataLayer(configStation, store.state.map.mapObj);
  const dataLayerLine = new DataLayer(configLine, store.state.map.mapObj);

  const layerUrlLine = `${getApiUri()}metroliniestmb/${linea.linea}/`;
  const layerUrlStacions = `${getApiUri()}metroestacionstmb/${linea.linea}/`;

  Promise.all([
    dataLayerLine.addGeoJsonDataLayer(layerUrlLine),
    dataLayerStation.addGeoJsonDataLayer(layerUrlStacions)
  ]).then(() => {
    if (station_name!==undefined) {
      const station = LineaTransportMetro.getStation(linea, station_name);
      store.state.map.mapObj.setCenter(new google.maps.LatLng(station.geometry.coordinates[1],station.geometry.coordinates[0]));
      store.state.map.mapObj.setZoom(configStation.zoomPoint);
    }
    if (zoom==='line') {
      dataLayerLine.cropToBounds();
      if (store.state.map.mapObj.getZoom()<12) {
        store.state.map.mapObj.setZoom(12);
      }
    }
    linea.selected = true;
    linea.group = "public_transport";
    store.commit('map/addActiveLayer', linea);

    LineaTransportMetro.loadAccess(dataLayerStation);

  });
}

LineaTransportMetro.loadAccess = function(dataLayerStation) {
  const configAccess = cloneDeep(dataLayerStation.conf);
  configAccess.name = `${dataLayerStation.get('layerName')}_accessos`;
  configAccess.minZoomLevel = 16;
  configAccess.maxZoomLevel = 22;
  configAccess.style.path =  '/images/markers/publico';
  // const img = `metro_${result.properties.NOM_LINIA}`;
  // configAccess.style.marker = 'logo-metro.png';
  // configAccess.style.icon = 'logo-metro.png';
  const dataLayerAccess = new DataLayer(configAccess, store.state.map.mapObj);
  const featarr = dataLayerStation.getAllFeatures();
  var accesosFeatures = [];
  for (var c in featarr) {
    var feature = featarr[c];
    for (var a in feature.getProperty('ACCESSOS')) {
      var acceso = feature.getProperty('ACCESSOS')[a];
      var latLng = new google.maps.LatLng({lat: acceso.COORDENADES[1], lng: acceso.COORDENADES[0]});
      var properties = acceso;
      properties.ACCESSOS_FISICS = acceso.ACCESSOS_FISICS.join(', ');
      properties.NOM_LINIA = feature.getProperty('NOM_LINIA');
      properties.NOM_ESTACIO = feature.getProperty('NOM_ESTACIO');
      for (var corr in feature.getProperty('CORRESPONDENCIES')) {
        if (corr === 'FGC' || corr === 'Rodalies-BCN' || corr === 'Rodalies-Regionals') {
          properties.CORRESPONDENCIES[corr] = feature.getProperty('CORRESPONDENCIES')[corr];
        }
      }
      var accesoFeature = new google.maps.Data.Feature({geometry: latLng, properties: properties});
      accesosFeatures.push(accesoFeature);
    }
  }
  DataLayer.layers[dataLayerAccess.get('layerName')] = dataLayerAccess;
  dataLayerAccess.removeAllFeatures();
  dataLayerAccess.addFeatures(accesosFeatures);
  dataLayerAccess.setOriginalFeatures(dataLayerAccess.getAllFeatures());
  dataLayerAccess.loaded = true;
  dataLayerAccess.setVisibilityByZoom();
}

<template>
    <div class="loader-container">
        <div class="text-center">
            <div class="spinner"></div>
            <div class="text-center d-block" v-html="$t('loader_message')"></div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'Loader',
  methods: {
    show() {
      this.$el.style.display = 'flex';
    },
    hide() {
      this.$el.style.display = 'none';
    },
  },
};
</script>
